import { adminLoginMutation } from "./auth/mutations/adminLoginMutation";
import { adminLogoutMutation } from "./auth/mutations/adminLogoutMutation";
import { adminPasswordResetMutation } from "./auth/mutations/adminPasswordResetMutation";
import { resetAdminPasswordMutation } from "./auth/mutations/resetAdminPasswordMutation";
import { updateAdminMutation } from "./admin/mutations/updateAdminMutation";
import { createAdminMutation } from "./admin/mutations/createAdminMutation";
import { updateAdminMeMutation } from "./admin/mutations/updateAdminMeMutation";
import { createContentMutation } from "./admin/mutations/createContentMutation";
import { updateContentMutation } from "./admin/mutations/updateContentMutation";
import { uploadDocumentMutation } from "./admin/mutations/uploadDocumentMutation";
import { removeDocumentMutation } from "./admin/mutations/removeDocumentMutation";
import { updateCategoryMutation } from "./admin/mutations/updateCategoryMutation";
import { createGalleryMutation } from "./admin/mutations/createGalleryMutation";
import { updateGalleryMutation } from "./admin/mutations/updateGalleryMutation";
import { deleteGalleryMutation } from "./admin/mutations/deleteGalleryMutation";
import { attachDocumentMutation } from "./admin/mutations/attachDocument";
import { detachDocumentMutation } from "./admin/mutations/detachDocument";
import { deleteContentMutation } from "./admin/mutations/deleteContent";
import { updateProductBrandMutation } from "./admin/mutations/updateProductBrandMutation";
import { updateProductCategoryMutation } from "./admin/mutations/updateProductCategoryMutation";
import { updateProductMutation } from "./admin/mutations/updateProductMutation";
import { createProductListMutation } from "./admin/mutations/createProductListMutation";
import { updateProductListMutation } from "./admin/mutations/updateProductListMutation";
import { createBadgeMutation } from "./admin/mutations/createBadgeMutation";
import { updateBadgeMutation } from "./admin/mutations/updateBadgeMutation";
import { createRoleMutation } from "./admin/mutations/createRoleMutation";
import { updateRoleMutation } from "./admin/mutations/updateRoleMutation";
import { deleteRoleMutation } from "./admin/mutations/deleteRoleMutation";
import { createBannerMutation } from "./admin/mutations/createBannerMutation";
import { updateBannerMutation } from "./admin/mutations/updateBannerMutation";
import { updateUserMutation } from "./admin/mutations/updateUserMutation";
import { createCategoryMutation } from "./admin/mutations/createCategoryMutation";
import { createPersonMutation } from "./admin/mutations/createPersonMutation";
import { updatePersonMutation } from "./admin/mutations/updatePersonMutation";
import { deletePersonMutation } from "./admin/mutations/deletePersonMutation";
import { createBenefitMutation } from "./admin/mutations/createBenefitMutation";
import { deleteBenefitMutation } from "./admin/mutations/deleteBenefitMutation";
import { updateBenefitMutation } from "./admin/mutations/updateBenefitMutation";
import { createDepartmentMutation } from "./admin/mutations/createDepartmentMutation";
import { deleteDepartmentMutation } from "./admin/mutations/deleteDepartmentMutation";
import { updateDepartmentMutation } from "./admin/mutations/updateDepartmentMutation";
import { createDivisionMutation } from "./admin/mutations/createDivisionMutation";
import { deleteDivisionMutation } from "./admin/mutations/deleteDivisionMutation";
import { updateDivisionMutation } from "./admin/mutations/updateDivisionMutation";
import { createSuccessStoryMutation } from "./admin/mutations/createSuccessStoryMutation";
import { deleteSuccessStoryMutation } from "./admin/mutations/deleteSuccessStoryMutation";
import { updateSuccessStoryMutation } from "./admin/mutations/updateSuccessStoryMutation";
import { createRedirectionMutation } from "./admin/mutations/createRedirectionMutation";
import { deleteRedirectionMutation } from "./admin/mutations/deleteRedirectionMutation";
import { updateRedirectionMutation } from "./admin/mutations/updateRedirectionMutation";
import { updateMailTemplateMutation } from "./admin/mutations/updateMailTemplateMutation";
import { previewMailTemplateMutation } from "./admin/mutations/previewMailTemplateMutation";
import { createPageMutation } from "./admin/mutations/createPageMutation";
import { updatePageMutation } from "./admin/mutations/updatePageMutation";
import { updateShippingMethodMutation } from "./admin/mutations/updateShippingMethodMutation";
import { updateOrderMutation } from "./admin/mutations/updateOrderMutation";
import { closeOrderMutation } from "./admin/mutations/closeOrderMutation";
import { stornoInvoiceMutation } from "./admin/mutations/stornoInvoiceMutation";
import { refundPaymentMutation } from "./admin/mutations/refundPaymentMutation";
import { deleteOrderMutation } from "./admin/mutations/deleteOrderMutation";
import { createWebsiteNotificationMutation } from "./admin/mutations/createWebsiteNotificationMutation";
import { updateWebsiteNotificationMutation } from "./admin/mutations/updateWebsiteNotificationMutation";
import { updateShipmentStatusMutation } from "./admin/mutations/updateShipmentStatusMutation";
import { deletePageMutation } from "./admin/mutations/deletePageMutation";
import { deleteBannerMutation } from "./admin/mutations/deleteBannerMutation";
import { deleteBadgeMutation } from "./admin/mutations/deleteBadgeMutation";
import { deleteWebsiteNotificationMutation } from "./admin/mutations/deleteWebsiteNotificationMutation";
import { modifyOrderMutation } from "./admin/mutations/modifyOrderMutation";
import { deleteProductListMutation } from "./admin/mutations/deleteProductListMutation";
import { deleteAdminMutation } from "./admin/mutations/deleteAdminMutation";
import { deleteUserMutation } from "./admin/mutations/deleteUserMutation";
import { updatePaymentMethodMutation } from "./admin/mutations/updatePaymentMethodMutation";
import { adminLoginAsUserMutation } from "./admin/mutations/adminLoginAsUserMutation";
import { setCalendarDayMutation } from "./admin/mutations/setCalendarDayMutation";
import { resetCalendarDayMutation } from "./admin/mutations/resetCalendarDayMutation";
import { updateSettingMutation } from "./admin/mutations/updateSettingMutation";
import { updateProductParamMutation } from "./admin/mutations/updateProductParamMutation";
import { createMaintenanceMutation } from "./admin/mutations/createMaintenanceMutation";
import { updateMaintenanceMutation } from "./admin/mutations/updateMaintenanceMutation";
import { deleteMaintenanceMutation } from "./admin/mutations/deleteMaintenanceMutation";
import { createMenuMutation } from "./admin/mutations/createMenuMutation";
import { updateMenuMutation } from "./admin/mutations/updateMenuMutation";
import { deleteMenuMutation } from "./admin/mutations/deleteMenuMutation";
import { testMailTemplateMutation } from "./admin/mutations/testMailTemplateMutations";
import { testMailTemplateAllMutation } from "./admin/mutations/testMailTemplateAllMutations";
import { updateTodoMutation } from "./admin/mutations/updateTodoMutation";
import { createProductBundleMutation } from "./admin/mutations/createProductBundleMutation";
import { updateProductBundleMutation } from "./admin/mutations/updateProductBundleMutation";
import { createProductVariantMutation } from "./admin/mutations/createProductVariantMutation";
import { updateProductVariantMutation } from "./admin/mutations/updateProductVariantMutation";
import { orderProductCategoryMutation } from "./admin/mutations/orderProductCategoryMutation";
import { createFulfillmentMutation } from "./admin/mutations/createFulfillmentMutation";
import { updateFulfillmentMutation } from "./admin/mutations/updateFulfillmentMutation";
import { deleteFulfillmentMutation } from "./admin/mutations/deleteFulfillmentMutation";
import { clearCacheMutation } from "./admin/mutations/clearCacheMutation";
import { clearProductCacheMutation } from "./admin/mutations/clearProductCacheMutation";
import { clearProductCategoryCacheMutation } from "./admin/mutations/clearProductCategoryCacheMutation";
import { clearProductPriceCacheMutation } from "./admin/mutations/clearProductPriceCacheMutation";
import { queueProductIndexMutation } from "./admin/mutations/queueProductIndexMutation";
import { indexProductMutation } from "./admin/mutations/indexProductMutation";
import { createCampaignMutation } from "./admin/mutations/createCampaignMutation";
import { updateCampaignMutation } from "./admin/mutations/updateCampaignMutation";
import { deleteCampaignMutation } from "./admin/mutations/deleteCampaignMutation";
import { deleteProductVariantMutation } from "./admin/mutations/deleteProductVariantMutation";
import { updateProductGratisMutation } from "./admin/mutations/updateProductGratisMutation";
import { replaceCardToVirtualMutation } from "./admin/mutations/replaceCardToVirtualMutation";
import { correctInvoiceMutation } from "./admin/mutations/correctInvoiceMutation";
import { createGiftCardOrderPrizeMutation } from "./admin/mutations/createGiftCardOrderPrizeMutation";
import { createGiftCardOrderCompanyMutation } from "./admin/mutations/createGiftCardOrderCompanyMutation";
import { resendShipmentMutation } from "./admin/mutations/resendShipmentMutation";
import { resendGiftCardMutation } from "./admin/mutations/resendGiftCardMutation";

export const Mutations = {
    adminLogin: adminLoginMutation,
    adminPasswordReset: adminPasswordResetMutation,
    resetAdminPassword: resetAdminPasswordMutation,
    updateAdminMe: updateAdminMeMutation,
    adminLogout: adminLogoutMutation,

    createAdmin: createAdminMutation,
    updateAdmin: updateAdminMutation,

    createContent: createContentMutation,
    updateContent: updateContentMutation,
    deleteContent: deleteContentMutation,

    createCategory: createCategoryMutation,
    updateCategory: updateCategoryMutation,

    createGallery: createGalleryMutation,
    updateGallery: updateGalleryMutation,
    deleteGallery: deleteGalleryMutation,

    uploadDocument: uploadDocumentMutation,
    removeDocument: removeDocumentMutation,
    attachDocument: attachDocumentMutation,
    detachDocument: detachDocumentMutation,

    updateProductCategory: updateProductCategoryMutation,
    updateProduct: updateProductMutation,

    updateProductGratis: updateProductGratisMutation,

    updateProductBrand: updateProductBrandMutation,

    createProductList: createProductListMutation,
    updateProductList: updateProductListMutation,

    createBadge: createBadgeMutation,
    updateBadge: updateBadgeMutation,
    deleteBadge: deleteBadgeMutation,

    createRole: createRoleMutation,
    updateRole: updateRoleMutation,
    deleteRole: deleteRoleMutation,

    createBanner: createBannerMutation,
    updateBanner: updateBannerMutation,
    deleteBanner: deleteBannerMutation,
    updateUser: updateUserMutation,

    createPerson: createPersonMutation,
    updatePerson: updatePersonMutation,
    deletePerson: deletePersonMutation,

    createBenefit: createBenefitMutation,
    updateBenefit: updateBenefitMutation,
    deleteBenefit: deleteBenefitMutation,

    createDepartment: createDepartmentMutation,
    updateDepartment: updateDepartmentMutation,
    deleteDepartment: deleteDepartmentMutation,

    createDivision: createDivisionMutation,
    updateDivision: updateDivisionMutation,
    deleteDivision: deleteDivisionMutation,

    createSuccessStory: createSuccessStoryMutation,
    updateSuccessStory: updateSuccessStoryMutation,
    deleteSuccessStory: deleteSuccessStoryMutation,

    createRedirection: createRedirectionMutation,
    updateRedirection: updateRedirectionMutation,
    deleteRedirection: deleteRedirectionMutation,

    updateMailTemplate: updateMailTemplateMutation,
    previewMailTemplate: previewMailTemplateMutation,

    createPage: createPageMutation,
    updatePage: updatePageMutation,
    deletePage: deletePageMutation,

    updateShippingMethod: updateShippingMethodMutation,

    updateOrder: updateOrderMutation,
    closeOrder: closeOrderMutation,
    stornoInvoice: stornoInvoiceMutation,
    refundPayment: refundPaymentMutation,
    deleteOrder: deleteOrderMutation,
    correctInvoice: correctInvoiceMutation,
    resendShipment: resendShipmentMutation,

    createWebsiteNotification: createWebsiteNotificationMutation,
    updateWebsiteNotification: updateWebsiteNotificationMutation,
    deleteWebsiteNotification: deleteWebsiteNotificationMutation,

    updateShipmentStatus: updateShipmentStatusMutation,

    modifyOrder: modifyOrderMutation,

    deleteProductList: deleteProductListMutation,
    deleteAdmin: deleteAdminMutation,
    deleteUser: deleteUserMutation,

    updatePaymentMethod: updatePaymentMethodMutation,
    adminLoginAsUser: adminLoginAsUserMutation,

    setCalendarDay: setCalendarDayMutation,
    resetCalendarDay: resetCalendarDayMutation,
    updateSetting: updateSettingMutation,

    updateProductParam: updateProductParamMutation,

    createMaintenance: createMaintenanceMutation,
    updateMaintenance: updateMaintenanceMutation,
    deleteMaintenance: deleteMaintenanceMutation,

    createMenu: createMenuMutation,
    updateMenu: updateMenuMutation,
    deleteMenu: deleteMenuMutation,

    testMailTemplate: testMailTemplateMutation,
    testMailTemplateAll: testMailTemplateAllMutation,

    updateTodo: updateTodoMutation,

    createProductBundle: createProductBundleMutation,
    updateProductBundle: updateProductBundleMutation,

    createProductVariant: createProductVariantMutation,
    updateProductVariant: updateProductVariantMutation,
    deleteProductVariant: deleteProductVariantMutation,

    orderProductCategory: orderProductCategoryMutation,

    createFulfillment: createFulfillmentMutation,
    updateFulfillment: updateFulfillmentMutation,
    deleteFulfillment: deleteFulfillmentMutation,

    clearCache: clearCacheMutation,
    clearProductCache: clearProductCacheMutation,
    clearProductCategoryCache: clearProductCategoryCacheMutation,
    clearProductPriceCache: clearProductPriceCacheMutation,

    queueProductIndex: queueProductIndexMutation,
    indexProduct: indexProductMutation,

    createCampaign: createCampaignMutation,
    updateCampaign: updateCampaignMutation,
    deleteCampaign: deleteCampaignMutation,

    replaceCardToVirtual: replaceCardToVirtualMutation,

    createGiftCardOrderPrize: createGiftCardOrderPrizeMutation,
    createGiftCardOrderCompany: createGiftCardOrderCompanyMutation,

    resendGiftCard: resendGiftCardMutation,
};
