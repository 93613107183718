import React, { useCallback, useEffect } from "react";
import { Form, FormType } from "Components/Form";
import { Button, Grid, Icon, TextField, Typography, Box, FormControl, RadioGroup, FormControlLabel, Radio, Tooltip } from "@bigfish/admin-ui/core";
import { Field, FieldProps, FormikProps } from "formik";
import { OrderInput, Order, Order_shipping_address, OrderItem, BankTransferStatus, ShipmentStatus, AdminNote, InvoiceTypeEnum, PermissionType } from "Api/graphql/admin/types";
import { I18n } from "Src/i18n/I18n";
import { FullscreenLoader, PageCard, SavePanel, TabsComponent, TitleBar } from "@bigfish/admin-ui/components";
import { Path } from "Utils/Path";
import { useHistory } from "react-router-dom";
import { DateFormat, DateUtils } from "Utils/DateUtils";
import "./styles.css";
import { NumberUtils } from "Utils/NumberUtils";
import { FunctionalButton } from "Components/FunctionalButton";
import UnControlledDataGrid from "Components/DataGrid/UCDataGrid";
import { NameWithAvatar } from "Components/NameWithAvatar/NameWithAvatar";
import { DataSource } from "Components/DataGrid/DataGridUtils";
import LogListDataGrid from "Pages/LogList/LogListDataGrid";
import { useSelector } from "react-redux";
import { ApplicationState } from "Redux/rootReducer";
import { AuthSelectors } from "Redux/selectors/authSelectors";
import { OrderFormProductsTab } from "./OrderFormProductsTab";
import { OrderFormPaymentsTab } from "./OrderFormPaymentsTab";
import { OrderUtils } from "Utils/OrderUtils";
import { AttachMoney, Block, DeleteForever, Healing, Replay, Undo } from "@bigfish/admin-ui/icons";

export type DisplayedContainer = {
    id: number;
    title: string;
};

export type OrderFormValues = OrderInput;

type ComponentProps = {
    formType: FormType;
    formProps: FormikProps<OrderFormValues>;
    order?: Order;
    canEdit: boolean;
    onCloseOrderClick: () => void;
    onDeleteClick: () => void;
    onCorrectionClick: () => void;
    onStornoClick: () => void;
    onRefundClick: () => void;
    onRetryClick: () => void;
    onModifyOrderSubmit: (orderItems: OrderItem[]) => Promise<boolean>;
    shipmentStatuses: ShipmentStatus[];
    refreshIndex: number;
};

type Props = ComponentProps;

export const OrderForm = (props: Props) => {
    const history = useHistory();
    const hasInvoiceUpdatePermission = useSelector((state: ApplicationState) => AuthSelectors.getPermissions(state.auth)).includes(PermissionType.invoice_update);
    const hasStornoPermission = useSelector((state: ApplicationState) => AuthSelectors.getPermissions(state.auth)).includes(PermissionType.order_storno);
    const hasRefundPermission = useSelector((state: ApplicationState) => AuthSelectors.getPermissions(state.auth)).includes(PermissionType.order_refund);
    const hasClosePermission = useSelector((state: ApplicationState) => AuthSelectors.getPermissions(state.auth)).includes(PermissionType.order_close);
    const hasDeletePermission = useSelector((state: ApplicationState) => AuthSelectors.getPermissions(state.auth)).includes(PermissionType.order_delete);
    const canShipmentBeRetried = !!props.order?.shipment?.can_retry;
    const isSuperadmin = useSelector((state: ApplicationState) => AuthSelectors.isSuperadmin(state.auth));

    const onCancelClick = useCallback(() => {
        history.push(Path.orderList);
    }, [history]);

    const [adminNote, setAdminNote] = React.useState<string>("");
    const [formTab, setFormTab] = React.useState<number>(0);

    useEffect(() => {
        setAdminNote("");
    }, [props.refreshIndex]);

    const changeTab = (_event: React.ChangeEvent<{}>, newValue: number) => {
        setFormTab(newValue);
    };

    const renderField = (i18nLabelId: string, value: JSX.Element | string | null) => {
        return (
            <div style={{ wordWrap: "break-word", whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
                <Typography color="textSecondary" gutterBottom>
                    {I18n.formatMessage({ id: `pages.order.form.label.${i18nLabelId}` })}
                </Typography>
                <Typography variant="subtitle1">{value}</Typography>
            </div>
        );
    };

    const renderFunctionalButton = (onClick: () => void, icon: JSX.Element, label: string, disabled?: boolean) => {
        const button = (
            <div>
                <FunctionalButton onClick={onClick} icon={icon} label={label} disabled={disabled} />
            </div>
        );

        return disabled ? <Tooltip title={I18n.formatMessage({ id: "pages.order.form.functionDisabled" })}>{button}</Tooltip> : button;
    };

    const renderRightButtonComponent = () => {
        if (!props.order) return null;

        const hasNormalInvoice = props.order.invoices.find(invoice => invoice.type === InvoiceTypeEnum.normal);
        const noStornoInvoice = !props.order.invoices.find(invoice => invoice.type === InvoiceTypeEnum.storno);

        return (
            <div style={{ display: "flex" }}>
                {(isSuperadmin || hasStornoPermission) &&
                    renderFunctionalButton(props.onStornoClick, <Undo />, I18n.formatMessage({ id: "pages.order.form.buttons.storno" }), !props.order.can_storno)}
                {(isSuperadmin || hasRefundPermission) &&
                    renderFunctionalButton(props.onRefundClick, <AttachMoney />, I18n.formatMessage({ id: "pages.order.form.buttons.refund" }), !props.order.can_refund)}
                {(isSuperadmin || hasInvoiceUpdatePermission) &&
                    renderFunctionalButton(
                        props.onCorrectionClick,
                        <Healing />,
                        I18n.formatMessage({ id: "pages.order.form.buttons.correctInvoice" }),
                        !hasNormalInvoice || !noStornoInvoice
                    )}
                {(isSuperadmin || hasClosePermission) &&
                    renderFunctionalButton(props.onCloseOrderClick, <Block />, I18n.formatMessage({ id: "pages.order.form.buttons.closeOrder" }), !props.order.status)}
                {(isSuperadmin || hasDeletePermission) &&
                    renderFunctionalButton(props.onDeleteClick, <DeleteForever />, I18n.formatMessage({ id: "common.delete" }), !props.order.can_be_deleted)}
                {renderFunctionalButton(props.onRetryClick, <Replay />, I18n.formatMessage({ id: "pages.order.form.buttons.retry" }), !canShipmentBeRetried)}
            </div>
        );
    };

    const renderShippingAddress = (shipping_address: Order_shipping_address | null) => {
        if (!shipping_address) return null;
        const shippingAddress = [shipping_address?.zip_code, shipping_address?.city, shipping_address?.address_line1, shipping_address?.address_line2]
            .filter(address => address !== null && address !== "")
            .join(" ");

        const isShippingAddressPresent = shipping_address?.zip_code || shipping_address?.city || shipping_address?.address_line1 || shipping_address?.address_line2;

        return (
            <div>
                <Typography color="textSecondary" gutterBottom>
                    {I18n.formatMessage({ id: "pages.order.form.label.shippingAddress" })}
                    {isShippingAddressPresent && (
                        <a
                            className="order-form-show-on-map"
                            href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(shippingAddress)}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {I18n.formatMessage({ id: "pages.order.form.showOnMap" })}
                        </a>
                    )}
                </Typography>
                <Typography variant="subtitle1">{isShippingAddressPresent ? shippingAddress : "-"}</Typography>
            </div>
        );
    };

    const getShippingTime = () => {
        if (!props.order) return "-";

        if (props.order.delivery_time_window) {
            const { date, weekday, interval } = props.order.delivery_time_window;

            return [date ? DateUtils.format(DateUtils.parseISO(date), DateFormat.default) : "", weekday, interval].filter(Boolean).join(", ") || "-";
        }

        if (props.order.delivery_day_expected) {
            return props.order.delivery_day_expected;
        }

        return null;
    };

    const getAdminNoteColumns = () => {
        return [
            { name: "created_at", header: I18n.formatMessage({ id: "pages.order.form.adminNotes.createdAtColumn" }), defaultWidth: 200 },
            { name: "note", sortable: true, header: I18n.formatMessage({ id: "pages.order.form.adminNotes.noteColumn" }), defaultFlex: 1, minWidth: 350 },
            {
                name: "created_by",
                sortable: true,
                header: I18n.formatMessage({ id: "pages.order.form.adminNotes.createdByColumn" }),
                defaultWidth: 200,
                render: ({ data }: { data: AdminNote }) => {
                    return data.created_by ? <NameWithAvatar identifier={data.created_by.id ?? Math.floor(Math.random() * 1000)} value={data.created_by?.name || ""} /> : null;
                },
            },
        ];
    };

    const getAdminNoteData = (): DataSource<AdminNote> => {
        return { data: [...props.order!.admin_notes].reverse(), count: props.order!.admin_notes.length };
    };

    const renderBasicDataTab = (): JSX.Element | null => {
        if (!props.order) return null;

        const { shipping_address, billing_address } = props.order;

        return (
            <div>
                <Box mt="30px" />

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {renderField("increment_id", `${props.order.increment_id}`)}
                    </Grid>
                    <Grid item xs={6}>
                        {renderField("created_at", `${DateUtils.format(DateUtils.parseISO(props.order.created_at), DateFormat.yyyymmddhhmm)}`)}
                    </Grid>
                </Grid>

                <Box mt="30px" />

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {renderField("userName", `${props.order.contact_fullname ?? "-"}`)}
                    </Grid>
                    <Grid item xs={6}>
                        {renderField("email", `${props.order.email || "-"}`)}
                    </Grid>
                </Grid>

                <Box mt="30px" />

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {renderField("phoneNumber", `${props.order.phone_number}`)}
                    </Grid>
                    <Grid item xs={6}>
                        {renderField("status", `${props.order.status.title}`)}
                    </Grid>
                </Grid>

                <Box mt="30px" />

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {renderField("logisticsStatus", `${props.order?.shipment?.status ? props.shipmentStatuses.find(s => s.id === props.order?.shipment?.status)?.title : "-"}`)}
                    </Grid>
                    <Grid item xs={6}>
                        {renderField("paymentStatus", `${props.order.payment?.status?.title || "-"}`)}
                    </Grid>
                </Grid>

                <Box mt="30px" />

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {renderField("invoiceStatus", `${props.order.invoice_status?.title || "-"}`)}
                    </Grid>
                    <Grid item xs={6}>
                        {renderField(
                            "shippingMethod",
                            <>
                                {`${props.order.shipping_method.name}`}
                                <br />
                                {props.order.pickup_point?.id && (
                                    <div>{`${I18n.formatMessage({ id: "pages.order.form.pickupPointId" }, { pickupPointId: props.order.pickup_point.id })}`}</div>
                                )}
                                {props.order.pickup_point?.address && (
                                    <div>{`${I18n.formatMessage({ id: "pages.order.form.pickupPointAddress" }, { pickupPointAddress: props.order.pickup_point.address })}`}</div>
                                )}
                            </>
                        )}
                    </Grid>
                </Grid>

                <Box mt="30px" />

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {renderField("shippingTime", getShippingTime())}
                    </Grid>
                    <Grid item xs={6}>
                        {renderField(
                            "deliveryTime",
                            `${props.order.shipment?.delivered_at ? DateUtils.format(DateUtils.parseISO(props.order.shipment.delivered_at), DateFormat.yyyymmddhhmm) || "-" : "-"}`
                        )}
                    </Grid>
                </Grid>

                <Box mt="30px" />

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {renderField("shippingNote", `${props.order.shipping_note ?? "-"}`)}
                    </Grid>
                    <Grid item xs={6}>
                        {renderShippingAddress(shipping_address)}
                    </Grid>
                </Grid>

                <Box mt="30px" />

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {renderField("billingAddress", billing_address ? OrderUtils.renderAddress(billing_address) : null)}
                    </Grid>
                    <Grid item xs={6}>
                        {renderField("paymentMethodName", `${props.order.payment_method.name || "-"}`)}
                    </Grid>
                </Grid>

                <Box mt="30px" />

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {renderField("grand_total", `${props.order.grand_total ? I18n.formatCurrency(props.order.grand_total) : "-"}`)}
                    </Grid>
                    <Grid item xs={6}>
                        {renderField("giftCardPaid", `${props.order.gift_card_payment ? I18n.formatCurrency(props.order.gift_card_payment) : "-"}`)}
                    </Grid>
                </Grid>

                <Box mt="30px" />

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {renderField("anum", `${props.order.payment?.transaction_latest?.anum || "-"}`)}
                    </Grid>
                    <Grid item xs={6}>
                        {renderField("onlineAmountRefunded", `${props.order.payment?.amount_refunded ? I18n.formatCurrency(props.order.payment.amount_refunded) : "-"}`)}
                    </Grid>
                </Grid>

                <Box mt="30px" />

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {renderField(
                            "giftCardAmountRefunded",
                            `${props.order.gift_cards.length > 0 ? I18n.formatCurrency(props.order.gift_cards.reduce((sum, iterated) => sum + iterated.voided_amount, 0)) : "-"}`
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        <Typography color="textSecondary" gutterBottom>
                            {I18n.formatMessage({ id: "pages.order.form.label.downloadInvoices" })}
                        </Typography>
                        <Typography variant="subtitle1">
                            {props.order.invoices && props.order.invoices.length > 0
                                ? props.order.invoices.map(i => {
                                      return i.url ? (
                                          <div>
                                              <a key={i.invoice_number} href={i.url} target="_blank" rel="noreferrer">
                                                  {i.invoice_number}
                                              </a>
                                          </div>
                                      ) : null;
                                  })
                                : "-"}
                        </Typography>
                    </Grid>
                </Grid>

                <Box mt="30px" />

                {props.order.payment_method.id === "bank_transfer" && (
                    <div>
                        <FormControl component="fieldset">
                            <RadioGroup
                                aria-label="gender"
                                name="gender1"
                                value={props.formProps.values.bank_transfer_status}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    props.formProps.setFieldValue("bank_transfer_status", event.currentTarget.value);
                                    props.formProps.setFieldTouched("bank_transfer_status", true);
                                }}
                            >
                                <FormControlLabel value={BankTransferStatus.paid} control={<Radio />} label={I18n.formatMessage({ id: "enums.bankTransferStatus.paid" })} />
                                <FormControlLabel
                                    value={BankTransferStatus.partially_paid}
                                    control={<Radio />}
                                    label={I18n.formatMessage({ id: "enums.bankTransferStatus.partially_paid" })}
                                />
                            </RadioGroup>
                        </FormControl>

                        <Box mt="30px" />

                        <Field name="bank_transfer_paid">
                            {({ field, meta }: FieldProps) => (
                                <TextField
                                    type="number"
                                    label={I18n.formatMessage({ id: "pages.order.form.bankTransferPaidLabel" })}
                                    fullWidth
                                    variant="outlined"
                                    required
                                    helperText={Form.getHelperText(meta, "")}
                                    error={meta.touched && !!meta.error}
                                    {...field}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        if (NumberUtils.isStringNumber(e.currentTarget.value)) {
                                            field.onChange(e);
                                        }
                                    }}
                                />
                            )}
                        </Field>
                    </div>
                )}

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {renderField("timeWindowIntervalLength", `${props.order.delivery_time_window?.interval_length ?? "-"}`)}
                    </Grid>
                    <Grid item xs={6}>
                        {renderField("user_agent_type", `${props.order.user_agent_type}`)}
                    </Grid>
                </Grid>

                <Box mt="30px" />

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {renderField("app_version", `${props.order.app_version || "-"}`)}
                    </Grid>
                    <Grid item xs={6}>
                        <div style={{ wordWrap: "break-word", whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
                            <Typography color="textSecondary" gutterBottom>
                                {I18n.formatMessage({ id: "pages.order.form.label.tracking_numbers" })}
                            </Typography>
                            {(props.order.shipment?.tracking_numbers ?? []).length > 0
                                ? props.order.shipment?.tracking_numbers.map(tn => (
                                      <Typography key={tn} variant="subtitle1">
                                          {tn}
                                      </Typography>
                                  ))
                                : "-"}
                        </div>
                    </Grid>
                </Grid>

                <Box mt="30px" />

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {renderField("no_package", `${props.order.no_package === null ? "-" : I18n.formatMessage({ id: `common.${props.order.no_package ? "yes" : "no"}` })}`)}
                    </Grid>
                </Grid>

                <Box mt="30px" />

                <PageCard.Heading title={I18n.formatMessage({ id: "pages.order.form.adminNoteHeading" })} />

                <Form formProps={props.formProps}>
                    <Grid container spacing={3}>
                        <Grid item xs={9}>
                            <TextField
                                type="text"
                                label={I18n.formatMessage({ id: "pages.order.form.adminNoteLabel" })}
                                fullWidth
                                variant="outlined"
                                required
                                value={adminNote}
                                disabled={!props.canEdit || !!props.formProps.touched.admin_note}
                                onChange={e => setAdminNote(e.currentTarget.value)}
                            />
                        </Grid>
                        <Grid item xs={3} className="order-form-admin-notes-add">
                            <Button
                                variant="outlined"
                                color="primary"
                                startIcon={<Icon className={props.formProps.touched.admin_note ? "fas fa-check" : "fa fa-plus-circle"} />}
                                size="medium"
                                onClick={() => {
                                    props.formProps.setFieldValue("admin_note", adminNote);
                                    props.formProps.setFieldTouched("admin_note", true);
                                }}
                                disabled={!adminNote || !!props.formProps.touched.admin_note}
                            >
                                {I18n.formatMessage({ id: `${props.formProps.touched.admin_note ? "pages.order.form.adminNoteAdded" : "common.add"}` })}
                            </Button>
                        </Grid>
                    </Grid>

                    <Box mt="30px" />

                    <UnControlledDataGrid<AdminNote>
                        rowHeight={50}
                        columns={getAdminNoteColumns()}
                        dataSource={async (): Promise<DataSource<AdminNote>> => {
                            return getAdminNoteData();
                        }}
                        style={{ maxHeight: 800 }}
                        pagination={false}
                    />

                    <SavePanel>
                        <Grid container justify="space-between">
                            <Button variant="outlined" color="primary" onClick={onCancelClick}>
                                {I18n.formatMessage({ id: "common.cancel" })}
                            </Button>
                            <Button
                                type="submit"
                                startIcon={<Icon className="fas fa-save" />}
                                variant="contained"
                                color="secondary"
                                disabled={!props.formProps.dirty || !props.formProps.isValid}
                            >
                                {I18n.formatMessage({ id: "common.save" })}
                            </Button>
                        </Grid>
                    </SavePanel>
                    <FullscreenLoader visible={props.formProps.isSubmitting} />
                </Form>

                <Box mt="30px" />

                {props.order.shipment?.tracking_url && (
                    <a href={props.order.shipment.tracking_url} target="_new">
                        <Button variant="contained" color="secondary">
                            {I18n.formatMessage({ id: "pages.order.form.trackingButtonLabel" })}
                        </Button>
                    </a>
                )}
            </div>
        );
    };

    return (
        <div>
            {!!props.order?.increment_id && (
                <TitleBar
                    title={I18n.formatMessage({ id: "pages.order.form.title" }, { orderId: props.order.increment_id })}
                    rightButtonsComponent={renderRightButtonComponent()}
                />
            )}

            <PageCard.Container>
                <TabsComponent
                    ariaLabel="order-form-tabs"
                    tabs={[
                        {
                            label: I18n.formatMessage({ id: "pages.order.form.tabs.data" }),
                            id: "order-form-data-tab",
                            content: renderBasicDataTab(),
                        },
                        {
                            label: I18n.formatMessage({ id: "pages.order.form.tabs.products" }),
                            id: "order-form-products-tab",
                            content: <OrderFormProductsTab order={props.order} onModifyOrderSubmit={props.onModifyOrderSubmit} />,
                        },
                        {
                            label: I18n.formatMessage({ id: "pages.order.form.tabs.payments" }),
                            id: "order-form-payments-tab",
                            content: <OrderFormPaymentsTab order={props.order} />,
                        },
                        {
                            label: I18n.formatMessage({ id: "pages.order.form.tabs.log" }),
                            id: "order-form-log-tab",
                            content: (
                                <Box mt="30px">
                                    <LogListDataGrid incrementId={props.order?.increment_id} />
                                </Box>
                            ),
                        },
                    ]}
                    currentTab={formTab}
                    changeTab={changeTab}
                />
            </PageCard.Container>
        </div>
    );
};
