/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: adminLoginAsUser
// ====================================================

export interface adminLoginAsUser_adminLoginAsUser {
  __typename: "AdminLoginAuthResult";
  token: string;
}

export interface adminLoginAsUser {
  adminLoginAsUser: adminLoginAsUser_adminLoginAsUser;
}

export interface adminLoginAsUserVariables {
  id: number;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: attachDocument
// ====================================================

export interface attachDocument_attachDocument_GalleryContent {
  __typename: "GalleryContent" | "VideoContent";
}

export interface attachDocument_attachDocument_TextContent {
  __typename: "TextContent";
  id: string;
}

export type attachDocument_attachDocument = attachDocument_attachDocument_GalleryContent | attachDocument_attachDocument_TextContent;

export interface attachDocument {
  attachDocument: attachDocument_attachDocument | null;
}

export interface attachDocumentVariables {
  contentId: string;
  documentId: string;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: clearCache
// ====================================================

export interface clearCache {
  clearCache: any | null;
}

export interface clearCacheVariables {
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: clearProductCache
// ====================================================

export interface clearProductCache {
  clearProductCache: any | null;
}

export interface clearProductCacheVariables {
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: clearProductCategoryCache
// ====================================================

export interface clearProductCategoryCache {
  clearProductCategoryCache: any | null;
}

export interface clearProductCategoryCacheVariables {
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: clearProductPriceCache
// ====================================================

export interface clearProductPriceCache {
  clearProductPriceCache: any | null;
}

export interface clearProductPriceCacheVariables {
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: closeOrder
// ====================================================

export interface closeOrder_closeOrder {
  __typename: "Order";
  id: number;
}

export interface closeOrder {
  closeOrder: closeOrder_closeOrder;
}

export interface closeOrderVariables {
  increment_id: number;
  delivered: boolean;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: correctInvoice
// ====================================================

export interface correctInvoice_correctInvoice_user {
  __typename: "User";
  email: string;
}

export interface correctInvoice_correctInvoice_status {
  __typename: "OrderStatus";
  id: OrderStatusEnum;
  title: string;
}

export interface correctInvoice_correctInvoice_shipping_method_cost_time_windows {
  __typename: "TimeWindowCost";
  interval_hours: number;
}

export interface correctInvoice_correctInvoice_shipping_method {
  __typename: "ShippingMethod";
  name: string | null;
  delivery_days: number | null;
  cost_time_windows: correctInvoice_correctInvoice_shipping_method_cost_time_windows[] | null;
}

export interface correctInvoice_correctInvoice_delivery_time_window {
  __typename: "DeliveryTimeWindow";
  date: any;
  weekday: string;
  interval: string;
  interval_length: number;
}

export interface correctInvoice_correctInvoice_shipping_address {
  __typename: "UserAddress";
  firstname: string | null;
  lastname: string | null;
  zip_code: string | null;
  city: string | null;
  address_line1: string | null;
  address_line2: string | null;
  company_name: string | null;
  company_number: string | null;
}

export interface correctInvoice_correctInvoice_billing_address {
  __typename: "UserAddress";
  firstname: string | null;
  lastname: string | null;
  zip_code: string | null;
  city: string | null;
  address_line1: string | null;
  address_line2: string | null;
  company_name: string | null;
  company_number: string | null;
}

export interface correctInvoice_correctInvoice_payment_method {
  __typename: "PaymentMethod";
  id: string;
  name: string | null;
}

export interface correctInvoice_correctInvoice_payment_status {
  __typename: "PaymentStatus";
  id: PaymentStatusEnum;
  title: string;
}

export interface correctInvoice_correctInvoice_payment_transaction_latest {
  __typename: "PaymentTransaction";
  id: number;
  anum: string | null;
}

export interface correctInvoice_correctInvoice_payment_transactions_payment_method {
  __typename: "PaymentMethod";
  name: string | null;
}

export interface correctInvoice_correctInvoice_payment_transactions {
  __typename: "PaymentTransaction";
  id: number;
  pmgw_transaction_id: string;
  provider_transaction_id: string | null;
  anum: string | null;
  payment_method: correctInvoice_correctInvoice_payment_transactions_payment_method;
  status_payment: string | null;
  amount: number;
  amount_refund: number | null;
  success: boolean;
}

export interface correctInvoice_correctInvoice_payment {
  __typename: "OrderPayment";
  status: correctInvoice_correctInvoice_payment_status;
  transaction_latest: correctInvoice_correctInvoice_payment_transaction_latest | null;
  amount_paid: number;
  amount_refunded: number;
  transactions: correctInvoice_correctInvoice_payment_transactions[];
}

export interface correctInvoice_correctInvoice_admin_notes_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface correctInvoice_correctInvoice_admin_notes {
  __typename: "AdminNote";
  note: string | null;
  created_by: correctInvoice_correctInvoice_admin_notes_created_by | null;
  created_at: any | null;
}

export interface correctInvoice_correctInvoice_items_product {
  __typename: "Product";
  name: string | null;
  lfdnr: number | null;
  ean: string[];
}

export interface correctInvoice_correctInvoice_items_discounts {
  __typename: "OrderItemDiscount";
  promotion_name: string;
  amount: number;
}

export interface correctInvoice_correctInvoice_items {
  __typename: "OrderItem";
  id: number;
  product: correctInvoice_correctInvoice_items_product;
  quantity: number;
  unit_price: number;
  sub_total: number;
  discount_total: number;
  discounts: correctInvoice_correctInvoice_items_discounts[];
  type: OrderItemType;
  quantity_missing: number;
  quantity_delivered: number;
}

export interface correctInvoice_correctInvoice_shipment {
  __typename: "RossmannShipment";
  status: ShipmentStatusEnum | null;
  delivered_at: any | null;
  tracking_url: string | null;
  tracking_numbers: string[];
  sent_at: any | null;
  delivery_in_progress_at: any | null;
  delivery_day_expected: any | null;
  can_retry: boolean;
}

export interface correctInvoice_correctInvoice_invoice_status {
  __typename: "InvoiceStatus";
  title: string;
}

export interface correctInvoice_correctInvoice_invoices {
  __typename: "OrderInvoice";
  type: InvoiceTypeEnum;
  invoice_number: string | null;
  url: string | null;
  url_remote: string | null;
  created_at: any;
  invoiced_at: any | null;
}

export interface correctInvoice_correctInvoice_pickup_point {
  __typename: "Store" | "MplPickupPoint" | "GlsPickupPoint" | "FoxpostPickupPoint" | "EasyboxPickupPoint";
  id: number;
  address: string;
}

export interface correctInvoice_correctInvoice_gift_cards {
  __typename: "GiftCardPayment";
  barcode: string;
  amount: number;
  voided_amount: number;
  paid_at: any | null;
  voided_at: any | null;
}

export interface correctInvoice_correctInvoice {
  __typename: "Order";
  id: number;
  increment_id: number | null;
  created_at: any;
  contact_fullname: string;
  email: string | null;
  phone_number: string | null;
  user: correctInvoice_correctInvoice_user | null;
  status: correctInvoice_correctInvoice_status;
  shipping_method: correctInvoice_correctInvoice_shipping_method;
  delivery_day_expected: any | null;
  delivery_time_window: correctInvoice_correctInvoice_delivery_time_window | null;
  shipping_address: correctInvoice_correctInvoice_shipping_address | null;
  billing_address: correctInvoice_correctInvoice_billing_address;
  payment_method: correctInvoice_correctInvoice_payment_method;
  grand_total: number;
  tax_total: number;
  payment: correctInvoice_correctInvoice_payment | null;
  admin_notes: correctInvoice_correctInvoice_admin_notes[];
  items: correctInvoice_correctInvoice_items[];
  shipment: correctInvoice_correctInvoice_shipment | null;
  invoice_status: correctInvoice_correctInvoice_invoice_status | null;
  shipping_note: string | null;
  grand_total_netto: number;
  invoices: correctInvoice_correctInvoice_invoices[];
  pickup_point: correctInvoice_correctInvoice_pickup_point | null;
  can_be_modified: boolean;
  can_be_deleted: boolean;
  evo_sent_at: any | null;
  user_agent_type: string;
  app_version: string | null;
  no_package: boolean | null;
  gift_cards: correctInvoice_correctInvoice_gift_cards[];
  gift_card_payment: number;
  can_storno: boolean;
  can_refund: boolean;
}

export interface correctInvoice {
  /**
   * Correct invoice after item change on delivery or return
   */
  correctInvoice: correctInvoice_correctInvoice;
}

export interface correctInvoiceVariables {
  increment_id: number;
  items: InvoiceCorrectionItemInput[];
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createAdmin
// ====================================================

export interface createAdmin_createAdmin {
  __typename: "Admin";
  id: number;
}

export interface createAdmin {
  createAdmin: createAdmin_createAdmin;
}

export interface createAdminVariables {
  input: AdminInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createBadge
// ====================================================

export interface createBadge_createBadge {
  __typename: "Badge";
  id: number;
}

export interface createBadge {
  createBadge: createBadge_createBadge | null;
}

export interface createBadgeVariables {
  input: BadgeInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createBanner
// ====================================================

export interface createBanner_createBanner_BannerNormal {
  __typename: "BannerNormal";
  id: number;
}

export interface createBanner_createBanner_BannerProductList {
  __typename: "BannerProductList";
  id: number;
}

export interface createBanner_createBanner_BannerProduct {
  __typename: "BannerProduct";
  id: number;
}

export type createBanner_createBanner = createBanner_createBanner_BannerNormal | createBanner_createBanner_BannerProductList | createBanner_createBanner_BannerProduct;

export interface createBanner {
  createBanner: createBanner_createBanner | null;
}

export interface createBannerVariables {
  input: BannerInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createBenefit
// ====================================================

export interface createBenefit_createBenefit {
  __typename: "Benefit";
  id: string;
}

export interface createBenefit {
  createBenefit: createBenefit_createBenefit;
}

export interface createBenefitVariables {
  input: BenefitInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createCampaign
// ====================================================

export interface createCampaign_createCampaign {
  __typename: "Campaign";
  id: number;
}

export interface createCampaign {
  createCampaign: createCampaign_createCampaign;
}

export interface createCampaignVariables {
  input: CampaignInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createCategory
// ====================================================

export interface createCategory_createCategory {
  __typename: "Category";
  id: string;
}

export interface createCategory {
  createCategory: createCategory_createCategory;
}

export interface createCategoryVariables {
  input: CreateCategoryInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createContent
// ====================================================

export interface createContent_createContent_GalleryContent {
  __typename: "GalleryContent" | "VideoContent";
}

export interface createContent_createContent_TextContent {
  __typename: "TextContent";
  id: string;
}

export type createContent_createContent = createContent_createContent_GalleryContent | createContent_createContent_TextContent;

export interface createContent {
  createContent: createContent_createContent;
}

export interface createContentVariables {
  input: CreateContentInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createDepartment
// ====================================================

export interface createDepartment_createDepartment {
  __typename: "Department";
  id: string;
}

export interface createDepartment {
  createDepartment: createDepartment_createDepartment;
}

export interface createDepartmentVariables {
  input: DepartmentInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createDivision
// ====================================================

export interface createDivision_createDivision {
  __typename: "Division";
  id: string;
}

export interface createDivision {
  createDivision: createDivision_createDivision;
}

export interface createDivisionVariables {
  input: DivisionInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createFulfillment
// ====================================================

export interface createFulfillment_createFulfillment {
  __typename: "Fulfillment";
  id: number;
}

export interface createFulfillment {
  createFulfillment: createFulfillment_createFulfillment | null;
}

export interface createFulfillmentVariables {
  input: FulfillmentInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createGallery
// ====================================================

export interface createGallery_createGallery {
  __typename: "Gallery";
  id: string;
  title: string;
  handle: string | null;
  image_count: number;
  updated_at: any | null;
}

export interface createGallery {
  createGallery: createGallery_createGallery;
}

export interface createGalleryVariables {
  input: CreateGalleryInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createGiftCardOrderCompany
// ====================================================

export interface createGiftCardOrderCompany_createGiftCardOrderCompany {
  __typename: "GiftCardOrder";
  id: number;
}

export interface createGiftCardOrderCompany {
  createGiftCardOrderCompany: createGiftCardOrderCompany_createGiftCardOrderCompany;
}

export interface createGiftCardOrderCompanyVariables {
  input: GiftCardOrderCompanyInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createGiftCardOrderPrize
// ====================================================

export interface createGiftCardOrderPrize_createGiftCardOrderPrize {
  __typename: "GiftCardOrder";
  id: number;
}

export interface createGiftCardOrderPrize {
  createGiftCardOrderPrize: createGiftCardOrderPrize_createGiftCardOrderPrize;
}

export interface createGiftCardOrderPrizeVariables {
  input: GiftCardOrderPrizeInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createMaintenance
// ====================================================

export interface createMaintenance_createMaintenance {
  __typename: "Maintenance";
  id: string;
}

export interface createMaintenance {
  createMaintenance: createMaintenance_createMaintenance;
}

export interface createMaintenanceVariables {
  input: MaintenanceInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createMenu
// ====================================================

export interface createMenu_createMenu {
  __typename: "Menu";
  id: number;
}

export interface createMenu {
  createMenu: createMenu_createMenu;
}

export interface createMenuVariables {
  input: MenuInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createPage
// ====================================================

export interface createPage_createPage {
  __typename: "Page";
  id: number;
}

export interface createPage {
  createPage: createPage_createPage;
}

export interface createPageVariables {
  input: PageInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createPerson
// ====================================================

export interface createPerson_createPerson {
  __typename: "Person";
  id: string;
}

export interface createPerson {
  createPerson: createPerson_createPerson;
}

export interface createPersonVariables {
  input: PersonInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createProductBundle
// ====================================================

export interface createProductBundle_createProductBundle_categories_category_path {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface createProductBundle_createProductBundle_categories {
  __typename: "ProductCategory";
  id: number;
  category_path: createProductBundle_createProductBundle_categories_category_path[];
}

export interface createProductBundle_createProductBundle_images {
  __typename: "ProductImage";
  id: number;
  source: ProductImageSource;
  position: number;
  url_list: string | null;
  is_active: boolean;
}

export interface createProductBundle_createProductBundle_param_values {
  __typename: "ProductParamValue";
  product_param_id: string;
  is_multi: boolean;
  values: string[];
}

export interface createProductBundle_createProductBundle_brand_bank_param_values {
  __typename: "ProductParamValue";
  product_param_id: string;
  is_multi: boolean;
  values: string[];
}

export interface createProductBundle_createProductBundle_brand_bank {
  __typename: "ProductBrandBankData";
  id: number;
  param_values: createProductBundle_createProductBundle_brand_bank_param_values[];
}

export interface createProductBundle_createProductBundle_badges_featured {
  __typename: "Badge";
  name: string;
}

export interface createProductBundle_createProductBundle_badges_product_params {
  __typename: "Badge";
  name: string;
}

export interface createProductBundle_createProductBundle_badges_notifications {
  __typename: "Badge";
  name: string;
}

export interface createProductBundle_createProductBundle_prices {
  __typename: "ProductPrice";
  id: number;
  price: number;
  price_unit: number | null;
  active_from: any | null;
  active_to: any | null;
}

export interface createProductBundle_createProductBundle_stock {
  __typename: "ProductStock";
  id: number;
  qty: number;
  updated_at: any | null;
}

export interface createProductBundle_createProductBundle_brand {
  __typename: "ProductBrand";
  id: number;
  name: string | null;
}

export interface createProductBundle_createProductBundle_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface createProductBundle_createProductBundle_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface createProductBundle_createProductBundle_children_categories_category_path {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface createProductBundle_createProductBundle_children_categories {
  __typename: "ProductCategory";
  id: number;
  category_path: createProductBundle_createProductBundle_children_categories_category_path[];
}

export interface createProductBundle_createProductBundle_children_images {
  __typename: "ProductImage";
  id: number;
  source: ProductImageSource;
  position: number;
  url_list: string | null;
  is_active: boolean;
}

export interface createProductBundle_createProductBundle_children_param_values {
  __typename: "ProductParamValue";
  product_param_id: string;
  is_multi: boolean;
  values: string[];
}

export interface createProductBundle_createProductBundle_children_brand_bank_param_values {
  __typename: "ProductParamValue";
  product_param_id: string;
  is_multi: boolean;
  values: string[];
}

export interface createProductBundle_createProductBundle_children_brand_bank {
  __typename: "ProductBrandBankData";
  id: number;
  param_values: createProductBundle_createProductBundle_children_brand_bank_param_values[];
}

export interface createProductBundle_createProductBundle_children_badges_featured {
  __typename: "Badge";
  name: string;
}

export interface createProductBundle_createProductBundle_children_badges_product_params {
  __typename: "Badge";
  name: string;
}

export interface createProductBundle_createProductBundle_children_badges_notifications {
  __typename: "Badge";
  name: string;
}

export interface createProductBundle_createProductBundle_children_prices {
  __typename: "ProductPrice";
  id: number;
  price: number;
  price_unit: number | null;
  active_from: any | null;
  active_to: any | null;
}

export interface createProductBundle_createProductBundle_children_stock {
  __typename: "ProductStock";
  id: number;
  qty: number;
  updated_at: any | null;
}

export interface createProductBundle_createProductBundle_children_brand {
  __typename: "ProductBrand";
  id: number;
  name: string | null;
}

export interface createProductBundle_createProductBundle_children_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface createProductBundle_createProductBundle_children_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface createProductBundle_createProductBundle_children {
  __typename: "Product";
  id: number;
  name: string | null;
  lfdnr: number | null;
  ean: string[];
  cart_qty_max: number;
  categories: createProductBundle_createProductBundle_children_categories[];
  slug: string | null;
  unit_base: string | null;
  images: createProductBundle_createProductBundle_children_images[];
  is_active: boolean;
  is_force_active: boolean | null;
  active_from: any | null;
  active_to: any | null;
  param_values: createProductBundle_createProductBundle_children_param_values[];
  brand_bank: createProductBundle_createProductBundle_children_brand_bank | null;
  badges_featured: createProductBundle_createProductBundle_children_badges_featured[];
  badges_product_params: createProductBundle_createProductBundle_children_badges_product_params[];
  badges_notifications: createProductBundle_createProductBundle_children_badges_notifications[];
  prices: createProductBundle_createProductBundle_children_prices[];
  stock: createProductBundle_createProductBundle_children_stock | null;
  brand: createProductBundle_createProductBundle_children_brand | null;
  created_at: any;
  updated_at: any;
  created_by: createProductBundle_createProductBundle_children_created_by | null;
  updated_by: createProductBundle_createProductBundle_children_updated_by | null;
  bundle_promotion_id: number | null;
  image_path_360: string | null;
  image_position_360: number | null;
  brandbank_exclude: boolean | null;
}

export interface createProductBundle_createProductBundle {
  __typename: "Product";
  id: number;
  name: string | null;
  lfdnr: number | null;
  ean: string[];
  cart_qty_max: number;
  categories: createProductBundle_createProductBundle_categories[];
  slug: string | null;
  unit_base: string | null;
  images: createProductBundle_createProductBundle_images[];
  is_active: boolean;
  is_force_active: boolean | null;
  active_from: any | null;
  active_to: any | null;
  param_values: createProductBundle_createProductBundle_param_values[];
  brand_bank: createProductBundle_createProductBundle_brand_bank | null;
  badges_featured: createProductBundle_createProductBundle_badges_featured[];
  badges_product_params: createProductBundle_createProductBundle_badges_product_params[];
  badges_notifications: createProductBundle_createProductBundle_badges_notifications[];
  prices: createProductBundle_createProductBundle_prices[];
  stock: createProductBundle_createProductBundle_stock | null;
  brand: createProductBundle_createProductBundle_brand | null;
  created_at: any;
  updated_at: any;
  created_by: createProductBundle_createProductBundle_created_by | null;
  updated_by: createProductBundle_createProductBundle_updated_by | null;
  bundle_promotion_id: number | null;
  image_path_360: string | null;
  image_position_360: number | null;
  brandbank_exclude: boolean | null;
  children: createProductBundle_createProductBundle_children[] | null;
}

export interface createProductBundle {
  createProductBundle: createProductBundle_createProductBundle | null;
}

export interface createProductBundleVariables {
  input: ProductBundleInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createProductList
// ====================================================

export interface createProductList_createProductList {
  __typename: "ProductList";
  id: number;
}

export interface createProductList {
  createProductList: createProductList_createProductList;
}

export interface createProductListVariables {
  input: ProductListInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createProductVariant
// ====================================================

export interface createProductVariant_createProductVariant {
  __typename: "Product";
  id: number;
}

export interface createProductVariant {
  createProductVariant: createProductVariant_createProductVariant | null;
}

export interface createProductVariantVariables {
  input: ProductVariantInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createRedirection
// ====================================================

export interface createRedirection_createRedirection {
  __typename: "Redirection";
  id: string;
}

export interface createRedirection {
  createRedirection: createRedirection_createRedirection;
}

export interface createRedirectionVariables {
  input: RedirectionInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createRole
// ====================================================

export interface createRole_createRole {
  __typename: "Role";
  id: number;
}

export interface createRole {
  createRole: createRole_createRole | null;
}

export interface createRoleVariables {
  name: string;
  input: RoleInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createSuccessStory
// ====================================================

export interface createSuccessStory_createSuccessStory {
  __typename: "SuccessStory";
  id: string;
}

export interface createSuccessStory {
  createSuccessStory: createSuccessStory_createSuccessStory;
}

export interface createSuccessStoryVariables {
  input: SuccessStoryInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createWebsiteNotification
// ====================================================

export interface createWebsiteNotification_createWebsiteNotification {
  __typename: "WebsiteNotification";
  id: number;
}

export interface createWebsiteNotification {
  createWebsiteNotification: createWebsiteNotification_createWebsiteNotification | null;
}

export interface createWebsiteNotificationVariables {
  input: WebsiteNotificationInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteAdmin
// ====================================================

export interface deleteAdmin_deleteAdmin {
  __typename: "Admin";
  id: number;
}

export interface deleteAdmin {
  deleteAdmin: deleteAdmin_deleteAdmin;
}

export interface deleteAdminVariables {
  id: number;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteBadge
// ====================================================

export interface deleteBadge {
  deleteBadge: any | null;
}

export interface deleteBadgeVariables {
  id: number;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteBanner
// ====================================================

export interface deleteBanner {
  deleteBanner: any | null;
}

export interface deleteBannerVariables {
  id: number;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteBenefit
// ====================================================

export interface deleteBenefit_deleteBenefit {
  __typename: "Benefit";
  id: string;
}

export interface deleteBenefit {
  deleteBenefit: deleteBenefit_deleteBenefit;
}

export interface deleteBenefitVariables {
  id: string;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteCampaign
// ====================================================

export interface deleteCampaign {
  deleteCampaign: any | null;
}

export interface deleteCampaignVariables {
  id: number;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteContent
// ====================================================

export interface deleteContent_deleteContent_GalleryContent {
  __typename: "GalleryContent" | "VideoContent";
}

export interface deleteContent_deleteContent_TextContent {
  __typename: "TextContent";
  id: string;
}

export type deleteContent_deleteContent = deleteContent_deleteContent_GalleryContent | deleteContent_deleteContent_TextContent;

export interface deleteContent {
  deleteContent: deleteContent_deleteContent | null;
}

export interface deleteContentVariables {
  id: string;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteDepartment
// ====================================================

export interface deleteDepartment_deleteDepartment {
  __typename: "Department";
  id: string;
}

export interface deleteDepartment {
  deleteDepartment: deleteDepartment_deleteDepartment;
}

export interface deleteDepartmentVariables {
  id: string;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteDivision
// ====================================================

export interface deleteDivision_deleteDivision {
  __typename: "Division";
  id: string;
}

export interface deleteDivision {
  deleteDivision: deleteDivision_deleteDivision;
}

export interface deleteDivisionVariables {
  id: string;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteFulfillment
// ====================================================

export interface deleteFulfillment {
  deleteFulfillment: any | null;
}

export interface deleteFulfillmentVariables {
  id: number;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteGallery
// ====================================================

export interface deleteGallery_deleteGallery {
  __typename: "Gallery";
  id: string;
}

export interface deleteGallery {
  deleteGallery: deleteGallery_deleteGallery | null;
}

export interface deleteGalleryVariables {
  id: string;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteMaintenance
// ====================================================

export interface deleteMaintenance_deleteMaintenance {
  __typename: "Maintenance";
  id: string;
}

export interface deleteMaintenance {
  deleteMaintenance: deleteMaintenance_deleteMaintenance;
}

export interface deleteMaintenanceVariables {
  id: string;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteMenu
// ====================================================

export interface deleteMenu {
  deleteMenu: any | null;
}

export interface deleteMenuVariables {
  id: number;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteOrder
// ====================================================

export interface deleteOrder {
  /**
   * Cancel order in progress
   */
  deleteOrder: any | null;
}

export interface deleteOrderVariables {
  increment_id: number;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deletePage
// ====================================================

export interface deletePage {
  deletePage: any | null;
}

export interface deletePageVariables {
  id: number;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deletePerson
// ====================================================

export interface deletePerson_deletePerson {
  __typename: "Person";
  id: string;
}

export interface deletePerson {
  deletePerson: deletePerson_deletePerson;
}

export interface deletePersonVariables {
  id: string;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteProductList
// ====================================================

export interface deleteProductList {
  deleteProductList: any | null;
}

export interface deleteProductListVariables {
  id: number;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteProductVariant
// ====================================================

export interface deleteProductVariant {
  deleteProductVariant: any | null;
}

export interface deleteProductVariantVariables {
  id: number;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteRedirection
// ====================================================

export interface deleteRedirection_deleteRedirection {
  __typename: "Redirection";
  id: string;
}

export interface deleteRedirection {
  deleteRedirection: deleteRedirection_deleteRedirection | null;
}

export interface deleteRedirectionVariables {
  id: number;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteRole
// ====================================================

export interface deleteRole {
  deleteRole: any | null;
}

export interface deleteRoleVariables {
  id: number;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteSuccessStory
// ====================================================

export interface deleteSuccessStory_deleteSuccessStory {
  __typename: "SuccessStory";
  id: string;
}

export interface deleteSuccessStory {
  deleteSuccessStory: deleteSuccessStory_deleteSuccessStory;
}

export interface deleteSuccessStoryVariables {
  id: string;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteUser
// ====================================================

export interface deleteUser {
  deleteUser: any | null;
}

export interface deleteUserVariables {
  id: number;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteWebsiteNotification
// ====================================================

export interface deleteWebsiteNotification {
  deleteWebsiteNotification: any | null;
}

export interface deleteWebsiteNotificationVariables {
  id: number;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: detachDocument
// ====================================================

export interface detachDocument_detachDocument_GalleryContent {
  __typename: "GalleryContent" | "VideoContent";
}

export interface detachDocument_detachDocument_TextContent {
  __typename: "TextContent";
  id: string;
}

export type detachDocument_detachDocument = detachDocument_detachDocument_GalleryContent | detachDocument_detachDocument_TextContent;

export interface detachDocument {
  detachDocument: detachDocument_detachDocument | null;
}

export interface detachDocumentVariables {
  contentId: string;
  documentId: string;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: indexProduct
// ====================================================

export interface indexProduct {
  indexProduct: any | null;
}

export interface indexProductVariables {
  lfdnr?: number[] | null;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: modifyOrder
// ====================================================

export interface modifyOrder_modifyOrder_user {
  __typename: "User";
  email: string;
}

export interface modifyOrder_modifyOrder_status {
  __typename: "OrderStatus";
  id: OrderStatusEnum;
  title: string;
}

export interface modifyOrder_modifyOrder_shipping_method_cost_time_windows {
  __typename: "TimeWindowCost";
  interval_hours: number;
}

export interface modifyOrder_modifyOrder_shipping_method {
  __typename: "ShippingMethod";
  name: string | null;
  delivery_days: number | null;
  cost_time_windows: modifyOrder_modifyOrder_shipping_method_cost_time_windows[] | null;
}

export interface modifyOrder_modifyOrder_delivery_time_window {
  __typename: "DeliveryTimeWindow";
  date: any;
  weekday: string;
  interval: string;
  interval_length: number;
}

export interface modifyOrder_modifyOrder_shipping_address {
  __typename: "UserAddress";
  firstname: string | null;
  lastname: string | null;
  zip_code: string | null;
  city: string | null;
  address_line1: string | null;
  address_line2: string | null;
  company_name: string | null;
  company_number: string | null;
}

export interface modifyOrder_modifyOrder_billing_address {
  __typename: "UserAddress";
  firstname: string | null;
  lastname: string | null;
  zip_code: string | null;
  city: string | null;
  address_line1: string | null;
  address_line2: string | null;
  company_name: string | null;
  company_number: string | null;
}

export interface modifyOrder_modifyOrder_payment_method {
  __typename: "PaymentMethod";
  id: string;
  name: string | null;
}

export interface modifyOrder_modifyOrder_payment_status {
  __typename: "PaymentStatus";
  id: PaymentStatusEnum;
  title: string;
}

export interface modifyOrder_modifyOrder_payment_transaction_latest {
  __typename: "PaymentTransaction";
  id: number;
  anum: string | null;
}

export interface modifyOrder_modifyOrder_payment_transactions_payment_method {
  __typename: "PaymentMethod";
  name: string | null;
}

export interface modifyOrder_modifyOrder_payment_transactions {
  __typename: "PaymentTransaction";
  id: number;
  pmgw_transaction_id: string;
  provider_transaction_id: string | null;
  anum: string | null;
  payment_method: modifyOrder_modifyOrder_payment_transactions_payment_method;
  status_payment: string | null;
  amount: number;
  amount_refund: number | null;
  success: boolean;
}

export interface modifyOrder_modifyOrder_payment {
  __typename: "OrderPayment";
  status: modifyOrder_modifyOrder_payment_status;
  transaction_latest: modifyOrder_modifyOrder_payment_transaction_latest | null;
  amount_paid: number;
  amount_refunded: number;
  transactions: modifyOrder_modifyOrder_payment_transactions[];
}

export interface modifyOrder_modifyOrder_admin_notes_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface modifyOrder_modifyOrder_admin_notes {
  __typename: "AdminNote";
  note: string | null;
  created_by: modifyOrder_modifyOrder_admin_notes_created_by | null;
  created_at: any | null;
}

export interface modifyOrder_modifyOrder_items_product {
  __typename: "Product";
  name: string | null;
  lfdnr: number | null;
  ean: string[];
}

export interface modifyOrder_modifyOrder_items_discounts {
  __typename: "OrderItemDiscount";
  promotion_name: string;
  amount: number;
}

export interface modifyOrder_modifyOrder_items {
  __typename: "OrderItem";
  id: number;
  product: modifyOrder_modifyOrder_items_product;
  quantity: number;
  unit_price: number;
  sub_total: number;
  discount_total: number;
  discounts: modifyOrder_modifyOrder_items_discounts[];
  type: OrderItemType;
  quantity_missing: number;
  quantity_delivered: number;
}

export interface modifyOrder_modifyOrder_shipment {
  __typename: "RossmannShipment";
  status: ShipmentStatusEnum | null;
  delivered_at: any | null;
  tracking_url: string | null;
  tracking_numbers: string[];
  sent_at: any | null;
  delivery_in_progress_at: any | null;
  delivery_day_expected: any | null;
  can_retry: boolean;
}

export interface modifyOrder_modifyOrder_invoice_status {
  __typename: "InvoiceStatus";
  title: string;
}

export interface modifyOrder_modifyOrder_invoices {
  __typename: "OrderInvoice";
  type: InvoiceTypeEnum;
  invoice_number: string | null;
  url: string | null;
  url_remote: string | null;
  created_at: any;
  invoiced_at: any | null;
}

export interface modifyOrder_modifyOrder_pickup_point {
  __typename: "Store" | "MplPickupPoint" | "GlsPickupPoint" | "FoxpostPickupPoint" | "EasyboxPickupPoint";
  id: number;
  address: string;
}

export interface modifyOrder_modifyOrder_gift_cards {
  __typename: "GiftCardPayment";
  barcode: string;
  amount: number;
  voided_amount: number;
  paid_at: any | null;
  voided_at: any | null;
}

export interface modifyOrder_modifyOrder {
  __typename: "Order";
  id: number;
  increment_id: number | null;
  created_at: any;
  contact_fullname: string;
  email: string | null;
  phone_number: string | null;
  user: modifyOrder_modifyOrder_user | null;
  status: modifyOrder_modifyOrder_status;
  shipping_method: modifyOrder_modifyOrder_shipping_method;
  delivery_day_expected: any | null;
  delivery_time_window: modifyOrder_modifyOrder_delivery_time_window | null;
  shipping_address: modifyOrder_modifyOrder_shipping_address | null;
  billing_address: modifyOrder_modifyOrder_billing_address;
  payment_method: modifyOrder_modifyOrder_payment_method;
  grand_total: number;
  tax_total: number;
  payment: modifyOrder_modifyOrder_payment | null;
  admin_notes: modifyOrder_modifyOrder_admin_notes[];
  items: modifyOrder_modifyOrder_items[];
  shipment: modifyOrder_modifyOrder_shipment | null;
  invoice_status: modifyOrder_modifyOrder_invoice_status | null;
  shipping_note: string | null;
  grand_total_netto: number;
  invoices: modifyOrder_modifyOrder_invoices[];
  pickup_point: modifyOrder_modifyOrder_pickup_point | null;
  can_be_modified: boolean;
  can_be_deleted: boolean;
  evo_sent_at: any | null;
  user_agent_type: string;
  app_version: string | null;
  no_package: boolean | null;
  gift_cards: modifyOrder_modifyOrder_gift_cards[];
  gift_card_payment: number;
  can_storno: boolean;
  can_refund: boolean;
}

export interface modifyOrder {
  /**
   * Modify order before shipping in case of missing items
   */
  modifyOrder: modifyOrder_modifyOrder;
}

export interface modifyOrderVariables {
  increment_id: number;
  input: OrderModifyInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: orderProductCategory
// ====================================================

export interface orderProductCategory {
  orderProductCategory: any | null;
}

export interface orderProductCategoryVariables {
  input: ProductCategoryOrderInput[];
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: previewMailTemplate
// ====================================================

export interface previewMailTemplate {
  previewMailTemplate: string;
}

export interface previewMailTemplateVariables {
  content: string;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: queueProductIndex
// ====================================================

export interface queueProductIndex {
  queueProductIndex: any | null;
}

export interface queueProductIndexVariables {
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: refundPayment
// ====================================================

export interface refundPayment_refundPayment {
  __typename: "Order";
  id: number;
}

export interface refundPayment {
  refundPayment: refundPayment_refundPayment;
}

export interface refundPaymentVariables {
  increment_id: number;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeDocument
// ====================================================

export interface removeDocument {
  removeDocument: boolean;
}

export interface removeDocumentVariables {
  id: string;
  force?: boolean | null;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: replaceCardToVirtual
// ====================================================

export interface replaceCardToVirtual_replaceCardToVirtual {
  __typename: "RossmannPlusProfile";
  card_number: string;
}

export interface replaceCardToVirtual {
  replaceCardToVirtual: replaceCardToVirtual_replaceCardToVirtual | null;
}

export interface replaceCardToVirtualVariables {
  user_id: number;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: resendGiftCard
// ====================================================

export interface resendGiftCard {
  resendGiftCard: any | null;
}

export interface resendGiftCardVariables {
  id: number;
  email: string;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: resendShipment
// ====================================================

export interface resendShipment_resendShipment_user {
  __typename: "User";
  email: string;
}

export interface resendShipment_resendShipment_status {
  __typename: "OrderStatus";
  id: OrderStatusEnum;
  title: string;
}

export interface resendShipment_resendShipment_shipping_method_cost_time_windows {
  __typename: "TimeWindowCost";
  interval_hours: number;
}

export interface resendShipment_resendShipment_shipping_method {
  __typename: "ShippingMethod";
  name: string | null;
  delivery_days: number | null;
  cost_time_windows: resendShipment_resendShipment_shipping_method_cost_time_windows[] | null;
}

export interface resendShipment_resendShipment_delivery_time_window {
  __typename: "DeliveryTimeWindow";
  date: any;
  weekday: string;
  interval: string;
  interval_length: number;
}

export interface resendShipment_resendShipment_shipping_address {
  __typename: "UserAddress";
  firstname: string | null;
  lastname: string | null;
  zip_code: string | null;
  city: string | null;
  address_line1: string | null;
  address_line2: string | null;
  company_name: string | null;
  company_number: string | null;
}

export interface resendShipment_resendShipment_billing_address {
  __typename: "UserAddress";
  firstname: string | null;
  lastname: string | null;
  zip_code: string | null;
  city: string | null;
  address_line1: string | null;
  address_line2: string | null;
  company_name: string | null;
  company_number: string | null;
}

export interface resendShipment_resendShipment_payment_method {
  __typename: "PaymentMethod";
  id: string;
  name: string | null;
}

export interface resendShipment_resendShipment_payment_status {
  __typename: "PaymentStatus";
  id: PaymentStatusEnum;
  title: string;
}

export interface resendShipment_resendShipment_payment_transaction_latest {
  __typename: "PaymentTransaction";
  id: number;
  anum: string | null;
}

export interface resendShipment_resendShipment_payment_transactions_payment_method {
  __typename: "PaymentMethod";
  name: string | null;
}

export interface resendShipment_resendShipment_payment_transactions {
  __typename: "PaymentTransaction";
  id: number;
  pmgw_transaction_id: string;
  provider_transaction_id: string | null;
  anum: string | null;
  payment_method: resendShipment_resendShipment_payment_transactions_payment_method;
  status_payment: string | null;
  amount: number;
  amount_refund: number | null;
  success: boolean;
}

export interface resendShipment_resendShipment_payment {
  __typename: "OrderPayment";
  status: resendShipment_resendShipment_payment_status;
  transaction_latest: resendShipment_resendShipment_payment_transaction_latest | null;
  amount_paid: number;
  amount_refunded: number;
  transactions: resendShipment_resendShipment_payment_transactions[];
}

export interface resendShipment_resendShipment_admin_notes_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface resendShipment_resendShipment_admin_notes {
  __typename: "AdminNote";
  note: string | null;
  created_by: resendShipment_resendShipment_admin_notes_created_by | null;
  created_at: any | null;
}

export interface resendShipment_resendShipment_items_product {
  __typename: "Product";
  name: string | null;
  lfdnr: number | null;
  ean: string[];
}

export interface resendShipment_resendShipment_items_discounts {
  __typename: "OrderItemDiscount";
  promotion_name: string;
  amount: number;
}

export interface resendShipment_resendShipment_items {
  __typename: "OrderItem";
  id: number;
  product: resendShipment_resendShipment_items_product;
  quantity: number;
  unit_price: number;
  sub_total: number;
  discount_total: number;
  discounts: resendShipment_resendShipment_items_discounts[];
  type: OrderItemType;
  quantity_missing: number;
  quantity_delivered: number;
}

export interface resendShipment_resendShipment_shipment {
  __typename: "RossmannShipment";
  status: ShipmentStatusEnum | null;
  delivered_at: any | null;
  tracking_url: string | null;
  tracking_numbers: string[];
  sent_at: any | null;
  delivery_in_progress_at: any | null;
  delivery_day_expected: any | null;
  can_retry: boolean;
}

export interface resendShipment_resendShipment_invoice_status {
  __typename: "InvoiceStatus";
  title: string;
}

export interface resendShipment_resendShipment_invoices {
  __typename: "OrderInvoice";
  type: InvoiceTypeEnum;
  invoice_number: string | null;
  url: string | null;
  url_remote: string | null;
  created_at: any;
  invoiced_at: any | null;
}

export interface resendShipment_resendShipment_pickup_point {
  __typename: "Store" | "MplPickupPoint" | "GlsPickupPoint" | "FoxpostPickupPoint" | "EasyboxPickupPoint";
  id: number;
  address: string;
}

export interface resendShipment_resendShipment_gift_cards {
  __typename: "GiftCardPayment";
  barcode: string;
  amount: number;
  voided_amount: number;
  paid_at: any | null;
  voided_at: any | null;
}

export interface resendShipment_resendShipment {
  __typename: "Order";
  id: number;
  increment_id: number | null;
  created_at: any;
  contact_fullname: string;
  email: string | null;
  phone_number: string | null;
  user: resendShipment_resendShipment_user | null;
  status: resendShipment_resendShipment_status;
  shipping_method: resendShipment_resendShipment_shipping_method;
  delivery_day_expected: any | null;
  delivery_time_window: resendShipment_resendShipment_delivery_time_window | null;
  shipping_address: resendShipment_resendShipment_shipping_address | null;
  billing_address: resendShipment_resendShipment_billing_address;
  payment_method: resendShipment_resendShipment_payment_method;
  grand_total: number;
  tax_total: number;
  payment: resendShipment_resendShipment_payment | null;
  admin_notes: resendShipment_resendShipment_admin_notes[];
  items: resendShipment_resendShipment_items[];
  shipment: resendShipment_resendShipment_shipment | null;
  invoice_status: resendShipment_resendShipment_invoice_status | null;
  shipping_note: string | null;
  grand_total_netto: number;
  invoices: resendShipment_resendShipment_invoices[];
  pickup_point: resendShipment_resendShipment_pickup_point | null;
  can_be_modified: boolean;
  can_be_deleted: boolean;
  evo_sent_at: any | null;
  user_agent_type: string;
  app_version: string | null;
  no_package: boolean | null;
  gift_cards: resendShipment_resendShipment_gift_cards[];
  gift_card_payment: number;
  can_storno: boolean;
  can_refund: boolean;
}

export interface resendShipment {
  resendShipment: resendShipment_resendShipment;
}

export interface resendShipmentVariables {
  increment_id: number;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: resetCalendarDay
// ====================================================

export interface resetCalendarDay {
  resetCalendarDay: any | null;
}

export interface resetCalendarDayVariables {
  date: any;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setCalendarDay
// ====================================================

export interface setCalendarDay_setCalendarDay {
  __typename: "CalendarDay";
  date: any;
  is_workday: boolean;
  is_delivery: boolean;
}

export interface setCalendarDay {
  setCalendarDay: setCalendarDay_setCalendarDay;
}

export interface setCalendarDayVariables {
  date: any;
  input: CalendarDayInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: stornoInvoice
// ====================================================

export interface stornoInvoice_stornoInvoice {
  __typename: "Order";
  id: number;
}

export interface stornoInvoice {
  stornoInvoice: stornoInvoice_stornoInvoice;
}

export interface stornoInvoiceVariables {
  increment_id: number;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: testMailTemplateAll
// ====================================================

export interface testMailTemplateAll {
  testMailTemplateAll: boolean;
}

export interface testMailTemplateAllVariables {
  recipients: string[];
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: testMailTemplate
// ====================================================

export interface testMailTemplate {
  testMailTemplate: boolean;
}

export interface testMailTemplateVariables {
  id: number;
  input: TestMailTemplateInput;
  recipients: string[];
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateAdminMe
// ====================================================

export interface updateAdminMe_updateAdminMe_roles_permissions {
  __typename: "Permission";
  id: number;
  name: PermissionType;
}

export interface updateAdminMe_updateAdminMe_roles {
  __typename: "Role";
  id: number;
  name: string;
  title: string;
  permissions: updateAdminMe_updateAdminMe_roles_permissions[];
}

export interface updateAdminMe_updateAdminMe_store {
  __typename: "Store";
  id: number;
  name: string;
}

export interface updateAdminMe_updateAdminMe {
  __typename: "Admin";
  id: number;
  name: string;
  email: string;
  is_active: boolean;
  created_at: any;
  updated_at: any;
  phone: string | null;
  last_login: any | null;
  roles: updateAdminMe_updateAdminMe_roles[];
  store: updateAdminMe_updateAdminMe_store | null;
}

export interface updateAdminMe {
  updateAdminMe: updateAdminMe_updateAdminMe;
}

export interface updateAdminMeVariables {
  input: UpdateAdminMeDataInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateAdmin
// ====================================================

export interface updateAdmin_updateAdmin_roles_permissions {
  __typename: "Permission";
  id: number;
  name: PermissionType;
}

export interface updateAdmin_updateAdmin_roles {
  __typename: "Role";
  id: number;
  name: string;
  title: string;
  permissions: updateAdmin_updateAdmin_roles_permissions[];
}

export interface updateAdmin_updateAdmin_store {
  __typename: "Store";
  id: number;
  name: string;
}

export interface updateAdmin_updateAdmin {
  __typename: "Admin";
  id: number;
  name: string;
  email: string;
  is_active: boolean;
  created_at: any;
  updated_at: any;
  phone: string | null;
  last_login: any | null;
  roles: updateAdmin_updateAdmin_roles[];
  store: updateAdmin_updateAdmin_store | null;
}

export interface updateAdmin {
  updateAdmin: updateAdmin_updateAdmin;
}

export interface updateAdminVariables {
  id: number;
  input: AdminInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateBadge
// ====================================================

export interface updateBadge_updateBadge_product_param {
  __typename: "ProductParam";
  id: string;
  name: string | null;
}

export interface updateBadge_updateBadge_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface updateBadge_updateBadge_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface updateBadge_updateBadge {
  __typename: "Badge";
  id: number;
  type: BadgeType;
  name: string;
  info: string | null;
  image: string | null;
  image_title: string | null;
  product_param: updateBadge_updateBadge_product_param | null;
  is_active: boolean;
  created_at: any | null;
  created_by: updateBadge_updateBadge_created_by;
  updated_at: any | null;
  updated_by: updateBadge_updateBadge_updated_by | null;
  active_from: any | null;
  active_to: any | null;
  is_visible: boolean;
}

export interface updateBadge {
  updateBadge: updateBadge_updateBadge | null;
}

export interface updateBadgeVariables {
  id: number;
  input: BadgeInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateBanner
// ====================================================

export interface updateBanner_updateBanner_BannerNormal_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface updateBanner_updateBanner_BannerNormal_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface updateBanner_updateBanner_BannerNormal {
  __typename: "BannerNormal";
  id: number;
  name: string;
  urls: string[];
  title: string;
  slider_title: string;
  type: BannerType;
  image_align: BannerAlign;
  button_title: string | null;
  image: string;
  image_title: string | null;
  image_mobile: string | null;
  image_mobile_title: string | null;
  target_url: string | null;
  target_popup: boolean;
  is_active: boolean;
  active_from: any | null;
  active_to: any | null;
  created_at: any | null;
  created_by: updateBanner_updateBanner_BannerNormal_created_by;
  updated_at: any | null;
  updated_by: updateBanner_updateBanner_BannerNormal_updated_by | null;
}

export interface updateBanner_updateBanner_BannerProductList_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface updateBanner_updateBanner_BannerProductList_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface updateBanner_updateBanner_BannerProductList {
  __typename: "BannerProductList";
  id: number;
  name: string;
  urls: string[];
  title: string;
  slider_title: string;
  type: BannerType;
  image_align: BannerAlign;
  button_title: string | null;
  image: string;
  image_title: string | null;
  image_mobile: string | null;
  image_mobile_title: string | null;
  target_url: string | null;
  target_popup: boolean;
  is_active: boolean;
  active_from: any | null;
  active_to: any | null;
  created_at: any | null;
  created_by: updateBanner_updateBanner_BannerProductList_created_by;
  updated_at: any | null;
  updated_by: updateBanner_updateBanner_BannerProductList_updated_by | null;
}

export interface updateBanner_updateBanner_BannerProduct_product_categories_category_path {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface updateBanner_updateBanner_BannerProduct_product_categories {
  __typename: "ProductCategory";
  id: number;
  category_path: updateBanner_updateBanner_BannerProduct_product_categories_category_path[];
}

export interface updateBanner_updateBanner_BannerProduct_product_images {
  __typename: "ProductImage";
  id: number;
  source: ProductImageSource;
  position: number;
  url_list: string | null;
  is_active: boolean;
}

export interface updateBanner_updateBanner_BannerProduct_product_param_values {
  __typename: "ProductParamValue";
  product_param_id: string;
  is_multi: boolean;
  values: string[];
}

export interface updateBanner_updateBanner_BannerProduct_product_brand_bank_param_values {
  __typename: "ProductParamValue";
  product_param_id: string;
  is_multi: boolean;
  values: string[];
}

export interface updateBanner_updateBanner_BannerProduct_product_brand_bank {
  __typename: "ProductBrandBankData";
  id: number;
  param_values: updateBanner_updateBanner_BannerProduct_product_brand_bank_param_values[];
}

export interface updateBanner_updateBanner_BannerProduct_product_badges_featured {
  __typename: "Badge";
  name: string;
}

export interface updateBanner_updateBanner_BannerProduct_product_badges_product_params {
  __typename: "Badge";
  name: string;
}

export interface updateBanner_updateBanner_BannerProduct_product_badges_notifications {
  __typename: "Badge";
  name: string;
}

export interface updateBanner_updateBanner_BannerProduct_product_prices {
  __typename: "ProductPrice";
  id: number;
  price: number;
  price_unit: number | null;
  active_from: any | null;
  active_to: any | null;
}

export interface updateBanner_updateBanner_BannerProduct_product_stock {
  __typename: "ProductStock";
  id: number;
  qty: number;
  updated_at: any | null;
}

export interface updateBanner_updateBanner_BannerProduct_product_brand {
  __typename: "ProductBrand";
  id: number;
  name: string | null;
}

export interface updateBanner_updateBanner_BannerProduct_product_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface updateBanner_updateBanner_BannerProduct_product_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface updateBanner_updateBanner_BannerProduct_product_children_categories_category_path {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface updateBanner_updateBanner_BannerProduct_product_children_categories {
  __typename: "ProductCategory";
  id: number;
  category_path: updateBanner_updateBanner_BannerProduct_product_children_categories_category_path[];
}

export interface updateBanner_updateBanner_BannerProduct_product_children_images {
  __typename: "ProductImage";
  id: number;
  source: ProductImageSource;
  position: number;
  url_list: string | null;
  is_active: boolean;
}

export interface updateBanner_updateBanner_BannerProduct_product_children_param_values {
  __typename: "ProductParamValue";
  product_param_id: string;
  is_multi: boolean;
  values: string[];
}

export interface updateBanner_updateBanner_BannerProduct_product_children_brand_bank_param_values {
  __typename: "ProductParamValue";
  product_param_id: string;
  is_multi: boolean;
  values: string[];
}

export interface updateBanner_updateBanner_BannerProduct_product_children_brand_bank {
  __typename: "ProductBrandBankData";
  id: number;
  param_values: updateBanner_updateBanner_BannerProduct_product_children_brand_bank_param_values[];
}

export interface updateBanner_updateBanner_BannerProduct_product_children_badges_featured {
  __typename: "Badge";
  name: string;
}

export interface updateBanner_updateBanner_BannerProduct_product_children_badges_product_params {
  __typename: "Badge";
  name: string;
}

export interface updateBanner_updateBanner_BannerProduct_product_children_badges_notifications {
  __typename: "Badge";
  name: string;
}

export interface updateBanner_updateBanner_BannerProduct_product_children_prices {
  __typename: "ProductPrice";
  id: number;
  price: number;
  price_unit: number | null;
  active_from: any | null;
  active_to: any | null;
}

export interface updateBanner_updateBanner_BannerProduct_product_children_stock {
  __typename: "ProductStock";
  id: number;
  qty: number;
  updated_at: any | null;
}

export interface updateBanner_updateBanner_BannerProduct_product_children_brand {
  __typename: "ProductBrand";
  id: number;
  name: string | null;
}

export interface updateBanner_updateBanner_BannerProduct_product_children_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface updateBanner_updateBanner_BannerProduct_product_children_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface updateBanner_updateBanner_BannerProduct_product_children {
  __typename: "Product";
  id: number;
  name: string | null;
  lfdnr: number | null;
  ean: string[];
  cart_qty_max: number;
  categories: updateBanner_updateBanner_BannerProduct_product_children_categories[];
  slug: string | null;
  unit_base: string | null;
  images: updateBanner_updateBanner_BannerProduct_product_children_images[];
  is_active: boolean;
  is_force_active: boolean | null;
  active_from: any | null;
  active_to: any | null;
  param_values: updateBanner_updateBanner_BannerProduct_product_children_param_values[];
  brand_bank: updateBanner_updateBanner_BannerProduct_product_children_brand_bank | null;
  badges_featured: updateBanner_updateBanner_BannerProduct_product_children_badges_featured[];
  badges_product_params: updateBanner_updateBanner_BannerProduct_product_children_badges_product_params[];
  badges_notifications: updateBanner_updateBanner_BannerProduct_product_children_badges_notifications[];
  prices: updateBanner_updateBanner_BannerProduct_product_children_prices[];
  stock: updateBanner_updateBanner_BannerProduct_product_children_stock | null;
  brand: updateBanner_updateBanner_BannerProduct_product_children_brand | null;
  created_at: any;
  updated_at: any;
  created_by: updateBanner_updateBanner_BannerProduct_product_children_created_by | null;
  updated_by: updateBanner_updateBanner_BannerProduct_product_children_updated_by | null;
  bundle_promotion_id: number | null;
  image_path_360: string | null;
  image_position_360: number | null;
  brandbank_exclude: boolean | null;
}

export interface updateBanner_updateBanner_BannerProduct_product {
  __typename: "Product";
  id: number;
  name: string | null;
  lfdnr: number | null;
  ean: string[];
  cart_qty_max: number;
  categories: updateBanner_updateBanner_BannerProduct_product_categories[];
  slug: string | null;
  unit_base: string | null;
  images: updateBanner_updateBanner_BannerProduct_product_images[];
  is_active: boolean;
  is_force_active: boolean | null;
  active_from: any | null;
  active_to: any | null;
  param_values: updateBanner_updateBanner_BannerProduct_product_param_values[];
  brand_bank: updateBanner_updateBanner_BannerProduct_product_brand_bank | null;
  badges_featured: updateBanner_updateBanner_BannerProduct_product_badges_featured[];
  badges_product_params: updateBanner_updateBanner_BannerProduct_product_badges_product_params[];
  badges_notifications: updateBanner_updateBanner_BannerProduct_product_badges_notifications[];
  prices: updateBanner_updateBanner_BannerProduct_product_prices[];
  stock: updateBanner_updateBanner_BannerProduct_product_stock | null;
  brand: updateBanner_updateBanner_BannerProduct_product_brand | null;
  created_at: any;
  updated_at: any;
  created_by: updateBanner_updateBanner_BannerProduct_product_created_by | null;
  updated_by: updateBanner_updateBanner_BannerProduct_product_updated_by | null;
  bundle_promotion_id: number | null;
  image_path_360: string | null;
  image_position_360: number | null;
  brandbank_exclude: boolean | null;
  children: updateBanner_updateBanner_BannerProduct_product_children[] | null;
}

export interface updateBanner_updateBanner_BannerProduct_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface updateBanner_updateBanner_BannerProduct_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface updateBanner_updateBanner_BannerProduct {
  __typename: "BannerProduct";
  id: number;
  name: string;
  type: BannerType;
  urls: string[];
  product: updateBanner_updateBanner_BannerProduct_product;
  position: number;
  is_active: boolean;
  active_from: any | null;
  active_to: any | null;
  created_at: any | null;
  created_by: updateBanner_updateBanner_BannerProduct_created_by;
  updated_at: any | null;
  updated_by: updateBanner_updateBanner_BannerProduct_updated_by | null;
}

export type updateBanner_updateBanner = updateBanner_updateBanner_BannerNormal | updateBanner_updateBanner_BannerProductList | updateBanner_updateBanner_BannerProduct;

export interface updateBanner {
  updateBanner: updateBanner_updateBanner | null;
}

export interface updateBannerVariables {
  id: number;
  input: BannerInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateBenefit
// ====================================================

export interface updateBenefit_updateBenefit {
  __typename: "Benefit";
  id: string;
  title: string;
  image: string;
  created_at: any;
  updated_at: any | null;
}

export interface updateBenefit {
  updateBenefit: updateBenefit_updateBenefit;
}

export interface updateBenefitVariables {
  id: string;
  input: BenefitInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateCampaign
// ====================================================

export interface updateCampaign_updateCampaign_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface updateCampaign_updateCampaign_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface updateCampaign_updateCampaign {
  __typename: "Campaign";
  id: number;
  name: string;
  description: string;
  target_url: string | null;
  target_title: string | null;
  image_foreground_desktop: string | null;
  image_background_desktop: string | null;
  image_foreground_mobile: string | null;
  image_background_mobile: string | null;
  active_from: any | null;
  active_to: any | null;
  is_active: boolean;
  created_at: any;
  updated_at: any | null;
  created_by: updateCampaign_updateCampaign_created_by;
  updated_by: updateCampaign_updateCampaign_updated_by | null;
}

export interface updateCampaign {
  updateCampaign: updateCampaign_updateCampaign;
}

export interface updateCampaignVariables {
  id: number;
  input: CampaignInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateCategory
// ====================================================

export interface updateCategory_updateCategory {
  __typename: "Category";
  id: string;
  lead: string | null;
  title: string;
  url: string;
  meta_title: string | null;
  meta_description: string | null;
  meta_keywords: string | null;
  created_at: any;
  updated_at: any | null;
}

export interface updateCategory {
  updateCategory: updateCategory_updateCategory;
}

export interface updateCategoryVariables {
  id: string;
  input: UpdateCategoryInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateContent
// ====================================================

export interface updateContent_updateContent_GalleryContent {
  __typename: "GalleryContent" | "VideoContent";
}

export interface updateContent_updateContent_TextContent {
  __typename: "TextContent";
  id: string;
}

export type updateContent_updateContent = updateContent_updateContent_GalleryContent | updateContent_updateContent_TextContent;

export interface updateContent {
  updateContent: updateContent_updateContent;
}

export interface updateContentVariables {
  id: string;
  input: UpdateContentInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateDepartment
// ====================================================

export interface updateDepartment_updateDepartment_divisions_department {
  __typename: "Department";
  id: string;
  name: string;
}

export interface updateDepartment_updateDepartment_divisions {
  __typename: "Division";
  id: string;
  name: string;
  department: updateDepartment_updateDepartment_divisions_department | null;
  created_at: any;
  updated_at: any | null;
}

export interface updateDepartment_updateDepartment_benefits {
  __typename: "Benefit";
  id: string;
  title: string;
  created_at: any;
  updated_at: any | null;
}

export interface updateDepartment_updateDepartment {
  __typename: "Department";
  id: string;
  name: string;
  is_grouped: boolean;
  is_filter_enabled: boolean | null;
  is_map_enabled: boolean | null;
  order: number;
  divisions: updateDepartment_updateDepartment_divisions[];
  benefits: updateDepartment_updateDepartment_benefits[];
  created_at: any;
  updated_at: any | null;
}

export interface updateDepartment {
  updateDepartment: updateDepartment_updateDepartment;
}

export interface updateDepartmentVariables {
  id: string;
  input: DepartmentInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateDivision
// ====================================================

export interface updateDivision_updateDivision_leaders {
  __typename: "Person";
  id: string;
  name: string;
  position: string;
  created_at: any;
  updated_at: any | null;
}

export interface updateDivision_updateDivision_department {
  __typename: "Department";
  id: string;
  name: string;
  order: number;
  created_at: any;
  updated_at: any | null;
}

export interface updateDivision_updateDivision_benefits {
  __typename: "Benefit";
  id: string;
  title: string;
  created_at: any;
  updated_at: any | null;
}

export interface updateDivision_updateDivision {
  __typename: "Division";
  id: string;
  name: string;
  description: string | null;
  quote: string | null;
  leaders: updateDivision_updateDivision_leaders[];
  department: updateDivision_updateDivision_department | null;
  benefits: updateDivision_updateDivision_benefits[];
  created_at: any;
  updated_at: any | null;
  is_virtual: boolean | null;
}

export interface updateDivision {
  updateDivision: updateDivision_updateDivision;
}

export interface updateDivisionVariables {
  id: string;
  input: DivisionInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateFulfillment
// ====================================================

export interface updateFulfillment_updateFulfillment_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface updateFulfillment_updateFulfillment_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface updateFulfillment_updateFulfillment {
  __typename: "Fulfillment";
  id: number;
  active_from: any;
  active_to: any;
  /**
   * Flag allocation limit between two daily reset timestamp
   */
  limit: number;
  /**
   * Flags allocated in the daily interval since the last reset timestamp
   */
  count: number;
  shipping_methods: string[];
  timewindow_allowed: boolean;
  vip_levels: RossmannPlusVipLevel[];
  created_at: any;
  updated_at: any | null;
  created_by: updateFulfillment_updateFulfillment_created_by | null;
  updated_by: updateFulfillment_updateFulfillment_updated_by | null;
  is_active: boolean;
  /**
   * Send email notification after every X flag assigment
   */
  notification_threshold: number | null;
  quantity_max: number | null;
  package_type_max: PackageType | null;
  product_id_force: number[];
  product_id_exclude: number[];
  type: FulfillmentTypeSelectable;
  weight: number;
}

export interface updateFulfillment {
  updateFulfillment: updateFulfillment_updateFulfillment | null;
}

export interface updateFulfillmentVariables {
  id: number;
  input: FulfillmentInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateGallery
// ====================================================

export interface updateGallery_updateGallery_images {
  __typename: "GalleryImage";
  url: string;
  caption: string | null;
  credit: string | null;
}

export interface updateGallery_updateGallery {
  __typename: "Gallery";
  id: string;
  title: string;
  handle: string | null;
  images: updateGallery_updateGallery_images[];
  image_count: number;
  updated_at: any | null;
}

export interface updateGallery {
  updateGallery: updateGallery_updateGallery;
}

export interface updateGalleryVariables {
  id: string;
  input: UpdateGalleryInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateMailTemplate
// ====================================================

export interface updateMailTemplate_updateMailTemplate {
  __typename: "MailTemplate";
  id: number;
  subject: string;
  content: string;
  description: string | null;
  is_active: boolean;
}

export interface updateMailTemplate {
  updateMailTemplate: updateMailTemplate_updateMailTemplate;
}

export interface updateMailTemplateVariables {
  id: number;
  input: UpdateMailTemplateInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateMaintenance
// ====================================================

export interface updateMaintenance_updateMaintenance {
  __typename: "Maintenance";
  id: string;
  active_from: any;
  active_to: any;
  scopes: MaintenanceScope[] | null;
  notification: string | null;
  maintenance: boolean;
  is_active: boolean;
}

export interface updateMaintenance {
  updateMaintenance: updateMaintenance_updateMaintenance;
}

export interface updateMaintenanceVariables {
  id: string;
  input: MaintenanceInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateMenu
// ====================================================

export interface updateMenu_updateMenu_items_type {
  __typename: "MenuItemType";
  id: string;
  name: string;
}

export interface updateMenu_updateMenu_items_page_overlay {
  __typename: "Page";
  id: number;
  name: string;
}

export interface updateMenu_updateMenu_items_items_type {
  __typename: "MenuItemType";
  id: string;
  name: string;
}

export interface updateMenu_updateMenu_items_items_page_overlay {
  __typename: "Page";
  id: number;
  name: string;
}

export interface updateMenu_updateMenu_items_items_items_type {
  __typename: "MenuItemType";
  id: string;
  name: string;
}

export interface updateMenu_updateMenu_items_items_items_page_overlay {
  __typename: "Page";
  id: number;
  name: string;
}

export interface updateMenu_updateMenu_items_items_items_items_type {
  __typename: "MenuItemType";
  id: string;
  name: string;
}

export interface updateMenu_updateMenu_items_items_items_items_page_overlay {
  __typename: "Page";
  id: number;
  name: string;
}

export interface updateMenu_updateMenu_items_items_items_items_items_type {
  __typename: "MenuItemType";
  id: string;
  name: string;
}

export interface updateMenu_updateMenu_items_items_items_items_items_page_overlay {
  __typename: "Page";
  id: number;
  name: string;
}

export interface updateMenu_updateMenu_items_items_items_items_items_items_type {
  __typename: "MenuItemType";
  id: string;
  name: string;
}

export interface updateMenu_updateMenu_items_items_items_items_items_items_page_overlay {
  __typename: "Page";
  id: number;
  name: string;
}

export interface updateMenu_updateMenu_items_items_items_items_items_items {
  __typename: "MenuItem";
  type: updateMenu_updateMenu_items_items_items_items_items_items_type;
  model_id: number | null;
  title: string | null;
  url: string | null;
  popup: boolean;
  is_active: boolean;
  page_overlay: updateMenu_updateMenu_items_items_items_items_items_items_page_overlay | null;
}

export interface updateMenu_updateMenu_items_items_items_items_items {
  __typename: "MenuItem";
  type: updateMenu_updateMenu_items_items_items_items_items_type;
  model_id: number | null;
  title: string | null;
  url: string | null;
  popup: boolean;
  is_active: boolean;
  page_overlay: updateMenu_updateMenu_items_items_items_items_items_page_overlay | null;
  items: updateMenu_updateMenu_items_items_items_items_items_items[] | null;
}

export interface updateMenu_updateMenu_items_items_items_items {
  __typename: "MenuItem";
  type: updateMenu_updateMenu_items_items_items_items_type;
  model_id: number | null;
  title: string | null;
  url: string | null;
  popup: boolean;
  is_active: boolean;
  page_overlay: updateMenu_updateMenu_items_items_items_items_page_overlay | null;
  items: updateMenu_updateMenu_items_items_items_items_items[] | null;
}

export interface updateMenu_updateMenu_items_items_items {
  __typename: "MenuItem";
  type: updateMenu_updateMenu_items_items_items_type;
  model_id: number | null;
  title: string | null;
  url: string | null;
  popup: boolean;
  is_active: boolean;
  page_overlay: updateMenu_updateMenu_items_items_items_page_overlay | null;
  items: updateMenu_updateMenu_items_items_items_items[] | null;
}

export interface updateMenu_updateMenu_items_items {
  __typename: "MenuItem";
  type: updateMenu_updateMenu_items_items_type;
  model_id: number | null;
  title: string | null;
  url: string | null;
  popup: boolean;
  is_active: boolean;
  page_overlay: updateMenu_updateMenu_items_items_page_overlay | null;
  items: updateMenu_updateMenu_items_items_items[] | null;
}

export interface updateMenu_updateMenu_items {
  __typename: "MenuItem";
  type: updateMenu_updateMenu_items_type;
  model_id: number | null;
  title: string | null;
  url: string | null;
  popup: boolean;
  is_active: boolean;
  page_overlay: updateMenu_updateMenu_items_page_overlay | null;
  items: updateMenu_updateMenu_items_items[] | null;
}

export interface updateMenu_updateMenu_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface updateMenu_updateMenu_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface updateMenu_updateMenu {
  __typename: "Menu";
  id: number;
  slug: string;
  name: string;
  created_at: any;
  updated_at: any | null;
  is_active: boolean;
  active_from: any | null;
  active_to: any | null;
  items: updateMenu_updateMenu_items[];
  created_by: updateMenu_updateMenu_created_by;
  updated_by: updateMenu_updateMenu_updated_by | null;
}

export interface updateMenu {
  updateMenu: updateMenu_updateMenu;
}

export interface updateMenuVariables {
  id: number;
  input: MenuInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateOrder
// ====================================================

export interface updateOrder_updateOrder_user {
  __typename: "User";
  email: string;
}

export interface updateOrder_updateOrder_status {
  __typename: "OrderStatus";
  id: OrderStatusEnum;
  title: string;
}

export interface updateOrder_updateOrder_shipping_method_cost_time_windows {
  __typename: "TimeWindowCost";
  interval_hours: number;
}

export interface updateOrder_updateOrder_shipping_method {
  __typename: "ShippingMethod";
  name: string | null;
  delivery_days: number | null;
  cost_time_windows: updateOrder_updateOrder_shipping_method_cost_time_windows[] | null;
}

export interface updateOrder_updateOrder_delivery_time_window {
  __typename: "DeliveryTimeWindow";
  date: any;
  weekday: string;
  interval: string;
  interval_length: number;
}

export interface updateOrder_updateOrder_shipping_address {
  __typename: "UserAddress";
  firstname: string | null;
  lastname: string | null;
  zip_code: string | null;
  city: string | null;
  address_line1: string | null;
  address_line2: string | null;
  company_name: string | null;
  company_number: string | null;
}

export interface updateOrder_updateOrder_billing_address {
  __typename: "UserAddress";
  firstname: string | null;
  lastname: string | null;
  zip_code: string | null;
  city: string | null;
  address_line1: string | null;
  address_line2: string | null;
  company_name: string | null;
  company_number: string | null;
}

export interface updateOrder_updateOrder_payment_method {
  __typename: "PaymentMethod";
  id: string;
  name: string | null;
}

export interface updateOrder_updateOrder_payment_status {
  __typename: "PaymentStatus";
  id: PaymentStatusEnum;
  title: string;
}

export interface updateOrder_updateOrder_payment_transaction_latest {
  __typename: "PaymentTransaction";
  id: number;
  anum: string | null;
}

export interface updateOrder_updateOrder_payment_transactions_payment_method {
  __typename: "PaymentMethod";
  name: string | null;
}

export interface updateOrder_updateOrder_payment_transactions {
  __typename: "PaymentTransaction";
  id: number;
  pmgw_transaction_id: string;
  provider_transaction_id: string | null;
  anum: string | null;
  payment_method: updateOrder_updateOrder_payment_transactions_payment_method;
  status_payment: string | null;
  amount: number;
  amount_refund: number | null;
  success: boolean;
}

export interface updateOrder_updateOrder_payment {
  __typename: "OrderPayment";
  status: updateOrder_updateOrder_payment_status;
  transaction_latest: updateOrder_updateOrder_payment_transaction_latest | null;
  amount_paid: number;
  amount_refunded: number;
  transactions: updateOrder_updateOrder_payment_transactions[];
}

export interface updateOrder_updateOrder_admin_notes_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface updateOrder_updateOrder_admin_notes {
  __typename: "AdminNote";
  note: string | null;
  created_by: updateOrder_updateOrder_admin_notes_created_by | null;
  created_at: any | null;
}

export interface updateOrder_updateOrder_items_product {
  __typename: "Product";
  name: string | null;
  lfdnr: number | null;
  ean: string[];
}

export interface updateOrder_updateOrder_items_discounts {
  __typename: "OrderItemDiscount";
  promotion_name: string;
  amount: number;
}

export interface updateOrder_updateOrder_items {
  __typename: "OrderItem";
  id: number;
  product: updateOrder_updateOrder_items_product;
  quantity: number;
  unit_price: number;
  sub_total: number;
  discount_total: number;
  discounts: updateOrder_updateOrder_items_discounts[];
  type: OrderItemType;
  quantity_missing: number;
  quantity_delivered: number;
}

export interface updateOrder_updateOrder_shipment {
  __typename: "RossmannShipment";
  status: ShipmentStatusEnum | null;
  delivered_at: any | null;
  tracking_url: string | null;
  tracking_numbers: string[];
  sent_at: any | null;
  delivery_in_progress_at: any | null;
  delivery_day_expected: any | null;
  can_retry: boolean;
}

export interface updateOrder_updateOrder_invoice_status {
  __typename: "InvoiceStatus";
  title: string;
}

export interface updateOrder_updateOrder_invoices {
  __typename: "OrderInvoice";
  type: InvoiceTypeEnum;
  invoice_number: string | null;
  url: string | null;
  url_remote: string | null;
  created_at: any;
  invoiced_at: any | null;
}

export interface updateOrder_updateOrder_pickup_point {
  __typename: "Store" | "MplPickupPoint" | "GlsPickupPoint" | "FoxpostPickupPoint" | "EasyboxPickupPoint";
  id: number;
  address: string;
}

export interface updateOrder_updateOrder_gift_cards {
  __typename: "GiftCardPayment";
  barcode: string;
  amount: number;
  voided_amount: number;
  paid_at: any | null;
  voided_at: any | null;
}

export interface updateOrder_updateOrder {
  __typename: "Order";
  id: number;
  increment_id: number | null;
  created_at: any;
  contact_fullname: string;
  email: string | null;
  phone_number: string | null;
  user: updateOrder_updateOrder_user | null;
  status: updateOrder_updateOrder_status;
  shipping_method: updateOrder_updateOrder_shipping_method;
  delivery_day_expected: any | null;
  delivery_time_window: updateOrder_updateOrder_delivery_time_window | null;
  shipping_address: updateOrder_updateOrder_shipping_address | null;
  billing_address: updateOrder_updateOrder_billing_address;
  payment_method: updateOrder_updateOrder_payment_method;
  grand_total: number;
  tax_total: number;
  payment: updateOrder_updateOrder_payment | null;
  admin_notes: updateOrder_updateOrder_admin_notes[];
  items: updateOrder_updateOrder_items[];
  shipment: updateOrder_updateOrder_shipment | null;
  invoice_status: updateOrder_updateOrder_invoice_status | null;
  shipping_note: string | null;
  grand_total_netto: number;
  invoices: updateOrder_updateOrder_invoices[];
  pickup_point: updateOrder_updateOrder_pickup_point | null;
  can_be_modified: boolean;
  can_be_deleted: boolean;
  evo_sent_at: any | null;
  user_agent_type: string;
  app_version: string | null;
  no_package: boolean | null;
  gift_cards: updateOrder_updateOrder_gift_cards[];
  gift_card_payment: number;
  can_storno: boolean;
  can_refund: boolean;
}

export interface updateOrder {
  /**
   * Update order's note and payment status
   */
  updateOrder: updateOrder_updateOrder;
}

export interface updateOrderVariables {
  increment_id: number;
  input: OrderInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updatePage
// ====================================================

export interface updatePage_updatePage_site {
  __typename: "Site";
  id: string;
  name: string;
  url: string;
}

export interface updatePage_updatePage_created_by {
  __typename: "Admin";
  name: string;
}

export interface updatePage_updatePage_updated_by {
  __typename: "Admin";
  name: string;
}

export interface updatePage_updatePage_blocks_PageBlockBanner_banners_BannerNormal {
  __typename: "BannerNormal";
  id: number;
  name: string;
}

export interface updatePage_updatePage_blocks_PageBlockBanner_banners_BannerProductList {
  __typename: "BannerProductList";
  id: number;
  name: string;
}

export interface updatePage_updatePage_blocks_PageBlockBanner_banners_BannerProduct {
  __typename: "BannerProduct";
  id: number;
  name: string;
}

export type updatePage_updatePage_blocks_PageBlockBanner_banners = updatePage_updatePage_blocks_PageBlockBanner_banners_BannerNormal | updatePage_updatePage_blocks_PageBlockBanner_banners_BannerProductList | updatePage_updatePage_blocks_PageBlockBanner_banners_BannerProduct;

export interface updatePage_updatePage_blocks_PageBlockBanner {
  __typename: "PageBlockBanner";
  id: number;
  type: PageBlockType;
  layout: PageBlockBannerLayout;
  title: string | null;
  banners: updatePage_updatePage_blocks_PageBlockBanner_banners[];
}

export interface updatePage_updatePage_blocks_PageBlockProductList_product_list {
  __typename: "ProductList";
  id: number;
  name: string;
}

export interface updatePage_updatePage_blocks_PageBlockProductList_product_sort_by {
  __typename: "ProductSortFrontend";
  field: ProductSortFieldCategory;
  direction: OrderDirection;
}

export interface updatePage_updatePage_blocks_PageBlockProductList {
  __typename: "PageBlockProductList";
  id: number;
  type: PageBlockType;
  title: string | null;
  product_list: updatePage_updatePage_blocks_PageBlockProductList_product_list | null;
  is_scrollable: boolean;
  is_filterable: boolean;
  product_limit: number | null;
  product_sort_by: updatePage_updatePage_blocks_PageBlockProductList_product_sort_by | null;
}

export interface updatePage_updatePage_blocks_PageBlockHtml {
  __typename: "PageBlockHtml";
  id: number;
  type: PageBlockType;
  title: string | null;
  content: string;
}

export interface updatePage_updatePage_blocks_PageBlockNewsletter {
  __typename: "PageBlockNewsletter";
  id: number;
  type: PageBlockType;
  title: string | null;
}

export interface updatePage_updatePage_blocks_PageBlockBrandList {
  __typename: "PageBlockBrandList";
  id: number;
  type: PageBlockType;
  title: string | null;
}

export interface updatePage_updatePage_blocks_PageBlockRecommendedProducts {
  __typename: "PageBlockRecommendedProducts";
  id: number;
  type: PageBlockType;
  title: string | null;
}

export interface updatePage_updatePage_blocks_PageBlockHomebox {
  __typename: "PageBlockHomebox";
  id: number;
  type: PageBlockType;
  title: string | null;
}

export type updatePage_updatePage_blocks = updatePage_updatePage_blocks_PageBlockBanner | updatePage_updatePage_blocks_PageBlockProductList | updatePage_updatePage_blocks_PageBlockHtml | updatePage_updatePage_blocks_PageBlockNewsletter | updatePage_updatePage_blocks_PageBlockBrandList | updatePage_updatePage_blocks_PageBlockRecommendedProducts | updatePage_updatePage_blocks_PageBlockHomebox;

export interface updatePage_updatePage {
  __typename: "Page";
  id: number;
  name: string;
  title: string | null;
  url: string;
  is_active: boolean;
  is_visible: boolean;
  active_from: any | null;
  active_to: any | null;
  meta_title: string | null;
  meta_description: string | null;
  og_title: string | null;
  og_description: string | null;
  og_image: string | null;
  background_image: string | null;
  created_at: any;
  updated_at: any | null;
  site: updatePage_updatePage_site;
  created_by: updatePage_updatePage_created_by;
  updated_by: updatePage_updatePage_updated_by | null;
  blocks: updatePage_updatePage_blocks[];
  is_header: boolean;
  is_footer: boolean;
}

export interface updatePage {
  updatePage: updatePage_updatePage;
}

export interface updatePageVariables {
  id: number;
  input: PageInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updatePaymentMethod
// ====================================================

export interface updatePaymentMethod_updatePaymentMethod {
  __typename: "PaymentMethod";
  id: string;
  name: string | null;
  description: string | null;
  cost: number;
  is_active: boolean;
  cart_value_max: number | null;
}

export interface updatePaymentMethod {
  updatePaymentMethod: updatePaymentMethod_updatePaymentMethod | null;
}

export interface updatePaymentMethodVariables {
  id: string;
  input: PaymentMethodInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updatePerson
// ====================================================

export interface updatePerson_updatePerson {
  __typename: "Person";
  id: string;
  name: string;
  image: string;
  position: string;
  created_at: any;
  updated_at: any | null;
}

export interface updatePerson {
  updatePerson: updatePerson_updatePerson;
}

export interface updatePersonVariables {
  id: string;
  input: PersonInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateProductBrand
// ====================================================

export interface updateProductBrand_updateProductBrand_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface updateProductBrand_updateProductBrand {
  __typename: "ProductBrand";
  id: number;
  name: string | null;
  evo_name: string;
  slug: string | null;
  logo: string | null;
  logo_title: string | null;
  meta_title: string | null;
  meta_description: string | null;
  og_image: string | null;
  og_title: string | null;
  og_description: string | null;
  is_logo: boolean;
  is_public: boolean;
  is_rossmann: boolean;
  created_at: any | null;
  updated_at: any | null;
  updated_by: updateProductBrand_updateProductBrand_updated_by | null;
}

export interface updateProductBrand {
  updateProductBrand: updateProductBrand_updateProductBrand | null;
}

export interface updateProductBrandVariables {
  id: number;
  input: ProductBrandInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateProductBundle
// ====================================================

export interface updateProductBundle_updateProductBundle_categories_category_path {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface updateProductBundle_updateProductBundle_categories {
  __typename: "ProductCategory";
  id: number;
  category_path: updateProductBundle_updateProductBundle_categories_category_path[];
}

export interface updateProductBundle_updateProductBundle_images {
  __typename: "ProductImage";
  id: number;
  source: ProductImageSource;
  position: number;
  url_list: string | null;
  is_active: boolean;
}

export interface updateProductBundle_updateProductBundle_param_values {
  __typename: "ProductParamValue";
  product_param_id: string;
  is_multi: boolean;
  values: string[];
}

export interface updateProductBundle_updateProductBundle_brand_bank_param_values {
  __typename: "ProductParamValue";
  product_param_id: string;
  is_multi: boolean;
  values: string[];
}

export interface updateProductBundle_updateProductBundle_brand_bank {
  __typename: "ProductBrandBankData";
  id: number;
  param_values: updateProductBundle_updateProductBundle_brand_bank_param_values[];
}

export interface updateProductBundle_updateProductBundle_badges_featured {
  __typename: "Badge";
  name: string;
}

export interface updateProductBundle_updateProductBundle_badges_product_params {
  __typename: "Badge";
  name: string;
}

export interface updateProductBundle_updateProductBundle_badges_notifications {
  __typename: "Badge";
  name: string;
}

export interface updateProductBundle_updateProductBundle_prices {
  __typename: "ProductPrice";
  id: number;
  price: number;
  price_unit: number | null;
  active_from: any | null;
  active_to: any | null;
}

export interface updateProductBundle_updateProductBundle_stock {
  __typename: "ProductStock";
  id: number;
  qty: number;
  updated_at: any | null;
}

export interface updateProductBundle_updateProductBundle_brand {
  __typename: "ProductBrand";
  id: number;
  name: string | null;
}

export interface updateProductBundle_updateProductBundle_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface updateProductBundle_updateProductBundle_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface updateProductBundle_updateProductBundle_children_categories_category_path {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface updateProductBundle_updateProductBundle_children_categories {
  __typename: "ProductCategory";
  id: number;
  category_path: updateProductBundle_updateProductBundle_children_categories_category_path[];
}

export interface updateProductBundle_updateProductBundle_children_images {
  __typename: "ProductImage";
  id: number;
  source: ProductImageSource;
  position: number;
  url_list: string | null;
  is_active: boolean;
}

export interface updateProductBundle_updateProductBundle_children_param_values {
  __typename: "ProductParamValue";
  product_param_id: string;
  is_multi: boolean;
  values: string[];
}

export interface updateProductBundle_updateProductBundle_children_brand_bank_param_values {
  __typename: "ProductParamValue";
  product_param_id: string;
  is_multi: boolean;
  values: string[];
}

export interface updateProductBundle_updateProductBundle_children_brand_bank {
  __typename: "ProductBrandBankData";
  id: number;
  param_values: updateProductBundle_updateProductBundle_children_brand_bank_param_values[];
}

export interface updateProductBundle_updateProductBundle_children_badges_featured {
  __typename: "Badge";
  name: string;
}

export interface updateProductBundle_updateProductBundle_children_badges_product_params {
  __typename: "Badge";
  name: string;
}

export interface updateProductBundle_updateProductBundle_children_badges_notifications {
  __typename: "Badge";
  name: string;
}

export interface updateProductBundle_updateProductBundle_children_prices {
  __typename: "ProductPrice";
  id: number;
  price: number;
  price_unit: number | null;
  active_from: any | null;
  active_to: any | null;
}

export interface updateProductBundle_updateProductBundle_children_stock {
  __typename: "ProductStock";
  id: number;
  qty: number;
  updated_at: any | null;
}

export interface updateProductBundle_updateProductBundle_children_brand {
  __typename: "ProductBrand";
  id: number;
  name: string | null;
}

export interface updateProductBundle_updateProductBundle_children_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface updateProductBundle_updateProductBundle_children_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface updateProductBundle_updateProductBundle_children {
  __typename: "Product";
  id: number;
  name: string | null;
  lfdnr: number | null;
  ean: string[];
  cart_qty_max: number;
  categories: updateProductBundle_updateProductBundle_children_categories[];
  slug: string | null;
  unit_base: string | null;
  images: updateProductBundle_updateProductBundle_children_images[];
  is_active: boolean;
  is_force_active: boolean | null;
  active_from: any | null;
  active_to: any | null;
  param_values: updateProductBundle_updateProductBundle_children_param_values[];
  brand_bank: updateProductBundle_updateProductBundle_children_brand_bank | null;
  badges_featured: updateProductBundle_updateProductBundle_children_badges_featured[];
  badges_product_params: updateProductBundle_updateProductBundle_children_badges_product_params[];
  badges_notifications: updateProductBundle_updateProductBundle_children_badges_notifications[];
  prices: updateProductBundle_updateProductBundle_children_prices[];
  stock: updateProductBundle_updateProductBundle_children_stock | null;
  brand: updateProductBundle_updateProductBundle_children_brand | null;
  created_at: any;
  updated_at: any;
  created_by: updateProductBundle_updateProductBundle_children_created_by | null;
  updated_by: updateProductBundle_updateProductBundle_children_updated_by | null;
  bundle_promotion_id: number | null;
  image_path_360: string | null;
  image_position_360: number | null;
  brandbank_exclude: boolean | null;
}

export interface updateProductBundle_updateProductBundle {
  __typename: "Product";
  id: number;
  name: string | null;
  lfdnr: number | null;
  ean: string[];
  cart_qty_max: number;
  categories: updateProductBundle_updateProductBundle_categories[];
  slug: string | null;
  unit_base: string | null;
  images: updateProductBundle_updateProductBundle_images[];
  is_active: boolean;
  is_force_active: boolean | null;
  active_from: any | null;
  active_to: any | null;
  param_values: updateProductBundle_updateProductBundle_param_values[];
  brand_bank: updateProductBundle_updateProductBundle_brand_bank | null;
  badges_featured: updateProductBundle_updateProductBundle_badges_featured[];
  badges_product_params: updateProductBundle_updateProductBundle_badges_product_params[];
  badges_notifications: updateProductBundle_updateProductBundle_badges_notifications[];
  prices: updateProductBundle_updateProductBundle_prices[];
  stock: updateProductBundle_updateProductBundle_stock | null;
  brand: updateProductBundle_updateProductBundle_brand | null;
  created_at: any;
  updated_at: any;
  created_by: updateProductBundle_updateProductBundle_created_by | null;
  updated_by: updateProductBundle_updateProductBundle_updated_by | null;
  bundle_promotion_id: number | null;
  image_path_360: string | null;
  image_position_360: number | null;
  brandbank_exclude: boolean | null;
  children: updateProductBundle_updateProductBundle_children[] | null;
}

export interface updateProductBundle {
  updateProductBundle: updateProductBundle_updateProductBundle | null;
}

export interface updateProductBundleVariables {
  id: number;
  input: ProductBundleInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateProductCategory
// ====================================================

export interface updateProductCategory_updateProductCategory_banners {
  __typename: "ProductCategoryBanner";
  is_active: boolean;
  active_from: any | null;
  active_to: any | null;
  image: string;
  image_title: string | null;
  link: string | null;
  link_new_tab: boolean;
}

export interface updateProductCategory_updateProductCategory_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface updateProductCategory_updateProductCategory_product_sort_by {
  __typename: "ProductSortFrontend";
  field: ProductSortFieldCategory;
  direction: OrderDirection;
}

export interface updateProductCategory_updateProductCategory_parent {
  __typename: "ProductCategory";
  id: number;
}

export interface updateProductCategory_updateProductCategory {
  __typename: "ProductCategory";
  id: number;
  name: string;
  icon: string | null;
  slug: string;
  icon_title: string | null;
  description: string | null;
  banners: updateProductCategory_updateProductCategory_banners[];
  is_active: boolean;
  include_in_menu: boolean;
  is_featured: boolean;
  feed_facebook: boolean;
  feed_google: boolean;
  feed_google_merchant: boolean;
  feed_dsa: boolean;
  og_image: string | null;
  og_title: string | null;
  og_description: string | null;
  meta_description: string | null;
  updated_at: any | null;
  updated_by: updateProductCategory_updateProductCategory_updated_by | null;
  product_sort_by: updateProductCategory_updateProductCategory_product_sort_by | null;
  parent: updateProductCategory_updateProductCategory_parent | null;
}

export interface updateProductCategory {
  updateProductCategory: updateProductCategory_updateProductCategory | null;
}

export interface updateProductCategoryVariables {
  id: number;
  input: ProductCategoryUpdateInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateProductGratis
// ====================================================

export interface updateProductGratis_updateProductGratis_categories_category_path {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface updateProductGratis_updateProductGratis_categories {
  __typename: "ProductCategory";
  id: number;
  category_path: updateProductGratis_updateProductGratis_categories_category_path[];
}

export interface updateProductGratis_updateProductGratis_images {
  __typename: "ProductImage";
  id: number;
  source: ProductImageSource;
  position: number;
  url_list: string | null;
  is_active: boolean;
}

export interface updateProductGratis_updateProductGratis_param_values {
  __typename: "ProductParamValue";
  product_param_id: string;
  is_multi: boolean;
  values: string[];
}

export interface updateProductGratis_updateProductGratis_brand_bank_param_values {
  __typename: "ProductParamValue";
  product_param_id: string;
  is_multi: boolean;
  values: string[];
}

export interface updateProductGratis_updateProductGratis_brand_bank {
  __typename: "ProductBrandBankData";
  id: number;
  param_values: updateProductGratis_updateProductGratis_brand_bank_param_values[];
}

export interface updateProductGratis_updateProductGratis_badges_featured {
  __typename: "Badge";
  name: string;
}

export interface updateProductGratis_updateProductGratis_badges_product_params {
  __typename: "Badge";
  name: string;
}

export interface updateProductGratis_updateProductGratis_badges_notifications {
  __typename: "Badge";
  name: string;
}

export interface updateProductGratis_updateProductGratis_prices {
  __typename: "ProductPrice";
  id: number;
  price: number;
  price_unit: number | null;
  active_from: any | null;
  active_to: any | null;
}

export interface updateProductGratis_updateProductGratis_stock {
  __typename: "ProductStock";
  id: number;
  qty: number;
  updated_at: any | null;
}

export interface updateProductGratis_updateProductGratis_brand {
  __typename: "ProductBrand";
  id: number;
  name: string | null;
}

export interface updateProductGratis_updateProductGratis_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface updateProductGratis_updateProductGratis_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface updateProductGratis_updateProductGratis_children_categories_category_path {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface updateProductGratis_updateProductGratis_children_categories {
  __typename: "ProductCategory";
  id: number;
  category_path: updateProductGratis_updateProductGratis_children_categories_category_path[];
}

export interface updateProductGratis_updateProductGratis_children_images {
  __typename: "ProductImage";
  id: number;
  source: ProductImageSource;
  position: number;
  url_list: string | null;
  is_active: boolean;
}

export interface updateProductGratis_updateProductGratis_children_param_values {
  __typename: "ProductParamValue";
  product_param_id: string;
  is_multi: boolean;
  values: string[];
}

export interface updateProductGratis_updateProductGratis_children_brand_bank_param_values {
  __typename: "ProductParamValue";
  product_param_id: string;
  is_multi: boolean;
  values: string[];
}

export interface updateProductGratis_updateProductGratis_children_brand_bank {
  __typename: "ProductBrandBankData";
  id: number;
  param_values: updateProductGratis_updateProductGratis_children_brand_bank_param_values[];
}

export interface updateProductGratis_updateProductGratis_children_badges_featured {
  __typename: "Badge";
  name: string;
}

export interface updateProductGratis_updateProductGratis_children_badges_product_params {
  __typename: "Badge";
  name: string;
}

export interface updateProductGratis_updateProductGratis_children_badges_notifications {
  __typename: "Badge";
  name: string;
}

export interface updateProductGratis_updateProductGratis_children_prices {
  __typename: "ProductPrice";
  id: number;
  price: number;
  price_unit: number | null;
  active_from: any | null;
  active_to: any | null;
}

export interface updateProductGratis_updateProductGratis_children_stock {
  __typename: "ProductStock";
  id: number;
  qty: number;
  updated_at: any | null;
}

export interface updateProductGratis_updateProductGratis_children_brand {
  __typename: "ProductBrand";
  id: number;
  name: string | null;
}

export interface updateProductGratis_updateProductGratis_children_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface updateProductGratis_updateProductGratis_children_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface updateProductGratis_updateProductGratis_children {
  __typename: "Product";
  id: number;
  name: string | null;
  lfdnr: number | null;
  ean: string[];
  cart_qty_max: number;
  categories: updateProductGratis_updateProductGratis_children_categories[];
  slug: string | null;
  unit_base: string | null;
  images: updateProductGratis_updateProductGratis_children_images[];
  is_active: boolean;
  is_force_active: boolean | null;
  active_from: any | null;
  active_to: any | null;
  param_values: updateProductGratis_updateProductGratis_children_param_values[];
  brand_bank: updateProductGratis_updateProductGratis_children_brand_bank | null;
  badges_featured: updateProductGratis_updateProductGratis_children_badges_featured[];
  badges_product_params: updateProductGratis_updateProductGratis_children_badges_product_params[];
  badges_notifications: updateProductGratis_updateProductGratis_children_badges_notifications[];
  prices: updateProductGratis_updateProductGratis_children_prices[];
  stock: updateProductGratis_updateProductGratis_children_stock | null;
  brand: updateProductGratis_updateProductGratis_children_brand | null;
  created_at: any;
  updated_at: any;
  created_by: updateProductGratis_updateProductGratis_children_created_by | null;
  updated_by: updateProductGratis_updateProductGratis_children_updated_by | null;
  bundle_promotion_id: number | null;
  image_path_360: string | null;
  image_position_360: number | null;
  brandbank_exclude: boolean | null;
}

export interface updateProductGratis_updateProductGratis {
  __typename: "Product";
  id: number;
  name: string | null;
  lfdnr: number | null;
  ean: string[];
  cart_qty_max: number;
  categories: updateProductGratis_updateProductGratis_categories[];
  slug: string | null;
  unit_base: string | null;
  images: updateProductGratis_updateProductGratis_images[];
  is_active: boolean;
  is_force_active: boolean | null;
  active_from: any | null;
  active_to: any | null;
  param_values: updateProductGratis_updateProductGratis_param_values[];
  brand_bank: updateProductGratis_updateProductGratis_brand_bank | null;
  badges_featured: updateProductGratis_updateProductGratis_badges_featured[];
  badges_product_params: updateProductGratis_updateProductGratis_badges_product_params[];
  badges_notifications: updateProductGratis_updateProductGratis_badges_notifications[];
  prices: updateProductGratis_updateProductGratis_prices[];
  stock: updateProductGratis_updateProductGratis_stock | null;
  brand: updateProductGratis_updateProductGratis_brand | null;
  created_at: any;
  updated_at: any;
  created_by: updateProductGratis_updateProductGratis_created_by | null;
  updated_by: updateProductGratis_updateProductGratis_updated_by | null;
  bundle_promotion_id: number | null;
  image_path_360: string | null;
  image_position_360: number | null;
  brandbank_exclude: boolean | null;
  children: updateProductGratis_updateProductGratis_children[] | null;
}

export interface updateProductGratis {
  updateProductGratis: updateProductGratis_updateProductGratis | null;
}

export interface updateProductGratisVariables {
  id: number;
  input: ProductGratisInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateProductList
// ====================================================

export interface updateProductList_updateProductList_products_main_categories {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface updateProductList_updateProductList_products_categories {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface updateProductList_updateProductList_products {
  __typename: "ProductSimple";
  id: number;
  lfdnr: number | null;
  name: string | null;
  main_categories: updateProductList_updateProductList_products_main_categories[];
  categories: updateProductList_updateProductList_products_categories[];
  is_visible: boolean;
  active_from: any | null;
  is_description: boolean;
  is_image: boolean;
  ean: string[];
  price: number | null;
  stock_qty: number | null;
  slug: string | null;
}

export interface updateProductList_updateProductList_badge {
  __typename: "Badge";
  id: number;
  name: string;
  type: BadgeType;
  created_at: any | null;
  active_from: any | null;
  is_active: boolean;
  is_visible: boolean;
}

export interface updateProductList_updateProductList_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface updateProductList_updateProductList_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface updateProductList_updateProductList_filters {
  __typename: "ProductFilter";
  type: ProductFilterType;
  operator: ProductFilterOperator | null;
  product_brand_id: number | null;
  product_category_id: number | null;
  product_param_id: string | null;
  clpe_promotion_id: number | null;
  price_type: PriceType | null;
  clpe_promotion_type: ClpePromotionType | null;
  created_at: any | null;
  rating: number | null;
  product_type: ProductType | null;
}

export interface updateProductList_updateProductList {
  __typename: "ProductList";
  id: number;
  type: ProductListType;
  name: string;
  slug: string;
  mode: ProductListMode;
  is_active: boolean;
  active_from: any | null;
  active_to: any | null;
  title: string;
  subtitle: string | null;
  image: string | null;
  image_title: string | null;
  image_mobile: string | null;
  image_mobile_title: string | null;
  products: updateProductList_updateProductList_products[];
  group_by_category: boolean;
  badge: updateProductList_updateProductList_badge | null;
  og_image: string | null;
  og_title: string | null;
  og_description: string | null;
  meta_description: string | null;
  created_at: any;
  created_by: updateProductList_updateProductList_created_by;
  updated_at: any | null;
  updated_by: updateProductList_updateProductList_updated_by | null;
  filters: updateProductList_updateProductList_filters[];
}

export interface updateProductList {
  updateProductList: updateProductList_updateProductList;
}

export interface updateProductListVariables {
  id: number;
  input: ProductListInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateProduct
// ====================================================

export interface updateProduct_updateProduct_categories_category_path {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface updateProduct_updateProduct_categories {
  __typename: "ProductCategory";
  id: number;
  category_path: updateProduct_updateProduct_categories_category_path[];
}

export interface updateProduct_updateProduct_images {
  __typename: "ProductImage";
  id: number;
  source: ProductImageSource;
  position: number;
  url_list: string | null;
  is_active: boolean;
}

export interface updateProduct_updateProduct_param_values {
  __typename: "ProductParamValue";
  product_param_id: string;
  is_multi: boolean;
  values: string[];
}

export interface updateProduct_updateProduct_brand_bank_param_values {
  __typename: "ProductParamValue";
  product_param_id: string;
  is_multi: boolean;
  values: string[];
}

export interface updateProduct_updateProduct_brand_bank {
  __typename: "ProductBrandBankData";
  id: number;
  param_values: updateProduct_updateProduct_brand_bank_param_values[];
}

export interface updateProduct_updateProduct_badges_featured {
  __typename: "Badge";
  name: string;
}

export interface updateProduct_updateProduct_badges_product_params {
  __typename: "Badge";
  name: string;
}

export interface updateProduct_updateProduct_badges_notifications {
  __typename: "Badge";
  name: string;
}

export interface updateProduct_updateProduct_prices {
  __typename: "ProductPrice";
  id: number;
  price: number;
  price_unit: number | null;
  active_from: any | null;
  active_to: any | null;
}

export interface updateProduct_updateProduct_stock {
  __typename: "ProductStock";
  id: number;
  qty: number;
  updated_at: any | null;
}

export interface updateProduct_updateProduct_brand {
  __typename: "ProductBrand";
  id: number;
  name: string | null;
}

export interface updateProduct_updateProduct_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface updateProduct_updateProduct_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface updateProduct_updateProduct_children_categories_category_path {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface updateProduct_updateProduct_children_categories {
  __typename: "ProductCategory";
  id: number;
  category_path: updateProduct_updateProduct_children_categories_category_path[];
}

export interface updateProduct_updateProduct_children_images {
  __typename: "ProductImage";
  id: number;
  source: ProductImageSource;
  position: number;
  url_list: string | null;
  is_active: boolean;
}

export interface updateProduct_updateProduct_children_param_values {
  __typename: "ProductParamValue";
  product_param_id: string;
  is_multi: boolean;
  values: string[];
}

export interface updateProduct_updateProduct_children_brand_bank_param_values {
  __typename: "ProductParamValue";
  product_param_id: string;
  is_multi: boolean;
  values: string[];
}

export interface updateProduct_updateProduct_children_brand_bank {
  __typename: "ProductBrandBankData";
  id: number;
  param_values: updateProduct_updateProduct_children_brand_bank_param_values[];
}

export interface updateProduct_updateProduct_children_badges_featured {
  __typename: "Badge";
  name: string;
}

export interface updateProduct_updateProduct_children_badges_product_params {
  __typename: "Badge";
  name: string;
}

export interface updateProduct_updateProduct_children_badges_notifications {
  __typename: "Badge";
  name: string;
}

export interface updateProduct_updateProduct_children_prices {
  __typename: "ProductPrice";
  id: number;
  price: number;
  price_unit: number | null;
  active_from: any | null;
  active_to: any | null;
}

export interface updateProduct_updateProduct_children_stock {
  __typename: "ProductStock";
  id: number;
  qty: number;
  updated_at: any | null;
}

export interface updateProduct_updateProduct_children_brand {
  __typename: "ProductBrand";
  id: number;
  name: string | null;
}

export interface updateProduct_updateProduct_children_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface updateProduct_updateProduct_children_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface updateProduct_updateProduct_children {
  __typename: "Product";
  id: number;
  name: string | null;
  lfdnr: number | null;
  ean: string[];
  cart_qty_max: number;
  categories: updateProduct_updateProduct_children_categories[];
  slug: string | null;
  unit_base: string | null;
  images: updateProduct_updateProduct_children_images[];
  is_active: boolean;
  is_force_active: boolean | null;
  active_from: any | null;
  active_to: any | null;
  param_values: updateProduct_updateProduct_children_param_values[];
  brand_bank: updateProduct_updateProduct_children_brand_bank | null;
  badges_featured: updateProduct_updateProduct_children_badges_featured[];
  badges_product_params: updateProduct_updateProduct_children_badges_product_params[];
  badges_notifications: updateProduct_updateProduct_children_badges_notifications[];
  prices: updateProduct_updateProduct_children_prices[];
  stock: updateProduct_updateProduct_children_stock | null;
  brand: updateProduct_updateProduct_children_brand | null;
  created_at: any;
  updated_at: any;
  created_by: updateProduct_updateProduct_children_created_by | null;
  updated_by: updateProduct_updateProduct_children_updated_by | null;
  bundle_promotion_id: number | null;
  image_path_360: string | null;
  image_position_360: number | null;
  brandbank_exclude: boolean | null;
}

export interface updateProduct_updateProduct {
  __typename: "Product";
  id: number;
  name: string | null;
  lfdnr: number | null;
  ean: string[];
  cart_qty_max: number;
  categories: updateProduct_updateProduct_categories[];
  slug: string | null;
  unit_base: string | null;
  images: updateProduct_updateProduct_images[];
  is_active: boolean;
  is_force_active: boolean | null;
  active_from: any | null;
  active_to: any | null;
  param_values: updateProduct_updateProduct_param_values[];
  brand_bank: updateProduct_updateProduct_brand_bank | null;
  badges_featured: updateProduct_updateProduct_badges_featured[];
  badges_product_params: updateProduct_updateProduct_badges_product_params[];
  badges_notifications: updateProduct_updateProduct_badges_notifications[];
  prices: updateProduct_updateProduct_prices[];
  stock: updateProduct_updateProduct_stock | null;
  brand: updateProduct_updateProduct_brand | null;
  created_at: any;
  updated_at: any;
  created_by: updateProduct_updateProduct_created_by | null;
  updated_by: updateProduct_updateProduct_updated_by | null;
  bundle_promotion_id: number | null;
  image_path_360: string | null;
  image_position_360: number | null;
  brandbank_exclude: boolean | null;
  children: updateProduct_updateProduct_children[] | null;
}

export interface updateProduct {
  updateProduct: updateProduct_updateProduct | null;
}

export interface updateProductVariables {
  id: number;
  input: ProductUpdateInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateProductParam
// ====================================================

export interface updateProductParam_updateProductParam_options {
  __typename: "ProductParamOption";
  id: number;
  label: string;
  value: string | null;
}

export interface updateProductParam_updateProductParam_badge_product_param {
  __typename: "ProductParam";
  id: string;
  name: string | null;
}

export interface updateProductParam_updateProductParam_badge_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface updateProductParam_updateProductParam_badge_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface updateProductParam_updateProductParam_badge {
  __typename: "Badge";
  id: number;
  type: BadgeType;
  name: string;
  info: string | null;
  image: string | null;
  image_title: string | null;
  product_param: updateProductParam_updateProductParam_badge_product_param | null;
  is_active: boolean;
  created_at: any | null;
  created_by: updateProductParam_updateProductParam_badge_created_by;
  updated_at: any | null;
  updated_by: updateProductParam_updateProductParam_badge_updated_by | null;
  active_from: any | null;
  active_to: any | null;
  is_visible: boolean;
}

export interface updateProductParam_updateProductParam_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface updateProductParam_updateProductParam {
  __typename: "ProductParam";
  id: string;
  name: string | null;
  evo_name: string | null;
  type: ProductParamType;
  is_active: boolean;
  is_filter: boolean;
  is_multi: boolean;
  type_widget: ProductParamWidgetType;
  created_at: any | null;
  updated_at: any | null;
  options: updateProductParam_updateProductParam_options[];
  badge: updateProductParam_updateProductParam_badge | null;
  updated_by: updateProductParam_updateProductParam_updated_by | null;
}

export interface updateProductParam {
  updateProductParam: updateProductParam_updateProductParam | null;
}

export interface updateProductParamVariables {
  id: string;
  input: ProductParamUpdateInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateProductVariant
// ====================================================

export interface updateProductVariant_updateProductVariant_children_main_categories {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface updateProductVariant_updateProductVariant_children_categories {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface updateProductVariant_updateProductVariant_children {
  __typename: "Product";
  id: number;
  lfdnr: number | null;
  ean: string[];
  name: string | null;
  main_categories: updateProductVariant_updateProductVariant_children_main_categories[];
  categories: updateProductVariant_updateProductVariant_children_categories[];
  is_visible: boolean;
  active_from: any | null;
  slug: string | null;
}

export interface updateProductVariant_updateProductVariant_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface updateProductVariant_updateProductVariant_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface updateProductVariant_updateProductVariant {
  __typename: "Product";
  id: number;
  variant_product_param_id: string | null;
  children: updateProductVariant_updateProductVariant_children[] | null;
  name: string | null;
  slug: string | null;
  is_active: boolean;
  active_from: any | null;
  active_to: any | null;
  created_at: any;
  created_by: updateProductVariant_updateProductVariant_created_by | null;
  updated_at: any;
  updated_by: updateProductVariant_updateProductVariant_updated_by | null;
}

export interface updateProductVariant {
  updateProductVariant: updateProductVariant_updateProductVariant | null;
}

export interface updateProductVariantVariables {
  id: number;
  input: ProductVariantInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateRedirection
// ====================================================

export interface updateRedirection_updateRedirection_from_site {
  __typename: "Site";
  id: string;
  name: string;
  url: string;
}

export interface updateRedirection_updateRedirection_to_site {
  __typename: "Site";
  id: string;
  name: string;
  url: string;
}

export interface updateRedirection_updateRedirection {
  __typename: "Redirection";
  id: string;
  from_site: updateRedirection_updateRedirection_from_site | null;
  from: string | null;
  to_site: updateRedirection_updateRedirection_to_site | null;
  to: string | null;
  active_from: any | null;
  active_to: any | null;
  is_active: boolean;
  is_permanent: boolean;
  is_regex: boolean | null;
  forward: boolean | null;
  created_at: any;
  updated_at: any | null;
}

export interface updateRedirection {
  updateRedirection: updateRedirection_updateRedirection;
}

export interface updateRedirectionVariables {
  id: number;
  input: RedirectionInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateRole
// ====================================================

export interface updateRole_updateRole_permissions {
  __typename: "Permission";
  id: number;
  name: PermissionType;
  title: string;
  group: string;
}

export interface updateRole_updateRole {
  __typename: "Role";
  id: number;
  name: string;
  title: string;
  created_at: any;
  updated_at: any | null;
  permissions: updateRole_updateRole_permissions[];
}

export interface updateRole {
  updateRole: updateRole_updateRole | null;
}

export interface updateRoleVariables {
  id: number;
  input: RoleInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateSetting
// ====================================================

export interface updateSetting_updateSetting_options {
  __typename: "SettingOption";
  title: string;
  value: string;
}

export interface updateSetting_updateSetting_created_by_roles_permissions {
  __typename: "Permission";
  id: number;
  name: PermissionType;
}

export interface updateSetting_updateSetting_created_by_roles {
  __typename: "Role";
  id: number;
  name: string;
  title: string;
  permissions: updateSetting_updateSetting_created_by_roles_permissions[];
}

export interface updateSetting_updateSetting_created_by_store {
  __typename: "Store";
  id: number;
  name: string;
}

export interface updateSetting_updateSetting_created_by {
  __typename: "Admin";
  id: number;
  name: string;
  email: string;
  is_active: boolean;
  created_at: any;
  updated_at: any;
  phone: string | null;
  last_login: any | null;
  roles: updateSetting_updateSetting_created_by_roles[];
  store: updateSetting_updateSetting_created_by_store | null;
}

export interface updateSetting_updateSetting {
  __typename: "Setting";
  id: string;
  type: SettingType;
  value: string[];
  options: updateSetting_updateSetting_options[];
  is_multi: boolean;
  created_at: any;
  updated_at: any;
  created_by: updateSetting_updateSetting_created_by;
}

export interface updateSetting {
  updateSetting: updateSetting_updateSetting | null;
}

export interface updateSettingVariables {
  id: string;
  input: SettingInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateShipmentStatus
// ====================================================

export interface updateShipmentStatus_updateShipmentStatus {
  __typename: "Order";
  id: number;
}

export interface updateShipmentStatus {
  updateShipmentStatus: updateShipmentStatus_updateShipmentStatus[];
}

export interface updateShipmentStatusVariables {
  order_ids: number[];
  status: ShipmentStatusStoreEnum;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateShippingMethod
// ====================================================

export interface updateShippingMethod_updateShippingMethod_cost {
  __typename: "ShippingCost";
  cart_limit: number;
  cost: number;
}

export interface updateShippingMethod_updateShippingMethod_cost_terminal {
  __typename: "ShippingCost";
  cart_limit: number;
  cost: number;
}

export interface updateShippingMethod_updateShippingMethod_cost_time_windows_cost {
  __typename: "ShippingCost";
  cart_limit: number;
  cost: number;
}

export interface updateShippingMethod_updateShippingMethod_cost_time_windows {
  __typename: "TimeWindowCost";
  interval_hours: number;
  cost: updateShippingMethod_updateShippingMethod_cost_time_windows_cost[];
}

export interface updateShippingMethod_updateShippingMethod_delivery_informations {
  __typename: "DeliveryInformation";
  info: string;
  active_from: any | null;
  active_to: any | null;
}

export interface updateShippingMethod_updateShippingMethod_roles {
  __typename: "ShippingMethodRole";
  vip_levels: RossmannPlusVipLevel[] | null;
  active_from: any | null;
  active_to: any | null;
}

export interface updateShippingMethod_updateShippingMethod {
  __typename: "ShippingMethod";
  id: string;
  name: string | null;
  lead: string | null;
  description: string | null;
  weight_min: number | null;
  weight_max: number | null;
  cart_value_min: number | null;
  order_max: number | null;
  cost: updateShippingMethod_updateShippingMethod_cost[];
  cost_terminal: updateShippingMethod_updateShippingMethod_cost_terminal[];
  delivery_days: number | null;
  is_active: boolean;
  is_visible: boolean;
  active_from: any | null;
  active_to: any | null;
  created_at: any;
  updated_at: any | null;
  cost_time_windows: updateShippingMethod_updateShippingMethod_cost_time_windows[] | null;
  /**
   *  default delivery information ha nincs időzített
   */
  delivery_information: string | null;
  /**
   *  időzített delivery information 
   */
  delivery_informations: updateShippingMethod_updateShippingMethod_delivery_informations[] | null;
  roles: updateShippingMethod_updateShippingMethod_roles[] | null;
  time_limit_next_day_departure: string | null;
}

export interface updateShippingMethod {
  updateShippingMethod: updateShippingMethod_updateShippingMethod | null;
}

export interface updateShippingMethodVariables {
  id: string;
  input: ShippingMethodInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateSuccessStory
// ====================================================

export interface updateSuccessStory_updateSuccessStory_division_department {
  __typename: "Department";
  id: string;
  name: string;
}

export interface updateSuccessStory_updateSuccessStory_division {
  __typename: "Division";
  id: string;
  name: string;
  department: updateSuccessStory_updateSuccessStory_division_department | null;
  created_at: any;
  updated_at: any | null;
}

export interface updateSuccessStory_updateSuccessStory_person {
  __typename: "Person";
  id: string;
  name: string;
  position: string;
  created_at: any;
  updated_at: any | null;
}

export interface updateSuccessStory_updateSuccessStory {
  __typename: "SuccessStory";
  id: string;
  content: string;
  division: updateSuccessStory_updateSuccessStory_division;
  person: updateSuccessStory_updateSuccessStory_person;
  created_at: any;
  updated_at: any | null;
}

export interface updateSuccessStory {
  updateSuccessStory: updateSuccessStory_updateSuccessStory;
}

export interface updateSuccessStoryVariables {
  id: string;
  input: SuccessStoryInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateTodo
// ====================================================

export interface updateTodo_updateTodo_role {
  __typename: "Role";
  id: number;
  name: string;
  title: string;
}

export interface updateTodo_updateTodo_assigned_to {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface updateTodo_updateTodo_created_by {
  __typename: "Admin";
  name: string;
}

export interface updateTodo_updateTodo_updated_by {
  __typename: "Admin";
  name: string;
}

export interface updateTodo_updateTodo {
  __typename: "Todo";
  id: string;
  status: TodoStatus;
  subject: string;
  message: string;
  role: updateTodo_updateTodo_role;
  created_at: any;
  updated_at: any | null;
  assigned_to: updateTodo_updateTodo_assigned_to | null;
  created_by: updateTodo_updateTodo_created_by | null;
  updated_by: updateTodo_updateTodo_updated_by | null;
}

export interface updateTodo {
  updateTodo: updateTodo_updateTodo | null;
}

export interface updateTodoVariables {
  id: number;
  input: TodoInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateUser
// ====================================================

export interface updateUser_updateUser_rossmann_plus_profile {
  __typename: "RossmannPlusProfile";
  card_number: string;
  is_virtual_card: boolean;
}

export interface updateUser_updateUser {
  __typename: "User";
  id: number;
  lastname: string | null;
  firstname: string | null;
  email: string;
  phone_number: string | null;
  rossmann_plus_profile: updateUser_updateUser_rossmann_plus_profile | null;
  created_at: any;
  banktransfer_enabled: boolean;
  cod_disabled: boolean;
  newsletter: boolean;
  is_active: boolean;
  country_code: string | null;
  area_code: string | null;
  order_count: number;
  order_total_sum: number;
  last_login: any | null;
}

export interface updateUser {
  updateUser: updateUser_updateUser;
}

export interface updateUserVariables {
  id: number;
  input: UserInput;
  remove_rplus_card?: boolean | null;
  replacement_rplus_card?: string | null;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateWebsiteNotification
// ====================================================

export interface updateWebsiteNotification_updateWebsiteNotification_created_by {
  __typename: "Admin";
  name: string;
}

export interface updateWebsiteNotification_updateWebsiteNotification_updated_by {
  __typename: "Admin";
  name: string;
}

export interface updateWebsiteNotification_updateWebsiteNotification {
  __typename: "WebsiteNotification";
  id: number;
  name: string;
  title: string | null;
  message: string;
  is_active: boolean;
  active_from: any | null;
  active_to: any | null;
  created_at: any;
  created_by: updateWebsiteNotification_updateWebsiteNotification_created_by;
  updated_at: any;
  updated_by: updateWebsiteNotification_updateWebsiteNotification_updated_by | null;
  target: ClientType[];
}

export interface updateWebsiteNotification {
  updateWebsiteNotification: updateWebsiteNotification_updateWebsiteNotification | null;
}

export interface updateWebsiteNotificationVariables {
  id: number;
  input: WebsiteNotificationInput;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: uploadDocument
// ====================================================

export interface uploadDocument_uploadDocument {
  __typename: "Document";
  id: string;
  file_name: string;
}

export interface uploadDocument {
  uploadDocument: uploadDocument_uploadDocument;
}

export interface uploadDocumentVariables {
  file: any;
  csrf?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: adminContents
// ====================================================

export interface adminContents_adminContents_data_GalleryContent {
  __typename: "GalleryContent" | "VideoContent";
}

export interface adminContents_adminContents_data_TextContent_category {
  __typename: "Category";
  id: string;
  title: string;
}

export interface adminContents_adminContents_data_TextContent {
  __typename: "TextContent";
  id: string;
  is_visible: boolean;
  is_active: boolean;
  active_from: any | null;
  updated_at: any | null;
  title: string;
  author: string | null;
  category: adminContents_adminContents_data_TextContent_category | null;
}

export type adminContents_adminContents_data = adminContents_adminContents_data_GalleryContent | adminContents_adminContents_data_TextContent;

export interface adminContents_adminContents_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface adminContents_adminContents {
  __typename: "ContentPaginator";
  /**
   * A list of Content items.
   */
  data: adminContents_adminContents_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: adminContents_adminContents_paginatorInfo;
}

export interface adminContents {
  adminContents: adminContents_adminContents | null;
}

export interface adminContentsVariables {
  filters?: ContentFilters | null;
  sortBy?: ContentOrder | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: adminMe
// ====================================================

export interface adminMe_adminMe_roles_permissions {
  __typename: "Permission";
  id: number;
  name: PermissionType;
}

export interface adminMe_adminMe_roles {
  __typename: "Role";
  id: number;
  name: string;
  title: string;
  permissions: adminMe_adminMe_roles_permissions[];
}

export interface adminMe_adminMe_store {
  __typename: "Store";
  id: number;
  name: string;
}

export interface adminMe_adminMe {
  __typename: "Admin";
  id: number;
  name: string;
  email: string;
  is_active: boolean;
  created_at: any;
  updated_at: any;
  phone: string | null;
  last_login: any | null;
  roles: adminMe_adminMe_roles[];
  store: adminMe_adminMe_store | null;
}

export interface adminMe {
  adminMe: adminMe_adminMe;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: benefits
// ====================================================

export interface benefits_benefits_data {
  __typename: "Benefit";
  id: string;
  title: string;
  created_at: any;
  updated_at: any | null;
}

export interface benefits_benefits_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface benefits_benefits {
  __typename: "BenefitPaginator";
  /**
   * A list of Benefit items.
   */
  data: benefits_benefits_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: benefits_benefits_paginatorInfo;
}

export interface benefits {
  benefits: benefits_benefits | null;
}

export interface benefitsVariables {
  filters?: BenefitFilters | null;
  sortBy?: BenefitSort | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: categories
// ====================================================

export interface categories_categories {
  __typename: "Category";
  id: string;
  title: string;
}

export interface categories {
  categories: categories_categories[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: departments
// ====================================================

export interface departments_departments_data {
  __typename: "Department";
  id: string;
  name: string;
  order: number;
  created_at: any;
  updated_at: any | null;
}

export interface departments_departments_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface departments_departments {
  __typename: "DepartmentPaginator";
  /**
   * A list of Department items.
   */
  data: departments_departments_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: departments_departments_paginatorInfo;
}

export interface departments {
  departments: departments_departments | null;
}

export interface departmentsVariables {
  filters?: DepartmentFilters | null;
  sortBy?: DepartmentSort | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: divisions
// ====================================================

export interface divisions_divisions_data_department {
  __typename: "Department";
  id: string;
  name: string;
}

export interface divisions_divisions_data {
  __typename: "Division";
  id: string;
  name: string;
  department: divisions_divisions_data_department | null;
  created_at: any;
  updated_at: any | null;
}

export interface divisions_divisions_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface divisions_divisions {
  __typename: "DivisionPaginator";
  /**
   * A list of Division items.
   */
  data: divisions_divisions_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: divisions_divisions_paginatorInfo;
}

export interface divisions {
  divisions: divisions_divisions | null;
}

export interface divisionsVariables {
  filters?: DivisionFilters | null;
  sortBy?: DivisionSort | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: documents
// ====================================================

export interface documents_documents_data {
  __typename: "Document";
  id: string;
  file_name: string;
  url: string;
}

export interface documents_documents_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface documents_documents {
  __typename: "DocumentPaginator";
  /**
   * A list of Document items.
   */
  data: documents_documents_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: documents_documents_paginatorInfo;
}

export interface documents {
  documents: documents_documents | null;
}

export interface documentsVariables {
  sortBy?: DocumentOrder | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: exportOrder
// ====================================================

export interface exportOrder {
  exportOrder: string;
}

export interface exportOrderVariables {
  increment_ids: number[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: galleries
// ====================================================

export interface galleries_galleries_data {
  __typename: "Gallery";
  id: string;
  title: string;
  handle: string | null;
  image_count: number;
  updated_at: any | null;
}

export interface galleries_galleries_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface galleries_galleries {
  __typename: "GalleryPaginator";
  /**
   * A list of Gallery items.
   */
  data: galleries_galleries_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: galleries_galleries_paginatorInfo;
}

export interface galleries {
  galleries: galleries_galleries | null;
}

export interface galleriesVariables {
  filters?: GalleryFilters | null;
  sortBy?: GalleryOrder | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAdminContent
// ====================================================

export interface getAdminContent_adminContents_data_GalleryContent {
  __typename: "GalleryContent" | "VideoContent";
}

export interface getAdminContent_adminContents_data_TextContent_category {
  __typename: "Category";
  id: string;
  title: string;
  url: string;
}

export interface getAdminContent_adminContents_data_TextContent_documents {
  __typename: "Document";
  id: string;
  file_name: string;
}

export interface getAdminContent_adminContents_data_TextContent {
  __typename: "TextContent";
  id: string;
  url: string;
  title: string;
  lead: string | null;
  lead_image: string | null;
  meta_image: string | null;
  content: string | null;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
  author: string | null;
  is_active: boolean;
  is_visible: boolean;
  active_from: any | null;
  active_to: any | null;
  created_at: any;
  updated_at: any | null;
  last_published_at: any | null;
  category: getAdminContent_adminContents_data_TextContent_category | null;
  documents: (getAdminContent_adminContents_data_TextContent_documents | null)[] | null;
}

export type getAdminContent_adminContents_data = getAdminContent_adminContents_data_GalleryContent | getAdminContent_adminContents_data_TextContent;

export interface getAdminContent_adminContents {
  __typename: "ContentPaginator";
  /**
   * A list of Content items.
   */
  data: getAdminContent_adminContents_data[];
}

export interface getAdminContent {
  adminContents: getAdminContent_adminContents | null;
}

export interface getAdminContentVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAdmin
// ====================================================

export interface getAdmin_listAdmins_data_roles_permissions {
  __typename: "Permission";
  id: number;
  name: PermissionType;
}

export interface getAdmin_listAdmins_data_roles {
  __typename: "Role";
  id: number;
  name: string;
  title: string;
  permissions: getAdmin_listAdmins_data_roles_permissions[];
}

export interface getAdmin_listAdmins_data_store {
  __typename: "Store";
  id: number;
  name: string;
}

export interface getAdmin_listAdmins_data {
  __typename: "Admin";
  id: number;
  name: string;
  email: string;
  is_active: boolean;
  created_at: any;
  updated_at: any;
  phone: string | null;
  last_login: any | null;
  roles: getAdmin_listAdmins_data_roles[];
  store: getAdmin_listAdmins_data_store | null;
}

export interface getAdmin_listAdmins {
  __typename: "AdminPaginator";
  /**
   * A list of Admin items.
   */
  data: getAdmin_listAdmins_data[];
}

export interface getAdmin {
  listAdmins: getAdmin_listAdmins | null;
}

export interface getAdminVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getBannerById
// ====================================================

export interface getBannerById_listBanners_data_BannerNormal_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface getBannerById_listBanners_data_BannerNormal_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface getBannerById_listBanners_data_BannerNormal {
  __typename: "BannerNormal";
  id: number;
  name: string;
  urls: string[];
  title: string;
  slider_title: string;
  type: BannerType;
  image_align: BannerAlign;
  button_title: string | null;
  image: string;
  image_title: string | null;
  image_mobile: string | null;
  image_mobile_title: string | null;
  target_url: string | null;
  target_popup: boolean;
  is_active: boolean;
  active_from: any | null;
  active_to: any | null;
  created_at: any | null;
  created_by: getBannerById_listBanners_data_BannerNormal_created_by;
  updated_at: any | null;
  updated_by: getBannerById_listBanners_data_BannerNormal_updated_by | null;
}

export interface getBannerById_listBanners_data_BannerProductList_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface getBannerById_listBanners_data_BannerProductList_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface getBannerById_listBanners_data_BannerProductList {
  __typename: "BannerProductList";
  id: number;
  name: string;
  urls: string[];
  title: string;
  slider_title: string;
  type: BannerType;
  image_align: BannerAlign;
  button_title: string | null;
  image: string;
  image_title: string | null;
  image_mobile: string | null;
  image_mobile_title: string | null;
  target_url: string | null;
  target_popup: boolean;
  is_active: boolean;
  active_from: any | null;
  active_to: any | null;
  created_at: any | null;
  created_by: getBannerById_listBanners_data_BannerProductList_created_by;
  updated_at: any | null;
  updated_by: getBannerById_listBanners_data_BannerProductList_updated_by | null;
}

export interface getBannerById_listBanners_data_BannerProduct_product_categories_category_path {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface getBannerById_listBanners_data_BannerProduct_product_categories {
  __typename: "ProductCategory";
  id: number;
  category_path: getBannerById_listBanners_data_BannerProduct_product_categories_category_path[];
}

export interface getBannerById_listBanners_data_BannerProduct_product_images {
  __typename: "ProductImage";
  id: number;
  source: ProductImageSource;
  position: number;
  url_list: string | null;
  is_active: boolean;
}

export interface getBannerById_listBanners_data_BannerProduct_product_param_values {
  __typename: "ProductParamValue";
  product_param_id: string;
  is_multi: boolean;
  values: string[];
}

export interface getBannerById_listBanners_data_BannerProduct_product_brand_bank_param_values {
  __typename: "ProductParamValue";
  product_param_id: string;
  is_multi: boolean;
  values: string[];
}

export interface getBannerById_listBanners_data_BannerProduct_product_brand_bank {
  __typename: "ProductBrandBankData";
  id: number;
  param_values: getBannerById_listBanners_data_BannerProduct_product_brand_bank_param_values[];
}

export interface getBannerById_listBanners_data_BannerProduct_product_badges_featured {
  __typename: "Badge";
  name: string;
}

export interface getBannerById_listBanners_data_BannerProduct_product_badges_product_params {
  __typename: "Badge";
  name: string;
}

export interface getBannerById_listBanners_data_BannerProduct_product_badges_notifications {
  __typename: "Badge";
  name: string;
}

export interface getBannerById_listBanners_data_BannerProduct_product_prices {
  __typename: "ProductPrice";
  id: number;
  price: number;
  price_unit: number | null;
  active_from: any | null;
  active_to: any | null;
}

export interface getBannerById_listBanners_data_BannerProduct_product_stock {
  __typename: "ProductStock";
  id: number;
  qty: number;
  updated_at: any | null;
}

export interface getBannerById_listBanners_data_BannerProduct_product_brand {
  __typename: "ProductBrand";
  id: number;
  name: string | null;
}

export interface getBannerById_listBanners_data_BannerProduct_product_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface getBannerById_listBanners_data_BannerProduct_product_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface getBannerById_listBanners_data_BannerProduct_product_children_categories_category_path {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface getBannerById_listBanners_data_BannerProduct_product_children_categories {
  __typename: "ProductCategory";
  id: number;
  category_path: getBannerById_listBanners_data_BannerProduct_product_children_categories_category_path[];
}

export interface getBannerById_listBanners_data_BannerProduct_product_children_images {
  __typename: "ProductImage";
  id: number;
  source: ProductImageSource;
  position: number;
  url_list: string | null;
  is_active: boolean;
}

export interface getBannerById_listBanners_data_BannerProduct_product_children_param_values {
  __typename: "ProductParamValue";
  product_param_id: string;
  is_multi: boolean;
  values: string[];
}

export interface getBannerById_listBanners_data_BannerProduct_product_children_brand_bank_param_values {
  __typename: "ProductParamValue";
  product_param_id: string;
  is_multi: boolean;
  values: string[];
}

export interface getBannerById_listBanners_data_BannerProduct_product_children_brand_bank {
  __typename: "ProductBrandBankData";
  id: number;
  param_values: getBannerById_listBanners_data_BannerProduct_product_children_brand_bank_param_values[];
}

export interface getBannerById_listBanners_data_BannerProduct_product_children_badges_featured {
  __typename: "Badge";
  name: string;
}

export interface getBannerById_listBanners_data_BannerProduct_product_children_badges_product_params {
  __typename: "Badge";
  name: string;
}

export interface getBannerById_listBanners_data_BannerProduct_product_children_badges_notifications {
  __typename: "Badge";
  name: string;
}

export interface getBannerById_listBanners_data_BannerProduct_product_children_prices {
  __typename: "ProductPrice";
  id: number;
  price: number;
  price_unit: number | null;
  active_from: any | null;
  active_to: any | null;
}

export interface getBannerById_listBanners_data_BannerProduct_product_children_stock {
  __typename: "ProductStock";
  id: number;
  qty: number;
  updated_at: any | null;
}

export interface getBannerById_listBanners_data_BannerProduct_product_children_brand {
  __typename: "ProductBrand";
  id: number;
  name: string | null;
}

export interface getBannerById_listBanners_data_BannerProduct_product_children_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface getBannerById_listBanners_data_BannerProduct_product_children_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface getBannerById_listBanners_data_BannerProduct_product_children {
  __typename: "Product";
  id: number;
  name: string | null;
  lfdnr: number | null;
  ean: string[];
  cart_qty_max: number;
  categories: getBannerById_listBanners_data_BannerProduct_product_children_categories[];
  slug: string | null;
  unit_base: string | null;
  images: getBannerById_listBanners_data_BannerProduct_product_children_images[];
  is_active: boolean;
  is_force_active: boolean | null;
  active_from: any | null;
  active_to: any | null;
  param_values: getBannerById_listBanners_data_BannerProduct_product_children_param_values[];
  brand_bank: getBannerById_listBanners_data_BannerProduct_product_children_brand_bank | null;
  badges_featured: getBannerById_listBanners_data_BannerProduct_product_children_badges_featured[];
  badges_product_params: getBannerById_listBanners_data_BannerProduct_product_children_badges_product_params[];
  badges_notifications: getBannerById_listBanners_data_BannerProduct_product_children_badges_notifications[];
  prices: getBannerById_listBanners_data_BannerProduct_product_children_prices[];
  stock: getBannerById_listBanners_data_BannerProduct_product_children_stock | null;
  brand: getBannerById_listBanners_data_BannerProduct_product_children_brand | null;
  created_at: any;
  updated_at: any;
  created_by: getBannerById_listBanners_data_BannerProduct_product_children_created_by | null;
  updated_by: getBannerById_listBanners_data_BannerProduct_product_children_updated_by | null;
  bundle_promotion_id: number | null;
  image_path_360: string | null;
  image_position_360: number | null;
  brandbank_exclude: boolean | null;
}

export interface getBannerById_listBanners_data_BannerProduct_product {
  __typename: "Product";
  id: number;
  name: string | null;
  lfdnr: number | null;
  ean: string[];
  cart_qty_max: number;
  categories: getBannerById_listBanners_data_BannerProduct_product_categories[];
  slug: string | null;
  unit_base: string | null;
  images: getBannerById_listBanners_data_BannerProduct_product_images[];
  is_active: boolean;
  is_force_active: boolean | null;
  active_from: any | null;
  active_to: any | null;
  param_values: getBannerById_listBanners_data_BannerProduct_product_param_values[];
  brand_bank: getBannerById_listBanners_data_BannerProduct_product_brand_bank | null;
  badges_featured: getBannerById_listBanners_data_BannerProduct_product_badges_featured[];
  badges_product_params: getBannerById_listBanners_data_BannerProduct_product_badges_product_params[];
  badges_notifications: getBannerById_listBanners_data_BannerProduct_product_badges_notifications[];
  prices: getBannerById_listBanners_data_BannerProduct_product_prices[];
  stock: getBannerById_listBanners_data_BannerProduct_product_stock | null;
  brand: getBannerById_listBanners_data_BannerProduct_product_brand | null;
  created_at: any;
  updated_at: any;
  created_by: getBannerById_listBanners_data_BannerProduct_product_created_by | null;
  updated_by: getBannerById_listBanners_data_BannerProduct_product_updated_by | null;
  bundle_promotion_id: number | null;
  image_path_360: string | null;
  image_position_360: number | null;
  brandbank_exclude: boolean | null;
  children: getBannerById_listBanners_data_BannerProduct_product_children[] | null;
}

export interface getBannerById_listBanners_data_BannerProduct_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface getBannerById_listBanners_data_BannerProduct_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface getBannerById_listBanners_data_BannerProduct {
  __typename: "BannerProduct";
  id: number;
  name: string;
  type: BannerType;
  urls: string[];
  product: getBannerById_listBanners_data_BannerProduct_product;
  position: number;
  is_active: boolean;
  active_from: any | null;
  active_to: any | null;
  created_at: any | null;
  created_by: getBannerById_listBanners_data_BannerProduct_created_by;
  updated_at: any | null;
  updated_by: getBannerById_listBanners_data_BannerProduct_updated_by | null;
}

export type getBannerById_listBanners_data = getBannerById_listBanners_data_BannerNormal | getBannerById_listBanners_data_BannerProductList | getBannerById_listBanners_data_BannerProduct;

export interface getBannerById_listBanners {
  __typename: "BannerPaginator";
  /**
   * A list of Banner items.
   */
  data: getBannerById_listBanners_data[];
}

export interface getBannerById {
  listBanners: getBannerById_listBanners | null;
}

export interface getBannerByIdVariables {
  filters?: BannerFilters | null;
  first: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getBenefit
// ====================================================

export interface getBenefit_benefit {
  __typename: "Benefit";
  id: string;
  title: string;
  image: string;
  created_at: any;
  updated_at: any | null;
}

export interface getBenefit {
  benefit: getBenefit_benefit | null;
}

export interface getBenefitVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getCampaignById
// ====================================================

export interface getCampaignById_campaign_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface getCampaignById_campaign_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface getCampaignById_campaign {
  __typename: "Campaign";
  id: number;
  name: string;
  description: string;
  target_url: string | null;
  target_title: string | null;
  image_foreground_desktop: string | null;
  image_background_desktop: string | null;
  image_foreground_mobile: string | null;
  image_background_mobile: string | null;
  active_from: any | null;
  active_to: any | null;
  is_active: boolean;
  created_at: any;
  updated_at: any | null;
  created_by: getCampaignById_campaign_created_by;
  updated_by: getCampaignById_campaign_updated_by | null;
}

export interface getCampaignById {
  campaign: getCampaignById_campaign;
}

export interface getCampaignByIdVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getCategoriesForSelect
// ====================================================

export interface getCategoriesForSelect_categories {
  __typename: "Category";
  id: string;
  title: string;
}

export interface getCategoriesForSelect {
  categories: getCategoriesForSelect_categories[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getCategory
// ====================================================

export interface getCategory_categories {
  __typename: "Category";
  id: string;
  lead: string | null;
  title: string;
  url: string;
  meta_title: string | null;
  meta_description: string | null;
  meta_keywords: string | null;
  created_at: any;
  updated_at: any | null;
}

export interface getCategory {
  categories: getCategory_categories[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getDepartment
// ====================================================

export interface getDepartment_department_divisions_department {
  __typename: "Department";
  id: string;
  name: string;
}

export interface getDepartment_department_divisions {
  __typename: "Division";
  id: string;
  name: string;
  department: getDepartment_department_divisions_department | null;
  created_at: any;
  updated_at: any | null;
}

export interface getDepartment_department_benefits {
  __typename: "Benefit";
  id: string;
  title: string;
  created_at: any;
  updated_at: any | null;
}

export interface getDepartment_department {
  __typename: "Department";
  id: string;
  name: string;
  is_grouped: boolean;
  is_filter_enabled: boolean | null;
  is_map_enabled: boolean | null;
  order: number;
  divisions: getDepartment_department_divisions[];
  benefits: getDepartment_department_benefits[];
  created_at: any;
  updated_at: any | null;
}

export interface getDepartment {
  department: getDepartment_department | null;
}

export interface getDepartmentVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getDivision
// ====================================================

export interface getDivision_division_leaders {
  __typename: "Person";
  id: string;
  name: string;
  position: string;
  created_at: any;
  updated_at: any | null;
}

export interface getDivision_division_department {
  __typename: "Department";
  id: string;
  name: string;
  order: number;
  created_at: any;
  updated_at: any | null;
}

export interface getDivision_division_benefits {
  __typename: "Benefit";
  id: string;
  title: string;
  created_at: any;
  updated_at: any | null;
}

export interface getDivision_division {
  __typename: "Division";
  id: string;
  name: string;
  description: string | null;
  quote: string | null;
  leaders: getDivision_division_leaders[];
  department: getDivision_division_department | null;
  benefits: getDivision_division_benefits[];
  created_at: any;
  updated_at: any | null;
  is_virtual: boolean | null;
}

export interface getDivision {
  division: getDivision_division | null;
}

export interface getDivisionVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getDocumentsForModal
// ====================================================

export interface getDocumentsForModal_documents_data {
  __typename: "Document";
  id: string;
  file_name: string;
}

export interface getDocumentsForModal_documents {
  __typename: "DocumentPaginator";
  /**
   * A list of Document items.
   */
  data: getDocumentsForModal_documents_data[];
}

export interface getDocumentsForModal {
  documents: getDocumentsForModal_documents | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getFulfillmentById
// ====================================================

export interface getFulfillmentById_listFulfillment_data_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface getFulfillmentById_listFulfillment_data_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface getFulfillmentById_listFulfillment_data {
  __typename: "Fulfillment";
  id: number;
  active_from: any;
  active_to: any;
  /**
   * Flag allocation limit between two daily reset timestamp
   */
  limit: number;
  /**
   * Flags allocated in the daily interval since the last reset timestamp
   */
  count: number;
  shipping_methods: string[];
  timewindow_allowed: boolean;
  vip_levels: RossmannPlusVipLevel[];
  created_at: any;
  updated_at: any | null;
  created_by: getFulfillmentById_listFulfillment_data_created_by | null;
  updated_by: getFulfillmentById_listFulfillment_data_updated_by | null;
  is_active: boolean;
  /**
   * Send email notification after every X flag assigment
   */
  notification_threshold: number | null;
  quantity_max: number | null;
  package_type_max: PackageType | null;
  product_id_force: number[];
  product_id_exclude: number[];
  type: FulfillmentTypeSelectable;
  weight: number;
}

export interface getFulfillmentById_listFulfillment {
  __typename: "FulfillmentPaginator";
  /**
   * A list of Fulfillment items.
   */
  data: getFulfillmentById_listFulfillment_data[];
}

export interface getFulfillmentById {
  listFulfillment: getFulfillmentById_listFulfillment | null;
}

export interface getFulfillmentByIdVariables {
  first: number;
  filters?: FulfillmentFilters | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getGalleryImages
// ====================================================

export interface getGalleryImages_galleries_data_images {
  __typename: "GalleryImage";
  url: string;
  caption: string | null;
  credit: string | null;
}

export interface getGalleryImages_galleries_data {
  __typename: "Gallery";
  id: string;
  images: getGalleryImages_galleries_data_images[];
}

export interface getGalleryImages_galleries {
  __typename: "GalleryPaginator";
  /**
   * A list of Gallery items.
   */
  data: getGalleryImages_galleries_data[];
}

export interface getGalleryImages {
  galleries: getGalleryImages_galleries | null;
}

export interface getGalleryImagesVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getGallery
// ====================================================

export interface getGallery_galleries_data_images {
  __typename: "GalleryImage";
  url: string;
  caption: string | null;
  credit: string | null;
}

export interface getGallery_galleries_data {
  __typename: "Gallery";
  id: string;
  title: string;
  handle: string | null;
  images: getGallery_galleries_data_images[];
  image_count: number;
  updated_at: any | null;
}

export interface getGallery_galleries {
  __typename: "GalleryPaginator";
  /**
   * A list of Gallery items.
   */
  data: getGallery_galleries_data[];
}

export interface getGallery {
  galleries: getGallery_galleries | null;
}

export interface getGalleryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getGiftCardDesigns
// ====================================================

export interface getGiftCardDesigns_giftCardDesign {
  __typename: "GiftCardDesign";
  id: number;
  title: string;
  slug: string;
}

export interface getGiftCardDesigns {
  giftCardDesign: getGiftCardDesigns_giftCardDesign[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getGiftCardOrderTypes
// ====================================================

export interface getGiftCardOrderTypes_giftCardOrderType {
  __typename: "GiftCardOrderTypeWithTitle";
  id: string;
  title: string;
}

export interface getGiftCardOrderTypes {
  giftCardOrderType: getGiftCardOrderTypes_giftCardOrderType[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getMailTemplateById
// ====================================================

export interface getMailTemplateById_listMailTemplates_data {
  __typename: "MailTemplate";
  id: number;
  subject: string;
  content: string;
  description: string | null;
  is_active: boolean;
}

export interface getMailTemplateById_listMailTemplates {
  __typename: "MailTemplatePaginator";
  /**
   * A list of MailTemplate items.
   */
  data: getMailTemplateById_listMailTemplates_data[];
}

export interface getMailTemplateById {
  listMailTemplates: getMailTemplateById_listMailTemplates | null;
}

export interface getMailTemplateByIdVariables {
  filters?: MailTemplateFilters | null;
  sortBy?: MailTemplateOrder | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getMaintenanceById
// ====================================================

export interface getMaintenanceById_maintenance {
  __typename: "Maintenance";
  id: string;
  active_from: any;
  active_to: any;
  scopes: MaintenanceScope[] | null;
  notification: string | null;
  maintenance: boolean;
  is_active: boolean;
}

export interface getMaintenanceById {
  maintenance: getMaintenanceById_maintenance | null;
}

export interface getMaintenanceByIdVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getMenuById
// ====================================================

export interface getMenuById_menu_items_type {
  __typename: "MenuItemType";
  id: string;
  name: string;
}

export interface getMenuById_menu_items_page_overlay {
  __typename: "Page";
  id: number;
  name: string;
}

export interface getMenuById_menu_items_items_type {
  __typename: "MenuItemType";
  id: string;
  name: string;
}

export interface getMenuById_menu_items_items_page_overlay {
  __typename: "Page";
  id: number;
  name: string;
}

export interface getMenuById_menu_items_items_items_type {
  __typename: "MenuItemType";
  id: string;
  name: string;
}

export interface getMenuById_menu_items_items_items_page_overlay {
  __typename: "Page";
  id: number;
  name: string;
}

export interface getMenuById_menu_items_items_items_items_type {
  __typename: "MenuItemType";
  id: string;
  name: string;
}

export interface getMenuById_menu_items_items_items_items_page_overlay {
  __typename: "Page";
  id: number;
  name: string;
}

export interface getMenuById_menu_items_items_items_items_items_type {
  __typename: "MenuItemType";
  id: string;
  name: string;
}

export interface getMenuById_menu_items_items_items_items_items_page_overlay {
  __typename: "Page";
  id: number;
  name: string;
}

export interface getMenuById_menu_items_items_items_items_items_items_type {
  __typename: "MenuItemType";
  id: string;
  name: string;
}

export interface getMenuById_menu_items_items_items_items_items_items_page_overlay {
  __typename: "Page";
  id: number;
  name: string;
}

export interface getMenuById_menu_items_items_items_items_items_items {
  __typename: "MenuItem";
  type: getMenuById_menu_items_items_items_items_items_items_type;
  model_id: number | null;
  title: string | null;
  url: string | null;
  popup: boolean;
  is_active: boolean;
  page_overlay: getMenuById_menu_items_items_items_items_items_items_page_overlay | null;
}

export interface getMenuById_menu_items_items_items_items_items {
  __typename: "MenuItem";
  type: getMenuById_menu_items_items_items_items_items_type;
  model_id: number | null;
  title: string | null;
  url: string | null;
  popup: boolean;
  is_active: boolean;
  page_overlay: getMenuById_menu_items_items_items_items_items_page_overlay | null;
  items: getMenuById_menu_items_items_items_items_items_items[] | null;
}

export interface getMenuById_menu_items_items_items_items {
  __typename: "MenuItem";
  type: getMenuById_menu_items_items_items_items_type;
  model_id: number | null;
  title: string | null;
  url: string | null;
  popup: boolean;
  is_active: boolean;
  page_overlay: getMenuById_menu_items_items_items_items_page_overlay | null;
  items: getMenuById_menu_items_items_items_items_items[] | null;
}

export interface getMenuById_menu_items_items_items {
  __typename: "MenuItem";
  type: getMenuById_menu_items_items_items_type;
  model_id: number | null;
  title: string | null;
  url: string | null;
  popup: boolean;
  is_active: boolean;
  page_overlay: getMenuById_menu_items_items_items_page_overlay | null;
  items: getMenuById_menu_items_items_items_items[] | null;
}

export interface getMenuById_menu_items_items {
  __typename: "MenuItem";
  type: getMenuById_menu_items_items_type;
  model_id: number | null;
  title: string | null;
  url: string | null;
  popup: boolean;
  is_active: boolean;
  page_overlay: getMenuById_menu_items_items_page_overlay | null;
  items: getMenuById_menu_items_items_items[] | null;
}

export interface getMenuById_menu_items {
  __typename: "MenuItem";
  type: getMenuById_menu_items_type;
  model_id: number | null;
  title: string | null;
  url: string | null;
  popup: boolean;
  is_active: boolean;
  page_overlay: getMenuById_menu_items_page_overlay | null;
  items: getMenuById_menu_items_items[] | null;
}

export interface getMenuById_menu_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface getMenuById_menu_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface getMenuById_menu {
  __typename: "Menu";
  id: number;
  slug: string;
  name: string;
  created_at: any;
  updated_at: any | null;
  is_active: boolean;
  active_from: any | null;
  active_to: any | null;
  items: getMenuById_menu_items[];
  created_by: getMenuById_menu_created_by;
  updated_by: getMenuById_menu_updated_by | null;
}

export interface getMenuById {
  menu: getMenuById_menu;
}

export interface getMenuByIdVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getOrderById
// ====================================================

export interface getOrderById_order_user {
  __typename: "User";
  email: string;
}

export interface getOrderById_order_status {
  __typename: "OrderStatus";
  id: OrderStatusEnum;
  title: string;
}

export interface getOrderById_order_shipping_method_cost_time_windows {
  __typename: "TimeWindowCost";
  interval_hours: number;
}

export interface getOrderById_order_shipping_method {
  __typename: "ShippingMethod";
  name: string | null;
  delivery_days: number | null;
  cost_time_windows: getOrderById_order_shipping_method_cost_time_windows[] | null;
}

export interface getOrderById_order_delivery_time_window {
  __typename: "DeliveryTimeWindow";
  date: any;
  weekday: string;
  interval: string;
  interval_length: number;
}

export interface getOrderById_order_shipping_address {
  __typename: "UserAddress";
  firstname: string | null;
  lastname: string | null;
  zip_code: string | null;
  city: string | null;
  address_line1: string | null;
  address_line2: string | null;
  company_name: string | null;
  company_number: string | null;
}

export interface getOrderById_order_billing_address {
  __typename: "UserAddress";
  firstname: string | null;
  lastname: string | null;
  zip_code: string | null;
  city: string | null;
  address_line1: string | null;
  address_line2: string | null;
  company_name: string | null;
  company_number: string | null;
}

export interface getOrderById_order_payment_method {
  __typename: "PaymentMethod";
  id: string;
  name: string | null;
}

export interface getOrderById_order_payment_status {
  __typename: "PaymentStatus";
  id: PaymentStatusEnum;
  title: string;
}

export interface getOrderById_order_payment_transaction_latest {
  __typename: "PaymentTransaction";
  id: number;
  anum: string | null;
}

export interface getOrderById_order_payment_transactions_payment_method {
  __typename: "PaymentMethod";
  name: string | null;
}

export interface getOrderById_order_payment_transactions {
  __typename: "PaymentTransaction";
  id: number;
  pmgw_transaction_id: string;
  provider_transaction_id: string | null;
  anum: string | null;
  payment_method: getOrderById_order_payment_transactions_payment_method;
  status_payment: string | null;
  amount: number;
  amount_refund: number | null;
  success: boolean;
}

export interface getOrderById_order_payment {
  __typename: "OrderPayment";
  status: getOrderById_order_payment_status;
  transaction_latest: getOrderById_order_payment_transaction_latest | null;
  amount_paid: number;
  amount_refunded: number;
  transactions: getOrderById_order_payment_transactions[];
}

export interface getOrderById_order_admin_notes_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface getOrderById_order_admin_notes {
  __typename: "AdminNote";
  note: string | null;
  created_by: getOrderById_order_admin_notes_created_by | null;
  created_at: any | null;
}

export interface getOrderById_order_items_product {
  __typename: "Product";
  name: string | null;
  lfdnr: number | null;
  ean: string[];
}

export interface getOrderById_order_items_discounts {
  __typename: "OrderItemDiscount";
  promotion_name: string;
  amount: number;
}

export interface getOrderById_order_items {
  __typename: "OrderItem";
  id: number;
  product: getOrderById_order_items_product;
  quantity: number;
  unit_price: number;
  sub_total: number;
  discount_total: number;
  discounts: getOrderById_order_items_discounts[];
  type: OrderItemType;
  quantity_missing: number;
  quantity_delivered: number;
}

export interface getOrderById_order_shipment {
  __typename: "RossmannShipment";
  status: ShipmentStatusEnum | null;
  delivered_at: any | null;
  tracking_url: string | null;
  tracking_numbers: string[];
  sent_at: any | null;
  delivery_in_progress_at: any | null;
  delivery_day_expected: any | null;
  can_retry: boolean;
}

export interface getOrderById_order_invoice_status {
  __typename: "InvoiceStatus";
  title: string;
}

export interface getOrderById_order_invoices {
  __typename: "OrderInvoice";
  type: InvoiceTypeEnum;
  invoice_number: string | null;
  url: string | null;
  url_remote: string | null;
  created_at: any;
  invoiced_at: any | null;
}

export interface getOrderById_order_pickup_point {
  __typename: "Store" | "MplPickupPoint" | "GlsPickupPoint" | "FoxpostPickupPoint" | "EasyboxPickupPoint";
  id: number;
  address: string;
}

export interface getOrderById_order_gift_cards {
  __typename: "GiftCardPayment";
  barcode: string;
  amount: number;
  voided_amount: number;
  paid_at: any | null;
  voided_at: any | null;
}

export interface getOrderById_order {
  __typename: "Order";
  id: number;
  increment_id: number | null;
  created_at: any;
  contact_fullname: string;
  email: string | null;
  phone_number: string | null;
  user: getOrderById_order_user | null;
  status: getOrderById_order_status;
  shipping_method: getOrderById_order_shipping_method;
  delivery_day_expected: any | null;
  delivery_time_window: getOrderById_order_delivery_time_window | null;
  shipping_address: getOrderById_order_shipping_address | null;
  billing_address: getOrderById_order_billing_address;
  payment_method: getOrderById_order_payment_method;
  grand_total: number;
  tax_total: number;
  payment: getOrderById_order_payment | null;
  admin_notes: getOrderById_order_admin_notes[];
  items: getOrderById_order_items[];
  shipment: getOrderById_order_shipment | null;
  invoice_status: getOrderById_order_invoice_status | null;
  shipping_note: string | null;
  grand_total_netto: number;
  invoices: getOrderById_order_invoices[];
  pickup_point: getOrderById_order_pickup_point | null;
  can_be_modified: boolean;
  can_be_deleted: boolean;
  evo_sent_at: any | null;
  user_agent_type: string;
  app_version: string | null;
  no_package: boolean | null;
  gift_cards: getOrderById_order_gift_cards[];
  gift_card_payment: number;
  can_storno: boolean;
  can_refund: boolean;
}

export interface getOrderById {
  order: getOrderById_order | null;
}

export interface getOrderByIdVariables {
  increment_id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPageById
// ====================================================

export interface getPageById_page_site {
  __typename: "Site";
  id: string;
  name: string;
  url: string;
}

export interface getPageById_page_created_by {
  __typename: "Admin";
  name: string;
}

export interface getPageById_page_updated_by {
  __typename: "Admin";
  name: string;
}

export interface getPageById_page_blocks_PageBlockBanner_banners_BannerNormal {
  __typename: "BannerNormal";
  id: number;
  name: string;
}

export interface getPageById_page_blocks_PageBlockBanner_banners_BannerProductList {
  __typename: "BannerProductList";
  id: number;
  name: string;
}

export interface getPageById_page_blocks_PageBlockBanner_banners_BannerProduct {
  __typename: "BannerProduct";
  id: number;
  name: string;
}

export type getPageById_page_blocks_PageBlockBanner_banners = getPageById_page_blocks_PageBlockBanner_banners_BannerNormal | getPageById_page_blocks_PageBlockBanner_banners_BannerProductList | getPageById_page_blocks_PageBlockBanner_banners_BannerProduct;

export interface getPageById_page_blocks_PageBlockBanner {
  __typename: "PageBlockBanner";
  id: number;
  type: PageBlockType;
  layout: PageBlockBannerLayout;
  title: string | null;
  banners: getPageById_page_blocks_PageBlockBanner_banners[];
}

export interface getPageById_page_blocks_PageBlockProductList_product_list {
  __typename: "ProductList";
  id: number;
  name: string;
}

export interface getPageById_page_blocks_PageBlockProductList_product_sort_by {
  __typename: "ProductSortFrontend";
  field: ProductSortFieldCategory;
  direction: OrderDirection;
}

export interface getPageById_page_blocks_PageBlockProductList {
  __typename: "PageBlockProductList";
  id: number;
  type: PageBlockType;
  title: string | null;
  product_list: getPageById_page_blocks_PageBlockProductList_product_list | null;
  is_scrollable: boolean;
  is_filterable: boolean;
  product_limit: number | null;
  product_sort_by: getPageById_page_blocks_PageBlockProductList_product_sort_by | null;
}

export interface getPageById_page_blocks_PageBlockHtml {
  __typename: "PageBlockHtml";
  id: number;
  type: PageBlockType;
  title: string | null;
  content: string;
}

export interface getPageById_page_blocks_PageBlockNewsletter {
  __typename: "PageBlockNewsletter";
  id: number;
  type: PageBlockType;
  title: string | null;
}

export interface getPageById_page_blocks_PageBlockBrandList {
  __typename: "PageBlockBrandList";
  id: number;
  type: PageBlockType;
  title: string | null;
}

export interface getPageById_page_blocks_PageBlockRecommendedProducts {
  __typename: "PageBlockRecommendedProducts";
  id: number;
  type: PageBlockType;
  title: string | null;
}

export interface getPageById_page_blocks_PageBlockHomebox {
  __typename: "PageBlockHomebox";
  id: number;
  type: PageBlockType;
  title: string | null;
}

export type getPageById_page_blocks = getPageById_page_blocks_PageBlockBanner | getPageById_page_blocks_PageBlockProductList | getPageById_page_blocks_PageBlockHtml | getPageById_page_blocks_PageBlockNewsletter | getPageById_page_blocks_PageBlockBrandList | getPageById_page_blocks_PageBlockRecommendedProducts | getPageById_page_blocks_PageBlockHomebox;

export interface getPageById_page {
  __typename: "Page";
  id: number;
  name: string;
  title: string | null;
  url: string;
  is_active: boolean;
  is_visible: boolean;
  active_from: any | null;
  active_to: any | null;
  meta_title: string | null;
  meta_description: string | null;
  og_title: string | null;
  og_description: string | null;
  og_image: string | null;
  background_image: string | null;
  created_at: any;
  updated_at: any | null;
  site: getPageById_page_site;
  created_by: getPageById_page_created_by;
  updated_by: getPageById_page_updated_by | null;
  blocks: getPageById_page_blocks[];
  is_header: boolean;
  is_footer: boolean;
}

export interface getPageById {
  page: getPageById_page;
}

export interface getPageByIdVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPaymentMethodById
// ====================================================

export interface getPaymentMethodById_listPaymentMethods_data {
  __typename: "PaymentMethod";
  id: string;
  name: string | null;
  description: string | null;
  cost: number;
  is_active: boolean;
  cart_value_max: number | null;
}

export interface getPaymentMethodById_listPaymentMethods {
  __typename: "PaymentMethodPaginator";
  /**
   * A list of PaymentMethod items.
   */
  data: getPaymentMethodById_listPaymentMethods_data[];
}

export interface getPaymentMethodById {
  listPaymentMethods: getPaymentMethodById_listPaymentMethods | null;
}

export interface getPaymentMethodByIdVariables {
  first: number;
  filters?: PaymentMethodFilters | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPerson
// ====================================================

export interface getPerson_person {
  __typename: "Person";
  id: string;
  name: string;
  image: string;
  position: string;
  created_at: any;
  updated_at: any | null;
}

export interface getPerson {
  person: getPerson_person | null;
}

export interface getPersonVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getProductBrandById
// ====================================================

export interface getProductBrandById_listProductBrands_data_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface getProductBrandById_listProductBrands_data {
  __typename: "ProductBrand";
  id: number;
  name: string | null;
  evo_name: string;
  slug: string | null;
  logo: string | null;
  logo_title: string | null;
  meta_title: string | null;
  meta_description: string | null;
  og_image: string | null;
  og_title: string | null;
  og_description: string | null;
  is_logo: boolean;
  is_public: boolean;
  is_rossmann: boolean;
  created_at: any | null;
  updated_at: any | null;
  updated_by: getProductBrandById_listProductBrands_data_updated_by | null;
}

export interface getProductBrandById_listProductBrands_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface getProductBrandById_listProductBrands {
  __typename: "ProductBrandPaginator";
  /**
   * A list of ProductBrand items.
   */
  data: getProductBrandById_listProductBrands_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: getProductBrandById_listProductBrands_paginatorInfo;
}

export interface getProductBrandById {
  listProductBrands: getProductBrandById_listProductBrands | null;
}

export interface getProductBrandByIdVariables {
  filters?: ProductBrandFilters | null;
  sortBy?: ProductBrandSort | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getProductById
// ====================================================

export interface getProductById_product_categories_category_path {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface getProductById_product_categories {
  __typename: "ProductCategory";
  id: number;
  category_path: getProductById_product_categories_category_path[];
}

export interface getProductById_product_images {
  __typename: "ProductImage";
  id: number;
  source: ProductImageSource;
  position: number;
  url_list: string | null;
  is_active: boolean;
}

export interface getProductById_product_param_values {
  __typename: "ProductParamValue";
  product_param_id: string;
  is_multi: boolean;
  values: string[];
}

export interface getProductById_product_brand_bank_param_values {
  __typename: "ProductParamValue";
  product_param_id: string;
  is_multi: boolean;
  values: string[];
}

export interface getProductById_product_brand_bank {
  __typename: "ProductBrandBankData";
  id: number;
  param_values: getProductById_product_brand_bank_param_values[];
}

export interface getProductById_product_badges_featured {
  __typename: "Badge";
  name: string;
}

export interface getProductById_product_badges_product_params {
  __typename: "Badge";
  name: string;
}

export interface getProductById_product_badges_notifications {
  __typename: "Badge";
  name: string;
}

export interface getProductById_product_prices {
  __typename: "ProductPrice";
  id: number;
  price: number;
  price_unit: number | null;
  active_from: any | null;
  active_to: any | null;
}

export interface getProductById_product_stock {
  __typename: "ProductStock";
  id: number;
  qty: number;
  updated_at: any | null;
}

export interface getProductById_product_brand {
  __typename: "ProductBrand";
  id: number;
  name: string | null;
}

export interface getProductById_product_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface getProductById_product_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface getProductById_product_children_categories_category_path {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface getProductById_product_children_categories {
  __typename: "ProductCategory";
  id: number;
  category_path: getProductById_product_children_categories_category_path[];
}

export interface getProductById_product_children_images {
  __typename: "ProductImage";
  id: number;
  source: ProductImageSource;
  position: number;
  url_list: string | null;
  is_active: boolean;
}

export interface getProductById_product_children_param_values {
  __typename: "ProductParamValue";
  product_param_id: string;
  is_multi: boolean;
  values: string[];
}

export interface getProductById_product_children_brand_bank_param_values {
  __typename: "ProductParamValue";
  product_param_id: string;
  is_multi: boolean;
  values: string[];
}

export interface getProductById_product_children_brand_bank {
  __typename: "ProductBrandBankData";
  id: number;
  param_values: getProductById_product_children_brand_bank_param_values[];
}

export interface getProductById_product_children_badges_featured {
  __typename: "Badge";
  name: string;
}

export interface getProductById_product_children_badges_product_params {
  __typename: "Badge";
  name: string;
}

export interface getProductById_product_children_badges_notifications {
  __typename: "Badge";
  name: string;
}

export interface getProductById_product_children_prices {
  __typename: "ProductPrice";
  id: number;
  price: number;
  price_unit: number | null;
  active_from: any | null;
  active_to: any | null;
}

export interface getProductById_product_children_stock {
  __typename: "ProductStock";
  id: number;
  qty: number;
  updated_at: any | null;
}

export interface getProductById_product_children_brand {
  __typename: "ProductBrand";
  id: number;
  name: string | null;
}

export interface getProductById_product_children_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface getProductById_product_children_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface getProductById_product_children {
  __typename: "Product";
  id: number;
  name: string | null;
  lfdnr: number | null;
  ean: string[];
  cart_qty_max: number;
  categories: getProductById_product_children_categories[];
  slug: string | null;
  unit_base: string | null;
  images: getProductById_product_children_images[];
  is_active: boolean;
  is_force_active: boolean | null;
  active_from: any | null;
  active_to: any | null;
  param_values: getProductById_product_children_param_values[];
  brand_bank: getProductById_product_children_brand_bank | null;
  badges_featured: getProductById_product_children_badges_featured[];
  badges_product_params: getProductById_product_children_badges_product_params[];
  badges_notifications: getProductById_product_children_badges_notifications[];
  prices: getProductById_product_children_prices[];
  stock: getProductById_product_children_stock | null;
  brand: getProductById_product_children_brand | null;
  created_at: any;
  updated_at: any;
  created_by: getProductById_product_children_created_by | null;
  updated_by: getProductById_product_children_updated_by | null;
  bundle_promotion_id: number | null;
  image_path_360: string | null;
  image_position_360: number | null;
  brandbank_exclude: boolean | null;
}

export interface getProductById_product {
  __typename: "Product";
  id: number;
  name: string | null;
  lfdnr: number | null;
  ean: string[];
  cart_qty_max: number;
  categories: getProductById_product_categories[];
  slug: string | null;
  unit_base: string | null;
  images: getProductById_product_images[];
  is_active: boolean;
  is_force_active: boolean | null;
  active_from: any | null;
  active_to: any | null;
  param_values: getProductById_product_param_values[];
  brand_bank: getProductById_product_brand_bank | null;
  badges_featured: getProductById_product_badges_featured[];
  badges_product_params: getProductById_product_badges_product_params[];
  badges_notifications: getProductById_product_badges_notifications[];
  prices: getProductById_product_prices[];
  stock: getProductById_product_stock | null;
  brand: getProductById_product_brand | null;
  created_at: any;
  updated_at: any;
  created_by: getProductById_product_created_by | null;
  updated_by: getProductById_product_updated_by | null;
  bundle_promotion_id: number | null;
  image_path_360: string | null;
  image_position_360: number | null;
  brandbank_exclude: boolean | null;
  children: getProductById_product_children[] | null;
}

export interface getProductById {
  product: getProductById_product;
}

export interface getProductByIdVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getProductCategoryChildren
// ====================================================

export interface getProductCategoryChildren_listProductCategories_data {
  __typename: "ProductCategory";
  id: number;
  name: string;
  is_active: boolean;
  is_featured: boolean;
  position: number | null;
  has_child: boolean;
}

export interface getProductCategoryChildren_listProductCategories {
  __typename: "ProductCategoryPaginator";
  /**
   * A list of ProductCategory items.
   */
  data: getProductCategoryChildren_listProductCategories_data[];
}

export interface getProductCategoryChildren {
  listProductCategories: getProductCategoryChildren_listProductCategories | null;
}

export interface getProductCategoryChildrenVariables {
  filters?: ProductCategoryFilters | null;
  sortBy?: ProductCategorySort | null;
  first: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getProductListById
// ====================================================

export interface getProductListById_listProductLists_data_products_main_categories {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface getProductListById_listProductLists_data_products_categories {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface getProductListById_listProductLists_data_products {
  __typename: "ProductSimple";
  id: number;
  lfdnr: number | null;
  name: string | null;
  main_categories: getProductListById_listProductLists_data_products_main_categories[];
  categories: getProductListById_listProductLists_data_products_categories[];
  is_visible: boolean;
  active_from: any | null;
  is_description: boolean;
  is_image: boolean;
  ean: string[];
  price: number | null;
  stock_qty: number | null;
  slug: string | null;
}

export interface getProductListById_listProductLists_data_badge {
  __typename: "Badge";
  id: number;
  name: string;
  type: BadgeType;
  created_at: any | null;
  active_from: any | null;
  is_active: boolean;
  is_visible: boolean;
}

export interface getProductListById_listProductLists_data_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface getProductListById_listProductLists_data_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface getProductListById_listProductLists_data_filters {
  __typename: "ProductFilter";
  type: ProductFilterType;
  operator: ProductFilterOperator | null;
  product_brand_id: number | null;
  product_category_id: number | null;
  product_param_id: string | null;
  clpe_promotion_id: number | null;
  price_type: PriceType | null;
  clpe_promotion_type: ClpePromotionType | null;
  created_at: any | null;
  rating: number | null;
  product_type: ProductType | null;
}

export interface getProductListById_listProductLists_data {
  __typename: "ProductList";
  id: number;
  type: ProductListType;
  name: string;
  slug: string;
  mode: ProductListMode;
  is_active: boolean;
  active_from: any | null;
  active_to: any | null;
  title: string;
  subtitle: string | null;
  image: string | null;
  image_title: string | null;
  image_mobile: string | null;
  image_mobile_title: string | null;
  products: getProductListById_listProductLists_data_products[];
  group_by_category: boolean;
  badge: getProductListById_listProductLists_data_badge | null;
  og_image: string | null;
  og_title: string | null;
  og_description: string | null;
  meta_description: string | null;
  created_at: any;
  created_by: getProductListById_listProductLists_data_created_by;
  updated_at: any | null;
  updated_by: getProductListById_listProductLists_data_updated_by | null;
  filters: getProductListById_listProductLists_data_filters[];
}

export interface getProductListById_listProductLists_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface getProductListById_listProductLists {
  __typename: "ProductListPaginator";
  /**
   * A list of ProductList items.
   */
  data: getProductListById_listProductLists_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: getProductListById_listProductLists_paginatorInfo;
}

export interface getProductListById {
  listProductLists: getProductListById_listProductLists | null;
}

export interface getProductListByIdVariables {
  filters?: ProductListFilters | null;
  sortBy?: ProductListSort | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getProductVariantById
// ====================================================

export interface getProductVariantById_product_children_main_categories {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface getProductVariantById_product_children_categories {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface getProductVariantById_product_children {
  __typename: "Product";
  id: number;
  lfdnr: number | null;
  ean: string[];
  name: string | null;
  main_categories: getProductVariantById_product_children_main_categories[];
  categories: getProductVariantById_product_children_categories[];
  is_visible: boolean;
  active_from: any | null;
  slug: string | null;
}

export interface getProductVariantById_product_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface getProductVariantById_product_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface getProductVariantById_product {
  __typename: "Product";
  id: number;
  variant_product_param_id: string | null;
  children: getProductVariantById_product_children[] | null;
  name: string | null;
  slug: string | null;
  is_active: boolean;
  active_from: any | null;
  active_to: any | null;
  created_at: any;
  created_by: getProductVariantById_product_created_by | null;
  updated_at: any;
  updated_by: getProductVariantById_product_updated_by | null;
}

export interface getProductVariantById {
  product: getProductVariantById_product;
}

export interface getProductVariantByIdVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getRedirection
// ====================================================

export interface getRedirection_redirection_from_site {
  __typename: "Site";
  id: string;
  name: string;
  url: string;
}

export interface getRedirection_redirection_to_site {
  __typename: "Site";
  id: string;
  name: string;
  url: string;
}

export interface getRedirection_redirection {
  __typename: "Redirection";
  id: string;
  from_site: getRedirection_redirection_from_site | null;
  from: string | null;
  to_site: getRedirection_redirection_to_site | null;
  to: string | null;
  active_from: any | null;
  active_to: any | null;
  is_active: boolean;
  is_permanent: boolean;
  is_regex: boolean | null;
  forward: boolean | null;
  created_at: any;
  updated_at: any | null;
}

export interface getRedirection {
  redirection: getRedirection_redirection | null;
}

export interface getRedirectionVariables {
  id?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getRoleById
// ====================================================

export interface getRoleById_listRoles_data_permissions {
  __typename: "Permission";
  id: number;
  name: PermissionType;
  title: string;
  group: string;
}

export interface getRoleById_listRoles_data {
  __typename: "Role";
  id: number;
  name: string;
  title: string;
  created_at: any;
  updated_at: any | null;
  permissions: getRoleById_listRoles_data_permissions[];
}

export interface getRoleById_listRoles {
  __typename: "RolePaginator";
  /**
   * A list of Role items.
   */
  data: getRoleById_listRoles_data[];
}

export interface getRoleById {
  listRoles: getRoleById_listRoles | null;
}

export interface getRoleByIdVariables {
  filters?: RoleFilters | null;
  first: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getShippingMethodById
// ====================================================

export interface getShippingMethodById_listShippingMethods_data_cost {
  __typename: "ShippingCost";
  cart_limit: number;
  cost: number;
}

export interface getShippingMethodById_listShippingMethods_data_cost_terminal {
  __typename: "ShippingCost";
  cart_limit: number;
  cost: number;
}

export interface getShippingMethodById_listShippingMethods_data_cost_time_windows_cost {
  __typename: "ShippingCost";
  cart_limit: number;
  cost: number;
}

export interface getShippingMethodById_listShippingMethods_data_cost_time_windows {
  __typename: "TimeWindowCost";
  interval_hours: number;
  cost: getShippingMethodById_listShippingMethods_data_cost_time_windows_cost[];
}

export interface getShippingMethodById_listShippingMethods_data_delivery_informations {
  __typename: "DeliveryInformation";
  info: string;
  active_from: any | null;
  active_to: any | null;
}

export interface getShippingMethodById_listShippingMethods_data_roles {
  __typename: "ShippingMethodRole";
  vip_levels: RossmannPlusVipLevel[] | null;
  active_from: any | null;
  active_to: any | null;
}

export interface getShippingMethodById_listShippingMethods_data {
  __typename: "ShippingMethod";
  id: string;
  name: string | null;
  lead: string | null;
  description: string | null;
  weight_min: number | null;
  weight_max: number | null;
  cart_value_min: number | null;
  order_max: number | null;
  cost: getShippingMethodById_listShippingMethods_data_cost[];
  cost_terminal: getShippingMethodById_listShippingMethods_data_cost_terminal[];
  delivery_days: number | null;
  is_active: boolean;
  is_visible: boolean;
  active_from: any | null;
  active_to: any | null;
  created_at: any;
  updated_at: any | null;
  cost_time_windows: getShippingMethodById_listShippingMethods_data_cost_time_windows[] | null;
  /**
   *  default delivery information ha nincs időzített
   */
  delivery_information: string | null;
  /**
   *  időzített delivery information 
   */
  delivery_informations: getShippingMethodById_listShippingMethods_data_delivery_informations[] | null;
  roles: getShippingMethodById_listShippingMethods_data_roles[] | null;
  time_limit_next_day_departure: string | null;
}

export interface getShippingMethodById_listShippingMethods {
  __typename: "ShippingMethodPaginator";
  /**
   * A list of ShippingMethod items.
   */
  data: getShippingMethodById_listShippingMethods_data[];
}

export interface getShippingMethodById {
  listShippingMethods: getShippingMethodById_listShippingMethods | null;
}

export interface getShippingMethodByIdVariables {
  first: number;
  filters?: ShippingMethodFilters | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getSubscriptionById
// ====================================================

export interface getSubscriptionById_orderSubscription_user {
  __typename: "User";
  firstname: string | null;
  lastname: string | null;
  email: string;
}

export interface getSubscriptionById_orderSubscription_billing_address {
  __typename: "UserAddress";
  firstname: string | null;
  lastname: string | null;
  zip_code: string | null;
  city: string | null;
  address_line1: string | null;
  address_line2: string | null;
  company_name: string | null;
  company_number: string | null;
}

export interface getSubscriptionById_orderSubscription_shipping_address {
  __typename: "UserAddress";
  firstname: string | null;
  lastname: string | null;
  zip_code: string | null;
  city: string | null;
  address_line1: string | null;
  address_line2: string | null;
  company_name: string | null;
  company_number: string | null;
}

export interface getSubscriptionById_orderSubscription_time_window_shipping_method {
  __typename: "ShippingMethod";
  name: string | null;
}

export interface getSubscriptionById_orderSubscription_items_product {
  __typename: "Product";
  id: number;
  name: string | null;
}

export interface getSubscriptionById_orderSubscription_items {
  __typename: "OrderSubscriptionItem";
  id: number;
  product: getSubscriptionById_orderSubscription_items_product;
  quantity: number;
}

export interface getSubscriptionById_orderSubscription_orders_status {
  __typename: "OrderStatus";
  title: string;
}

export interface getSubscriptionById_orderSubscription_orders {
  __typename: "Order";
  increment_id: number | null;
  status: getSubscriptionById_orderSubscription_orders_status;
  delivery_day_expected: any | null;
  created_at: any;
}

export interface getSubscriptionById_orderSubscription {
  __typename: "OrderSubscription";
  id: number;
  user: getSubscriptionById_orderSubscription_user;
  billing_address: getSubscriptionById_orderSubscription_billing_address;
  shipping_address: getSubscriptionById_orderSubscription_shipping_address;
  created_at: any;
  updated_at: any;
  paused_at: any | null;
  /**
   * Date canceled
   */
  deleted_at: any | null;
  notified_at: any | null;
  notified_at_sms: any | null;
  /**
   * Expected delivery date
   */
  date_next: any;
  /**
   * Selected time window
   */
  time_window_date: any | null;
  time_window_interval: string | null;
  time_window_changed: number | null;
  /**
   * Shipping method time window selected for
   */
  time_window_shipping_method: getSubscriptionById_orderSubscription_time_window_shipping_method | null;
  items: getSubscriptionById_orderSubscription_items[];
  orders: getSubscriptionById_orderSubscription_orders[];
}

export interface getSubscriptionById {
  orderSubscription: getSubscriptionById_orderSubscription | null;
}

export interface getSubscriptionByIdVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getSuccessStory
// ====================================================

export interface getSuccessStory_successStory_division_department {
  __typename: "Department";
  id: string;
  name: string;
}

export interface getSuccessStory_successStory_division {
  __typename: "Division";
  id: string;
  name: string;
  department: getSuccessStory_successStory_division_department | null;
  created_at: any;
  updated_at: any | null;
}

export interface getSuccessStory_successStory_person {
  __typename: "Person";
  id: string;
  name: string;
  position: string;
  created_at: any;
  updated_at: any | null;
}

export interface getSuccessStory_successStory {
  __typename: "SuccessStory";
  id: string;
  content: string;
  division: getSuccessStory_successStory_division;
  person: getSuccessStory_successStory_person;
  created_at: any;
  updated_at: any | null;
}

export interface getSuccessStory {
  successStory: getSuccessStory_successStory | null;
}

export interface getSuccessStoryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getTodoById
// ====================================================

export interface getTodoById_todo_role {
  __typename: "Role";
  id: number;
  name: string;
  title: string;
}

export interface getTodoById_todo_assigned_to {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface getTodoById_todo_created_by {
  __typename: "Admin";
  name: string;
}

export interface getTodoById_todo_updated_by {
  __typename: "Admin";
  name: string;
}

export interface getTodoById_todo {
  __typename: "Todo";
  id: string;
  status: TodoStatus;
  subject: string;
  message: string;
  role: getTodoById_todo_role;
  created_at: any;
  updated_at: any | null;
  assigned_to: getTodoById_todo_assigned_to | null;
  created_by: getTodoById_todo_created_by | null;
  updated_by: getTodoById_todo_updated_by | null;
}

export interface getTodoById {
  todo: getTodoById_todo | null;
}

export interface getTodoByIdVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getUserById
// ====================================================

export interface getUserById_getUser_rossmann_plus_profile {
  __typename: "RossmannPlusProfile";
  card_number: string;
  is_virtual_card: boolean;
}

export interface getUserById_getUser {
  __typename: "User";
  id: number;
  lastname: string | null;
  firstname: string | null;
  email: string;
  phone_number: string | null;
  rossmann_plus_profile: getUserById_getUser_rossmann_plus_profile | null;
  created_at: any;
  banktransfer_enabled: boolean;
  cod_disabled: boolean;
  newsletter: boolean;
  is_active: boolean;
  country_code: string | null;
  area_code: string | null;
  order_count: number;
  order_total_sum: number;
  last_login: any | null;
}

export interface getUserById {
  getUser: getUserById_getUser;
}

export interface getUserByIdVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getWebsiteNotificationById
// ====================================================

export interface getWebsiteNotificationById_listWebsiteNotifications_data_created_by {
  __typename: "Admin";
  name: string;
}

export interface getWebsiteNotificationById_listWebsiteNotifications_data_updated_by {
  __typename: "Admin";
  name: string;
}

export interface getWebsiteNotificationById_listWebsiteNotifications_data {
  __typename: "WebsiteNotification";
  id: number;
  name: string;
  title: string | null;
  message: string;
  is_active: boolean;
  active_from: any | null;
  active_to: any | null;
  created_at: any;
  created_by: getWebsiteNotificationById_listWebsiteNotifications_data_created_by;
  updated_at: any;
  updated_by: getWebsiteNotificationById_listWebsiteNotifications_data_updated_by | null;
  target: ClientType[];
}

export interface getWebsiteNotificationById_listWebsiteNotifications {
  __typename: "WebsiteNotificationPaginator";
  /**
   * A list of WebsiteNotification items.
   */
  data: getWebsiteNotificationById_listWebsiteNotifications_data[];
}

export interface getWebsiteNotificationById {
  listWebsiteNotifications: getWebsiteNotificationById_listWebsiteNotifications | null;
}

export interface getWebsiteNotificationByIdVariables {
  first: number;
  filters?: WebsiteNotificationFilters | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: giftCardOrderGiftCards
// ====================================================

export interface giftCardOrderGiftCards_giftCardOrder_gift_cards_log_sent_to {
  __typename: "GiftCardLog";
  date: any;
  email: string;
}

export interface giftCardOrderGiftCards_giftCardOrder_gift_cards {
  __typename: "GiftCard";
  id: number;
  value: number;
  barcode: string | null;
  expire_at: any | null;
  mail_to: string;
  sent_at: any | null;
  log_sent_to: giftCardOrderGiftCards_giftCardOrder_gift_cards_log_sent_to[];
}

export interface giftCardOrderGiftCards_giftCardOrder {
  __typename: "GiftCardOrder";
  gift_cards: (giftCardOrderGiftCards_giftCardOrder_gift_cards | null)[];
}

export interface giftCardOrderGiftCards {
  giftCardOrder: giftCardOrderGiftCards_giftCardOrder | null;
}

export interface giftCardOrderGiftCardsVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: giftCardOrder
// ====================================================

export interface giftCardOrder_giftCardOrder_user {
  __typename: "User";
  email: string;
}

export interface giftCardOrder_giftCardOrder_status {
  __typename: "OrderStatus";
  id: OrderStatusEnum;
  title: string;
}

export interface giftCardOrder_giftCardOrder_billing_address {
  __typename: "UserAddress";
  firstname: string | null;
  lastname: string | null;
  zip_code: string | null;
  city: string | null;
  address_line1: string | null;
  address_line2: string | null;
  company_name: string | null;
  company_number: string | null;
}

export interface giftCardOrder_giftCardOrder_payment_method {
  __typename: "PaymentMethod";
  id: string;
  name: string | null;
}

export interface giftCardOrder_giftCardOrder_payment_status {
  __typename: "PaymentStatus";
  id: PaymentStatusEnum;
  title: string;
}

export interface giftCardOrder_giftCardOrder_payment_transaction_latest {
  __typename: "PaymentTransaction";
  id: number;
  anum: string | null;
}

export interface giftCardOrder_giftCardOrder_payment {
  __typename: "OrderPayment";
  status: giftCardOrder_giftCardOrder_payment_status;
  transaction_latest: giftCardOrder_giftCardOrder_payment_transaction_latest | null;
  amount_paid: number;
  amount_refunded: number;
}

export interface giftCardOrder_giftCardOrder_admin_notes_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface giftCardOrder_giftCardOrder_admin_notes {
  __typename: "AdminNote";
  note: string | null;
  created_by: giftCardOrder_giftCardOrder_admin_notes_created_by | null;
  created_at: any | null;
}

export interface giftCardOrder_giftCardOrder_items {
  __typename: "GiftCardOrderItem";
  id: number;
  quantity: number;
  value: number;
  design: number;
  sub_total: number;
  name_from: string;
  name_to: string | null;
  mail_to: string[] | null;
}

export interface giftCardOrder_giftCardOrder_invoice_status {
  __typename: "InvoiceStatus";
  title: string;
}

export interface giftCardOrder_giftCardOrder_invoices {
  __typename: "OrderInvoice";
  invoice_number: string | null;
  url: string | null;
}

export interface giftCardOrder_giftCardOrder_gift_cards_log_sent_to {
  __typename: "GiftCardLog";
  date: any;
  email: string;
}

export interface giftCardOrder_giftCardOrder_gift_cards {
  __typename: "GiftCard";
  id: number;
  value: number;
  barcode: string | null;
  expire_at: any | null;
  mail_to: string;
  sent_at: any | null;
  log_sent_to: giftCardOrder_giftCardOrder_gift_cards_log_sent_to[];
}

export interface giftCardOrder_giftCardOrder_type {
  __typename: "GiftCardOrderTypeWithTitle";
  title: string;
}

export interface giftCardOrder_giftCardOrder {
  __typename: "GiftCardOrder";
  id: number;
  code: string;
  created_at: any;
  contact_fullname: string;
  email: string | null;
  phone_number: string | null;
  user: giftCardOrder_giftCardOrder_user | null;
  status: giftCardOrder_giftCardOrder_status;
  billing_address: giftCardOrder_giftCardOrder_billing_address;
  payment_method: giftCardOrder_giftCardOrder_payment_method;
  grand_total: number;
  payment: giftCardOrder_giftCardOrder_payment | null;
  admin_notes: giftCardOrder_giftCardOrder_admin_notes[];
  items: giftCardOrder_giftCardOrder_items[];
  invoice_status: giftCardOrder_giftCardOrder_invoice_status | null;
  invoices: giftCardOrder_giftCardOrder_invoices[];
  can_be_deleted: boolean;
  user_agent_type: string;
  gift_cards: (giftCardOrder_giftCardOrder_gift_cards | null)[];
  type: giftCardOrder_giftCardOrder_type;
}

export interface giftCardOrder {
  giftCardOrder: giftCardOrder_giftCardOrder | null;
}

export interface giftCardOrderVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: indexStatus
// ====================================================

export interface indexStatus_indexStatus {
  __typename: "IndexStatus";
  /**
   * 5000 max
   */
  queue_length: number;
}

export interface indexStatus {
  indexStatus: indexStatus_indexStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listAdminContentsForMenu
// ====================================================

export interface listAdminContentsForMenu_adminContents_data_GalleryContent {
  __typename: "GalleryContent" | "VideoContent";
}

export interface listAdminContentsForMenu_adminContents_data_TextContent_category {
  __typename: "Category";
  url: string;
}

export interface listAdminContentsForMenu_adminContents_data_TextContent {
  __typename: "TextContent";
  id: string;
  title: string;
  url: string;
  category: listAdminContentsForMenu_adminContents_data_TextContent_category | null;
}

export type listAdminContentsForMenu_adminContents_data = listAdminContentsForMenu_adminContents_data_GalleryContent | listAdminContentsForMenu_adminContents_data_TextContent;

export interface listAdminContentsForMenu_adminContents_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface listAdminContentsForMenu_adminContents {
  __typename: "ContentPaginator";
  /**
   * A list of Content items.
   */
  data: listAdminContentsForMenu_adminContents_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listAdminContentsForMenu_adminContents_paginatorInfo;
}

export interface listAdminContentsForMenu {
  adminContents: listAdminContentsForMenu_adminContents | null;
}

export interface listAdminContentsForMenuVariables {
  filters?: ContentFilters | null;
  sortBy?: ContentOrder | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listAdminsForSelect
// ====================================================

export interface listAdminsForSelect_listAdmins_data {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface listAdminsForSelect_listAdmins_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface listAdminsForSelect_listAdmins {
  __typename: "AdminPaginator";
  /**
   * A list of Admin items.
   */
  data: listAdminsForSelect_listAdmins_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listAdminsForSelect_listAdmins_paginatorInfo;
}

export interface listAdminsForSelect {
  listAdmins: listAdminsForSelect_listAdmins | null;
}

export interface listAdminsForSelectVariables {
  filters?: AdminFilters | null;
  sortBy?: AdminSort | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listAdmins
// ====================================================

export interface listAdmins_listAdmins_data_roles {
  __typename: "Role";
  title: string;
}

export interface listAdmins_listAdmins_data {
  __typename: "Admin";
  id: number;
  name: string;
  email: string;
  is_active: boolean;
  last_login: any | null;
  roles: listAdmins_listAdmins_data_roles[];
}

export interface listAdmins_listAdmins_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface listAdmins_listAdmins {
  __typename: "AdminPaginator";
  /**
   * A list of Admin items.
   */
  data: listAdmins_listAdmins_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listAdmins_listAdmins_paginatorInfo;
}

export interface listAdmins {
  listAdmins: listAdmins_listAdmins | null;
}

export interface listAdminsVariables {
  filters?: AdminFilters | null;
  sortBy?: AdminSort | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listBadgeById
// ====================================================

export interface listBadgeById_listBadges_data_product_param {
  __typename: "ProductParam";
  id: string;
  name: string | null;
}

export interface listBadgeById_listBadges_data_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface listBadgeById_listBadges_data_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface listBadgeById_listBadges_data {
  __typename: "Badge";
  id: number;
  type: BadgeType;
  name: string;
  info: string | null;
  image: string | null;
  image_title: string | null;
  product_param: listBadgeById_listBadges_data_product_param | null;
  is_active: boolean;
  created_at: any | null;
  created_by: listBadgeById_listBadges_data_created_by;
  updated_at: any | null;
  updated_by: listBadgeById_listBadges_data_updated_by | null;
  active_from: any | null;
  active_to: any | null;
  is_visible: boolean;
}

export interface listBadgeById_listBadges {
  __typename: "BadgePaginator";
  /**
   * A list of Badge items.
   */
  data: listBadgeById_listBadges_data[];
}

export interface listBadgeById {
  listBadges: listBadgeById_listBadges | null;
}

export interface listBadgeByIdVariables {
  filters?: BadgeFilters | null;
  first: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listBadges
// ====================================================

export interface listBadges_listBadges_data {
  __typename: "Badge";
  id: number;
  name: string;
  type: BadgeType;
  created_at: any | null;
  active_from: any | null;
  is_active: boolean;
  is_visible: boolean;
}

export interface listBadges_listBadges_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface listBadges_listBadges {
  __typename: "BadgePaginator";
  /**
   * A list of Badge items.
   */
  data: listBadges_listBadges_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listBadges_listBadges_paginatorInfo;
}

export interface listBadges {
  listBadges: listBadges_listBadges | null;
}

export interface listBadgesVariables {
  filters?: BadgeFilters | null;
  sortBy?: BadgeSort | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listBanners
// ====================================================

export interface listBanners_listBanners_data_BannerNormal {
  __typename: "BannerNormal";
  id: number;
  name: string;
  target_url: string | null;
  type: BannerType;
  active_from: any | null;
  active_to: any | null;
  is_active: boolean;
}

export interface listBanners_listBanners_data_BannerProductList {
  __typename: "BannerProductList";
  id: number;
  name: string;
  target_url: string | null;
  type: BannerType;
  active_from: any | null;
  active_to: any | null;
  is_active: boolean;
}

export interface listBanners_listBanners_data_BannerProduct {
  __typename: "BannerProduct";
  id: number;
  name: string;
  type: BannerType;
  active_from: any | null;
  active_to: any | null;
  is_active: boolean;
}

export type listBanners_listBanners_data = listBanners_listBanners_data_BannerNormal | listBanners_listBanners_data_BannerProductList | listBanners_listBanners_data_BannerProduct;

export interface listBanners_listBanners_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface listBanners_listBanners {
  __typename: "BannerPaginator";
  /**
   * A list of Banner items.
   */
  data: listBanners_listBanners_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listBanners_listBanners_paginatorInfo;
}

export interface listBanners {
  listBanners: listBanners_listBanners | null;
}

export interface listBannersVariables {
  filters?: BannerFilters | null;
  sortBy?: BannerSort | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listCalendarDays
// ====================================================

export interface listCalendarDays_listCalendarDays_data {
  __typename: "CalendarDay";
  date: any;
  is_workday: boolean;
  is_delivery: boolean;
}

export interface listCalendarDays_listCalendarDays_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface listCalendarDays_listCalendarDays {
  __typename: "CalendarDayPaginator";
  /**
   * A list of CalendarDay items.
   */
  data: listCalendarDays_listCalendarDays_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listCalendarDays_listCalendarDays_paginatorInfo;
}

export interface listCalendarDays {
  listCalendarDays: listCalendarDays_listCalendarDays | null;
}

export interface listCalendarDaysVariables {
  filters?: CalendarDayFilters | null;
  sortBy?: CalendarDaySort | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listCampaigns
// ====================================================

export interface listCampaigns_listCampaigns_data {
  __typename: "Campaign";
  id: number;
  name: string;
  active_from: any | null;
  active_to: any | null;
  is_active: boolean;
}

export interface listCampaigns_listCampaigns_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface listCampaigns_listCampaigns {
  __typename: "CampaignPaginator";
  /**
   * A list of Campaign items.
   */
  data: listCampaigns_listCampaigns_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listCampaigns_listCampaigns_paginatorInfo;
}

export interface listCampaigns {
  listCampaigns: listCampaigns_listCampaigns | null;
}

export interface listCampaignsVariables {
  filters?: CampaignFilters | null;
  sortBy?: CampaignSort | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listClpePromotionSelectItems
// ====================================================

export interface listClpePromotionSelectItems_listPromotions_data {
  __typename: "ClpePromotion";
  id: number;
  title: string;
}

export interface listClpePromotionSelectItems_listPromotions {
  __typename: "ClpePromotionPaginator";
  /**
   * A list of ClpePromotion items.
   */
  data: listClpePromotionSelectItems_listPromotions_data[];
}

export interface listClpePromotionSelectItems {
  listPromotions: listClpePromotionSelectItems_listPromotions | null;
}

export interface listClpePromotionSelectItemsVariables {
  first: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listFulfillment
// ====================================================

export interface listFulfillment_listFulfillment_data_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface listFulfillment_listFulfillment_data_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface listFulfillment_listFulfillment_data {
  __typename: "Fulfillment";
  id: number;
  active_from: any;
  active_to: any;
  date_reset_next: any;
  /**
   * Flag allocation limit between two daily reset timestamp
   */
  limit: number;
  /**
   * Flags allocated in the daily interval since the last reset timestamp
   */
  count: number;
  shipping_methods: string[];
  timewindow_allowed: boolean;
  vip_levels: RossmannPlusVipLevel[];
  created_at: any;
  updated_at: any | null;
  created_by: listFulfillment_listFulfillment_data_created_by | null;
  updated_by: listFulfillment_listFulfillment_data_updated_by | null;
  is_active: boolean;
  weight: number;
}

export interface listFulfillment_listFulfillment_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface listFulfillment_listFulfillment {
  __typename: "FulfillmentPaginator";
  /**
   * A list of Fulfillment items.
   */
  data: listFulfillment_listFulfillment_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listFulfillment_listFulfillment_paginatorInfo;
}

export interface listFulfillment {
  listFulfillment: listFulfillment_listFulfillment | null;
}

export interface listFulfillmentVariables {
  filters?: FulfillmentFilters | null;
  sortBy?: FulfillmentSort | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listGiftCardOrders
// ====================================================

export interface listGiftCardOrders_listGiftCardOrders_data_status {
  __typename: "OrderStatus";
  id: OrderStatusEnum;
  title: string;
}

export interface listGiftCardOrders_listGiftCardOrders_data_type {
  __typename: "GiftCardOrderTypeWithTitle";
  id: string;
  title: string;
}

export interface listGiftCardOrders_listGiftCardOrders_data_created_by {
  __typename: "Admin";
  name: string;
}

export interface listGiftCardOrders_listGiftCardOrders_data {
  __typename: "GiftCardOrderSimple";
  id: number;
  user_fullname: string | null;
  user_email: string | null;
  payment_method_name: string;
  payment_status_title: string | null;
  grand_total: number;
  created_at: any;
  updated_at: any;
  billing_fullname: string;
  status: listGiftCardOrders_listGiftCardOrders_data_status;
  user_agent_type: string;
  type: listGiftCardOrders_listGiftCardOrders_data_type;
  created_by: listGiftCardOrders_listGiftCardOrders_data_created_by | null;
}

export interface listGiftCardOrders_listGiftCardOrders_aggregations {
  __typename: "OrderAggregation";
  sum_grand_total: number | null;
}

export interface listGiftCardOrders_listGiftCardOrders_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface listGiftCardOrders_listGiftCardOrders {
  __typename: "GiftCardOrderListResult";
  data: listGiftCardOrders_listGiftCardOrders_data[];
  aggregations: listGiftCardOrders_listGiftCardOrders_aggregations;
  paginatorInfo: listGiftCardOrders_listGiftCardOrders_paginatorInfo;
}

export interface listGiftCardOrders {
  listGiftCardOrders: listGiftCardOrders_listGiftCardOrders;
}

export interface listGiftCardOrdersVariables {
  filters?: GiftCardOrderFilters | null;
  sortBy?: GiftCardOrderSort | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listGiftCardsExport
// ====================================================

export interface listGiftCardsExport_listGiftCardsExport {
  __typename: "BigFishLaravelExportListResponse";
  url: string | null;
  send_in_email: boolean;
}

export interface listGiftCardsExport {
  listGiftCardsExport: listGiftCardsExport_listGiftCardsExport | null;
}

export interface listGiftCardsExportVariables {
  filters?: GiftCardOrderFilters | null;
  sortBy?: GiftCardOrderSort | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listMailTemplates
// ====================================================

export interface listMailTemplates_listMailTemplates_data {
  __typename: "MailTemplate";
  id: number;
  subject: string;
  is_active: boolean;
}

export interface listMailTemplates_listMailTemplates_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface listMailTemplates_listMailTemplates {
  __typename: "MailTemplatePaginator";
  /**
   * A list of MailTemplate items.
   */
  data: listMailTemplates_listMailTemplates_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listMailTemplates_listMailTemplates_paginatorInfo;
}

export interface listMailTemplates {
  listMailTemplates: listMailTemplates_listMailTemplates | null;
}

export interface listMailTemplatesVariables {
  filters?: MailTemplateFilters | null;
  sortBy?: MailTemplateOrder | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listMaintenances
// ====================================================

export interface listMaintenances_maintenances_data {
  __typename: "Maintenance";
  id: string;
  active_from: any;
  active_to: any;
  scopes: MaintenanceScope[] | null;
  notification: string | null;
  maintenance: boolean;
  is_active: boolean;
}

export interface listMaintenances_maintenances_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface listMaintenances_maintenances {
  __typename: "MaintenancePaginator";
  /**
   * A list of Maintenance items.
   */
  data: listMaintenances_maintenances_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listMaintenances_maintenances_paginatorInfo;
}

export interface listMaintenances {
  maintenances: listMaintenances_maintenances | null;
}

export interface listMaintenancesVariables {
  filters?: MaintenanceFilters | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listMenus
// ====================================================

export interface listMenus_listMenus_data {
  __typename: "Menu";
  id: number;
  slug: string;
  name: string;
  created_at: any;
  updated_at: any | null;
}

export interface listMenus_listMenus_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface listMenus_listMenus {
  __typename: "MenuPaginator";
  /**
   * A list of Menu items.
   */
  data: listMenus_listMenus_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listMenus_listMenus_paginatorInfo;
}

export interface listMenus {
  listMenus: listMenus_listMenus | null;
}

export interface listMenusVariables {
  filters?: MenuFilters | null;
  sortBy?: MenuSort | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listOrdersExport
// ====================================================

export interface listOrdersExport_listOrdersExport {
  __typename: "BigFishLaravelExportListResponse";
  url: string | null;
  send_in_email: boolean;
}

export interface listOrdersExport {
  listOrdersExport: listOrdersExport_listOrdersExport | null;
}

export interface listOrdersExportVariables {
  filters?: OrderFilters | null;
  sortBy?: OrderSort | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listOrders
// ====================================================

export interface listOrders_listOrders_data_pickup_point {
  __typename: "PickupPointSimple";
  id: number;
}

export interface listOrders_listOrders_data_shipment_status {
  __typename: "ShipmentStatus";
  title: string;
}

export interface listOrders_listOrders_data_status {
  __typename: "OrderStatus";
  title: string;
}

export interface listOrders_listOrders_data {
  __typename: "OrderSimple";
  id: number;
  increment_id: number;
  created_at: any;
  time_window_date: any | null;
  delivery_day_expected: any | null;
  user_fullname: string | null;
  user_email: string | null;
  grand_total: number;
  shipping_method_name: string;
  payment_method_name: string;
  payment_status_title: string | null;
  time_window_interval: string | null;
  time_window_interval_length: number | null;
  pickup_point: listOrders_listOrders_data_pickup_point | null;
  shipment_status: listOrders_listOrders_data_shipment_status | null;
  status: listOrders_listOrders_data_status;
  user_agent_type: string;
  fulfillment: FulfillmentTypeEnum;
  card_number: string | null;
  order_subscription_id: number | null;
}

export interface listOrders_listOrders_aggregations {
  __typename: "OrderAggregation";
  sum_grand_total: number | null;
}

export interface listOrders_listOrders_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface listOrders_listOrders {
  __typename: "OrderListResult";
  data: listOrders_listOrders_data[];
  aggregations: listOrders_listOrders_aggregations;
  paginatorInfo: listOrders_listOrders_paginatorInfo;
}

export interface listOrders {
  listOrders: listOrders_listOrders;
}

export interface listOrdersVariables {
  filters?: OrderFilters | null;
  sortBy?: OrderSort | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listPagesForMenu
// ====================================================

export interface listPagesForMenu_listPages_data {
  __typename: "Page";
  id: number;
  name: string;
  url: string;
}

export interface listPagesForMenu_listPages_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface listPagesForMenu_listPages {
  __typename: "PagePaginator";
  /**
   * A list of Page items.
   */
  data: listPagesForMenu_listPages_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listPagesForMenu_listPages_paginatorInfo;
}

export interface listPagesForMenu {
  listPages: listPagesForMenu_listPages | null;
}

export interface listPagesForMenuVariables {
  filters?: PageFilters | null;
  sortBy?: PageSort | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listPagesForSelect
// ====================================================

export interface listPagesForSelect_listPages_data {
  __typename: "Page";
  id: number;
  name: string;
}

export interface listPagesForSelect_listPages_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface listPagesForSelect_listPages {
  __typename: "PagePaginator";
  /**
   * A list of Page items.
   */
  data: listPagesForSelect_listPages_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listPagesForSelect_listPages_paginatorInfo;
}

export interface listPagesForSelect {
  listPages: listPagesForSelect_listPages | null;
}

export interface listPagesForSelectVariables {
  filters?: PageFilters | null;
  sortBy?: PageSort | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listPages
// ====================================================

export interface listPages_listPages_data {
  __typename: "Page";
  id: number;
  name: string;
  url: string;
  active_from: any | null;
  active_to: any | null;
  updated_at: any | null;
  is_visible: boolean;
}

export interface listPages_listPages_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface listPages_listPages {
  __typename: "PagePaginator";
  /**
   * A list of Page items.
   */
  data: listPages_listPages_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listPages_listPages_paginatorInfo;
}

export interface listPages {
  listPages: listPages_listPages | null;
}

export interface listPagesVariables {
  filters?: PageFilters | null;
  sortBy?: PageSort | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listPaymentMethodSelectItems
// ====================================================

export interface listPaymentMethodSelectItems_listPaymentMethods_data {
  __typename: "PaymentMethod";
  id: string;
  name: string | null;
}

export interface listPaymentMethodSelectItems_listPaymentMethods_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface listPaymentMethodSelectItems_listPaymentMethods {
  __typename: "PaymentMethodPaginator";
  /**
   * A list of PaymentMethod items.
   */
  data: listPaymentMethodSelectItems_listPaymentMethods_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listPaymentMethodSelectItems_listPaymentMethods_paginatorInfo;
}

export interface listPaymentMethodSelectItems {
  listPaymentMethods: listPaymentMethodSelectItems_listPaymentMethods | null;
}

export interface listPaymentMethodSelectItemsVariables {
  filters?: PaymentMethodFilters | null;
  sortBy?: PaymentMethodSort | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listPaymentMethods
// ====================================================

export interface listPaymentMethods_listPaymentMethods_data {
  __typename: "PaymentMethod";
  id: string;
  name: string | null;
}

export interface listPaymentMethods_listPaymentMethods_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface listPaymentMethods_listPaymentMethods {
  __typename: "PaymentMethodPaginator";
  /**
   * A list of PaymentMethod items.
   */
  data: listPaymentMethods_listPaymentMethods_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listPaymentMethods_listPaymentMethods_paginatorInfo;
}

export interface listPaymentMethods {
  listPaymentMethods: listPaymentMethods_listPaymentMethods | null;
}

export interface listPaymentMethodsVariables {
  first: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listPaymentStatusSelectItems
// ====================================================

export interface listPaymentStatusSelectItems_paymentStatuses {
  __typename: "PaymentStatus";
  id: PaymentStatusEnum;
  title: string;
}

export interface listPaymentStatusSelectItems {
  paymentStatuses: listPaymentStatusSelectItems_paymentStatuses[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listPermissions
// ====================================================

export interface listPermissions_listPermissions_data {
  __typename: "Permission";
  id: number;
  name: PermissionType;
  title: string;
  group: string;
}

export interface listPermissions_listPermissions {
  __typename: "PermissionPaginator";
  /**
   * A list of Permission items.
   */
  data: listPermissions_listPermissions_data[];
}

export interface listPermissions {
  listPermissions: listPermissions_listPermissions | null;
}

export interface listPermissionsVariables {
  first: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listProductBrandSelectItems
// ====================================================

export interface listProductBrandSelectItems_listProductBrands_data {
  __typename: "ProductBrand";
  id: number;
  name: string | null;
}

export interface listProductBrandSelectItems_listProductBrands {
  __typename: "ProductBrandPaginator";
  /**
   * A list of ProductBrand items.
   */
  data: listProductBrandSelectItems_listProductBrands_data[];
}

export interface listProductBrandSelectItems {
  listProductBrands: listProductBrandSelectItems_listProductBrands | null;
}

export interface listProductBrandSelectItemsVariables {
  first: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listProductBrandsForMenu
// ====================================================

export interface listProductBrandsForMenu_listProductBrands_data {
  __typename: "ProductBrand";
  id: number;
  name: string | null;
  slug: string | null;
}

export interface listProductBrandsForMenu_listProductBrands_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface listProductBrandsForMenu_listProductBrands {
  __typename: "ProductBrandPaginator";
  /**
   * A list of ProductBrand items.
   */
  data: listProductBrandsForMenu_listProductBrands_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listProductBrandsForMenu_listProductBrands_paginatorInfo;
}

export interface listProductBrandsForMenu {
  listProductBrands: listProductBrandsForMenu_listProductBrands | null;
}

export interface listProductBrandsForMenuVariables {
  filters?: ProductBrandFilters | null;
  sortBy?: ProductBrandSort | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listProductBrands
// ====================================================

export interface listProductBrands_listProductBrands_data {
  __typename: "ProductBrand";
  id: number;
  name: string | null;
  slug: string | null;
  is_logo: boolean;
  is_public: boolean;
  is_rossmann: boolean;
  created_at: any | null;
  updated_at: any | null;
}

export interface listProductBrands_listProductBrands_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface listProductBrands_listProductBrands {
  __typename: "ProductBrandPaginator";
  /**
   * A list of ProductBrand items.
   */
  data: listProductBrands_listProductBrands_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listProductBrands_listProductBrands_paginatorInfo;
}

export interface listProductBrands {
  listProductBrands: listProductBrands_listProductBrands | null;
}

export interface listProductBrandsVariables {
  filters?: ProductBrandFilters | null;
  sortBy?: ProductBrandSort | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listProductBundles
// ====================================================

export interface listProductBundles_listProductBundles_data_main_categories {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface listProductBundles_listProductBundles_data_categories {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface listProductBundles_listProductBundles_data {
  __typename: "ProductSimple";
  id: number;
  lfdnr: number | null;
  name: string | null;
  is_active: boolean;
  is_visible: boolean;
  main_categories: listProductBundles_listProductBundles_data_main_categories[];
  categories: listProductBundles_listProductBundles_data_categories[];
  created_at: any;
  updated_at: any;
  active_from: any | null;
}

export interface listProductBundles_listProductBundles_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface listProductBundles_listProductBundles {
  __typename: "ProductSimplePaginator";
  /**
   * A list of ProductSimple items.
   */
  data: listProductBundles_listProductBundles_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listProductBundles_listProductBundles_paginatorInfo;
}

export interface listProductBundles {
  listProductBundles: listProductBundles_listProductBundles | null;
}

export interface listProductBundlesVariables {
  filters_base?: ProductFilterInput[] | null;
  filters?: ProductFilters | null;
  sortBy?: ProductSort | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listProductCategoriesById
// ====================================================

export interface listProductCategoriesById_listProductCategories_data_banners {
  __typename: "ProductCategoryBanner";
  is_active: boolean;
  active_from: any | null;
  active_to: any | null;
  image: string;
  image_title: string | null;
  link: string | null;
  link_new_tab: boolean;
}

export interface listProductCategoriesById_listProductCategories_data_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface listProductCategoriesById_listProductCategories_data_product_sort_by {
  __typename: "ProductSortFrontend";
  field: ProductSortFieldCategory;
  direction: OrderDirection;
}

export interface listProductCategoriesById_listProductCategories_data_parent {
  __typename: "ProductCategory";
  id: number;
}

export interface listProductCategoriesById_listProductCategories_data {
  __typename: "ProductCategory";
  id: number;
  name: string;
  icon: string | null;
  slug: string;
  icon_title: string | null;
  description: string | null;
  banners: listProductCategoriesById_listProductCategories_data_banners[];
  is_active: boolean;
  include_in_menu: boolean;
  is_featured: boolean;
  feed_facebook: boolean;
  feed_google: boolean;
  feed_google_merchant: boolean;
  feed_dsa: boolean;
  og_image: string | null;
  og_title: string | null;
  og_description: string | null;
  meta_description: string | null;
  updated_at: any | null;
  updated_by: listProductCategoriesById_listProductCategories_data_updated_by | null;
  product_sort_by: listProductCategoriesById_listProductCategories_data_product_sort_by | null;
  parent: listProductCategoriesById_listProductCategories_data_parent | null;
}

export interface listProductCategoriesById_listProductCategories {
  __typename: "ProductCategoryPaginator";
  /**
   * A list of ProductCategory items.
   */
  data: listProductCategoriesById_listProductCategories_data[];
}

export interface listProductCategoriesById {
  listProductCategories: listProductCategoriesById_listProductCategories | null;
}

export interface listProductCategoriesByIdVariables {
  filters?: ProductCategoryFilters | null;
  first: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listProductCategoriesForMenu
// ====================================================

export interface listProductCategoriesForMenu_productCategoryTree_children_children_children_children_children {
  __typename: "ProductCategorySimple";
  id: number;
  name: string;
  slug: string;
}

export interface listProductCategoriesForMenu_productCategoryTree_children_children_children_children {
  __typename: "ProductCategorySimple";
  id: number;
  name: string;
  slug: string;
  children: listProductCategoriesForMenu_productCategoryTree_children_children_children_children_children[] | null;
}

export interface listProductCategoriesForMenu_productCategoryTree_children_children_children {
  __typename: "ProductCategorySimple";
  id: number;
  name: string;
  slug: string;
  children: listProductCategoriesForMenu_productCategoryTree_children_children_children_children[] | null;
}

export interface listProductCategoriesForMenu_productCategoryTree_children_children {
  __typename: "ProductCategorySimple";
  id: number;
  name: string;
  slug: string;
  children: listProductCategoriesForMenu_productCategoryTree_children_children_children[] | null;
}

export interface listProductCategoriesForMenu_productCategoryTree_children {
  __typename: "ProductCategorySimple";
  id: number;
  name: string;
  slug: string;
  children: listProductCategoriesForMenu_productCategoryTree_children_children[] | null;
}

export interface listProductCategoriesForMenu_productCategoryTree {
  __typename: "ProductCategorySimple";
  id: number;
  name: string;
  slug: string;
  children: listProductCategoriesForMenu_productCategoryTree_children[] | null;
}

export interface listProductCategoriesForMenu {
  productCategoryTree: listProductCategoriesForMenu_productCategoryTree[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listProductCategorySelectItems
// ====================================================

export interface listProductCategorySelectItems_productCategoryTree_children_children_children_children_children {
  __typename: "ProductCategorySimple";
  id: number;
  name: string;
}

export interface listProductCategorySelectItems_productCategoryTree_children_children_children_children {
  __typename: "ProductCategorySimple";
  id: number;
  name: string;
  children: listProductCategorySelectItems_productCategoryTree_children_children_children_children_children[] | null;
}

export interface listProductCategorySelectItems_productCategoryTree_children_children_children {
  __typename: "ProductCategorySimple";
  id: number;
  name: string;
  children: listProductCategorySelectItems_productCategoryTree_children_children_children_children[] | null;
}

export interface listProductCategorySelectItems_productCategoryTree_children_children {
  __typename: "ProductCategorySimple";
  id: number;
  name: string;
  children: listProductCategorySelectItems_productCategoryTree_children_children_children[] | null;
}

export interface listProductCategorySelectItems_productCategoryTree_children {
  __typename: "ProductCategorySimple";
  id: number;
  name: string;
  children: listProductCategorySelectItems_productCategoryTree_children_children[] | null;
}

export interface listProductCategorySelectItems_productCategoryTree {
  __typename: "ProductCategorySimple";
  id: number;
  name: string;
  children: listProductCategorySelectItems_productCategoryTree_children[] | null;
}

export interface listProductCategorySelectItems {
  productCategoryTree: listProductCategorySelectItems_productCategoryTree[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listProductFilterParams
// ====================================================

export interface listProductFilterParams_listProductFilterParams {
  __typename: "ProductParam";
  id: string;
  name: string | null;
}

export interface listProductFilterParams {
  listProductFilterParams: listProductFilterParams_listProductFilterParams[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listProductGratis
// ====================================================

export interface listProductGratis_listProductGratis_data_main_categories {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface listProductGratis_listProductGratis_data {
  __typename: "ProductSimple";
  id: number;
  lfdnr: number | null;
  ean: string[];
  name: string | null;
  main_categories: listProductGratis_listProductGratis_data_main_categories[];
  is_active: boolean;
  is_visible: boolean;
  active_from: any | null;
  active_to: any | null;
  is_image: boolean;
  stock_qty: number | null;
}

export interface listProductGratis_listProductGratis_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface listProductGratis_listProductGratis {
  __typename: "ProductSimplePaginator";
  /**
   * A list of ProductSimple items.
   */
  data: listProductGratis_listProductGratis_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listProductGratis_listProductGratis_paginatorInfo;
}

export interface listProductGratis {
  listProductGratis: listProductGratis_listProductGratis | null;
}

export interface listProductGratisVariables {
  filters_base?: ProductFilterInput[] | null;
  filters?: ProductFilters | null;
  sortBy?: ProductSort | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listProductListsForMenu
// ====================================================

export interface listProductListsForMenu_listProductLists_data {
  __typename: "ProductList";
  id: number;
  name: string;
  slug: string;
}

export interface listProductListsForMenu_listProductLists_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface listProductListsForMenu_listProductLists {
  __typename: "ProductListPaginator";
  /**
   * A list of ProductList items.
   */
  data: listProductListsForMenu_listProductLists_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listProductListsForMenu_listProductLists_paginatorInfo;
}

export interface listProductListsForMenu {
  listProductLists: listProductListsForMenu_listProductLists | null;
}

export interface listProductListsForMenuVariables {
  filters?: ProductListFilters | null;
  sortBy?: ProductListSort | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listProductLists
// ====================================================

export interface listProductLists_listProductLists_data {
  __typename: "ProductList";
  id: number;
  name: string;
  mode: ProductListMode;
  active_from: any | null;
  active_to: any | null;
  is_visible: boolean;
  type: ProductListType;
}

export interface listProductLists_listProductLists_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface listProductLists_listProductLists {
  __typename: "ProductListPaginator";
  /**
   * A list of ProductList items.
   */
  data: listProductLists_listProductLists_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listProductLists_listProductLists_paginatorInfo;
}

export interface listProductLists {
  listProductLists: listProductLists_listProductLists | null;
}

export interface listProductListsVariables {
  filters?: ProductListFilters | null;
  sortBy?: ProductListSort | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listProductParamSelectItems
// ====================================================

export interface listProductParamSelectItems_productParamList_data {
  __typename: "ProductParamSimple";
  id: string;
  name: string | null;
}

export interface listProductParamSelectItems_productParamList_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface listProductParamSelectItems_productParamList {
  __typename: "ProductParamSimplePaginator";
  /**
   * A list of ProductParamSimple items.
   */
  data: listProductParamSelectItems_productParamList_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listProductParamSelectItems_productParamList_paginatorInfo;
}

export interface listProductParamSelectItems {
  productParamList: listProductParamSelectItems_productParamList | null;
}

export interface listProductParamSelectItemsVariables {
  filters?: ProductParamFilters | null;
  sortBy?: ProductParamSort | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listProductParamStructures
// ====================================================

export interface listProductParamStructures_productParamList_data {
  __typename: "ProductParamSimple";
  id: string;
  name: string | null;
  group_name: string | null;
}

export interface listProductParamStructures_productParamList {
  __typename: "ProductParamSimplePaginator";
  /**
   * A list of ProductParamSimple items.
   */
  data: listProductParamStructures_productParamList_data[];
}

export interface listProductParamStructures {
  productParamList: listProductParamStructures_productParamList | null;
}

export interface listProductParamStructuresVariables {
  first: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listProductsForMenu
// ====================================================

export interface listProductsForMenu_listProducts_data {
  __typename: "ProductSimple";
  id: number;
  name: string | null;
  slug: string | null;
}

export interface listProductsForMenu_listProducts_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface listProductsForMenu_listProducts {
  __typename: "ProductSimplePaginator";
  /**
   * A list of ProductSimple items.
   */
  data: listProductsForMenu_listProducts_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listProductsForMenu_listProducts_paginatorInfo;
}

export interface listProductsForMenu {
  listProducts: listProductsForMenu_listProducts | null;
}

export interface listProductsForMenuVariables {
  filters_base?: ProductFilterInput[] | null;
  filters?: ProductFilters | null;
  sortBy?: ProductSort | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listProducts
// ====================================================

export interface listProducts_listProducts_data_main_categories {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface listProducts_listProducts_data_categories {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface listProducts_listProducts_data {
  __typename: "ProductSimple";
  id: number;
  lfdnr: number | null;
  name: string | null;
  main_categories: listProducts_listProducts_data_main_categories[];
  categories: listProducts_listProducts_data_categories[];
  is_visible: boolean;
  active_from: any | null;
  is_description: boolean;
  is_image: boolean;
  ean: string[];
  price: number | null;
  stock_qty: number | null;
  slug: string | null;
}

export interface listProducts_listProducts_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface listProducts_listProducts {
  __typename: "ProductSimplePaginator";
  /**
   * A list of ProductSimple items.
   */
  data: listProducts_listProducts_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listProducts_listProducts_paginatorInfo;
}

export interface listProducts {
  listProducts: listProducts_listProducts | null;
}

export interface listProductsVariables {
  filters_base?: ProductFilterInput[] | null;
  filters?: ProductFilters | null;
  sortBy?: ProductSort | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listProductVariants
// ====================================================

export interface listProductVariants_listProductVariants_data_main_categories {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface listProductVariants_listProductVariants_data_categories {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface listProductVariants_listProductVariants_data {
  __typename: "ProductSimple";
  id: number;
  lfdnr: number | null;
  name: string | null;
  main_categories: listProductVariants_listProductVariants_data_main_categories[];
  categories: listProductVariants_listProductVariants_data_categories[];
  is_visible: boolean;
  created_at: any;
  active_from: any | null;
}

export interface listProductVariants_listProductVariants_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface listProductVariants_listProductVariants {
  __typename: "ProductSimplePaginator";
  /**
   * A list of ProductSimple items.
   */
  data: listProductVariants_listProductVariants_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listProductVariants_listProductVariants_paginatorInfo;
}

export interface listProductVariants {
  listProductVariants: listProductVariants_listProductVariants | null;
}

export interface listProductVariantsVariables {
  filters?: ProductFilters | null;
  sortBy?: ProductSort | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listRatings
// ====================================================

export interface listRatings_listOrderRatings_aggregations {
  __typename: "OrderRatingAggregation";
  avg_rating_purchase: number;
  avg_rating_courier: number;
  avg_rating_recommend: number;
}

export interface listRatings_listOrderRatings_data {
  __typename: "OrderRating";
  id: number;
  order_increment_id: number;
  rating_courier: number;
  note_courier: string | null;
  rating_purchase: number;
  note_purchase: string | null;
  rating_recommend: number;
  created_at: any;
  updated_at: any | null;
}

export interface listRatings_listOrderRatings_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface listRatings_listOrderRatings {
  __typename: "OrderRatingListResult";
  aggregations: listRatings_listOrderRatings_aggregations;
  data: listRatings_listOrderRatings_data[];
  paginatorInfo: listRatings_listOrderRatings_paginatorInfo;
}

export interface listRatings {
  listOrderRatings: listRatings_listOrderRatings;
}

export interface listRatingsVariables {
  filters?: OrderRatingFilters | null;
  sortBy?: OrderRatingSort | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listRoles
// ====================================================

export interface listRoles_listRoles_data {
  __typename: "Role";
  id: number;
  name: string;
  title: string;
}

export interface listRoles_listRoles_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface listRoles_listRoles {
  __typename: "RolePaginator";
  /**
   * A list of Role items.
   */
  data: listRoles_listRoles_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listRoles_listRoles_paginatorInfo;
}

export interface listRoles {
  listRoles: listRoles_listRoles | null;
}

export interface listRolesVariables {
  first: number;
  sortBy?: RoleSort | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listShipmentStatuses
// ====================================================

export interface listShipmentStatuses_shipmentStatuses {
  __typename: "ShipmentStatus";
  id: ShipmentStatusEnum;
  title: string;
}

export interface listShipmentStatuses {
  shipmentStatuses: listShipmentStatuses_shipmentStatuses[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listShipmentStatusSelectItems
// ====================================================

export interface listShipmentStatusSelectItems_shipmentStatuses {
  __typename: "ShipmentStatus";
  id: ShipmentStatusEnum;
  title: string;
}

export interface listShipmentStatusSelectItems {
  shipmentStatuses: listShipmentStatusSelectItems_shipmentStatuses[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listShippingMethodSelectItems
// ====================================================

export interface listShippingMethodSelectItems_listShippingMethods_data {
  __typename: "ShippingMethod";
  id: string;
  name: string | null;
}

export interface listShippingMethodSelectItems_listShippingMethods_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface listShippingMethodSelectItems_listShippingMethods {
  __typename: "ShippingMethodPaginator";
  /**
   * A list of ShippingMethod items.
   */
  data: listShippingMethodSelectItems_listShippingMethods_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listShippingMethodSelectItems_listShippingMethods_paginatorInfo;
}

export interface listShippingMethodSelectItems {
  listShippingMethods: listShippingMethodSelectItems_listShippingMethods | null;
}

export interface listShippingMethodSelectItemsVariables {
  filters?: ShippingMethodFilters | null;
  sortBy?: ShippingMethodSort | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listShippingMethods
// ====================================================

export interface listShippingMethods_listShippingMethods_data {
  __typename: "ShippingMethod";
  id: string;
  name: string | null;
}

export interface listShippingMethods_listShippingMethods_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface listShippingMethods_listShippingMethods {
  __typename: "ShippingMethodPaginator";
  /**
   * A list of ShippingMethod items.
   */
  data: listShippingMethods_listShippingMethods_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listShippingMethods_listShippingMethods_paginatorInfo;
}

export interface listShippingMethods {
  listShippingMethods: listShippingMethods_listShippingMethods | null;
}

export interface listShippingMethodsVariables {
  first: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listSites
// ====================================================

export interface listSites_sites {
  __typename: "Site";
  id: string;
  name: string;
  url: string;
}

export interface listSites {
  sites: listSites_sites[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listStoreOrders
// ====================================================

export interface listStoreOrders_listOrders_data_packages {
  __typename: "Package";
  id: string;
}

export interface listStoreOrders_listOrders_data_shipment_status {
  __typename: "ShipmentStatus";
  title: string;
}

export interface listStoreOrders_listOrders_data {
  __typename: "OrderSimple";
  id: number;
  increment_id: number;
  created_at: any;
  billing_fullname: string;
  shipping_fullname: string;
  delivery_day_expected: any | null;
  packages: listStoreOrders_listOrders_data_packages[];
  is_in_store: boolean;
  store_admin_is_took_over: boolean | null;
  delivered_at: any | null;
  shipment_status: listStoreOrders_listOrders_data_shipment_status | null;
}

export interface listStoreOrders_listOrders_aggregations {
  __typename: "OrderAggregation";
  sum_grand_total: number | null;
}

export interface listStoreOrders_listOrders_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface listStoreOrders_listOrders {
  __typename: "OrderListResult";
  data: listStoreOrders_listOrders_data[];
  aggregations: listStoreOrders_listOrders_aggregations;
  paginatorInfo: listStoreOrders_listOrders_paginatorInfo;
}

export interface listStoreOrders {
  listOrders: listStoreOrders_listOrders;
}

export interface listStoreOrdersVariables {
  filters?: OrderFilters | null;
  sortBy?: OrderSort | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listStoresForSelect
// ====================================================

export interface listStoresForSelect_listStores_data {
  __typename: "Store";
  id: number;
  address: string;
}

export interface listStoresForSelect_listStores_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface listStoresForSelect_listStores {
  __typename: "StorePaginator";
  /**
   * A list of Store items.
   */
  data: listStoresForSelect_listStores_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listStoresForSelect_listStores_paginatorInfo;
}

export interface listStoresForSelect {
  listStores: listStoresForSelect_listStores | null;
}

export interface listStoresForSelectVariables {
  first: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listSubscriptions
// ====================================================

export interface listSubscriptions_listOrderSubscriptions_data {
  __typename: "OrderSubscriptionSimple";
  id: number;
  user_id: number;
  user_fullname: string;
  user_email: string;
  payment_provider: string | null;
  created_at: any;
  updated_at: any;
  /**
   * Shipping method time window selected for
   */
  shipping_method_name: string | null;
  /**
   * Date canceled
   */
  deleted_at: any | null;
}

export interface listSubscriptions_listOrderSubscriptions_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface listSubscriptions_listOrderSubscriptions {
  __typename: "OrderSubscriptionSimplePaginator";
  /**
   * A list of OrderSubscriptionSimple items.
   */
  data: listSubscriptions_listOrderSubscriptions_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listSubscriptions_listOrderSubscriptions_paginatorInfo;
}

export interface listSubscriptions {
  listOrderSubscriptions: listSubscriptions_listOrderSubscriptions | null;
}

export interface listSubscriptionsVariables {
  filters?: OrderSubscriptionFilters | null;
  sortBy?: OrderSubscriptionSort | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listSystemSettings
// ====================================================

export interface listSystemSettings_listSettings_data_options {
  __typename: "SettingOption";
  title: string;
  value: string;
}

export interface listSystemSettings_listSettings_data_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface listSystemSettings_listSettings_data {
  __typename: "Setting";
  id: string;
  type: SettingType;
  value: string[];
  options: listSystemSettings_listSettings_data_options[];
  is_multi: boolean;
  description: string | null;
  updated_at: any;
  updated_by: listSystemSettings_listSettings_data_updated_by | null;
}

export interface listSystemSettings_listSettings_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface listSystemSettings_listSettings {
  __typename: "SettingPaginator";
  /**
   * A list of Setting items.
   */
  data: listSystemSettings_listSettings_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listSystemSettings_listSettings_paginatorInfo;
}

export interface listSystemSettings {
  listSettings: listSystemSettings_listSettings | null;
}

export interface listSystemSettingsVariables {
  filters?: SettingFilters | null;
  sortBy?: SettingSort | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listTimeWindowIntervalSelectItems
// ====================================================

export interface listTimeWindowIntervalSelectItems {
  orderTimeWindowIntervals: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listTodos
// ====================================================

export interface listTodos_listTodos_data {
  __typename: "TodoSimple";
  id: string;
  status: TodoStatus;
  subject: string;
  message: string;
  role_name: string;
  assigned_to_name: string | null;
  created_at: any;
  updated_at: any | null;
}

export interface listTodos_listTodos_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface listTodos_listTodos {
  __typename: "TodoSimplePaginator";
  /**
   * A list of TodoSimple items.
   */
  data: listTodos_listTodos_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listTodos_listTodos_paginatorInfo;
}

export interface listTodos {
  listTodos: listTodos_listTodos | null;
}

export interface listTodosVariables {
  filters?: TodoFilters | null;
  sortBy?: TodoSort | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listUserOrders
// ====================================================

export interface listUserOrders_listOrders_data {
  __typename: "OrderSimple";
  id: number;
  created_at: any;
  grand_total: number;
  shipping_method_name: string;
  payment_method_name: string;
  payment_status_title: string | null;
}

export interface listUserOrders_listOrders_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface listUserOrders_listOrders {
  __typename: "OrderListResult";
  data: listUserOrders_listOrders_data[];
  paginatorInfo: listUserOrders_listOrders_paginatorInfo;
}

export interface listUserOrders {
  listOrders: listUserOrders_listOrders;
}

export interface listUserOrdersVariables {
  filters?: OrderFilters | null;
  sortBy?: OrderSort | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listUsers
// ====================================================

export interface listUsers_listUsers_data {
  __typename: "UserSimple";
  id: number;
  name: string;
  email: string;
  card_number: string | null;
  phone_number_full: string;
  zip_code: string | null;
  created_at: any;
  is_active: boolean;
}

export interface listUsers_listUsers_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface listUsers_listUsers {
  __typename: "UserSimplePaginator";
  /**
   * A list of UserSimple items.
   */
  data: listUsers_listUsers_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listUsers_listUsers_paginatorInfo;
}

export interface listUsers {
  listUsers: listUsers_listUsers | null;
}

export interface listUsersVariables {
  filters?: UserFilters | null;
  sortBy?: UserOrder | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listWebsiteNotifications
// ====================================================

export interface listWebsiteNotifications_listWebsiteNotifications_data {
  __typename: "WebsiteNotification";
  id: number;
  name: string;
  active_from: any | null;
  active_to: any | null;
}

export interface listWebsiteNotifications_listWebsiteNotifications_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface listWebsiteNotifications_listWebsiteNotifications {
  __typename: "WebsiteNotificationPaginator";
  /**
   * A list of WebsiteNotification items.
   */
  data: listWebsiteNotifications_listWebsiteNotifications_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listWebsiteNotifications_listWebsiteNotifications_paginatorInfo;
}

export interface listWebsiteNotifications {
  listWebsiteNotifications: listWebsiteNotifications_listWebsiteNotifications | null;
}

export interface listWebsiteNotificationsVariables {
  filters?: WebsiteNotificationFilters | null;
  sortBy?: WebsiteNotificationSort | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: modelUpdateLog
// ====================================================

export interface modelUpdateLog_modelUpdateLog_data {
  __typename: "ModelUpdateLog";
  object_type: string;
  action: string;
  model_id: number;
  ean: string | null;
  increment_id: number | null;
  parent_type: string | null;
  changed: string;
  admin_id: number | null;
  admin_name: string | null;
  timestamp: any | null;
}

export interface modelUpdateLog_modelUpdateLog_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface modelUpdateLog_modelUpdateLog {
  __typename: "ModelUpdateLogListResult";
  data: modelUpdateLog_modelUpdateLog_data[];
  paginatorInfo: modelUpdateLog_modelUpdateLog_paginatorInfo;
}

export interface modelUpdateLog {
  modelUpdateLog: modelUpdateLog_modelUpdateLog;
}

export interface modelUpdateLogVariables {
  filters?: LogFilters | null;
  sortBy?: LogSort | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: persons
// ====================================================

export interface persons_persons_data {
  __typename: "Person";
  id: string;
  name: string;
  position: string;
  created_at: any;
  updated_at: any | null;
}

export interface persons_persons_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface persons_persons {
  __typename: "PersonPaginator";
  /**
   * A list of Person items.
   */
  data: persons_persons_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: persons_persons_paginatorInfo;
}

export interface persons {
  persons: persons_persons | null;
}

export interface personsVariables {
  filters?: PersonFilters | null;
  sortBy?: PersonSort | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: printOrder
// ====================================================

export interface printOrder {
  printOrder: string;
}

export interface printOrderVariables {
  increment_ids: number[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: productParamList
// ====================================================

export interface productParamList_productParamList_data {
  __typename: "ProductParamSimple";
  id: string;
  name: string | null;
  is_filter: boolean;
  created_at: any;
  updated_at: any | null;
}

export interface productParamList_productParamList_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface productParamList_productParamList {
  __typename: "ProductParamSimplePaginator";
  /**
   * A list of ProductParamSimple items.
   */
  data: productParamList_productParamList_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: productParamList_productParamList_paginatorInfo;
}

export interface productParamList {
  productParamList: productParamList_productParamList | null;
}

export interface productParamListVariables {
  filters?: ProductParamFilters | null;
  sortBy?: ProductParamSort | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: productParam
// ====================================================

export interface productParam_productParam_options {
  __typename: "ProductParamOption";
  id: number;
  label: string;
  value: string | null;
}

export interface productParam_productParam_badge_product_param {
  __typename: "ProductParam";
  id: string;
  name: string | null;
}

export interface productParam_productParam_badge_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface productParam_productParam_badge_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface productParam_productParam_badge {
  __typename: "Badge";
  id: number;
  type: BadgeType;
  name: string;
  info: string | null;
  image: string | null;
  image_title: string | null;
  product_param: productParam_productParam_badge_product_param | null;
  is_active: boolean;
  created_at: any | null;
  created_by: productParam_productParam_badge_created_by;
  updated_at: any | null;
  updated_by: productParam_productParam_badge_updated_by | null;
  active_from: any | null;
  active_to: any | null;
  is_visible: boolean;
}

export interface productParam_productParam_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface productParam_productParam {
  __typename: "ProductParam";
  id: string;
  name: string | null;
  evo_name: string | null;
  type: ProductParamType;
  is_active: boolean;
  is_filter: boolean;
  is_multi: boolean;
  type_widget: ProductParamWidgetType;
  created_at: any | null;
  updated_at: any | null;
  options: productParam_productParam_options[];
  badge: productParam_productParam_badge | null;
  updated_by: productParam_productParam_updated_by | null;
}

export interface productParam {
  productParam: productParam_productParam;
}

export interface productParamVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: redirections
// ====================================================

export interface redirections_redirections_data_from_site {
  __typename: "Site";
  id: string;
  name: string;
  url: string;
}

export interface redirections_redirections_data_to_site {
  __typename: "Site";
  id: string;
  name: string;
  url: string;
}

export interface redirections_redirections_data {
  __typename: "Redirection";
  id: string;
  from_site: redirections_redirections_data_from_site | null;
  from: string | null;
  to_site: redirections_redirections_data_to_site | null;
  to: string | null;
  created_at: any;
  updated_at: any | null;
  is_active: boolean;
}

export interface redirections_redirections_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface redirections_redirections {
  __typename: "RedirectionPaginator";
  /**
   * A list of Redirection items.
   */
  data: redirections_redirections_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: redirections_redirections_paginatorInfo;
}

export interface redirections {
  redirections: redirections_redirections | null;
}

export interface redirectionsVariables {
  filters?: RedirectionFilters | null;
  sortBy?: RedirectionOrder | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: storeAdminPrintOrders
// ====================================================

export interface storeAdminPrintOrders {
  storeAdminPrintOrders: string;
}

export interface storeAdminPrintOrdersVariables {
  ids: number[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: successStories
// ====================================================

export interface successStories_successStories_data_person {
  __typename: "Person";
  id: string;
  name: string;
}

export interface successStories_successStories_data_division {
  __typename: "Division";
  id: string;
  name: string;
}

export interface successStories_successStories_data {
  __typename: "SuccessStory";
  id: string;
  person: successStories_successStories_data_person;
  division: successStories_successStories_data_division;
  created_at: any;
  updated_at: any | null;
}

export interface successStories_successStories_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface successStories_successStories {
  __typename: "SuccessStoryPaginator";
  /**
   * A list of SuccessStory items.
   */
  data: successStories_successStories_data[];
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: successStories_successStories_paginatorInfo;
}

export interface successStories {
  successStories: successStories_successStories | null;
}

export interface successStoriesVariables {
  filters?: SuccessStoryFilters | null;
  sortBy?: SuccessStorySort | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Admin
// ====================================================

export interface Admin_roles_permissions {
  __typename: "Permission";
  id: number;
  name: PermissionType;
}

export interface Admin_roles {
  __typename: "Role";
  id: number;
  name: string;
  title: string;
  permissions: Admin_roles_permissions[];
}

export interface Admin_store {
  __typename: "Store";
  id: number;
  name: string;
}

export interface Admin {
  __typename: "Admin";
  id: number;
  name: string;
  email: string;
  is_active: boolean;
  created_at: any;
  updated_at: any;
  phone: string | null;
  last_login: any | null;
  roles: Admin_roles[];
  store: Admin_store | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AdminListItem
// ====================================================

export interface AdminListItem_roles {
  __typename: "Role";
  title: string;
}

export interface AdminListItem {
  __typename: "Admin";
  id: number;
  name: string;
  email: string;
  is_active: boolean;
  last_login: any | null;
  roles: AdminListItem_roles[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AdminNote
// ====================================================

export interface AdminNote_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface AdminNote {
  __typename: "AdminNote";
  note: string | null;
  created_by: AdminNote_created_by | null;
  created_at: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AdminSelectItem
// ====================================================

export interface AdminSelectItem {
  __typename: "Admin";
  id: number;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Badge
// ====================================================

export interface Badge_product_param {
  __typename: "ProductParam";
  id: string;
  name: string | null;
}

export interface Badge_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface Badge_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface Badge {
  __typename: "Badge";
  id: number;
  type: BadgeType;
  name: string;
  info: string | null;
  image: string | null;
  image_title: string | null;
  product_param: Badge_product_param | null;
  is_active: boolean;
  created_at: any | null;
  created_by: Badge_created_by;
  updated_at: any | null;
  updated_by: Badge_updated_by | null;
  active_from: any | null;
  active_to: any | null;
  is_visible: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BadgeListItem
// ====================================================

export interface BadgeListItem {
  __typename: "Badge";
  id: number;
  name: string;
  type: BadgeType;
  created_at: any | null;
  active_from: any | null;
  is_active: boolean;
  is_visible: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Banner
// ====================================================

export interface Banner_BannerNormal_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface Banner_BannerNormal_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface Banner_BannerNormal {
  __typename: "BannerNormal";
  id: number;
  name: string;
  urls: string[];
  title: string;
  slider_title: string;
  type: BannerType;
  image_align: BannerAlign;
  button_title: string | null;
  image: string;
  image_title: string | null;
  image_mobile: string | null;
  image_mobile_title: string | null;
  target_url: string | null;
  target_popup: boolean;
  is_active: boolean;
  active_from: any | null;
  active_to: any | null;
  created_at: any | null;
  created_by: Banner_BannerNormal_created_by;
  updated_at: any | null;
  updated_by: Banner_BannerNormal_updated_by | null;
}

export interface Banner_BannerProductList_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface Banner_BannerProductList_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface Banner_BannerProductList {
  __typename: "BannerProductList";
  id: number;
  name: string;
  urls: string[];
  title: string;
  slider_title: string;
  type: BannerType;
  image_align: BannerAlign;
  button_title: string | null;
  image: string;
  image_title: string | null;
  image_mobile: string | null;
  image_mobile_title: string | null;
  target_url: string | null;
  target_popup: boolean;
  is_active: boolean;
  active_from: any | null;
  active_to: any | null;
  created_at: any | null;
  created_by: Banner_BannerProductList_created_by;
  updated_at: any | null;
  updated_by: Banner_BannerProductList_updated_by | null;
}

export interface Banner_BannerProduct_product_categories_category_path {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface Banner_BannerProduct_product_categories {
  __typename: "ProductCategory";
  id: number;
  category_path: Banner_BannerProduct_product_categories_category_path[];
}

export interface Banner_BannerProduct_product_images {
  __typename: "ProductImage";
  id: number;
  source: ProductImageSource;
  position: number;
  url_list: string | null;
  is_active: boolean;
}

export interface Banner_BannerProduct_product_param_values {
  __typename: "ProductParamValue";
  product_param_id: string;
  is_multi: boolean;
  values: string[];
}

export interface Banner_BannerProduct_product_brand_bank_param_values {
  __typename: "ProductParamValue";
  product_param_id: string;
  is_multi: boolean;
  values: string[];
}

export interface Banner_BannerProduct_product_brand_bank {
  __typename: "ProductBrandBankData";
  id: number;
  param_values: Banner_BannerProduct_product_brand_bank_param_values[];
}

export interface Banner_BannerProduct_product_badges_featured {
  __typename: "Badge";
  name: string;
}

export interface Banner_BannerProduct_product_badges_product_params {
  __typename: "Badge";
  name: string;
}

export interface Banner_BannerProduct_product_badges_notifications {
  __typename: "Badge";
  name: string;
}

export interface Banner_BannerProduct_product_prices {
  __typename: "ProductPrice";
  id: number;
  price: number;
  price_unit: number | null;
  active_from: any | null;
  active_to: any | null;
}

export interface Banner_BannerProduct_product_stock {
  __typename: "ProductStock";
  id: number;
  qty: number;
  updated_at: any | null;
}

export interface Banner_BannerProduct_product_brand {
  __typename: "ProductBrand";
  id: number;
  name: string | null;
}

export interface Banner_BannerProduct_product_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface Banner_BannerProduct_product_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface Banner_BannerProduct_product_children_categories_category_path {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface Banner_BannerProduct_product_children_categories {
  __typename: "ProductCategory";
  id: number;
  category_path: Banner_BannerProduct_product_children_categories_category_path[];
}

export interface Banner_BannerProduct_product_children_images {
  __typename: "ProductImage";
  id: number;
  source: ProductImageSource;
  position: number;
  url_list: string | null;
  is_active: boolean;
}

export interface Banner_BannerProduct_product_children_param_values {
  __typename: "ProductParamValue";
  product_param_id: string;
  is_multi: boolean;
  values: string[];
}

export interface Banner_BannerProduct_product_children_brand_bank_param_values {
  __typename: "ProductParamValue";
  product_param_id: string;
  is_multi: boolean;
  values: string[];
}

export interface Banner_BannerProduct_product_children_brand_bank {
  __typename: "ProductBrandBankData";
  id: number;
  param_values: Banner_BannerProduct_product_children_brand_bank_param_values[];
}

export interface Banner_BannerProduct_product_children_badges_featured {
  __typename: "Badge";
  name: string;
}

export interface Banner_BannerProduct_product_children_badges_product_params {
  __typename: "Badge";
  name: string;
}

export interface Banner_BannerProduct_product_children_badges_notifications {
  __typename: "Badge";
  name: string;
}

export interface Banner_BannerProduct_product_children_prices {
  __typename: "ProductPrice";
  id: number;
  price: number;
  price_unit: number | null;
  active_from: any | null;
  active_to: any | null;
}

export interface Banner_BannerProduct_product_children_stock {
  __typename: "ProductStock";
  id: number;
  qty: number;
  updated_at: any | null;
}

export interface Banner_BannerProduct_product_children_brand {
  __typename: "ProductBrand";
  id: number;
  name: string | null;
}

export interface Banner_BannerProduct_product_children_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface Banner_BannerProduct_product_children_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface Banner_BannerProduct_product_children {
  __typename: "Product";
  id: number;
  name: string | null;
  lfdnr: number | null;
  ean: string[];
  cart_qty_max: number;
  categories: Banner_BannerProduct_product_children_categories[];
  slug: string | null;
  unit_base: string | null;
  images: Banner_BannerProduct_product_children_images[];
  is_active: boolean;
  is_force_active: boolean | null;
  active_from: any | null;
  active_to: any | null;
  param_values: Banner_BannerProduct_product_children_param_values[];
  brand_bank: Banner_BannerProduct_product_children_brand_bank | null;
  badges_featured: Banner_BannerProduct_product_children_badges_featured[];
  badges_product_params: Banner_BannerProduct_product_children_badges_product_params[];
  badges_notifications: Banner_BannerProduct_product_children_badges_notifications[];
  prices: Banner_BannerProduct_product_children_prices[];
  stock: Banner_BannerProduct_product_children_stock | null;
  brand: Banner_BannerProduct_product_children_brand | null;
  created_at: any;
  updated_at: any;
  created_by: Banner_BannerProduct_product_children_created_by | null;
  updated_by: Banner_BannerProduct_product_children_updated_by | null;
  bundle_promotion_id: number | null;
  image_path_360: string | null;
  image_position_360: number | null;
  brandbank_exclude: boolean | null;
}

export interface Banner_BannerProduct_product {
  __typename: "Product";
  id: number;
  name: string | null;
  lfdnr: number | null;
  ean: string[];
  cart_qty_max: number;
  categories: Banner_BannerProduct_product_categories[];
  slug: string | null;
  unit_base: string | null;
  images: Banner_BannerProduct_product_images[];
  is_active: boolean;
  is_force_active: boolean | null;
  active_from: any | null;
  active_to: any | null;
  param_values: Banner_BannerProduct_product_param_values[];
  brand_bank: Banner_BannerProduct_product_brand_bank | null;
  badges_featured: Banner_BannerProduct_product_badges_featured[];
  badges_product_params: Banner_BannerProduct_product_badges_product_params[];
  badges_notifications: Banner_BannerProduct_product_badges_notifications[];
  prices: Banner_BannerProduct_product_prices[];
  stock: Banner_BannerProduct_product_stock | null;
  brand: Banner_BannerProduct_product_brand | null;
  created_at: any;
  updated_at: any;
  created_by: Banner_BannerProduct_product_created_by | null;
  updated_by: Banner_BannerProduct_product_updated_by | null;
  bundle_promotion_id: number | null;
  image_path_360: string | null;
  image_position_360: number | null;
  brandbank_exclude: boolean | null;
  children: Banner_BannerProduct_product_children[] | null;
}

export interface Banner_BannerProduct_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface Banner_BannerProduct_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface Banner_BannerProduct {
  __typename: "BannerProduct";
  id: number;
  name: string;
  type: BannerType;
  urls: string[];
  product: Banner_BannerProduct_product;
  position: number;
  is_active: boolean;
  active_from: any | null;
  active_to: any | null;
  created_at: any | null;
  created_by: Banner_BannerProduct_created_by;
  updated_at: any | null;
  updated_by: Banner_BannerProduct_updated_by | null;
}

export type Banner = Banner_BannerNormal | Banner_BannerProductList | Banner_BannerProduct;

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BannerListItem
// ====================================================

export interface BannerListItem_BannerNormal {
  __typename: "BannerNormal";
  id: number;
  name: string;
  target_url: string | null;
  type: BannerType;
  active_from: any | null;
  active_to: any | null;
  is_active: boolean;
}

export interface BannerListItem_BannerProductList {
  __typename: "BannerProductList";
  id: number;
  name: string;
  target_url: string | null;
  type: BannerType;
  active_from: any | null;
  active_to: any | null;
  is_active: boolean;
}

export interface BannerListItem_BannerProduct {
  __typename: "BannerProduct";
  id: number;
  name: string;
  type: BannerType;
  active_from: any | null;
  active_to: any | null;
  is_active: boolean;
}

export type BannerListItem = BannerListItem_BannerNormal | BannerListItem_BannerProductList | BannerListItem_BannerProduct;

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BannerNormal
// ====================================================

export interface BannerNormal_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface BannerNormal_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface BannerNormal {
  __typename: "BannerNormal";
  id: number;
  name: string;
  urls: string[];
  title: string;
  slider_title: string;
  type: BannerType;
  image_align: BannerAlign;
  button_title: string | null;
  image: string;
  image_title: string | null;
  image_mobile: string | null;
  image_mobile_title: string | null;
  target_url: string | null;
  target_popup: boolean;
  is_active: boolean;
  active_from: any | null;
  active_to: any | null;
  created_at: any | null;
  created_by: BannerNormal_created_by;
  updated_at: any | null;
  updated_by: BannerNormal_updated_by | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BannerProduct
// ====================================================

export interface BannerProduct_product_categories_category_path {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface BannerProduct_product_categories {
  __typename: "ProductCategory";
  id: number;
  category_path: BannerProduct_product_categories_category_path[];
}

export interface BannerProduct_product_images {
  __typename: "ProductImage";
  id: number;
  source: ProductImageSource;
  position: number;
  url_list: string | null;
  is_active: boolean;
}

export interface BannerProduct_product_param_values {
  __typename: "ProductParamValue";
  product_param_id: string;
  is_multi: boolean;
  values: string[];
}

export interface BannerProduct_product_brand_bank_param_values {
  __typename: "ProductParamValue";
  product_param_id: string;
  is_multi: boolean;
  values: string[];
}

export interface BannerProduct_product_brand_bank {
  __typename: "ProductBrandBankData";
  id: number;
  param_values: BannerProduct_product_brand_bank_param_values[];
}

export interface BannerProduct_product_badges_featured {
  __typename: "Badge";
  name: string;
}

export interface BannerProduct_product_badges_product_params {
  __typename: "Badge";
  name: string;
}

export interface BannerProduct_product_badges_notifications {
  __typename: "Badge";
  name: string;
}

export interface BannerProduct_product_prices {
  __typename: "ProductPrice";
  id: number;
  price: number;
  price_unit: number | null;
  active_from: any | null;
  active_to: any | null;
}

export interface BannerProduct_product_stock {
  __typename: "ProductStock";
  id: number;
  qty: number;
  updated_at: any | null;
}

export interface BannerProduct_product_brand {
  __typename: "ProductBrand";
  id: number;
  name: string | null;
}

export interface BannerProduct_product_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface BannerProduct_product_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface BannerProduct_product_children_categories_category_path {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface BannerProduct_product_children_categories {
  __typename: "ProductCategory";
  id: number;
  category_path: BannerProduct_product_children_categories_category_path[];
}

export interface BannerProduct_product_children_images {
  __typename: "ProductImage";
  id: number;
  source: ProductImageSource;
  position: number;
  url_list: string | null;
  is_active: boolean;
}

export interface BannerProduct_product_children_param_values {
  __typename: "ProductParamValue";
  product_param_id: string;
  is_multi: boolean;
  values: string[];
}

export interface BannerProduct_product_children_brand_bank_param_values {
  __typename: "ProductParamValue";
  product_param_id: string;
  is_multi: boolean;
  values: string[];
}

export interface BannerProduct_product_children_brand_bank {
  __typename: "ProductBrandBankData";
  id: number;
  param_values: BannerProduct_product_children_brand_bank_param_values[];
}

export interface BannerProduct_product_children_badges_featured {
  __typename: "Badge";
  name: string;
}

export interface BannerProduct_product_children_badges_product_params {
  __typename: "Badge";
  name: string;
}

export interface BannerProduct_product_children_badges_notifications {
  __typename: "Badge";
  name: string;
}

export interface BannerProduct_product_children_prices {
  __typename: "ProductPrice";
  id: number;
  price: number;
  price_unit: number | null;
  active_from: any | null;
  active_to: any | null;
}

export interface BannerProduct_product_children_stock {
  __typename: "ProductStock";
  id: number;
  qty: number;
  updated_at: any | null;
}

export interface BannerProduct_product_children_brand {
  __typename: "ProductBrand";
  id: number;
  name: string | null;
}

export interface BannerProduct_product_children_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface BannerProduct_product_children_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface BannerProduct_product_children {
  __typename: "Product";
  id: number;
  name: string | null;
  lfdnr: number | null;
  ean: string[];
  cart_qty_max: number;
  categories: BannerProduct_product_children_categories[];
  slug: string | null;
  unit_base: string | null;
  images: BannerProduct_product_children_images[];
  is_active: boolean;
  is_force_active: boolean | null;
  active_from: any | null;
  active_to: any | null;
  param_values: BannerProduct_product_children_param_values[];
  brand_bank: BannerProduct_product_children_brand_bank | null;
  badges_featured: BannerProduct_product_children_badges_featured[];
  badges_product_params: BannerProduct_product_children_badges_product_params[];
  badges_notifications: BannerProduct_product_children_badges_notifications[];
  prices: BannerProduct_product_children_prices[];
  stock: BannerProduct_product_children_stock | null;
  brand: BannerProduct_product_children_brand | null;
  created_at: any;
  updated_at: any;
  created_by: BannerProduct_product_children_created_by | null;
  updated_by: BannerProduct_product_children_updated_by | null;
  bundle_promotion_id: number | null;
  image_path_360: string | null;
  image_position_360: number | null;
  brandbank_exclude: boolean | null;
}

export interface BannerProduct_product {
  __typename: "Product";
  id: number;
  name: string | null;
  lfdnr: number | null;
  ean: string[];
  cart_qty_max: number;
  categories: BannerProduct_product_categories[];
  slug: string | null;
  unit_base: string | null;
  images: BannerProduct_product_images[];
  is_active: boolean;
  is_force_active: boolean | null;
  active_from: any | null;
  active_to: any | null;
  param_values: BannerProduct_product_param_values[];
  brand_bank: BannerProduct_product_brand_bank | null;
  badges_featured: BannerProduct_product_badges_featured[];
  badges_product_params: BannerProduct_product_badges_product_params[];
  badges_notifications: BannerProduct_product_badges_notifications[];
  prices: BannerProduct_product_prices[];
  stock: BannerProduct_product_stock | null;
  brand: BannerProduct_product_brand | null;
  created_at: any;
  updated_at: any;
  created_by: BannerProduct_product_created_by | null;
  updated_by: BannerProduct_product_updated_by | null;
  bundle_promotion_id: number | null;
  image_path_360: string | null;
  image_position_360: number | null;
  brandbank_exclude: boolean | null;
  children: BannerProduct_product_children[] | null;
}

export interface BannerProduct_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface BannerProduct_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface BannerProduct {
  __typename: "BannerProduct";
  id: number;
  name: string;
  type: BannerType;
  urls: string[];
  product: BannerProduct_product;
  position: number;
  is_active: boolean;
  active_from: any | null;
  active_to: any | null;
  created_at: any | null;
  created_by: BannerProduct_created_by;
  updated_at: any | null;
  updated_by: BannerProduct_updated_by | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BannerProductList
// ====================================================

export interface BannerProductList_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface BannerProductList_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface BannerProductList {
  __typename: "BannerProductList";
  id: number;
  name: string;
  urls: string[];
  title: string;
  slider_title: string;
  type: BannerType;
  image_align: BannerAlign;
  button_title: string | null;
  image: string;
  image_title: string | null;
  image_mobile: string | null;
  image_mobile_title: string | null;
  target_url: string | null;
  target_popup: boolean;
  is_active: boolean;
  active_from: any | null;
  active_to: any | null;
  created_at: any | null;
  created_by: BannerProductList_created_by;
  updated_at: any | null;
  updated_by: BannerProductList_updated_by | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Benefit
// ====================================================

export interface Benefit {
  __typename: "Benefit";
  id: string;
  title: string;
  image: string;
  created_at: any;
  updated_at: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BenefitListItem
// ====================================================

export interface BenefitListItem {
  __typename: "Benefit";
  id: string;
  title: string;
  created_at: any;
  updated_at: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CalendarDay
// ====================================================

export interface CalendarDay {
  __typename: "CalendarDay";
  date: any;
  is_workday: boolean;
  is_delivery: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Campaign
// ====================================================

export interface Campaign_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface Campaign_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface Campaign {
  __typename: "Campaign";
  id: number;
  name: string;
  description: string;
  target_url: string | null;
  target_title: string | null;
  image_foreground_desktop: string | null;
  image_background_desktop: string | null;
  image_foreground_mobile: string | null;
  image_background_mobile: string | null;
  active_from: any | null;
  active_to: any | null;
  is_active: boolean;
  created_at: any;
  updated_at: any | null;
  created_by: Campaign_created_by;
  updated_by: Campaign_updated_by | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CampaignListItem
// ====================================================

export interface CampaignListItem {
  __typename: "Campaign";
  id: number;
  name: string;
  active_from: any | null;
  active_to: any | null;
  is_active: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Category
// ====================================================

export interface Category {
  __typename: "Category";
  id: string;
  lead: string | null;
  title: string;
  url: string;
  meta_title: string | null;
  meta_description: string | null;
  meta_keywords: string | null;
  created_at: any;
  updated_at: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CategoryListItem
// ====================================================

export interface CategoryListItem {
  __typename: "Category";
  id: string;
  title: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CategorySelectItem
// ====================================================

export interface CategorySelectItem {
  __typename: "Category";
  id: string;
  title: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ChildProductCategoryForMenuFields
// ====================================================

export interface ChildProductCategoryForMenuFields_children_children_children_children_children {
  __typename: "ProductCategorySimple";
  id: number;
  name: string;
  slug: string;
}

export interface ChildProductCategoryForMenuFields_children_children_children_children {
  __typename: "ProductCategorySimple";
  id: number;
  name: string;
  slug: string;
  children: ChildProductCategoryForMenuFields_children_children_children_children_children[] | null;
}

export interface ChildProductCategoryForMenuFields_children_children_children {
  __typename: "ProductCategorySimple";
  id: number;
  name: string;
  slug: string;
  children: ChildProductCategoryForMenuFields_children_children_children_children[] | null;
}

export interface ChildProductCategoryForMenuFields_children_children {
  __typename: "ProductCategorySimple";
  id: number;
  name: string;
  slug: string;
  children: ChildProductCategoryForMenuFields_children_children_children[] | null;
}

export interface ChildProductCategoryForMenuFields_children {
  __typename: "ProductCategorySimple";
  id: number;
  name: string;
  slug: string;
  children: ChildProductCategoryForMenuFields_children_children[] | null;
}

export interface ChildProductCategoryForMenuFields {
  __typename: "ProductCategorySimple";
  children: ChildProductCategoryForMenuFields_children[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ChildProductCategorySelectItemFields
// ====================================================

export interface ChildProductCategorySelectItemFields_children_children_children_children_children {
  __typename: "ProductCategorySimple";
  id: number;
  name: string;
}

export interface ChildProductCategorySelectItemFields_children_children_children_children {
  __typename: "ProductCategorySimple";
  id: number;
  name: string;
  children: ChildProductCategorySelectItemFields_children_children_children_children_children[] | null;
}

export interface ChildProductCategorySelectItemFields_children_children_children {
  __typename: "ProductCategorySimple";
  id: number;
  name: string;
  children: ChildProductCategorySelectItemFields_children_children_children_children[] | null;
}

export interface ChildProductCategorySelectItemFields_children_children {
  __typename: "ProductCategorySimple";
  id: number;
  name: string;
  children: ChildProductCategorySelectItemFields_children_children_children[] | null;
}

export interface ChildProductCategorySelectItemFields_children {
  __typename: "ProductCategorySimple";
  id: number;
  name: string;
  children: ChildProductCategorySelectItemFields_children_children[] | null;
}

export interface ChildProductCategorySelectItemFields {
  __typename: "ProductCategorySimple";
  children: ChildProductCategorySelectItemFields_children[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ClpePromotionSelectItem
// ====================================================

export interface ClpePromotionSelectItem {
  __typename: "ClpePromotion";
  id: number;
  title: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DeliveryInformation
// ====================================================

export interface DeliveryInformation {
  __typename: "DeliveryInformation";
  info: string;
  active_from: any | null;
  active_to: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Department
// ====================================================

export interface Department_divisions_department {
  __typename: "Department";
  id: string;
  name: string;
}

export interface Department_divisions {
  __typename: "Division";
  id: string;
  name: string;
  department: Department_divisions_department | null;
  created_at: any;
  updated_at: any | null;
}

export interface Department_benefits {
  __typename: "Benefit";
  id: string;
  title: string;
  created_at: any;
  updated_at: any | null;
}

export interface Department {
  __typename: "Department";
  id: string;
  name: string;
  is_grouped: boolean;
  is_filter_enabled: boolean | null;
  is_map_enabled: boolean | null;
  order: number;
  divisions: Department_divisions[];
  benefits: Department_benefits[];
  created_at: any;
  updated_at: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DepartmentListItem
// ====================================================

export interface DepartmentListItem {
  __typename: "Department";
  id: string;
  name: string;
  order: number;
  created_at: any;
  updated_at: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Division
// ====================================================

export interface Division_leaders {
  __typename: "Person";
  id: string;
  name: string;
  position: string;
  created_at: any;
  updated_at: any | null;
}

export interface Division_department {
  __typename: "Department";
  id: string;
  name: string;
  order: number;
  created_at: any;
  updated_at: any | null;
}

export interface Division_benefits {
  __typename: "Benefit";
  id: string;
  title: string;
  created_at: any;
  updated_at: any | null;
}

export interface Division {
  __typename: "Division";
  id: string;
  name: string;
  description: string | null;
  quote: string | null;
  leaders: Division_leaders[];
  department: Division_department | null;
  benefits: Division_benefits[];
  created_at: any;
  updated_at: any | null;
  is_virtual: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DivisionListItem
// ====================================================

export interface DivisionListItem_department {
  __typename: "Department";
  id: string;
  name: string;
}

export interface DivisionListItem {
  __typename: "Division";
  id: string;
  name: string;
  department: DivisionListItem_department | null;
  created_at: any;
  updated_at: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DocumentListItem
// ====================================================

export interface DocumentListItem {
  __typename: "Document";
  id: string;
  file_name: string;
  url: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DocumentModalListItem
// ====================================================

export interface DocumentModalListItem {
  __typename: "Document";
  id: string;
  file_name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Fulfillment
// ====================================================

export interface Fulfillment_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface Fulfillment_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface Fulfillment {
  __typename: "Fulfillment";
  id: number;
  active_from: any;
  active_to: any;
  /**
   * Flag allocation limit between two daily reset timestamp
   */
  limit: number;
  /**
   * Flags allocated in the daily interval since the last reset timestamp
   */
  count: number;
  shipping_methods: string[];
  timewindow_allowed: boolean;
  vip_levels: RossmannPlusVipLevel[];
  created_at: any;
  updated_at: any | null;
  created_by: Fulfillment_created_by | null;
  updated_by: Fulfillment_updated_by | null;
  is_active: boolean;
  /**
   * Send email notification after every X flag assigment
   */
  notification_threshold: number | null;
  quantity_max: number | null;
  package_type_max: PackageType | null;
  product_id_force: number[];
  product_id_exclude: number[];
  type: FulfillmentTypeSelectable;
  weight: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FulfillmentListItem
// ====================================================

export interface FulfillmentListItem_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface FulfillmentListItem_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface FulfillmentListItem {
  __typename: "Fulfillment";
  id: number;
  active_from: any;
  active_to: any;
  date_reset_next: any;
  /**
   * Flag allocation limit between two daily reset timestamp
   */
  limit: number;
  /**
   * Flags allocated in the daily interval since the last reset timestamp
   */
  count: number;
  shipping_methods: string[];
  timewindow_allowed: boolean;
  vip_levels: RossmannPlusVipLevel[];
  created_at: any;
  updated_at: any | null;
  created_by: FulfillmentListItem_created_by | null;
  updated_by: FulfillmentListItem_updated_by | null;
  is_active: boolean;
  weight: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Gallery
// ====================================================

export interface Gallery_images {
  __typename: "GalleryImage";
  url: string;
  caption: string | null;
  credit: string | null;
}

export interface Gallery {
  __typename: "Gallery";
  id: string;
  title: string;
  handle: string | null;
  images: Gallery_images[];
  image_count: number;
  updated_at: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GalleryImage
// ====================================================

export interface GalleryImage {
  __typename: "GalleryImage";
  url: string;
  caption: string | null;
  credit: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GalleryListItem
// ====================================================

export interface GalleryListItem {
  __typename: "Gallery";
  id: string;
  title: string;
  handle: string | null;
  image_count: number;
  updated_at: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GiftCardDesign
// ====================================================

export interface GiftCardDesign {
  __typename: "GiftCardDesign";
  id: number;
  title: string;
  slug: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GiftCard
// ====================================================

export interface GiftCard_log_sent_to {
  __typename: "GiftCardLog";
  date: any;
  email: string;
}

export interface GiftCard {
  __typename: "GiftCard";
  id: number;
  value: number;
  barcode: string | null;
  expire_at: any | null;
  mail_to: string;
  sent_at: any | null;
  log_sent_to: GiftCard_log_sent_to[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GiftCardLog
// ====================================================

export interface GiftCardLog {
  __typename: "GiftCardLog";
  date: any;
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GiftCardOrder
// ====================================================

export interface GiftCardOrder_user {
  __typename: "User";
  email: string;
}

export interface GiftCardOrder_status {
  __typename: "OrderStatus";
  id: OrderStatusEnum;
  title: string;
}

export interface GiftCardOrder_billing_address {
  __typename: "UserAddress";
  firstname: string | null;
  lastname: string | null;
  zip_code: string | null;
  city: string | null;
  address_line1: string | null;
  address_line2: string | null;
  company_name: string | null;
  company_number: string | null;
}

export interface GiftCardOrder_payment_method {
  __typename: "PaymentMethod";
  id: string;
  name: string | null;
}

export interface GiftCardOrder_payment_status {
  __typename: "PaymentStatus";
  id: PaymentStatusEnum;
  title: string;
}

export interface GiftCardOrder_payment_transaction_latest {
  __typename: "PaymentTransaction";
  id: number;
  anum: string | null;
}

export interface GiftCardOrder_payment {
  __typename: "OrderPayment";
  status: GiftCardOrder_payment_status;
  transaction_latest: GiftCardOrder_payment_transaction_latest | null;
  amount_paid: number;
  amount_refunded: number;
}

export interface GiftCardOrder_admin_notes_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface GiftCardOrder_admin_notes {
  __typename: "AdminNote";
  note: string | null;
  created_by: GiftCardOrder_admin_notes_created_by | null;
  created_at: any | null;
}

export interface GiftCardOrder_items {
  __typename: "GiftCardOrderItem";
  id: number;
  quantity: number;
  value: number;
  design: number;
  sub_total: number;
  name_from: string;
  name_to: string | null;
  mail_to: string[] | null;
}

export interface GiftCardOrder_invoice_status {
  __typename: "InvoiceStatus";
  title: string;
}

export interface GiftCardOrder_invoices {
  __typename: "OrderInvoice";
  invoice_number: string | null;
  url: string | null;
}

export interface GiftCardOrder_gift_cards_log_sent_to {
  __typename: "GiftCardLog";
  date: any;
  email: string;
}

export interface GiftCardOrder_gift_cards {
  __typename: "GiftCard";
  id: number;
  value: number;
  barcode: string | null;
  expire_at: any | null;
  mail_to: string;
  sent_at: any | null;
  log_sent_to: GiftCardOrder_gift_cards_log_sent_to[];
}

export interface GiftCardOrder_type {
  __typename: "GiftCardOrderTypeWithTitle";
  title: string;
}

export interface GiftCardOrder {
  __typename: "GiftCardOrder";
  id: number;
  code: string;
  created_at: any;
  contact_fullname: string;
  email: string | null;
  phone_number: string | null;
  user: GiftCardOrder_user | null;
  status: GiftCardOrder_status;
  billing_address: GiftCardOrder_billing_address;
  payment_method: GiftCardOrder_payment_method;
  grand_total: number;
  payment: GiftCardOrder_payment | null;
  admin_notes: GiftCardOrder_admin_notes[];
  items: GiftCardOrder_items[];
  invoice_status: GiftCardOrder_invoice_status | null;
  invoices: GiftCardOrder_invoices[];
  can_be_deleted: boolean;
  user_agent_type: string;
  gift_cards: (GiftCardOrder_gift_cards | null)[];
  type: GiftCardOrder_type;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GiftCardOrderItem
// ====================================================

export interface GiftCardOrderItem {
  __typename: "GiftCardOrderItem";
  id: number;
  quantity: number;
  value: number;
  design: number;
  sub_total: number;
  name_from: string;
  name_to: string | null;
  mail_to: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GiftCardOrderSimple
// ====================================================

export interface GiftCardOrderSimple_status {
  __typename: "OrderStatus";
  id: OrderStatusEnum;
  title: string;
}

export interface GiftCardOrderSimple_type {
  __typename: "GiftCardOrderTypeWithTitle";
  id: string;
  title: string;
}

export interface GiftCardOrderSimple_created_by {
  __typename: "Admin";
  name: string;
}

export interface GiftCardOrderSimple {
  __typename: "GiftCardOrderSimple";
  id: number;
  user_fullname: string | null;
  user_email: string | null;
  payment_method_name: string;
  payment_status_title: string | null;
  grand_total: number;
  created_at: any;
  updated_at: any;
  billing_fullname: string;
  status: GiftCardOrderSimple_status;
  user_agent_type: string;
  type: GiftCardOrderSimple_type;
  created_by: GiftCardOrderSimple_created_by | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GiftCardOrderTypeWithTitle
// ====================================================

export interface GiftCardOrderTypeWithTitle {
  __typename: "GiftCardOrderTypeWithTitle";
  id: string;
  title: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GiftCardPayment
// ====================================================

export interface GiftCardPayment {
  __typename: "GiftCardPayment";
  barcode: string;
  amount: number;
  voided_amount: number;
  paid_at: any | null;
  voided_at: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MailTemplate
// ====================================================

export interface MailTemplate {
  __typename: "MailTemplate";
  id: number;
  subject: string;
  content: string;
  description: string | null;
  is_active: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MailTemplateListItem
// ====================================================

export interface MailTemplateListItem {
  __typename: "MailTemplate";
  id: number;
  subject: string;
  is_active: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Maintenance
// ====================================================

export interface Maintenance {
  __typename: "Maintenance";
  id: string;
  active_from: any;
  active_to: any;
  scopes: MaintenanceScope[] | null;
  notification: string | null;
  maintenance: boolean;
  is_active: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MaintenanceListItem
// ====================================================

export interface MaintenanceListItem {
  __typename: "Maintenance";
  id: string;
  active_from: any;
  active_to: any;
  scopes: MaintenanceScope[] | null;
  notification: string | null;
  maintenance: boolean;
  is_active: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Menu
// ====================================================

export interface Menu_items_type {
  __typename: "MenuItemType";
  id: string;
  name: string;
}

export interface Menu_items_page_overlay {
  __typename: "Page";
  id: number;
  name: string;
}

export interface Menu_items_items_type {
  __typename: "MenuItemType";
  id: string;
  name: string;
}

export interface Menu_items_items_page_overlay {
  __typename: "Page";
  id: number;
  name: string;
}

export interface Menu_items_items_items_type {
  __typename: "MenuItemType";
  id: string;
  name: string;
}

export interface Menu_items_items_items_page_overlay {
  __typename: "Page";
  id: number;
  name: string;
}

export interface Menu_items_items_items_items_type {
  __typename: "MenuItemType";
  id: string;
  name: string;
}

export interface Menu_items_items_items_items_page_overlay {
  __typename: "Page";
  id: number;
  name: string;
}

export interface Menu_items_items_items_items_items_type {
  __typename: "MenuItemType";
  id: string;
  name: string;
}

export interface Menu_items_items_items_items_items_page_overlay {
  __typename: "Page";
  id: number;
  name: string;
}

export interface Menu_items_items_items_items_items_items_type {
  __typename: "MenuItemType";
  id: string;
  name: string;
}

export interface Menu_items_items_items_items_items_items_page_overlay {
  __typename: "Page";
  id: number;
  name: string;
}

export interface Menu_items_items_items_items_items_items {
  __typename: "MenuItem";
  type: Menu_items_items_items_items_items_items_type;
  model_id: number | null;
  title: string | null;
  url: string | null;
  popup: boolean;
  is_active: boolean;
  page_overlay: Menu_items_items_items_items_items_items_page_overlay | null;
}

export interface Menu_items_items_items_items_items {
  __typename: "MenuItem";
  type: Menu_items_items_items_items_items_type;
  model_id: number | null;
  title: string | null;
  url: string | null;
  popup: boolean;
  is_active: boolean;
  page_overlay: Menu_items_items_items_items_items_page_overlay | null;
  items: Menu_items_items_items_items_items_items[] | null;
}

export interface Menu_items_items_items_items {
  __typename: "MenuItem";
  type: Menu_items_items_items_items_type;
  model_id: number | null;
  title: string | null;
  url: string | null;
  popup: boolean;
  is_active: boolean;
  page_overlay: Menu_items_items_items_items_page_overlay | null;
  items: Menu_items_items_items_items_items[] | null;
}

export interface Menu_items_items_items {
  __typename: "MenuItem";
  type: Menu_items_items_items_type;
  model_id: number | null;
  title: string | null;
  url: string | null;
  popup: boolean;
  is_active: boolean;
  page_overlay: Menu_items_items_items_page_overlay | null;
  items: Menu_items_items_items_items[] | null;
}

export interface Menu_items_items {
  __typename: "MenuItem";
  type: Menu_items_items_type;
  model_id: number | null;
  title: string | null;
  url: string | null;
  popup: boolean;
  is_active: boolean;
  page_overlay: Menu_items_items_page_overlay | null;
  items: Menu_items_items_items[] | null;
}

export interface Menu_items {
  __typename: "MenuItem";
  type: Menu_items_type;
  model_id: number | null;
  title: string | null;
  url: string | null;
  popup: boolean;
  is_active: boolean;
  page_overlay: Menu_items_page_overlay | null;
  items: Menu_items_items[] | null;
}

export interface Menu_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface Menu_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface Menu {
  __typename: "Menu";
  id: number;
  slug: string;
  name: string;
  created_at: any;
  updated_at: any | null;
  is_active: boolean;
  active_from: any | null;
  active_to: any | null;
  items: Menu_items[];
  created_by: Menu_created_by;
  updated_by: Menu_updated_by | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MenuItemFields
// ====================================================

export interface MenuItemFields_type {
  __typename: "MenuItemType";
  id: string;
  name: string;
}

export interface MenuItemFields_page_overlay {
  __typename: "Page";
  id: number;
  name: string;
}

export interface MenuItemFields {
  __typename: "MenuItem";
  type: MenuItemFields_type;
  model_id: number | null;
  title: string | null;
  url: string | null;
  popup: boolean;
  is_active: boolean;
  page_overlay: MenuItemFields_page_overlay | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MenuItem
// ====================================================

export interface MenuItem_type {
  __typename: "MenuItemType";
  id: string;
  name: string;
}

export interface MenuItem_page_overlay {
  __typename: "Page";
  id: number;
  name: string;
}

export interface MenuItem_items_type {
  __typename: "MenuItemType";
  id: string;
  name: string;
}

export interface MenuItem_items_page_overlay {
  __typename: "Page";
  id: number;
  name: string;
}

export interface MenuItem_items_items_type {
  __typename: "MenuItemType";
  id: string;
  name: string;
}

export interface MenuItem_items_items_page_overlay {
  __typename: "Page";
  id: number;
  name: string;
}

export interface MenuItem_items_items_items_type {
  __typename: "MenuItemType";
  id: string;
  name: string;
}

export interface MenuItem_items_items_items_page_overlay {
  __typename: "Page";
  id: number;
  name: string;
}

export interface MenuItem_items_items_items_items_type {
  __typename: "MenuItemType";
  id: string;
  name: string;
}

export interface MenuItem_items_items_items_items_page_overlay {
  __typename: "Page";
  id: number;
  name: string;
}

export interface MenuItem_items_items_items_items_items_type {
  __typename: "MenuItemType";
  id: string;
  name: string;
}

export interface MenuItem_items_items_items_items_items_page_overlay {
  __typename: "Page";
  id: number;
  name: string;
}

export interface MenuItem_items_items_items_items_items {
  __typename: "MenuItem";
  type: MenuItem_items_items_items_items_items_type;
  model_id: number | null;
  title: string | null;
  url: string | null;
  popup: boolean;
  is_active: boolean;
  page_overlay: MenuItem_items_items_items_items_items_page_overlay | null;
}

export interface MenuItem_items_items_items_items {
  __typename: "MenuItem";
  type: MenuItem_items_items_items_items_type;
  model_id: number | null;
  title: string | null;
  url: string | null;
  popup: boolean;
  is_active: boolean;
  page_overlay: MenuItem_items_items_items_items_page_overlay | null;
  items: MenuItem_items_items_items_items_items[] | null;
}

export interface MenuItem_items_items_items {
  __typename: "MenuItem";
  type: MenuItem_items_items_items_type;
  model_id: number | null;
  title: string | null;
  url: string | null;
  popup: boolean;
  is_active: boolean;
  page_overlay: MenuItem_items_items_items_page_overlay | null;
  items: MenuItem_items_items_items_items[] | null;
}

export interface MenuItem_items_items {
  __typename: "MenuItem";
  type: MenuItem_items_items_type;
  model_id: number | null;
  title: string | null;
  url: string | null;
  popup: boolean;
  is_active: boolean;
  page_overlay: MenuItem_items_items_page_overlay | null;
  items: MenuItem_items_items_items[] | null;
}

export interface MenuItem_items {
  __typename: "MenuItem";
  type: MenuItem_items_type;
  model_id: number | null;
  title: string | null;
  url: string | null;
  popup: boolean;
  is_active: boolean;
  page_overlay: MenuItem_items_page_overlay | null;
  items: MenuItem_items_items[] | null;
}

export interface MenuItem {
  __typename: "MenuItem";
  type: MenuItem_type;
  model_id: number | null;
  title: string | null;
  url: string | null;
  popup: boolean;
  is_active: boolean;
  page_overlay: MenuItem_page_overlay | null;
  items: MenuItem_items[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MenuListItem
// ====================================================

export interface MenuListItem {
  __typename: "Menu";
  id: number;
  slug: string;
  name: string;
  created_at: any;
  updated_at: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ModelUpdateLog
// ====================================================

export interface ModelUpdateLog {
  __typename: "ModelUpdateLog";
  object_type: string;
  action: string;
  model_id: number;
  ean: string | null;
  increment_id: number | null;
  parent_type: string | null;
  changed: string;
  admin_id: number | null;
  admin_name: string | null;
  timestamp: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Order
// ====================================================

export interface Order_user {
  __typename: "User";
  email: string;
}

export interface Order_status {
  __typename: "OrderStatus";
  id: OrderStatusEnum;
  title: string;
}

export interface Order_shipping_method_cost_time_windows {
  __typename: "TimeWindowCost";
  interval_hours: number;
}

export interface Order_shipping_method {
  __typename: "ShippingMethod";
  name: string | null;
  delivery_days: number | null;
  cost_time_windows: Order_shipping_method_cost_time_windows[] | null;
}

export interface Order_delivery_time_window {
  __typename: "DeliveryTimeWindow";
  date: any;
  weekday: string;
  interval: string;
  interval_length: number;
}

export interface Order_shipping_address {
  __typename: "UserAddress";
  firstname: string | null;
  lastname: string | null;
  zip_code: string | null;
  city: string | null;
  address_line1: string | null;
  address_line2: string | null;
  company_name: string | null;
  company_number: string | null;
}

export interface Order_billing_address {
  __typename: "UserAddress";
  firstname: string | null;
  lastname: string | null;
  zip_code: string | null;
  city: string | null;
  address_line1: string | null;
  address_line2: string | null;
  company_name: string | null;
  company_number: string | null;
}

export interface Order_payment_method {
  __typename: "PaymentMethod";
  id: string;
  name: string | null;
}

export interface Order_payment_status {
  __typename: "PaymentStatus";
  id: PaymentStatusEnum;
  title: string;
}

export interface Order_payment_transaction_latest {
  __typename: "PaymentTransaction";
  id: number;
  anum: string | null;
}

export interface Order_payment_transactions_payment_method {
  __typename: "PaymentMethod";
  name: string | null;
}

export interface Order_payment_transactions {
  __typename: "PaymentTransaction";
  id: number;
  pmgw_transaction_id: string;
  provider_transaction_id: string | null;
  anum: string | null;
  payment_method: Order_payment_transactions_payment_method;
  status_payment: string | null;
  amount: number;
  amount_refund: number | null;
  success: boolean;
}

export interface Order_payment {
  __typename: "OrderPayment";
  status: Order_payment_status;
  transaction_latest: Order_payment_transaction_latest | null;
  amount_paid: number;
  amount_refunded: number;
  transactions: Order_payment_transactions[];
}

export interface Order_admin_notes_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface Order_admin_notes {
  __typename: "AdminNote";
  note: string | null;
  created_by: Order_admin_notes_created_by | null;
  created_at: any | null;
}

export interface Order_items_product {
  __typename: "Product";
  name: string | null;
  lfdnr: number | null;
  ean: string[];
}

export interface Order_items_discounts {
  __typename: "OrderItemDiscount";
  promotion_name: string;
  amount: number;
}

export interface Order_items {
  __typename: "OrderItem";
  id: number;
  product: Order_items_product;
  quantity: number;
  unit_price: number;
  sub_total: number;
  discount_total: number;
  discounts: Order_items_discounts[];
  type: OrderItemType;
  quantity_missing: number;
  quantity_delivered: number;
}

export interface Order_shipment {
  __typename: "RossmannShipment";
  status: ShipmentStatusEnum | null;
  delivered_at: any | null;
  tracking_url: string | null;
  tracking_numbers: string[];
  sent_at: any | null;
  delivery_in_progress_at: any | null;
  delivery_day_expected: any | null;
  can_retry: boolean;
}

export interface Order_invoice_status {
  __typename: "InvoiceStatus";
  title: string;
}

export interface Order_invoices {
  __typename: "OrderInvoice";
  type: InvoiceTypeEnum;
  invoice_number: string | null;
  url: string | null;
  url_remote: string | null;
  created_at: any;
  invoiced_at: any | null;
}

export interface Order_pickup_point {
  __typename: "Store" | "MplPickupPoint" | "GlsPickupPoint" | "FoxpostPickupPoint" | "EasyboxPickupPoint";
  id: number;
  address: string;
}

export interface Order_gift_cards {
  __typename: "GiftCardPayment";
  barcode: string;
  amount: number;
  voided_amount: number;
  paid_at: any | null;
  voided_at: any | null;
}

export interface Order {
  __typename: "Order";
  id: number;
  increment_id: number | null;
  created_at: any;
  contact_fullname: string;
  email: string | null;
  phone_number: string | null;
  user: Order_user | null;
  status: Order_status;
  shipping_method: Order_shipping_method;
  delivery_day_expected: any | null;
  delivery_time_window: Order_delivery_time_window | null;
  shipping_address: Order_shipping_address | null;
  billing_address: Order_billing_address;
  payment_method: Order_payment_method;
  grand_total: number;
  tax_total: number;
  payment: Order_payment | null;
  admin_notes: Order_admin_notes[];
  items: Order_items[];
  shipment: Order_shipment | null;
  invoice_status: Order_invoice_status | null;
  shipping_note: string | null;
  grand_total_netto: number;
  invoices: Order_invoices[];
  pickup_point: Order_pickup_point | null;
  can_be_modified: boolean;
  can_be_deleted: boolean;
  evo_sent_at: any | null;
  user_agent_type: string;
  app_version: string | null;
  no_package: boolean | null;
  gift_cards: Order_gift_cards[];
  gift_card_payment: number;
  can_storno: boolean;
  can_refund: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrderInvoice
// ====================================================

export interface OrderInvoice {
  __typename: "OrderInvoice";
  type: InvoiceTypeEnum;
  invoice_number: string | null;
  url: string | null;
  url_remote: string | null;
  created_at: any;
  invoiced_at: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrderItem
// ====================================================

export interface OrderItem_product {
  __typename: "Product";
  name: string | null;
  lfdnr: number | null;
  ean: string[];
}

export interface OrderItem_discounts {
  __typename: "OrderItemDiscount";
  promotion_name: string;
  amount: number;
}

export interface OrderItem {
  __typename: "OrderItem";
  id: number;
  product: OrderItem_product;
  quantity: number;
  unit_price: number;
  sub_total: number;
  discount_total: number;
  discounts: OrderItem_discounts[];
  type: OrderItemType;
  quantity_missing: number;
  quantity_delivered: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrderListItem
// ====================================================

export interface OrderListItem_pickup_point {
  __typename: "PickupPointSimple";
  id: number;
}

export interface OrderListItem_shipment_status {
  __typename: "ShipmentStatus";
  title: string;
}

export interface OrderListItem_status {
  __typename: "OrderStatus";
  title: string;
}

export interface OrderListItem {
  __typename: "OrderSimple";
  id: number;
  increment_id: number;
  created_at: any;
  time_window_date: any | null;
  delivery_day_expected: any | null;
  user_fullname: string | null;
  user_email: string | null;
  grand_total: number;
  shipping_method_name: string;
  payment_method_name: string;
  payment_status_title: string | null;
  time_window_interval: string | null;
  time_window_interval_length: number | null;
  pickup_point: OrderListItem_pickup_point | null;
  shipment_status: OrderListItem_shipment_status | null;
  status: OrderListItem_status;
  user_agent_type: string;
  fulfillment: FulfillmentTypeEnum;
  card_number: string | null;
  order_subscription_id: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PageBlockBanner
// ====================================================

export interface PageBlockBanner_banners_BannerNormal {
  __typename: "BannerNormal";
  id: number;
  name: string;
}

export interface PageBlockBanner_banners_BannerProductList {
  __typename: "BannerProductList";
  id: number;
  name: string;
}

export interface PageBlockBanner_banners_BannerProduct {
  __typename: "BannerProduct";
  id: number;
  name: string;
}

export type PageBlockBanner_banners = PageBlockBanner_banners_BannerNormal | PageBlockBanner_banners_BannerProductList | PageBlockBanner_banners_BannerProduct;

export interface PageBlockBanner {
  __typename: "PageBlockBanner";
  id: number;
  type: PageBlockType;
  layout: PageBlockBannerLayout;
  title: string | null;
  banners: PageBlockBanner_banners[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PageBlockBrandList
// ====================================================

export interface PageBlockBrandList {
  __typename: "PageBlockBrandList";
  id: number;
  type: PageBlockType;
  title: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PageBlockHomebox
// ====================================================

export interface PageBlockHomebox {
  __typename: "PageBlockHomebox";
  id: number;
  type: PageBlockType;
  title: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PageBlockHtml
// ====================================================

export interface PageBlockHtml {
  __typename: "PageBlockHtml";
  id: number;
  type: PageBlockType;
  title: string | null;
  content: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PageBlockNewsletter
// ====================================================

export interface PageBlockNewsletter {
  __typename: "PageBlockNewsletter";
  id: number;
  type: PageBlockType;
  title: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PageBlockProductList
// ====================================================

export interface PageBlockProductList_product_list {
  __typename: "ProductList";
  id: number;
  name: string;
}

export interface PageBlockProductList_product_sort_by {
  __typename: "ProductSortFrontend";
  field: ProductSortFieldCategory;
  direction: OrderDirection;
}

export interface PageBlockProductList {
  __typename: "PageBlockProductList";
  id: number;
  type: PageBlockType;
  title: string | null;
  product_list: PageBlockProductList_product_list | null;
  is_scrollable: boolean;
  is_filterable: boolean;
  product_limit: number | null;
  product_sort_by: PageBlockProductList_product_sort_by | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PageBlockRecommendedProducts
// ====================================================

export interface PageBlockRecommendedProducts {
  __typename: "PageBlockRecommendedProducts";
  id: number;
  type: PageBlockType;
  title: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PageForMenu
// ====================================================

export interface PageForMenu {
  __typename: "Page";
  id: number;
  name: string;
  url: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PageForSelect
// ====================================================

export interface PageForSelect {
  __typename: "Page";
  id: number;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Page
// ====================================================

export interface Page_site {
  __typename: "Site";
  id: string;
  name: string;
  url: string;
}

export interface Page_created_by {
  __typename: "Admin";
  name: string;
}

export interface Page_updated_by {
  __typename: "Admin";
  name: string;
}

export interface Page_blocks_PageBlockBanner_banners_BannerNormal {
  __typename: "BannerNormal";
  id: number;
  name: string;
}

export interface Page_blocks_PageBlockBanner_banners_BannerProductList {
  __typename: "BannerProductList";
  id: number;
  name: string;
}

export interface Page_blocks_PageBlockBanner_banners_BannerProduct {
  __typename: "BannerProduct";
  id: number;
  name: string;
}

export type Page_blocks_PageBlockBanner_banners = Page_blocks_PageBlockBanner_banners_BannerNormal | Page_blocks_PageBlockBanner_banners_BannerProductList | Page_blocks_PageBlockBanner_banners_BannerProduct;

export interface Page_blocks_PageBlockBanner {
  __typename: "PageBlockBanner";
  id: number;
  type: PageBlockType;
  layout: PageBlockBannerLayout;
  title: string | null;
  banners: Page_blocks_PageBlockBanner_banners[];
}

export interface Page_blocks_PageBlockProductList_product_list {
  __typename: "ProductList";
  id: number;
  name: string;
}

export interface Page_blocks_PageBlockProductList_product_sort_by {
  __typename: "ProductSortFrontend";
  field: ProductSortFieldCategory;
  direction: OrderDirection;
}

export interface Page_blocks_PageBlockProductList {
  __typename: "PageBlockProductList";
  id: number;
  type: PageBlockType;
  title: string | null;
  product_list: Page_blocks_PageBlockProductList_product_list | null;
  is_scrollable: boolean;
  is_filterable: boolean;
  product_limit: number | null;
  product_sort_by: Page_blocks_PageBlockProductList_product_sort_by | null;
}

export interface Page_blocks_PageBlockHtml {
  __typename: "PageBlockHtml";
  id: number;
  type: PageBlockType;
  title: string | null;
  content: string;
}

export interface Page_blocks_PageBlockNewsletter {
  __typename: "PageBlockNewsletter";
  id: number;
  type: PageBlockType;
  title: string | null;
}

export interface Page_blocks_PageBlockBrandList {
  __typename: "PageBlockBrandList";
  id: number;
  type: PageBlockType;
  title: string | null;
}

export interface Page_blocks_PageBlockRecommendedProducts {
  __typename: "PageBlockRecommendedProducts";
  id: number;
  type: PageBlockType;
  title: string | null;
}

export interface Page_blocks_PageBlockHomebox {
  __typename: "PageBlockHomebox";
  id: number;
  type: PageBlockType;
  title: string | null;
}

export type Page_blocks = Page_blocks_PageBlockBanner | Page_blocks_PageBlockProductList | Page_blocks_PageBlockHtml | Page_blocks_PageBlockNewsletter | Page_blocks_PageBlockBrandList | Page_blocks_PageBlockRecommendedProducts | Page_blocks_PageBlockHomebox;

export interface Page {
  __typename: "Page";
  id: number;
  name: string;
  title: string | null;
  url: string;
  is_active: boolean;
  is_visible: boolean;
  active_from: any | null;
  active_to: any | null;
  meta_title: string | null;
  meta_description: string | null;
  og_title: string | null;
  og_description: string | null;
  og_image: string | null;
  background_image: string | null;
  created_at: any;
  updated_at: any | null;
  site: Page_site;
  created_by: Page_created_by;
  updated_by: Page_updated_by | null;
  blocks: Page_blocks[];
  is_header: boolean;
  is_footer: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PageListItem
// ====================================================

export interface PageListItem {
  __typename: "Page";
  id: number;
  name: string;
  url: string;
  active_from: any | null;
  active_to: any | null;
  updated_at: any | null;
  is_visible: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PageOverlay
// ====================================================

export interface PageOverlay {
  __typename: "Page";
  id: number;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PaginatorInfo
// ====================================================

export interface PaginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Number of total available items.
   */
  total: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PaymentMethod
// ====================================================

export interface PaymentMethod {
  __typename: "PaymentMethod";
  id: string;
  name: string | null;
  description: string | null;
  cost: number;
  is_active: boolean;
  cart_value_max: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PaymentMethodSelectItem
// ====================================================

export interface PaymentMethodSelectItem {
  __typename: "PaymentMethod";
  id: string;
  name: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PaymentMethodTabItem
// ====================================================

export interface PaymentMethodTabItem {
  __typename: "PaymentMethod";
  id: string;
  name: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PaymentStatus
// ====================================================

export interface PaymentStatus {
  __typename: "PaymentStatus";
  id: PaymentStatusEnum;
  title: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PaymentTransaction
// ====================================================

export interface PaymentTransaction_payment_method {
  __typename: "PaymentMethod";
  name: string | null;
}

export interface PaymentTransaction {
  __typename: "PaymentTransaction";
  id: number;
  pmgw_transaction_id: string;
  provider_transaction_id: string | null;
  anum: string | null;
  payment_method: PaymentTransaction_payment_method;
  status_payment: string | null;
  amount: number;
  amount_refund: number | null;
  success: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Permission
// ====================================================

export interface Permission {
  __typename: "Permission";
  id: number;
  name: PermissionType;
  title: string;
  group: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Person
// ====================================================

export interface Person {
  __typename: "Person";
  id: string;
  name: string;
  image: string;
  position: string;
  created_at: any;
  updated_at: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PersonListItem
// ====================================================

export interface PersonListItem {
  __typename: "Person";
  id: string;
  name: string;
  position: string;
  created_at: any;
  updated_at: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductBrandForMenu
// ====================================================

export interface ProductBrandForMenu {
  __typename: "ProductBrand";
  id: number;
  name: string | null;
  slug: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductBrand
// ====================================================

export interface ProductBrand_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface ProductBrand {
  __typename: "ProductBrand";
  id: number;
  name: string | null;
  evo_name: string;
  slug: string | null;
  logo: string | null;
  logo_title: string | null;
  meta_title: string | null;
  meta_description: string | null;
  og_image: string | null;
  og_title: string | null;
  og_description: string | null;
  is_logo: boolean;
  is_public: boolean;
  is_rossmann: boolean;
  created_at: any | null;
  updated_at: any | null;
  updated_by: ProductBrand_updated_by | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductBrandListItem
// ====================================================

export interface ProductBrandListItem {
  __typename: "ProductBrand";
  id: number;
  name: string | null;
  slug: string | null;
  is_logo: boolean;
  is_public: boolean;
  is_rossmann: boolean;
  created_at: any | null;
  updated_at: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductBrandSelectItem
// ====================================================

export interface ProductBrandSelectItem {
  __typename: "ProductBrand";
  id: number;
  name: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductCatalogItem
// ====================================================

export interface ProductCatalogItem_main_categories {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface ProductCatalogItem_categories {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface ProductCatalogItem {
  __typename: "ProductSimple";
  id: number;
  lfdnr: number | null;
  name: string | null;
  main_categories: ProductCatalogItem_main_categories[];
  categories: ProductCatalogItem_categories[];
  is_visible: boolean;
  active_from: any | null;
  is_description: boolean;
  is_image: boolean;
  ean: string[];
  price: number | null;
  stock_qty: number | null;
  slug: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductCategoryBanner
// ====================================================

export interface ProductCategoryBanner {
  __typename: "ProductCategoryBanner";
  is_active: boolean;
  active_from: any | null;
  active_to: any | null;
  image: string;
  image_title: string | null;
  link: string | null;
  link_new_tab: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductCategoryChild
// ====================================================

export interface ProductCategoryChild {
  __typename: "ProductCategory";
  id: number;
  name: string;
  is_active: boolean;
  is_featured: boolean;
  position: number | null;
  has_child: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductCategoryForMenuFields
// ====================================================

export interface ProductCategoryForMenuFields {
  __typename: "ProductCategorySimple";
  id: number;
  name: string;
  slug: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductCategoryForMenu
// ====================================================

export interface ProductCategoryForMenu_children_children_children_children_children {
  __typename: "ProductCategorySimple";
  id: number;
  name: string;
  slug: string;
}

export interface ProductCategoryForMenu_children_children_children_children {
  __typename: "ProductCategorySimple";
  id: number;
  name: string;
  slug: string;
  children: ProductCategoryForMenu_children_children_children_children_children[] | null;
}

export interface ProductCategoryForMenu_children_children_children {
  __typename: "ProductCategorySimple";
  id: number;
  name: string;
  slug: string;
  children: ProductCategoryForMenu_children_children_children_children[] | null;
}

export interface ProductCategoryForMenu_children_children {
  __typename: "ProductCategorySimple";
  id: number;
  name: string;
  slug: string;
  children: ProductCategoryForMenu_children_children_children[] | null;
}

export interface ProductCategoryForMenu_children {
  __typename: "ProductCategorySimple";
  id: number;
  name: string;
  slug: string;
  children: ProductCategoryForMenu_children_children[] | null;
}

export interface ProductCategoryForMenu {
  __typename: "ProductCategorySimple";
  id: number;
  name: string;
  slug: string;
  children: ProductCategoryForMenu_children[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductCategory
// ====================================================

export interface ProductCategory_banners {
  __typename: "ProductCategoryBanner";
  is_active: boolean;
  active_from: any | null;
  active_to: any | null;
  image: string;
  image_title: string | null;
  link: string | null;
  link_new_tab: boolean;
}

export interface ProductCategory_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface ProductCategory_product_sort_by {
  __typename: "ProductSortFrontend";
  field: ProductSortFieldCategory;
  direction: OrderDirection;
}

export interface ProductCategory_parent {
  __typename: "ProductCategory";
  id: number;
}

export interface ProductCategory {
  __typename: "ProductCategory";
  id: number;
  name: string;
  icon: string | null;
  slug: string;
  icon_title: string | null;
  description: string | null;
  banners: ProductCategory_banners[];
  is_active: boolean;
  include_in_menu: boolean;
  is_featured: boolean;
  feed_facebook: boolean;
  feed_google: boolean;
  feed_google_merchant: boolean;
  feed_dsa: boolean;
  og_image: string | null;
  og_title: string | null;
  og_description: string | null;
  meta_description: string | null;
  updated_at: any | null;
  updated_by: ProductCategory_updated_by | null;
  product_sort_by: ProductCategory_product_sort_by | null;
  parent: ProductCategory_parent | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductCategorySelectItemFields
// ====================================================

export interface ProductCategorySelectItemFields {
  __typename: "ProductCategorySimple";
  id: number;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductCategorySelectItem
// ====================================================

export interface ProductCategorySelectItem_children_children_children_children_children {
  __typename: "ProductCategorySimple";
  id: number;
  name: string;
}

export interface ProductCategorySelectItem_children_children_children_children {
  __typename: "ProductCategorySimple";
  id: number;
  name: string;
  children: ProductCategorySelectItem_children_children_children_children_children[] | null;
}

export interface ProductCategorySelectItem_children_children_children {
  __typename: "ProductCategorySimple";
  id: number;
  name: string;
  children: ProductCategorySelectItem_children_children_children_children[] | null;
}

export interface ProductCategorySelectItem_children_children {
  __typename: "ProductCategorySimple";
  id: number;
  name: string;
  children: ProductCategorySelectItem_children_children_children[] | null;
}

export interface ProductCategorySelectItem_children {
  __typename: "ProductCategorySimple";
  id: number;
  name: string;
  children: ProductCategorySelectItem_children_children[] | null;
}

export interface ProductCategorySelectItem {
  __typename: "ProductCategorySimple";
  id: number;
  name: string;
  children: ProductCategorySelectItem_children[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductFilter
// ====================================================

export interface ProductFilter {
  __typename: "ProductFilter";
  type: ProductFilterType;
  operator: ProductFilterOperator | null;
  product_brand_id: number | null;
  product_category_id: number | null;
  product_param_id: string | null;
  clpe_promotion_id: number | null;
  price_type: PriceType | null;
  clpe_promotion_type: ClpePromotionType | null;
  created_at: any | null;
  rating: number | null;
  product_type: ProductType | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductForMenu
// ====================================================

export interface ProductForMenu {
  __typename: "ProductSimple";
  id: number;
  name: string | null;
  slug: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductFields
// ====================================================

export interface ProductFields_categories_category_path {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface ProductFields_categories {
  __typename: "ProductCategory";
  id: number;
  category_path: ProductFields_categories_category_path[];
}

export interface ProductFields_images {
  __typename: "ProductImage";
  id: number;
  source: ProductImageSource;
  position: number;
  url_list: string | null;
  is_active: boolean;
}

export interface ProductFields_param_values {
  __typename: "ProductParamValue";
  product_param_id: string;
  is_multi: boolean;
  values: string[];
}

export interface ProductFields_brand_bank_param_values {
  __typename: "ProductParamValue";
  product_param_id: string;
  is_multi: boolean;
  values: string[];
}

export interface ProductFields_brand_bank {
  __typename: "ProductBrandBankData";
  id: number;
  param_values: ProductFields_brand_bank_param_values[];
}

export interface ProductFields_badges_featured {
  __typename: "Badge";
  name: string;
}

export interface ProductFields_badges_product_params {
  __typename: "Badge";
  name: string;
}

export interface ProductFields_badges_notifications {
  __typename: "Badge";
  name: string;
}

export interface ProductFields_prices {
  __typename: "ProductPrice";
  id: number;
  price: number;
  price_unit: number | null;
  active_from: any | null;
  active_to: any | null;
}

export interface ProductFields_stock {
  __typename: "ProductStock";
  id: number;
  qty: number;
  updated_at: any | null;
}

export interface ProductFields_brand {
  __typename: "ProductBrand";
  id: number;
  name: string | null;
}

export interface ProductFields_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface ProductFields_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface ProductFields {
  __typename: "Product";
  id: number;
  name: string | null;
  lfdnr: number | null;
  ean: string[];
  cart_qty_max: number;
  categories: ProductFields_categories[];
  slug: string | null;
  unit_base: string | null;
  images: ProductFields_images[];
  is_active: boolean;
  is_force_active: boolean | null;
  active_from: any | null;
  active_to: any | null;
  param_values: ProductFields_param_values[];
  brand_bank: ProductFields_brand_bank | null;
  badges_featured: ProductFields_badges_featured[];
  badges_product_params: ProductFields_badges_product_params[];
  badges_notifications: ProductFields_badges_notifications[];
  prices: ProductFields_prices[];
  stock: ProductFields_stock | null;
  brand: ProductFields_brand | null;
  created_at: any;
  updated_at: any;
  created_by: ProductFields_created_by | null;
  updated_by: ProductFields_updated_by | null;
  bundle_promotion_id: number | null;
  image_path_360: string | null;
  image_position_360: number | null;
  brandbank_exclude: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Product
// ====================================================

export interface Product_categories_category_path {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface Product_categories {
  __typename: "ProductCategory";
  id: number;
  category_path: Product_categories_category_path[];
}

export interface Product_images {
  __typename: "ProductImage";
  id: number;
  source: ProductImageSource;
  position: number;
  url_list: string | null;
  is_active: boolean;
}

export interface Product_param_values {
  __typename: "ProductParamValue";
  product_param_id: string;
  is_multi: boolean;
  values: string[];
}

export interface Product_brand_bank_param_values {
  __typename: "ProductParamValue";
  product_param_id: string;
  is_multi: boolean;
  values: string[];
}

export interface Product_brand_bank {
  __typename: "ProductBrandBankData";
  id: number;
  param_values: Product_brand_bank_param_values[];
}

export interface Product_badges_featured {
  __typename: "Badge";
  name: string;
}

export interface Product_badges_product_params {
  __typename: "Badge";
  name: string;
}

export interface Product_badges_notifications {
  __typename: "Badge";
  name: string;
}

export interface Product_prices {
  __typename: "ProductPrice";
  id: number;
  price: number;
  price_unit: number | null;
  active_from: any | null;
  active_to: any | null;
}

export interface Product_stock {
  __typename: "ProductStock";
  id: number;
  qty: number;
  updated_at: any | null;
}

export interface Product_brand {
  __typename: "ProductBrand";
  id: number;
  name: string | null;
}

export interface Product_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface Product_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface Product_children_categories_category_path {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface Product_children_categories {
  __typename: "ProductCategory";
  id: number;
  category_path: Product_children_categories_category_path[];
}

export interface Product_children_images {
  __typename: "ProductImage";
  id: number;
  source: ProductImageSource;
  position: number;
  url_list: string | null;
  is_active: boolean;
}

export interface Product_children_param_values {
  __typename: "ProductParamValue";
  product_param_id: string;
  is_multi: boolean;
  values: string[];
}

export interface Product_children_brand_bank_param_values {
  __typename: "ProductParamValue";
  product_param_id: string;
  is_multi: boolean;
  values: string[];
}

export interface Product_children_brand_bank {
  __typename: "ProductBrandBankData";
  id: number;
  param_values: Product_children_brand_bank_param_values[];
}

export interface Product_children_badges_featured {
  __typename: "Badge";
  name: string;
}

export interface Product_children_badges_product_params {
  __typename: "Badge";
  name: string;
}

export interface Product_children_badges_notifications {
  __typename: "Badge";
  name: string;
}

export interface Product_children_prices {
  __typename: "ProductPrice";
  id: number;
  price: number;
  price_unit: number | null;
  active_from: any | null;
  active_to: any | null;
}

export interface Product_children_stock {
  __typename: "ProductStock";
  id: number;
  qty: number;
  updated_at: any | null;
}

export interface Product_children_brand {
  __typename: "ProductBrand";
  id: number;
  name: string | null;
}

export interface Product_children_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface Product_children_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface Product_children {
  __typename: "Product";
  id: number;
  name: string | null;
  lfdnr: number | null;
  ean: string[];
  cart_qty_max: number;
  categories: Product_children_categories[];
  slug: string | null;
  unit_base: string | null;
  images: Product_children_images[];
  is_active: boolean;
  is_force_active: boolean | null;
  active_from: any | null;
  active_to: any | null;
  param_values: Product_children_param_values[];
  brand_bank: Product_children_brand_bank | null;
  badges_featured: Product_children_badges_featured[];
  badges_product_params: Product_children_badges_product_params[];
  badges_notifications: Product_children_badges_notifications[];
  prices: Product_children_prices[];
  stock: Product_children_stock | null;
  brand: Product_children_brand | null;
  created_at: any;
  updated_at: any;
  created_by: Product_children_created_by | null;
  updated_by: Product_children_updated_by | null;
  bundle_promotion_id: number | null;
  image_path_360: string | null;
  image_position_360: number | null;
  brandbank_exclude: boolean | null;
}

export interface Product {
  __typename: "Product";
  id: number;
  name: string | null;
  lfdnr: number | null;
  ean: string[];
  cart_qty_max: number;
  categories: Product_categories[];
  slug: string | null;
  unit_base: string | null;
  images: Product_images[];
  is_active: boolean;
  is_force_active: boolean | null;
  active_from: any | null;
  active_to: any | null;
  param_values: Product_param_values[];
  brand_bank: Product_brand_bank | null;
  badges_featured: Product_badges_featured[];
  badges_product_params: Product_badges_product_params[];
  badges_notifications: Product_badges_notifications[];
  prices: Product_prices[];
  stock: Product_stock | null;
  brand: Product_brand | null;
  created_at: any;
  updated_at: any;
  created_by: Product_created_by | null;
  updated_by: Product_updated_by | null;
  bundle_promotion_id: number | null;
  image_path_360: string | null;
  image_position_360: number | null;
  brandbank_exclude: boolean | null;
  children: Product_children[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductGratisListItem
// ====================================================

export interface ProductGratisListItem_main_categories {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface ProductGratisListItem {
  __typename: "ProductSimple";
  id: number;
  lfdnr: number | null;
  ean: string[];
  name: string | null;
  main_categories: ProductGratisListItem_main_categories[];
  is_active: boolean;
  is_visible: boolean;
  active_from: any | null;
  active_to: any | null;
  is_image: boolean;
  stock_qty: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductImage
// ====================================================

export interface ProductImage {
  __typename: "ProductImage";
  id: number;
  source: ProductImageSource;
  position: number;
  url_list: string | null;
  is_active: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductListForMenu
// ====================================================

export interface ProductListForMenu {
  __typename: "ProductList";
  id: number;
  name: string;
  slug: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductList
// ====================================================

export interface ProductList_products_main_categories {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface ProductList_products_categories {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface ProductList_products {
  __typename: "ProductSimple";
  id: number;
  lfdnr: number | null;
  name: string | null;
  main_categories: ProductList_products_main_categories[];
  categories: ProductList_products_categories[];
  is_visible: boolean;
  active_from: any | null;
  is_description: boolean;
  is_image: boolean;
  ean: string[];
  price: number | null;
  stock_qty: number | null;
  slug: string | null;
}

export interface ProductList_badge {
  __typename: "Badge";
  id: number;
  name: string;
  type: BadgeType;
  created_at: any | null;
  active_from: any | null;
  is_active: boolean;
  is_visible: boolean;
}

export interface ProductList_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface ProductList_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface ProductList_filters {
  __typename: "ProductFilter";
  type: ProductFilterType;
  operator: ProductFilterOperator | null;
  product_brand_id: number | null;
  product_category_id: number | null;
  product_param_id: string | null;
  clpe_promotion_id: number | null;
  price_type: PriceType | null;
  clpe_promotion_type: ClpePromotionType | null;
  created_at: any | null;
  rating: number | null;
  product_type: ProductType | null;
}

export interface ProductList {
  __typename: "ProductList";
  id: number;
  type: ProductListType;
  name: string;
  slug: string;
  mode: ProductListMode;
  is_active: boolean;
  active_from: any | null;
  active_to: any | null;
  title: string;
  subtitle: string | null;
  image: string | null;
  image_title: string | null;
  image_mobile: string | null;
  image_mobile_title: string | null;
  products: ProductList_products[];
  group_by_category: boolean;
  badge: ProductList_badge | null;
  og_image: string | null;
  og_title: string | null;
  og_description: string | null;
  meta_description: string | null;
  created_at: any;
  created_by: ProductList_created_by;
  updated_at: any | null;
  updated_by: ProductList_updated_by | null;
  filters: ProductList_filters[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductListListItem
// ====================================================

export interface ProductListListItem {
  __typename: "ProductList";
  id: number;
  name: string;
  mode: ProductListMode;
  active_from: any | null;
  active_to: any | null;
  is_visible: boolean;
  type: ProductListType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductListMinimal
// ====================================================

export interface ProductListMinimal {
  __typename: "ProductList";
  id: number;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductParam
// ====================================================

export interface ProductParam_options {
  __typename: "ProductParamOption";
  id: number;
  label: string;
  value: string | null;
}

export interface ProductParam_badge_product_param {
  __typename: "ProductParam";
  id: string;
  name: string | null;
}

export interface ProductParam_badge_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface ProductParam_badge_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface ProductParam_badge {
  __typename: "Badge";
  id: number;
  type: BadgeType;
  name: string;
  info: string | null;
  image: string | null;
  image_title: string | null;
  product_param: ProductParam_badge_product_param | null;
  is_active: boolean;
  created_at: any | null;
  created_by: ProductParam_badge_created_by;
  updated_at: any | null;
  updated_by: ProductParam_badge_updated_by | null;
  active_from: any | null;
  active_to: any | null;
  is_visible: boolean;
}

export interface ProductParam_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface ProductParam {
  __typename: "ProductParam";
  id: string;
  name: string | null;
  evo_name: string | null;
  type: ProductParamType;
  is_active: boolean;
  is_filter: boolean;
  is_multi: boolean;
  type_widget: ProductParamWidgetType;
  created_at: any | null;
  updated_at: any | null;
  options: ProductParam_options[];
  badge: ProductParam_badge | null;
  updated_by: ProductParam_updated_by | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductParamSelectItem
// ====================================================

export interface ProductParamSelectItem {
  __typename: "ProductParamSimple";
  id: string;
  name: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductParamSimple
// ====================================================

export interface ProductParamSimple {
  __typename: "ProductParamSimple";
  id: string;
  name: string | null;
  is_filter: boolean;
  created_at: any;
  updated_at: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductParamStructure
// ====================================================

export interface ProductParamStructure {
  __typename: "ProductParamSimple";
  id: string;
  name: string | null;
  group_name: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductParamValue
// ====================================================

export interface ProductParamValue {
  __typename: "ProductParamValue";
  product_param_id: string;
  is_multi: boolean;
  values: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductPrice
// ====================================================

export interface ProductPrice {
  __typename: "ProductPrice";
  id: number;
  price: number;
  price_unit: number | null;
  active_from: any | null;
  active_to: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductSimple
// ====================================================

export interface ProductSimple_main_categories {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface ProductSimple_categories {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface ProductSimple {
  __typename: "ProductSimple";
  id: number;
  lfdnr: number | null;
  name: string | null;
  is_active: boolean;
  is_visible: boolean;
  main_categories: ProductSimple_main_categories[];
  categories: ProductSimple_categories[];
  created_at: any;
  updated_at: any;
  active_from: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductStock
// ====================================================

export interface ProductStock {
  __typename: "ProductStock";
  id: number;
  qty: number;
  updated_at: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductVariantChild
// ====================================================

export interface ProductVariantChild_main_categories {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface ProductVariantChild_categories {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface ProductVariantChild {
  __typename: "Product";
  id: number;
  lfdnr: number | null;
  ean: string[];
  name: string | null;
  main_categories: ProductVariantChild_main_categories[];
  categories: ProductVariantChild_categories[];
  is_visible: boolean;
  active_from: any | null;
  slug: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductVariant
// ====================================================

export interface ProductVariant_children_main_categories {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface ProductVariant_children_categories {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface ProductVariant_children {
  __typename: "Product";
  id: number;
  lfdnr: number | null;
  ean: string[];
  name: string | null;
  main_categories: ProductVariant_children_main_categories[];
  categories: ProductVariant_children_categories[];
  is_visible: boolean;
  active_from: any | null;
  slug: string | null;
}

export interface ProductVariant_created_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface ProductVariant_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface ProductVariant {
  __typename: "Product";
  id: number;
  variant_product_param_id: string | null;
  children: ProductVariant_children[] | null;
  name: string | null;
  slug: string | null;
  is_active: boolean;
  active_from: any | null;
  active_to: any | null;
  created_at: any;
  created_by: ProductVariant_created_by | null;
  updated_at: any;
  updated_by: ProductVariant_updated_by | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductVariantListItem
// ====================================================

export interface ProductVariantListItem_main_categories {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface ProductVariantListItem_categories {
  __typename: "ProductCategory";
  id: number;
  name: string;
}

export interface ProductVariantListItem {
  __typename: "ProductSimple";
  id: number;
  lfdnr: number | null;
  name: string | null;
  main_categories: ProductVariantListItem_main_categories[];
  categories: ProductVariantListItem_categories[];
  is_visible: boolean;
  created_at: any;
  active_from: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: RatingListItem
// ====================================================

export interface RatingListItem {
  __typename: "OrderRating";
  id: number;
  order_increment_id: number;
  rating_courier: number;
  note_courier: string | null;
  rating_purchase: number;
  note_purchase: string | null;
  rating_recommend: number;
  created_at: any;
  updated_at: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Redirection
// ====================================================

export interface Redirection_from_site {
  __typename: "Site";
  id: string;
  name: string;
  url: string;
}

export interface Redirection_to_site {
  __typename: "Site";
  id: string;
  name: string;
  url: string;
}

export interface Redirection {
  __typename: "Redirection";
  id: string;
  from_site: Redirection_from_site | null;
  from: string | null;
  to_site: Redirection_to_site | null;
  to: string | null;
  active_from: any | null;
  active_to: any | null;
  is_active: boolean;
  is_permanent: boolean;
  is_regex: boolean | null;
  forward: boolean | null;
  created_at: any;
  updated_at: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: RedirectionListItem
// ====================================================

export interface RedirectionListItem_from_site {
  __typename: "Site";
  id: string;
  name: string;
  url: string;
}

export interface RedirectionListItem_to_site {
  __typename: "Site";
  id: string;
  name: string;
  url: string;
}

export interface RedirectionListItem {
  __typename: "Redirection";
  id: string;
  from_site: RedirectionListItem_from_site | null;
  from: string | null;
  to_site: RedirectionListItem_to_site | null;
  to: string | null;
  created_at: any;
  updated_at: any | null;
  is_active: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Role
// ====================================================

export interface Role_permissions {
  __typename: "Permission";
  id: number;
  name: PermissionType;
  title: string;
  group: string;
}

export interface Role {
  __typename: "Role";
  id: number;
  name: string;
  title: string;
  created_at: any;
  updated_at: any | null;
  permissions: Role_permissions[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: RoleListItem
// ====================================================

export interface RoleListItem {
  __typename: "Role";
  id: number;
  name: string;
  title: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ShipmentStatus
// ====================================================

export interface ShipmentStatus {
  __typename: "ShipmentStatus";
  id: ShipmentStatusEnum;
  title: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ShipmentStatusSelectItem
// ====================================================

export interface ShipmentStatusSelectItem {
  __typename: "ShipmentStatus";
  id: ShipmentStatusEnum;
  title: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ShippingCost
// ====================================================

export interface ShippingCost {
  __typename: "ShippingCost";
  cart_limit: number;
  cost: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ShippingMethod
// ====================================================

export interface ShippingMethod_cost {
  __typename: "ShippingCost";
  cart_limit: number;
  cost: number;
}

export interface ShippingMethod_cost_terminal {
  __typename: "ShippingCost";
  cart_limit: number;
  cost: number;
}

export interface ShippingMethod_cost_time_windows_cost {
  __typename: "ShippingCost";
  cart_limit: number;
  cost: number;
}

export interface ShippingMethod_cost_time_windows {
  __typename: "TimeWindowCost";
  interval_hours: number;
  cost: ShippingMethod_cost_time_windows_cost[];
}

export interface ShippingMethod_delivery_informations {
  __typename: "DeliveryInformation";
  info: string;
  active_from: any | null;
  active_to: any | null;
}

export interface ShippingMethod_roles {
  __typename: "ShippingMethodRole";
  vip_levels: RossmannPlusVipLevel[] | null;
  active_from: any | null;
  active_to: any | null;
}

export interface ShippingMethod {
  __typename: "ShippingMethod";
  id: string;
  name: string | null;
  lead: string | null;
  description: string | null;
  weight_min: number | null;
  weight_max: number | null;
  cart_value_min: number | null;
  order_max: number | null;
  cost: ShippingMethod_cost[];
  cost_terminal: ShippingMethod_cost_terminal[];
  delivery_days: number | null;
  is_active: boolean;
  is_visible: boolean;
  active_from: any | null;
  active_to: any | null;
  created_at: any;
  updated_at: any | null;
  cost_time_windows: ShippingMethod_cost_time_windows[] | null;
  /**
   *  default delivery information ha nincs időzített
   */
  delivery_information: string | null;
  /**
   *  időzített delivery information 
   */
  delivery_informations: ShippingMethod_delivery_informations[] | null;
  roles: ShippingMethod_roles[] | null;
  time_limit_next_day_departure: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ShippingMethodSelectItem
// ====================================================

export interface ShippingMethodSelectItem {
  __typename: "ShippingMethod";
  id: string;
  name: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ShippingMethodTabItem
// ====================================================

export interface ShippingMethodTabItem {
  __typename: "ShippingMethod";
  id: string;
  name: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Site
// ====================================================

export interface Site {
  __typename: "Site";
  id: string;
  name: string;
  url: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: StoreOrderListItem
// ====================================================

export interface StoreOrderListItem_packages {
  __typename: "Package";
  id: string;
}

export interface StoreOrderListItem_shipment_status {
  __typename: "ShipmentStatus";
  title: string;
}

export interface StoreOrderListItem {
  __typename: "OrderSimple";
  id: number;
  increment_id: number;
  created_at: any;
  billing_fullname: string;
  shipping_fullname: string;
  delivery_day_expected: any | null;
  packages: StoreOrderListItem_packages[];
  is_in_store: boolean;
  store_admin_is_took_over: boolean | null;
  delivered_at: any | null;
  shipment_status: StoreOrderListItem_shipment_status | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: StoreSelectItem
// ====================================================

export interface StoreSelectItem {
  __typename: "Store";
  id: number;
  address: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Subscription
// ====================================================

export interface Subscription_user {
  __typename: "User";
  firstname: string | null;
  lastname: string | null;
  email: string;
}

export interface Subscription_billing_address {
  __typename: "UserAddress";
  firstname: string | null;
  lastname: string | null;
  zip_code: string | null;
  city: string | null;
  address_line1: string | null;
  address_line2: string | null;
  company_name: string | null;
  company_number: string | null;
}

export interface Subscription_shipping_address {
  __typename: "UserAddress";
  firstname: string | null;
  lastname: string | null;
  zip_code: string | null;
  city: string | null;
  address_line1: string | null;
  address_line2: string | null;
  company_name: string | null;
  company_number: string | null;
}

export interface Subscription_time_window_shipping_method {
  __typename: "ShippingMethod";
  name: string | null;
}

export interface Subscription_items_product {
  __typename: "Product";
  id: number;
  name: string | null;
}

export interface Subscription_items {
  __typename: "OrderSubscriptionItem";
  id: number;
  product: Subscription_items_product;
  quantity: number;
}

export interface Subscription_orders_status {
  __typename: "OrderStatus";
  title: string;
}

export interface Subscription_orders {
  __typename: "Order";
  increment_id: number | null;
  status: Subscription_orders_status;
  delivery_day_expected: any | null;
  created_at: any;
}

export interface Subscription {
  __typename: "OrderSubscription";
  id: number;
  user: Subscription_user;
  billing_address: Subscription_billing_address;
  shipping_address: Subscription_shipping_address;
  created_at: any;
  updated_at: any;
  paused_at: any | null;
  /**
   * Date canceled
   */
  deleted_at: any | null;
  notified_at: any | null;
  notified_at_sms: any | null;
  /**
   * Expected delivery date
   */
  date_next: any;
  /**
   * Selected time window
   */
  time_window_date: any | null;
  time_window_interval: string | null;
  time_window_changed: number | null;
  /**
   * Shipping method time window selected for
   */
  time_window_shipping_method: Subscription_time_window_shipping_method | null;
  items: Subscription_items[];
  orders: Subscription_orders[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SubscriptionItem
// ====================================================

export interface SubscriptionItem_product {
  __typename: "Product";
  id: number;
  name: string | null;
}

export interface SubscriptionItem {
  __typename: "OrderSubscriptionItem";
  id: number;
  product: SubscriptionItem_product;
  quantity: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SubscriptionListItem
// ====================================================

export interface SubscriptionListItem {
  __typename: "OrderSubscriptionSimple";
  id: number;
  user_id: number;
  user_fullname: string;
  user_email: string;
  payment_provider: string | null;
  created_at: any;
  updated_at: any;
  /**
   * Shipping method time window selected for
   */
  shipping_method_name: string | null;
  /**
   * Date canceled
   */
  deleted_at: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SubscriptionOrder
// ====================================================

export interface SubscriptionOrder_status {
  __typename: "OrderStatus";
  title: string;
}

export interface SubscriptionOrder {
  __typename: "Order";
  increment_id: number | null;
  status: SubscriptionOrder_status;
  delivery_day_expected: any | null;
  created_at: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SuccessStory
// ====================================================

export interface SuccessStory_division_department {
  __typename: "Department";
  id: string;
  name: string;
}

export interface SuccessStory_division {
  __typename: "Division";
  id: string;
  name: string;
  department: SuccessStory_division_department | null;
  created_at: any;
  updated_at: any | null;
}

export interface SuccessStory_person {
  __typename: "Person";
  id: string;
  name: string;
  position: string;
  created_at: any;
  updated_at: any | null;
}

export interface SuccessStory {
  __typename: "SuccessStory";
  id: string;
  content: string;
  division: SuccessStory_division;
  person: SuccessStory_person;
  created_at: any;
  updated_at: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SuccessStoryListItem
// ====================================================

export interface SuccessStoryListItem_person {
  __typename: "Person";
  id: string;
  name: string;
}

export interface SuccessStoryListItem_division {
  __typename: "Division";
  id: string;
  name: string;
}

export interface SuccessStoryListItem {
  __typename: "SuccessStory";
  id: string;
  person: SuccessStoryListItem_person;
  division: SuccessStoryListItem_division;
  created_at: any;
  updated_at: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SystemSettingListItem
// ====================================================

export interface SystemSettingListItem_options {
  __typename: "SettingOption";
  title: string;
  value: string;
}

export interface SystemSettingListItem_updated_by {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface SystemSettingListItem {
  __typename: "Setting";
  id: string;
  type: SettingType;
  value: string[];
  options: SystemSettingListItem_options[];
  is_multi: boolean;
  description: string | null;
  updated_at: any;
  updated_by: SystemSettingListItem_updated_by | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SystemSettingOption
// ====================================================

export interface SystemSettingOption {
  __typename: "SettingOption";
  title: string;
  value: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TextContentForMenu
// ====================================================

export interface TextContentForMenu_category {
  __typename: "Category";
  url: string;
}

export interface TextContentForMenu {
  __typename: "TextContent";
  id: string;
  title: string;
  url: string;
  category: TextContentForMenu_category | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TextContent
// ====================================================

export interface TextContent_category {
  __typename: "Category";
  id: string;
  title: string;
  url: string;
}

export interface TextContent_documents {
  __typename: "Document";
  id: string;
  file_name: string;
}

export interface TextContent {
  __typename: "TextContent";
  id: string;
  url: string;
  title: string;
  lead: string | null;
  lead_image: string | null;
  meta_image: string | null;
  content: string | null;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
  author: string | null;
  is_active: boolean;
  is_visible: boolean;
  active_from: any | null;
  active_to: any | null;
  created_at: any;
  updated_at: any | null;
  last_published_at: any | null;
  category: TextContent_category | null;
  documents: (TextContent_documents | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TextContentListItem
// ====================================================

export interface TextContentListItem_category {
  __typename: "Category";
  id: string;
  title: string;
}

export interface TextContentListItem {
  __typename: "TextContent";
  id: string;
  is_visible: boolean;
  is_active: boolean;
  active_from: any | null;
  updated_at: any | null;
  title: string;
  author: string | null;
  category: TextContentListItem_category | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TimeWindowCost
// ====================================================

export interface TimeWindowCost_cost {
  __typename: "ShippingCost";
  cart_limit: number;
  cost: number;
}

export interface TimeWindowCost {
  __typename: "TimeWindowCost";
  interval_hours: number;
  cost: TimeWindowCost_cost[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Todo
// ====================================================

export interface Todo_role {
  __typename: "Role";
  id: number;
  name: string;
  title: string;
}

export interface Todo_assigned_to {
  __typename: "Admin";
  id: number;
  name: string;
}

export interface Todo_created_by {
  __typename: "Admin";
  name: string;
}

export interface Todo_updated_by {
  __typename: "Admin";
  name: string;
}

export interface Todo {
  __typename: "Todo";
  id: string;
  status: TodoStatus;
  subject: string;
  message: string;
  role: Todo_role;
  created_at: any;
  updated_at: any | null;
  assigned_to: Todo_assigned_to | null;
  created_by: Todo_created_by | null;
  updated_by: Todo_updated_by | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TodoListItem
// ====================================================

export interface TodoListItem {
  __typename: "TodoSimple";
  id: string;
  status: TodoStatus;
  subject: string;
  message: string;
  role_name: string;
  assigned_to_name: string | null;
  created_at: any;
  updated_at: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserAddressSmall
// ====================================================

export interface UserAddressSmall {
  __typename: "UserAddress";
  firstname: string | null;
  lastname: string | null;
  zip_code: string | null;
  city: string | null;
  address_line1: string | null;
  address_line2: string | null;
  company_name: string | null;
  company_number: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: User
// ====================================================

export interface User_rossmann_plus_profile {
  __typename: "RossmannPlusProfile";
  card_number: string;
  is_virtual_card: boolean;
}

export interface User {
  __typename: "User";
  id: number;
  lastname: string | null;
  firstname: string | null;
  email: string;
  phone_number: string | null;
  rossmann_plus_profile: User_rossmann_plus_profile | null;
  created_at: any;
  banktransfer_enabled: boolean;
  cod_disabled: boolean;
  newsletter: boolean;
  is_active: boolean;
  country_code: string | null;
  area_code: string | null;
  order_count: number;
  order_total_sum: number;
  last_login: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserListItem
// ====================================================

export interface UserListItem {
  __typename: "UserSimple";
  id: number;
  name: string;
  email: string;
  card_number: string | null;
  phone_number_full: string;
  zip_code: string | null;
  created_at: any;
  is_active: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserOrderItem
// ====================================================

export interface UserOrderItem {
  __typename: "OrderSimple";
  id: number;
  created_at: any;
  grand_total: number;
  shipping_method_name: string;
  payment_method_name: string;
  payment_status_title: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: WebsiteNotification
// ====================================================

export interface WebsiteNotification_created_by {
  __typename: "Admin";
  name: string;
}

export interface WebsiteNotification_updated_by {
  __typename: "Admin";
  name: string;
}

export interface WebsiteNotification {
  __typename: "WebsiteNotification";
  id: number;
  name: string;
  title: string | null;
  message: string;
  is_active: boolean;
  active_from: any | null;
  active_to: any | null;
  created_at: any;
  created_by: WebsiteNotification_created_by;
  updated_at: any;
  updated_by: WebsiteNotification_updated_by | null;
  target: ClientType[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: WebsiteNotificationListItem
// ====================================================

export interface WebsiteNotificationListItem {
  __typename: "WebsiteNotification";
  id: number;
  name: string;
  active_from: any | null;
  active_to: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AdminSortField {
  email = "email",
  id = "id",
  is_active = "is_active",
  last_login = "last_login",
  login = "login",
  name = "name",
  phone = "phone",
}

export enum BadgeSortField {
  created_at = "created_at",
  id = "id",
  is_active = "is_active",
  is_visible = "is_visible",
  name = "name",
  type = "type",
  updated_at = "updated_at",
}

export enum BadgeType {
  delisting = "delisting",
  discounted = "discounted",
  low_stock = "low_stock",
  new_product = "new_product",
  other = "other",
  product_bundle = "product_bundle",
  product_param = "product_param",
  promotion = "promotion",
  top = "top",
}

export enum BankTransferStatus {
  paid = "paid",
  partially_paid = "partially_paid",
}

export enum BannerAlign {
  center = "center",
  left = "left",
  right = "right",
}

export enum BannerSortField {
  active_from = "active_from",
  active_to = "active_to",
  created_at = "created_at",
  id = "id",
  is_active = "is_active",
  name = "name",
  target_url = "target_url",
  type = "type",
  updated_at = "updated_at",
}

export enum BannerType {
  normal = "normal",
  product = "product",
  product_list = "product_list",
}

export enum BenefitSortField {
  created_at = "created_at",
  id = "id",
  title = "title",
  updated_at = "updated_at",
}

export enum CalendarDaySortField {
  date = "date",
  is_delivery = "is_delivery",
  is_workday = "is_workday",
}

export enum CampaignSortField {
  active_from = "active_from",
  active_to = "active_to",
  created_at = "created_at",
  id = "id",
  is_active = "is_active",
  is_visible = "is_visible",
  name = "name",
  updated_at = "updated_at",
}

export enum ClientType {
  android = "android",
  desktop = "desktop",
  ios = "ios",
  mobile = "mobile",
}

export enum ClpePromotionType {
  baby_coupon = "baby_coupon",
  black_friday = "black_friday",
  free_shipping = "free_shipping",
  glamour = "glamour",
  gratis_product = "gratis_product",
  joy = "joy",
  one_plus_one = "one_plus_one",
  qty_discount = "qty_discount",
  rossmann_plus = "rossmann_plus",
  rossmann_plus_baby = "rossmann_plus_baby",
  two_plus_one = "two_plus_one",
}

export enum ContentField {
  active_from = "active_from",
  active_to = "active_to",
  created_at = "created_at",
  id = "id",
  title = "title",
  updated_at = "updated_at",
  url = "url",
}

export enum ContentType {
  GALLERY = "GALLERY",
  TEXT = "TEXT",
  VIDEO = "VIDEO",
}

export enum DepartmentSortField {
  created_at = "created_at",
  id = "id",
  is_grouped = "is_grouped",
  name = "name",
  order = "order",
  updated_at = "updated_at",
}

export enum DivisionSortField {
  created_at = "created_at",
  id = "id",
  name = "name",
  order = "order",
  updated_at = "updated_at",
}

export enum DocumentField {
  created_at = "created_at",
  id = "id",
  updated_at = "updated_at",
}

export enum FulfillmentSortField {
  active_from = "active_from",
  active_to = "active_to",
  created_at = "created_at",
  id = "id",
  is_active = "is_active",
  limit = "limit",
  timewindow_allowed = "timewindow_allowed",
  type = "type",
  updated_at = "updated_at",
  weight = "weight",
}

export enum FulfillmentTypeEnum {
  DEFAULT = "DEFAULT",
  DHL = "DHL",
  ROSSMANN_A2 = "ROSSMANN_A2",
}

export enum FulfillmentTypeSelectable {
  DHL = "DHL",
  ROSSMANN_A2 = "ROSSMANN_A2",
}

export enum GalleryField {
  created_at = "created_at",
  id = "id",
  title = "title",
  updated_at = "updated_at",
}

export enum GiftCardOrderSortField {
  created_at = "created_at",
  grand_total = "grand_total",
  id = "id",
  payment_method_name = "payment_method_name",
  payment_status_title = "payment_status_title",
  user_email = "user_email",
  user_fullname = "user_fullname",
}

export enum GiftCardOrderType {
  compensation = "compensation",
  corporate_order = "corporate_order",
  customer_compensation = "customer_compensation",
  donation = "donation",
  employee_benefit = "employee_benefit",
  event_support = "event_support",
  lottery = "lottery",
  normal = "normal",
  other = "other",
}

export enum InvoiceTypeEnum {
  corrective = "corrective",
  normal = "normal",
  proforma = "proforma",
  storno = "storno",
}

export enum LogActionType {
  created = "created",
  deleting = "deleting",
  updating = "updating",
}

export enum LogSortField {
  timestamp = "timestamp",
}

export enum MailTemplateOrderField {
  created_at = "created_at",
  description = "description",
  id = "id",
  is_active = "is_active",
  subject = "subject",
  updated_at = "updated_at",
}

export enum MaintenanceScope {
  android = "android",
  application = "application",
  clpe = "clpe",
  ios = "ios",
  loyalty = "loyalty",
  webshop = "webshop",
  website = "website",
}

export enum MenuItemTypeEnum {
  article = "article",
  page = "page",
  product = "product",
  productBrand = "productBrand",
  productCategory = "productCategory",
  productList = "productList",
  url = "url",
}

export enum MenuSortField {
  active_from = "active_from",
  active_to = "active_to",
  created_at = "created_at",
  id = "id",
  is_active = "is_active",
  is_visible = "is_visible",
  name = "name",
  slug = "slug",
  updated_at = "updated_at",
}

export enum ModelType {
  admin = "admin",
  banner = "banner",
  invoice = "invoice",
  order = "order",
  orderItem = "orderItem",
  orderPayment = "orderPayment",
  product = "product",
  productPrice = "productPrice",
  productStock = "productStock",
  shipment = "shipment",
}

export enum OrderDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum OrderItemType {
  fee = "fee",
  product = "product",
  shipping = "shipping",
  tip = "tip",
}

export enum OrderRatingSortField {
  created_at = "created_at",
  id = "id",
  order_increment_id = "order_increment_id",
  rating_courier = "rating_courier",
  rating_purchase = "rating_purchase",
  rating_recommend = "rating_recommend",
  updated_at = "updated_at",
}

export enum OrderSortField {
  created_at = "created_at",
  delivered_at = "delivered_at",
  delivery_day_expected = "delivery_day_expected",
  fulfillment = "fulfillment",
  grand_total = "grand_total",
  id = "id",
  increment_id = "increment_id",
  is_in_store = "is_in_store",
  order_subscription_id = "order_subscription_id",
  payment_method_name = "payment_method_name",
  payment_status_title = "payment_status_title",
  pickup_point_address = "pickup_point_address",
  pickup_point_id = "pickup_point_id",
  pickup_point_name = "pickup_point_name",
  shipping_method_name = "shipping_method_name",
  store_admin_is_took_over = "store_admin_is_took_over",
  time_window_date = "time_window_date",
  time_window_interval = "time_window_interval",
  time_window_interval_length = "time_window_interval_length",
  user_email = "user_email",
  user_fullname = "user_fullname",
}

export enum OrderStatusEnum {
  canceled = "canceled",
  completed = "completed",
  in_progress = "in_progress",
  modified = "modified",
  open = "open",
}

export enum OrderSubscriptionSortField {
  created_at = "created_at",
  deleted_at = "deleted_at",
  id = "id",
  notified_at = "notified_at",
  notified_at_sms = "notified_at_sms",
  paused_at = "paused_at",
  paused_until = "paused_until",
  payment_provider = "payment_provider",
  shipping_method_name = "shipping_method_name",
  time_window_changed = "time_window_changed",
  time_window_date = "time_window_date",
  updated_at = "updated_at",
}

export enum PackageType {
  A = "A",
  B = "B",
  C = "C",
  CRATE = "CRATE",
  D = "D",
  E = "E",
  F = "F",
}

export enum PageBlockBannerLayout {
  full = "full",
  half_half = "half_half",
  half_quarter_quarter = "half_quarter_quarter",
  quarter_quarter_half = "quarter_quarter_half",
  quarter_quarter_quarter_quarter = "quarter_quarter_quarter_quarter",
  quarter_threequarter = "quarter_threequarter",
  threequarter_quarter = "threequarter_quarter",
}

export enum PageBlockType {
  banner = "banner",
  brand_list = "brand_list",
  homebox = "homebox",
  html = "html",
  newsletter = "newsletter",
  product_list = "product_list",
  recommended_products = "recommended_products",
}

export enum PageSortField {
  active_from = "active_from",
  active_to = "active_to",
  created_at = "created_at",
  id = "id",
  is_active = "is_active",
  is_visible = "is_visible",
  name = "name",
  updated_at = "updated_at",
  url = "url",
}

export enum PaymentMethodSortField {
  cost = "cost",
  created_at = "created_at",
  id = "id",
  is_active = "is_active",
  is_visible = "is_visible",
  name = "name",
  updated_at = "updated_at",
}

export enum PaymentStatusEnum {
  failed = "failed",
  in_progress = "in_progress",
  paid = "paid",
  partially_paid = "partially_paid",
  partially_refunded = "partially_refunded",
  refunded = "refunded",
  voided = "voided",
  waiting = "waiting",
}

export enum PermissionType {
  admin_read = "admin_read",
  admin_todo_read = "admin_todo_read",
  admin_todo_update = "admin_todo_update",
  admin_update = "admin_update",
  badge_read = "badge_read",
  badge_update = "badge_update",
  banner_read = "banner_read",
  banner_update = "banner_update",
  content_read = "content_read",
  content_update = "content_update",
  debug = "debug",
  gift_card_order_create = "gift_card_order_create",
  gift_card_order_update = "gift_card_order_update",
  gift_card_resend = "gift_card_resend",
  invoice_update = "invoice_update",
  log_read = "log_read",
  mail_template_read = "mail_template_read",
  mail_template_update = "mail_template_update",
  maintenance_create = "maintenance_create",
  maintenance_delete = "maintenance_delete",
  maintenance_read = "maintenance_read",
  maintenance_update = "maintenance_update",
  manage_cache = "manage_cache",
  manage_calendar = "manage_calendar",
  manage_career_benefits = "manage_career_benefits",
  manage_career_departments = "manage_career_departments",
  manage_career_divisions = "manage_career_divisions",
  manage_career_persons = "manage_career_persons",
  manage_career_success_stories = "manage_career_success_stories",
  manage_promotions = "manage_promotions",
  manage_redirections = "manage_redirections",
  manage_system = "manage_system",
  menu_read = "menu_read",
  menu_update = "menu_update",
  order_close = "order_close",
  order_delete = "order_delete",
  order_read = "order_read",
  order_refund = "order_refund",
  order_storno = "order_storno",
  order_update = "order_update",
  payment_method_read = "payment_method_read",
  payment_method_update = "payment_method_update",
  product_brand_read = "product_brand_read",
  product_brand_update = "product_brand_update",
  product_bundle_create = "product_bundle_create",
  product_bundle_update = "product_bundle_update",
  product_list_create = "product_list_create",
  product_list_delete = "product_list_delete",
  product_list_read = "product_list_read",
  product_list_update = "product_list_update",
  product_param_read = "product_param_read",
  product_param_update = "product_param_update",
  product_read = "product_read",
  product_update = "product_update",
  product_variant_create = "product_variant_create",
  product_variant_delete = "product_variant_delete",
  product_variant_update = "product_variant_update",
  publication_read = "publication_read",
  publication_update = "publication_update",
  role_read = "role_read",
  role_update = "role_update",
  setting_read = "setting_read",
  setting_update = "setting_update",
  shipment_update = "shipment_update",
  shipping_method_read = "shipping_method_read",
  shipping_method_update = "shipping_method_update",
  store_read = "store_read",
  store_update = "store_update",
  user_add_coupon = "user_add_coupon",
  user_delete = "user_delete",
  user_login = "user_login",
  user_read = "user_read",
  user_update = "user_update",
  website_notification_create = "website_notification_create",
  website_notification_read = "website_notification_read",
  website_notification_update = "website_notification_update",
}

export enum PersonSortField {
  created_at = "created_at",
  id = "id",
  name = "name",
  position = "position",
  updated_at = "updated_at",
}

export enum PriceType {
  delisting = "delisting",
  promo = "promo",
}

export enum ProductBrandSortField {
  created_at = "created_at",
  id = "id",
  is_logo = "is_logo",
  is_public = "is_public",
  is_rossmann = "is_rossmann",
  name = "name",
  updated_at = "updated_at",
}

export enum ProductCategorySortField {
  created_at = "created_at",
  id = "id",
  is_active = "is_active",
  name = "name",
  position = "position",
  slug = "slug",
  updated_at = "updated_at",
}

export enum ProductFilterOperator {
  eq = "eq",
  gte = "gte",
  lte = "lte",
}

export enum ProductFilterType {
  brand = "brand",
  clpe_promotion_type = "clpe_promotion_type",
  created = "created",
  new = "new",
  price_type = "price_type",
  product_category = "product_category",
  product_param = "product_param",
  product_type = "product_type",
  promotion = "promotion",
  rating = "rating",
}

export enum ProductImageSource {
  brandbank = "brandbank",
  evo = "evo",
  webshop_admin = "webshop_admin",
}

export enum ProductListMode {
  auto = "auto",
  manual = "manual",
}

export enum ProductListSortField {
  active_from = "active_from",
  active_to = "active_to",
  created_at = "created_at",
  id = "id",
  is_active = "is_active",
  is_visible = "is_visible",
  mode = "mode",
  name = "name",
  title = "title",
  updated_at = "updated_at",
}

export enum ProductListType {
  offer = "offer",
  promotion = "promotion",
}

export enum ProductParamSortField {
  created_at = "created_at",
  group_name = "group_name",
  id = "id",
  is_filter = "is_filter",
  name = "name",
  updated_at = "updated_at",
}

export enum ProductParamType {
  PRODUCT_PARAM_BOOL = "PRODUCT_PARAM_BOOL",
  PRODUCT_PARAM_FLOAT = "PRODUCT_PARAM_FLOAT",
  PRODUCT_PARAM_INT = "PRODUCT_PARAM_INT",
  PRODUCT_PARAM_OPTION = "PRODUCT_PARAM_OPTION",
  PRODUCT_PARAM_RAW = "PRODUCT_PARAM_RAW",
  PRODUCT_PARAM_STRING = "PRODUCT_PARAM_STRING",
  PRODUCT_PARAM_TEXT = "PRODUCT_PARAM_TEXT",
}

export enum ProductParamWidgetType {
  PRODUCT_PARAM_WIDGET_CHECKBOX = "PRODUCT_PARAM_WIDGET_CHECKBOX",
  PRODUCT_PARAM_WIDGET_INPUT = "PRODUCT_PARAM_WIDGET_INPUT",
  PRODUCT_PARAM_WIDGET_SELECT = "PRODUCT_PARAM_WIDGET_SELECT",
  PRODUCT_PARAM_WIDGET_TEXTAREA = "PRODUCT_PARAM_WIDGET_TEXTAREA",
  PRODUCT_PARAM_WIDGET_WYSIWYG = "PRODUCT_PARAM_WIDGET_WYSIWYG",
}

export enum ProductSortField {
  ean = "ean",
  id = "id",
  id_by_position = "id_by_position",
  is_active = "is_active",
  is_description = "is_description",
  is_image = "is_image",
  is_visible = "is_visible",
  lfdnr = "lfdnr",
  name = "name",
  price = "price",
  stock_qty = "stock_qty",
}

export enum ProductSortFieldCategory {
  name = "name",
  ordered = "ordered",
  price = "price",
  price_unit = "price_unit",
  rating = "rating",
}

export enum ProductType {
  bundle = "bundle",
  digital = "digital",
  gratis = "gratis",
  normal = "normal",
  tip = "tip",
  variant = "variant",
}

export enum RedirectionField {
  active_from = "active_from",
  active_to = "active_to",
  created_at = "created_at",
  forward = "forward",
  from = "from",
  id = "id",
  is_permanent = "is_permanent",
  is_regex = "is_regex",
  to = "to",
  updated_at = "updated_at",
}

export enum RoleSortField {
  created_at = "created_at",
  id = "id",
  name = "name",
  title = "title",
  updated_at = "updated_at",
}

export enum RossmannPlusVipLevel {
  BASIC = "BASIC",
  BRONZE = "BRONZE",
  GOLD = "GOLD",
  NONE = "NONE",
  SILVER = "SILVER",
}

export enum SettingSortField {
  created_at = "created_at",
  id = "id",
  type = "type",
  updated_at = "updated_at",
}

export enum SettingType {
  boolean = "boolean",
  datetime = "datetime",
  enum = "enum",
  json = "json",
  number = "number",
  string = "string",
  text = "text",
}

export enum ShipmentStatusEnum {
  canceled = "canceled",
  collected = "collected",
  delivered = "delivered",
  delivering = "delivering",
  delivery_in_progress = "delivery_in_progress",
  dispatched = "dispatched",
  failed = "failed",
  incomplete = "incomplete",
  new = "new",
  not_found = "not_found",
  packed = "packed",
  picked = "picked",
  picking_in_progress = "picking_in_progress",
  posted = "posted",
  ready_to_pickup = "ready_to_pickup",
  suspended = "suspended",
  unsent = "unsent",
  wrapped = "wrapped",
}

export enum ShipmentStatusStoreEnum {
  delivered = "delivered",
  failed = "failed",
  not_found = "not_found",
  ready_to_pickup = "ready_to_pickup",
}

export enum ShippingMethodSortField {
  cost = "cost",
  created_at = "created_at",
  id = "id",
  is_active = "is_active",
  is_visible = "is_visible",
  name = "name",
  updated_at = "updated_at",
}

export enum ShippingMethodType {
  foxpost = "foxpost",
  gls_delivery = "gls_delivery",
  gls_pickup = "gls_pickup",
  mpl_delivery = "mpl_delivery",
  mpl_pickup = "mpl_pickup",
  no_delivery = "no_delivery",
  rossmann_delivery = "rossmann_delivery",
  rossmann_shop = "rossmann_shop",
  sameday_easybox = "sameday_easybox",
}

export enum SuccessStorySortField {
  created_at = "created_at",
  id = "id",
  updated_at = "updated_at",
}

export enum TodoSortField {
  assigned_to_name = "assigned_to_name",
  created_at = "created_at",
  id = "id",
  role_name = "role_name",
  status = "status",
  subject = "subject",
  updated_at = "updated_at",
}

export enum TodoStatus {
  done = "done",
  todo = "todo",
}

export enum UserSortField {
  card_number = "card_number",
  created_at = "created_at",
  deleted_at = "deleted_at",
  email = "email",
  id = "id",
  is_active = "is_active",
  last_login = "last_login",
  name = "name",
  phone_number_full = "phone_number_full",
  updated_at = "updated_at",
  zip_code = "zip_code",
}

export enum WebsiteNotificationSortField {
  active_from = "active_from",
  active_to = "active_to",
  created_at = "created_at",
  id = "id",
  name = "name",
  target = "target",
  updated_at = "updated_at",
}

export interface AdminFilters {
  id?: number | null;
  name?: string | null;
  email?: string | null;
  login?: string | null;
  phone?: string | null;
  is_active?: boolean | null;
  last_login?: DateRangeFilter | null;
  roles?: string[] | null;
}

export interface AdminInput {
  name: string;
  email: string;
  login?: string | null;
  phone?: string | null;
  is_active: boolean;
  roles: string[];
  store_id?: number | null;
}

export interface AdminSort {
  field: AdminSortField;
  direction: OrderDirection;
}

export interface BadgeFilters {
  id?: number | null;
  name?: string | null;
  type?: BadgeType | null;
  product_param_id?: string | null;
  is_active?: boolean | null;
  is_visible?: boolean | null;
  active_from?: DateRangeFilter | null;
  active_to?: DateRangeFilter | null;
  created_at?: DateRangeFilter | null;
  updated_at?: DateRangeFilter | null;
}

export interface BadgeInput {
  name: string;
  type: BadgeType;
  image?: string | null;
  image_title?: string | null;
  info?: string | null;
  product_param_id?: string | null;
  clpe_promotion_type?: ClpePromotionType | null;
  is_active: boolean;
  active_from?: any | null;
  active_to?: any | null;
}

export interface BadgeSort {
  field: BadgeSortField;
  direction: OrderDirection;
}

export interface BannerFilters {
  id?: number | null;
  type?: BannerType | null;
  name?: string | null;
  title?: string | null;
  target_url?: string | null;
  is_active?: boolean | null;
  active_from?: DateRangeFilter | null;
  active_to?: DateRangeFilter | null;
  created_at?: DateRangeFilter | null;
  updated_at?: DateRangeFilter | null;
}

export interface BannerInput {
  name: string;
  title?: string | null;
  slider_title?: string | null;
  type: BannerType;
  image?: string | null;
  image_align?: BannerAlign | null;
  image_title?: string | null;
  image_mobile?: string | null;
  image_mobile_title?: string | null;
  button_title?: string | null;
  target_url?: string | null;
  target_popup?: boolean | null;
  urls: string[];
  is_active: boolean;
  active_from?: any | null;
  active_to?: any | null;
  product_id?: number | null;
  position?: number | null;
}

export interface BannerSort {
  field: BannerSortField;
  direction: OrderDirection;
}

export interface BenefitFilters {
  id?: string[] | null;
  title?: string | null;
  created_at?: DateRangeFilter | null;
  updated_at?: DateRangeFilter | null;
}

export interface BenefitInput {
  title: string;
  image: string;
}

export interface BenefitRelationInput {
  id: string;
  order: number;
}

export interface BenefitSort {
  field: BenefitSortField;
  direction: OrderDirection;
}

export interface CalendarDayFilters {
  date?: DateRangeFilter | null;
  is_workday?: boolean | null;
  is_delivery?: boolean | null;
}

export interface CalendarDayInput {
  is_workday: boolean;
  is_delivery: boolean;
}

export interface CalendarDaySort {
  field: CalendarDaySortField;
  direction: OrderDirection;
}

export interface CampaignFilters {
  id?: number | null;
  name?: string | null;
  is_active?: boolean | null;
  is_visible?: boolean | null;
  active_from?: DateRangeFilter | null;
  active_to?: DateRangeFilter | null;
  created_at?: DateRangeFilter | null;
  updated_at?: DateRangeFilter | null;
}

export interface CampaignInput {
  name: string;
  description: string;
  target_url?: string | null;
  target_title?: string | null;
  image_foreground_desktop?: string | null;
  image_background_desktop?: string | null;
  image_foreground_mobile?: string | null;
  image_background_mobile?: string | null;
  is_active: boolean;
  active_from?: any | null;
  active_to?: any | null;
}

export interface CampaignSort {
  field: CampaignSortField;
  direction: OrderDirection;
}

export interface ContentFilters {
  search?: string | null;
  id?: string | null;
  title?: string | null;
  is_visible?: boolean | null;
  is_active?: boolean | null;
  url?: string | null;
  type?: ContentType | null;
  category_id?: string | null;
}

export interface ContentOrder {
  field: ContentField;
  direction: OrderDirection;
}

export interface CreateCategoryInput {
  url: string;
  title: string;
  lead?: string | null;
  meta_title?: string | null;
  meta_keywords?: string | null;
  meta_description?: string | null;
  site_id?: string | null;
}

export interface CreateContentInput {
  type: ContentType;
  url: string;
  video_url?: string | null;
  title: string;
  lead?: string | null;
  lead_image?: string | null;
  meta_image?: string | null;
  content?: string | null;
  meta_title?: string | null;
  meta_keywords?: string | null;
  meta_description?: string | null;
  author?: string | null;
  is_active: boolean;
  active_from?: any | null;
  active_to?: any | null;
  category_id?: string | null;
  gallery_id?: string | null;
  site_id?: string | null;
}

export interface CreateGalleryInput {
  handle?: string | null;
  title: string;
  images: GalleryImageInput[];
}

export interface DateRangeFilter {
  from?: any | null;
  to?: any | null;
}

export interface DeliveryInformationInput {
  info: string;
  active_from?: any | null;
  active_to?: any | null;
}

export interface DepartmentFilters {
  id?: string[] | null;
  name?: string | null;
  is_grouped?: boolean | null;
  order?: number | null;
  created_at?: DateRangeFilter | null;
  updated_at?: DateRangeFilter | null;
}

export interface DepartmentInput {
  name: string;
  is_grouped: boolean;
  is_filter_enabled?: boolean | null;
  is_map_enabled?: boolean | null;
  order?: number | null;
  benefits?: BenefitRelationInput[] | null;
}

export interface DepartmentSort {
  field: DepartmentSortField;
  direction: OrderDirection;
}

export interface DivisionFilters {
  id?: string[] | null;
  department_id?: string[] | null;
  name?: string | null;
  description?: string | null;
  quote?: string | null;
  order?: number | null;
  created_at?: DateRangeFilter | null;
  updated_at?: DateRangeFilter | null;
}

export interface DivisionInput {
  department_id?: string | null;
  name: string;
  description: string;
  quote: string;
  is_virtual?: boolean | null;
  order?: number | null;
  leaders?: PersonRelationInput[] | null;
  benefits?: BenefitRelationInput[] | null;
}

export interface DivisionSort {
  field: DivisionSortField;
  direction: OrderDirection;
}

export interface DocumentOrder {
  field: DocumentField;
  direction: OrderDirection;
}

export interface FulfillmentFilters {
  id?: number | null;
  type?: FulfillmentTypeSelectable | null;
  weight?: RangeFilter | null;
  is_active?: boolean | null;
  active_from?: DateRangeFilter | null;
  active_to?: DateRangeFilter | null;
  limit?: RangeFilter | null;
  timewindow_allowed?: boolean | null;
  shipping_methods?: ShippingMethodType[] | null;
  vip_levels?: RossmannPlusVipLevel[] | null;
  created_at?: DateRangeFilter | null;
  updated_at?: DateRangeFilter | null;
}

export interface FulfillmentInput {
  type: FulfillmentTypeSelectable;
  weight: number;
  is_active: boolean;
  active_from: any;
  active_to: any;
  limit: number;
  shipping_methods: ShippingMethodType[];
  timewindow_allowed: boolean;
  notification_threshold: number;
  vip_levels: RossmannPlusVipLevel[];
  quantity_max?: number | null;
  package_type_max?: PackageType | null;
  product_id_force?: number[] | null;
  product_id_exclude?: number[] | null;
}

export interface FulfillmentSort {
  field: FulfillmentSortField;
  direction: OrderDirection;
}

export interface GalleryFilters {
  handle?: string | null;
  id?: string | null;
  title?: string | null;
}

export interface GalleryImageInput {
  url: string;
  caption?: string | null;
  credit?: string | null;
}

export interface GalleryOrder {
  field: GalleryField;
  direction: OrderDirection;
}

export interface GiftCardCompanyInput {
  value: number;
  quantity?: number | null;
}

export interface GiftCardOrderCompanyInput {
  design: number;
  type: GiftCardOrderType;
  name_from: string;
  message?: string | null;
  name_to?: string | null;
  mail_to: string;
  gift_cards: GiftCardCompanyInput[];
  payment_reference: string;
}

export interface GiftCardOrderFilters {
  id?: number | null;
  type?: GiftCardOrderType | null;
  user_id?: number | null;
  user_fullname?: string | null;
  user_email?: string | null;
  payment_method_id?: string | null;
  payment_status_id?: PaymentStatusEnum | null;
  status?: OrderStatusEnum | null;
  grand_total?: RangeFilter | null;
  created_at?: DateRangeFilter | null;
  updated_at?: DateRangeFilter | null;
  ip?: string | null;
  user_agent_type?: string | null;
  billing_fullname?: string | null;
}

export interface GiftCardOrderPrizeInput {
  design: number;
  type: GiftCardOrderType;
  name_from: string;
  message?: string | null;
  gift_cards: GiftCardPrizeInput[];
  payment_reference: string;
}

export interface GiftCardOrderSort {
  field: GiftCardOrderSortField;
  direction: OrderDirection;
}

export interface GiftCardPrizeInput {
  value: number;
  name_to?: string | null;
  mail_to: string[];
}

export interface InvoiceCorrectionItemInput {
  id: number;
  enabled: boolean;
  quantity: number;
}

export interface LogFilters {
  action?: LogActionType | null;
  object_type?: ModelType | null;
  model_id?: number | null;
  parent_type?: ModelType | null;
  parent_id?: number | null;
  increment_id?: number | null;
  ean?: string | null;
  admin_id?: number | null;
  timestamp?: DateRangeFilter | null;
}

export interface LogSort {
  field: LogSortField;
  direction: OrderDirection;
}

export interface MailTemplateFilters {
  id?: number | null;
  subject?: string | null;
  description?: string | null;
  is_active?: boolean | null;
}

export interface MailTemplateOrder {
  field: MailTemplateOrderField;
  direction: OrderDirection;
}

export interface MaintenanceFilters {
  id?: string[] | null;
  is_active?: boolean | null;
  scopes?: MaintenanceScope[] | null;
  notification?: string | null;
  maintenance?: boolean | null;
}

export interface MaintenanceInput {
  active_from: any;
  active_to: any;
  scopes?: MaintenanceScope[] | null;
  notification?: string | null;
  maintenance?: boolean | null;
}

export interface MenuFilters {
  id?: number | null;
  slug?: string | null;
  is_visible?: boolean | null;
  created_at?: DateRangeFilter | null;
}

export interface MenuInput {
  slug: string;
  name: string;
  items: MenuItemInput[];
  is_active: boolean;
  active_from?: any | null;
  active_to?: any | null;
}

export interface MenuItemInput {
  type: MenuItemTypeEnum;
  model_id?: number | null;
  title?: string | null;
  url?: string | null;
  is_active: boolean;
  popup?: boolean | null;
  page_id_overlay?: number | null;
  items?: MenuItemInput[] | null;
}

export interface MenuSort {
  field: MenuSortField;
  direction: OrderDirection;
}

export interface OrderFilters {
  id?: number | null;
  increment_id?: number | null;
  user_id?: number | null;
  user_fullname?: string | null;
  user_email?: string | null;
  shipping_method_id?: string | null;
  pickup_point_id?: number | null;
  pickup_point_name?: string | null;
  pickup_point_address?: string | null;
  payment_method_id?: string | null;
  payment_status_id?: PaymentStatusEnum | null;
  status?: OrderStatusEnum | null;
  grand_total?: RangeFilter | null;
  created_at?: DateRangeFilter | null;
  updated_at?: DateRangeFilter | null;
  ip?: string | null;
  user_agent_type?: string | null;
  billing_fullname?: string | null;
  shipping_fullname?: string | null;
  card_number?: string | null;
  shipment_statuses?: (ShipmentStatusEnum | null)[] | null;
  delivery_day_expected?: DateRangeFilter | null;
  time_window_date?: DateRangeFilter | null;
  time_window_interval?: string | null;
  time_window_interval_length?: number | null;
  is_in_store?: boolean | null;
  fulfillment?: FulfillmentTypeEnum | null;
  store_admin_is_took_over?: boolean | null;
  is_closed?: boolean | null;
  delivered_at?: DateRangeFilter | null;
  order_subscription_id?: number | null;
  is_subscription?: boolean | null;
}

export interface OrderInput {
  admin_note?: string | null;
  bank_transfer_status?: BankTransferStatus | null;
  bank_transfer_paid?: number | null;
}

export interface OrderItemInput {
  id: number;
  quantity: number;
  unit_price: number;
}

export interface OrderModifyInput {
  shipping_cost: number;
  items: OrderItemInput[];
}

export interface OrderRatingFilters {
  id?: number | null;
  order_increment_id?: number | null;
  rating_purchase?: RangeFilter | null;
  rating_courier?: RangeFilter | null;
  rating_recommend?: RangeFilter | null;
  created_at?: DateRangeFilter | null;
  updated_at?: DateRangeFilter | null;
}

export interface OrderRatingSort {
  field: OrderRatingSortField;
  direction: OrderDirection;
}

export interface OrderSort {
  field: OrderSortField;
  direction: OrderDirection;
}

export interface OrderSubscriptionFilters {
  id?: number | null;
  user_id?: number | null;
  user_fullname?: string | null;
  user_email?: string | null;
  billing_fullname?: string | null;
  shipping_fullname?: string | null;
  payment_provider?: string | null;
  created_at?: DateRangeFilter | null;
  updated_at?: DateRangeFilter | null;
  paused_at?: DateRangeFilter | null;
  paused_until?: DateRangeFilter | null;
  deleted_at?: DateRangeFilter | null;
  notified_at?: DateRangeFilter | null;
  notified_at_sms?: DateRangeFilter | null;
  time_window_date?: DateRangeFilter | null;
  time_window_interval?: string | null;
  time_window_changed?: number | null;
  shipping_method_name?: string | null;
}

export interface OrderSubscriptionSort {
  field: OrderSubscriptionSortField;
  direction: OrderDirection;
}

export interface PageBlockInput {
  id?: number | null;
  delete?: boolean | null;
  title?: string | null;
  type: PageBlockType;
  banner_ids?: number[] | null;
  product_list_id?: number | null;
  content?: string | null;
  layout?: PageBlockBannerLayout | null;
  product_limit?: number | null;
  is_scrollable?: boolean | null;
  is_filterable?: boolean | null;
  product_sort_by?: ProductSortFrontendInput | null;
}

export interface PageFilters {
  id?: number | null;
  name?: string | null;
  url?: string | null;
  is_active?: boolean | null;
  is_visible?: boolean | null;
  active_from?: DateRangeFilter | null;
  active_to?: DateRangeFilter | null;
  created_at?: DateRangeFilter | null;
  updated_at?: DateRangeFilter | null;
  site_id?: string | null;
}

export interface PageInput {
  name: string;
  title?: string | null;
  url: string;
  is_active: boolean;
  active_from?: any | null;
  active_to?: any | null;
  meta_title?: string | null;
  meta_description?: string | null;
  og_title?: string | null;
  og_description?: string | null;
  og_image?: string | null;
  is_header: boolean;
  is_footer: boolean;
  blocks: PageBlockInput[];
  site_id: string;
}

export interface PageSort {
  field: PageSortField;
  direction: OrderDirection;
}

export interface PaymentMethodFilters {
  id?: string | null;
  name?: string | null;
  is_active?: boolean | null;
  is_visible?: boolean | null;
  created_at?: DateRangeFilter | null;
  updated_at?: DateRangeFilter | null;
}

export interface PaymentMethodInput {
  name: string;
  description?: string | null;
  cost: number;
  is_active: boolean;
  payalert?: boolean | null;
  cart_value_max?: number | null;
  active_from?: any | null;
  active_to?: any | null;
}

export interface PaymentMethodSort {
  field: PaymentMethodSortField;
  direction: OrderDirection;
}

export interface PersonFilters {
  id?: string[] | null;
  name?: string | null;
  position?: string | null;
  created_at?: DateRangeFilter | null;
  updated_at?: DateRangeFilter | null;
}

export interface PersonInput {
  name: string;
  position: string;
  image: string;
}

export interface PersonRelationInput {
  id: string;
}

export interface PersonSort {
  field: PersonSortField;
  direction: OrderDirection;
}

export interface ProductBrandFilters {
  id?: number | null;
  name?: string | null;
  slug?: string | null;
  is_logo?: boolean | null;
  is_rossmann?: boolean | null;
  is_public?: boolean | null;
  created_at?: DateRangeFilter | null;
  updated_at?: DateRangeFilter | null;
}

export interface ProductBrandInput {
  name: string;
  slug: string;
  image?: string | null;
  logo?: string | null;
  logo_title?: string | null;
  meta_title?: string | null;
  meta_description?: string | null;
  og_image?: string | null;
  og_title?: string | null;
  og_description?: string | null;
  is_rossmann: boolean;
  is_public: boolean;
}

export interface ProductBrandSort {
  field: ProductBrandSortField;
  direction: OrderDirection;
}

export interface ProductBundleInput {
  name: string;
  bundle_promotion_id: number;
  slug: string;
  product_brand_id?: number | null;
  is_active: boolean;
  is_force_active?: boolean | null;
  active_from?: any | null;
  active_to?: any | null;
  images: ProductImageInput[];
  params: ProductParamValueInput[];
}

export interface ProductCategoryBannerInput {
  is_active: boolean;
  active_from?: any | null;
  active_to?: any | null;
  image: string;
  image_title?: string | null;
  link?: string | null;
  link_new_tab: boolean;
}

export interface ProductCategoryFilters {
  id?: number | null;
  parent_id?: number | null;
  name?: string | null;
  slug?: string | null;
  is_adult?: boolean | null;
  is_active?: boolean | null;
  created_at?: DateRangeFilter | null;
  updated_at?: DateRangeFilter | null;
}

export interface ProductCategoryOrderInput {
  id: number;
  position: number;
}

export interface ProductCategorySort {
  field: ProductCategorySortField;
  direction: OrderDirection;
}

export interface ProductCategoryUpdateInput {
  parent_id?: number | null;
  name: string;
  slug: string;
  description?: string | null;
  image?: string | null;
  icon?: string | null;
  icon_title?: string | null;
  meta_description?: string | null;
  meta_title?: string | null;
  meta_keywords?: string | null;
  og_title?: string | null;
  og_description?: string | null;
  og_image?: string | null;
  banners: ProductCategoryBannerInput[];
  google_category_id?: number | null;
  is_featured: boolean;
  include_in_menu: boolean;
  position?: number | null;
  product_sort_by?: ProductSortFrontendInput | null;
  is_adult?: boolean | null;
  feed_facebook: boolean;
  feed_google: boolean;
  feed_google_merchant: boolean;
  feed_dsa: boolean;
  is_active: boolean;
  active_from?: any | null;
  active_to?: any | null;
}

export interface ProductFilterInput {
  type: ProductFilterType;
  operator?: ProductFilterOperator | null;
  product_brand_id?: number | null;
  product_category_id?: number | null;
  product_param_id?: string | null;
  clpe_promotion_id?: number | null;
  clpe_promotion_type?: ClpePromotionType | null;
  created_at?: any | null;
  rating?: number | null;
  price_type?: PriceType | null;
  product_type?: ProductType | null;
}

export interface ProductFilters {
  id?: number[] | null;
  lfdnr?: number | null;
  ean?: string | null;
  name?: string | null;
  product_brand_id?: number | null;
  product_category_id?: number[] | null;
  product_category_id_main?: number[] | null;
  price?: RangeFilter | null;
  stock_qty?: RangeFilter | null;
  is_active?: boolean | null;
  is_visible?: boolean | null;
  is_description?: boolean | null;
  is_image?: boolean | null;
  created_at?: DateRangeFilter | null;
  updated_at?: DateRangeFilter | null;
}

export interface ProductGratisInput {
  is_force_active?: boolean | null;
  active_from?: any | null;
  active_to?: any | null;
}

export interface ProductImageInput {
  is_new: boolean;
  id?: number | null;
  filename_medialib?: string | null;
  source: ProductImageSource;
  is_active: boolean;
  delete?: boolean | null;
}

export interface ProductListFilters {
  id?: number | null;
  name?: string | null;
  title?: string | null;
  slug?: string | null;
  type?: ProductListType | null;
  mode?: ProductListMode | null;
  is_active?: boolean | null;
  is_visible?: boolean | null;
  active_from?: DateRangeFilter | null;
  active_to?: DateRangeFilter | null;
  created_at?: DateRangeFilter | null;
  updated_at?: DateRangeFilter | null;
}

export interface ProductListInput {
  type: ProductListType;
  mode: ProductListMode;
  name: string;
  title: string;
  subtitle?: string | null;
  slug: string;
  is_active: boolean;
  active_from?: any | null;
  active_to?: any | null;
  description?: string | null;
  image?: string | null;
  image_title?: string | null;
  image_mobile?: string | null;
  image_mobile_title?: string | null;
  meta_description?: string | null;
  og_title?: string | null;
  og_description?: string | null;
  og_image?: string | null;
  badge_id?: number | null;
  group_by_category: boolean;
  filters: ProductFilterInput[];
  products: number[];
}

export interface ProductListSort {
  field: ProductListSortField;
  direction: OrderDirection;
}

export interface ProductParamFilters {
  id?: string | null;
  name?: string | null;
  group_name?: string | null;
  is_filter?: boolean | null;
  created_at?: DateRangeFilter | null;
  updated_at?: DateRangeFilter | null;
}

export interface ProductParamOptionUpdateInput {
  id: number;
  label?: string | null;
}

export interface ProductParamSort {
  field: ProductParamSortField;
  direction: OrderDirection;
}

export interface ProductParamUpdateInput {
  name?: string | null;
  product_param_group_id?: string | null;
  options?: ProductParamOptionUpdateInput[] | null;
}

export interface ProductParamValueInput {
  product_param_id: string;
  values: string[];
}

export interface ProductSort {
  field: ProductSortField;
  direction: OrderDirection;
}

export interface ProductSortFrontendInput {
  field: ProductSortFieldCategory;
  direction: OrderDirection;
}

export interface ProductUpdateInput {
  name: string;
  slug: string;
  cart_qty_max: number;
  order_qty_max: number;
  is_active: boolean;
  active_from?: any | null;
  active_to?: any | null;
  is_force_active?: boolean | null;
  brandbank_exclude?: boolean | null;
  image_position_360?: number | null;
  images: ProductImageInput[];
  params: ProductParamValueInput[];
}

export interface ProductVariantInput {
  name: string;
  is_active: boolean;
  is_force_active?: boolean | null;
  active_from?: any | null;
  active_to?: any | null;
  images?: ProductImageInput[] | null;
  children: number[];
  product_param_id: string;
}

export interface RangeFilter {
  min?: number | null;
  max?: number | null;
}

export interface RedirectionFilters {
  id?: string[] | null;
  from_site_id?: string[] | null;
  from?: string | null;
  is_regex?: boolean | null;
  to_site_id?: string[] | null;
  to?: string | null;
  forward?: boolean | null;
  is_permanent?: boolean | null;
  is_active?: boolean | null;
}

export interface RedirectionInput {
  from_site_id: number;
  from?: string | null;
  is_regex?: boolean | null;
  to_site_id?: number | null;
  to?: string | null;
  forward?: boolean | null;
  is_permanent: boolean;
  active_from?: any | null;
  active_to?: any | null;
}

export interface RedirectionOrder {
  field: RedirectionField;
  direction: OrderDirection;
}

export interface RoleFilters {
  id?: number | null;
  name?: string | null;
  title?: string | null;
}

export interface RoleInput {
  title: string;
  permissions: PermissionType[];
}

export interface RoleSort {
  field: RoleSortField;
  direction: OrderDirection;
}

export interface SettingFilters {
  id?: string | null;
  type?: SettingType | null;
}

export interface SettingInput {
  value: string[];
  description?: string | null;
}

export interface SettingSort {
  field: SettingSortField;
  direction: OrderDirection;
}

export interface ShippingCostInput {
  cart_limit: number;
  cost: number;
}

export interface ShippingMethodFilters {
  id?: string | null;
  name?: string | null;
  is_active?: boolean | null;
  is_visible?: boolean | null;
  created_at?: DateRangeFilter | null;
  updated_at?: DateRangeFilter | null;
}

export interface ShippingMethodInput {
  name: string;
  lead?: string | null;
  description?: string | null;
  weight_min?: number | null;
  weight_max?: number | null;
  cart_value_min?: number | null;
  order_max?: number | null;
  cost: ShippingCostInput[];
  cost_time_windows?: TimeWindowCostInput[] | null;
  cost_terminal?: ShippingCostInput[] | null;
  delivery_days?: number | null;
  time_limit_next_day_departure?: string | null;
  is_active: boolean;
  active_from?: any | null;
  active_to?: any | null;
  delivery_information?: string | null;
  delivery_informations?: DeliveryInformationInput[] | null;
  roles?: ShippingMethodRoleInput[] | null;
}

export interface ShippingMethodRoleInput {
  active_from?: any | null;
  active_to?: any | null;
  vip_levels?: RossmannPlusVipLevel[] | null;
}

export interface ShippingMethodSort {
  field: ShippingMethodSortField;
  direction: OrderDirection;
}

export interface SuccessStoryFilters {
  id?: string[] | null;
  division_id?: string[] | null;
  person_id?: string[] | null;
  content?: string | null;
  created_at?: DateRangeFilter | null;
  updated_at?: DateRangeFilter | null;
}

export interface SuccessStoryInput {
  division_id: string;
  person_id: string;
  content: string;
}

export interface SuccessStorySort {
  field: SuccessStorySortField;
  direction: OrderDirection;
}

export interface TestMailTemplateInput {
  subject?: string | null;
  content?: string | null;
}

export interface TimeWindowCostInput {
  interval_hours: number;
  cost: ShippingCostInput[];
}

export interface TodoFilters {
  id?: number | null;
  status?: TodoStatus | null;
  role_name?: string | null;
  subject?: string | null;
  message?: string | null;
  created_at?: DateRangeFilter | null;
  updated_at?: DateRangeFilter | null;
  assigned_to_name?: string | null;
}

export interface TodoInput {
  status: TodoStatus;
  assigned_to?: number | null;
  subject: string;
  message: string;
}

export interface TodoSort {
  field: TodoSortField;
  direction: OrderDirection;
}

export interface UpdateAdminMeDataInput {
  name?: string | null;
  email?: string | null;
  phone?: string | null;
  password?: string | null;
  password_new?: string | null;
  password_new_confirmation?: string | null;
}

export interface UpdateCategoryInput {
  title?: string | null;
  lead?: string | null;
  meta_title?: string | null;
  meta_keywords?: string | null;
  meta_description?: string | null;
  site_id?: string | null;
}

export interface UpdateContentInput {
  type?: ContentType | null;
  title?: string | null;
  video_url?: string | null;
  lead?: string | null;
  lead_image?: string | null;
  meta_image?: string | null;
  content?: string | null;
  meta_title?: string | null;
  meta_keywords?: string | null;
  meta_description?: string | null;
  author?: string | null;
  is_active?: boolean | null;
  active_from?: any | null;
  active_to?: any | null;
  category_id?: string | null;
  gallery_id?: string | null;
  site_id?: string | null;
}

export interface UpdateGalleryInput {
  title: string;
  images?: GalleryImageInput[] | null;
}

export interface UpdateMailTemplateInput {
  subject: string;
  content: string;
  description?: string | null;
  is_active: boolean;
}

export interface UserFilters {
  id?: number | null;
  magento_id?: number | null;
  name?: string | null;
  email?: string | null;
  phone_number_full?: string | null;
  zip_code?: string | null;
  is_active?: boolean | null;
  created_at?: DateRangeFilter | null;
  is_deleted?: boolean | null;
  deleted_at?: DateRangeFilter | null;
  card_number?: string | null;
  promo_coupons?: string | null;
  reg_source?: string | null;
}

export interface UserInput {
  firstname: string;
  lastname: string;
  email: string;
  is_active: boolean;
  newsletter: boolean;
  banktransfer_enabled: boolean;
  cod_disabled: boolean;
}

export interface UserOrder {
  field: UserSortField;
  direction: OrderDirection;
}

export interface WebsiteNotificationFilters {
  id?: number | null;
  name?: string | null;
  target?: ClientType | null;
  active_from?: DateRangeFilter | null;
  active_to?: DateRangeFilter | null;
}

export interface WebsiteNotificationInput {
  name: string;
  title?: string | null;
  message: string;
  target?: ClientType[] | null;
  is_active: boolean;
  active_from?: any | null;
  active_to?: any | null;
}

export interface WebsiteNotificationSort {
  field: WebsiteNotificationSortField;
  direction: OrderDirection;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
