import { I18n } from "I18n/I18n";
import { ApiError } from "./ApiError";
import { GraphQLClient, OnProgress } from "./graphql/GraphQLClient";
import { Mutations } from "./graphql/mutations";
import { Queries } from "./graphql/queries";
import * as AuthGqlTypes from "./graphql/auth/types";
import * as AdminGqlTypes from "./graphql/admin/types";

export class Api {
    public static async getCsrfToken(mutation: string): Promise<string> {
        const result = await GraphQLClient.query<AuthGqlTypes.getCsrfToken, AuthGqlTypes.getCsrfTokenVariables>({
            query: Queries.getCsrfToken,
            variables: { mutation },
            context: { client: "auth" },
        });
        return result.csrfToken!;
    }

    /**
     * Login admin
     * @param credentials AdminLoginDataInput
     */
    public static async adminLogin(credentials: AuthGqlTypes.Login): Promise<AuthGqlTypes.adminLogin_login> {
        const result = await GraphQLClient.mutate<AuthGqlTypes.adminLogin, AuthGqlTypes.adminLoginVariables>({
            mutation: Mutations.adminLogin,
            variables: { credentials },
            context: { client: "auth" },
        });

        return result.login;
    }

    /**
     * Start a password reset flow
     * @param email string
     */
    public static async adminPasswordReset(email: string): Promise<boolean> {
        const result = await GraphQLClient.mutate<AuthGqlTypes.adminPasswordReset, AuthGqlTypes.adminPasswordResetVariables>({
            mutation: Mutations.adminPasswordReset,
            variables: { email },
            context: { client: "auth" },
        });
        return result.passwordReset;
    }

    /**
     * Finish the password reset flow
     * @param input SetAdminPasswordInput
     */
    public static async resetAdminPassword(input: AuthGqlTypes.SetAdminPasswordInput): Promise<AuthGqlTypes.resetAdminPassword_resetPassword> {
        const result = await GraphQLClient.mutate<AuthGqlTypes.resetAdminPassword, AuthGqlTypes.resetAdminPasswordVariables>({
            mutation: Mutations.resetAdminPassword,
            variables: { input },
            context: { client: "auth" },
        });
        return result.resetPassword;
    }

    /**
     * Get admin data
     */
    public static async adminMe(): Promise<AdminGqlTypes.Admin> {
        const result = await GraphQLClient.query<AdminGqlTypes.adminMe>({ query: Queries.adminMe });
        return result.adminMe;
    }

    /**
     * Update admin
     */
    public static async updateAdminMe(input: AdminGqlTypes.UpdateAdminMeDataInput): Promise<AdminGqlTypes.Admin> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.updateAdminMe, AdminGqlTypes.updateAdminMeVariables>({
            mutation: Mutations.updateAdminMe,
            variables: { input },
        });
        return result.updateAdminMe;
    }

    public static async adminLogout(): Promise<void> {
        await GraphQLClient.mutate<AuthGqlTypes.adminLogout>({ mutation: Mutations.adminLogout, context: { client: "auth" } });
    }

    public static async listAdmins(variables: AdminGqlTypes.listAdminsVariables): Promise<AdminGqlTypes.listAdmins_listAdmins> {
        const result = await GraphQLClient.query<AdminGqlTypes.listAdmins, AdminGqlTypes.listAdminsVariables>({
            query: Queries.listAdmins,
            variables,
        });
        return result.listAdmins!;
    }

    public static async getAdmin(id: number): Promise<AdminGqlTypes.Admin> {
        const result = await GraphQLClient.query<AdminGqlTypes.getAdmin, AdminGqlTypes.getAdminVariables>({ query: Queries.getAdmin, variables: { id } });
        const admin = result.listAdmins?.data[0];
        if (!admin) {
            throw new ApiError(I18n.formatMessage({ id: "api.error.notFound" }));
        }
        return admin;
    }

    public static async createAdmin(input: AdminGqlTypes.AdminInput): Promise<number> {
        const csrf = await this.getCsrfToken("createAdmin");

        const result = await GraphQLClient.mutate<AdminGqlTypes.createAdmin, AdminGqlTypes.createAdminVariables>({
            mutation: Mutations.createAdmin,
            variables: { input, csrf },
        });
        return result.createAdmin.id;
    }

    public static async updateAdmin(id: number, input: AdminGqlTypes.AdminInput): Promise<AdminGqlTypes.Admin> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.updateAdmin, AdminGqlTypes.updateAdminVariables>({
            mutation: Mutations.updateAdmin,
            variables: { id, input },
        });
        return result.updateAdmin;
    }

    /**
     * List contents for admin user list
     * @param params Object
     */
    public static async adminContents(params: {
        first: number;
        filters?: AdminGqlTypes.ContentFilters;
        page?: number;
        sortBy?: AdminGqlTypes.ContentOrder;
    }): Promise<AdminGqlTypes.adminContents_adminContents> {
        const result = await GraphQLClient.query<AdminGqlTypes.adminContents, AdminGqlTypes.adminContentsVariables>({
            query: Queries.adminContents,
            variables: { ...params },
        });

        return {
            ...result.adminContents!,
            data: result.adminContents!.data.filter((content: AdminGqlTypes.adminContents_adminContents_data): content is AdminGqlTypes.TextContentListItem => {
                return content.__typename === "TextContent";
            }),
        };
    }

    public static async listRoles(variables: AdminGqlTypes.listRolesVariables): Promise<AdminGqlTypes.listRoles_listRoles> {
        const result = await GraphQLClient.query<AdminGqlTypes.listRoles, AdminGqlTypes.listRolesVariables>({
            query: Queries.listRoles,
            variables,
        });
        return result.listRoles!;
    }

    public static async getContent(id: string): Promise<AdminGqlTypes.TextContent> {
        const result = await GraphQLClient.query<AdminGqlTypes.getAdminContent, AdminGqlTypes.getAdminContentVariables>({
            query: Queries.getAdminContent,
            variables: { id },
        });

        const content = result.adminContents?.data[0];
        if (!content) {
            throw new ApiError(I18n.formatMessage({ id: "api.error.notFound" }));
        }
        if (content.__typename !== "TextContent") {
            console.warn("content type is not TextContent...");
        }
        return content as AdminGqlTypes.TextContent;
    }

    public static async createContent(input: AdminGqlTypes.CreateContentInput): Promise<string> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.createContent, AdminGqlTypes.createContentVariables>({
            mutation: Mutations.createContent,
            variables: { input },
        });

        return (result.createContent as AdminGqlTypes.TextContent).id;
    }

    public static async updateContent(id: string, input: AdminGqlTypes.UpdateContentInput): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.updateContent, AdminGqlTypes.updateContentVariables>({
            mutation: Mutations.updateContent,
            variables: { id, input },
        });
    }

    public static async getCategoriesForSelect(): Promise<AdminGqlTypes.CategorySelectItem[]> {
        const result = await GraphQLClient.query<AdminGqlTypes.getCategoriesForSelect>({ query: Queries.getCategoriesForSelect });
        return result.categories;
    }

    public static async getDocumentsForModal(): Promise<AdminGqlTypes.DocumentModalListItem[]> {
        const result = await GraphQLClient.query<AdminGqlTypes.getDocumentsForModal>({ query: Queries.getDocumentsForModal });
        return result.documents?.data || [];
    }

    public static async documents(variables: AdminGqlTypes.documentsVariables): Promise<AdminGqlTypes.documents_documents> {
        const result = await GraphQLClient.query<AdminGqlTypes.documents, AdminGqlTypes.documentsVariables>({
            query: Queries.documents,
            variables,
        });
        return result.documents!;
    }

    public static async uploadDocument(file: File, onProgress?: OnProgress): Promise<AdminGqlTypes.DocumentModalListItem> {
        const result = await GraphQLClient.upload<AdminGqlTypes.uploadDocument, AdminGqlTypes.uploadDocumentVariables>({
            mutation: Mutations.uploadDocument,
            variables: { file },
            onProgress,
            file,
        });
        return result.uploadDocument;
    }

    public static async removeDocument(id: string, force?: boolean): Promise<boolean> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.removeDocument, AdminGqlTypes.removeDocumentVariables>({
            mutation: Mutations.removeDocument,
            variables: { id, force },
        });
        return result.removeDocument;
    }

    public static async categories(): Promise<AdminGqlTypes.CategoryListItem[]> {
        const result = await GraphQLClient.query<AdminGqlTypes.categories>({ query: Queries.categories });
        return result.categories;
    }

    public static async getCategory(id: string): Promise<AdminGqlTypes.Category> {
        const result = await GraphQLClient.query<AdminGqlTypes.getCategory>({ query: Queries.getCategory });
        const category = result.categories.find(c => c.id === id);
        if (!category) {
            throw new ApiError(I18n.formatMessage({ id: "api.error.notFound" }));
        }
        return category;
    }

    public static async updateCategory(id: string, input: AdminGqlTypes.UpdateCategoryInput): Promise<AdminGqlTypes.Category> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.updateCategory, AdminGqlTypes.updateCategoryVariables>({
            mutation: Mutations.updateCategory,
            variables: {
                id,
                input,
            },
        });

        return result.updateCategory;
    }

    public static async galleries(variables: AdminGqlTypes.galleriesVariables): Promise<AdminGqlTypes.galleries_galleries> {
        const result = await GraphQLClient.query<AdminGqlTypes.galleries, AdminGqlTypes.galleriesVariables>({
            query: Queries.galleries,
            variables,
        });
        return result.galleries!;
    }

    public static async createCategory(input: AdminGqlTypes.CreateCategoryInput): Promise<string> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.createCategory, AdminGqlTypes.createCategoryVariables>({
            mutation: Mutations.createCategory,
            variables: { input },
        });

        return result.createCategory.id;
    }

    public static async createGallery(input: AdminGqlTypes.CreateGalleryInput): Promise<AdminGqlTypes.GalleryListItem> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.createGallery, AdminGqlTypes.createGalleryVariables>({
            mutation: Mutations.createGallery,
            variables: { input },
        });
        return result.createGallery;
    }

    public static async getGallery(id: string): Promise<AdminGqlTypes.Gallery> {
        const result = await GraphQLClient.query<AdminGqlTypes.getGallery, AdminGqlTypes.getGalleryVariables>({
            query: Queries.getGallery,
            variables: { id },
        });

        const gallery = result.galleries?.data[0];
        if (!gallery) {
            throw new ApiError(I18n.formatMessage({ id: "api.error.notFound" }));
        }

        return gallery;
    }

    public static async getGalleryImages(id: string): Promise<AdminGqlTypes.GalleryImage[]> {
        const result = await GraphQLClient.query<AdminGqlTypes.getGalleryImages, AdminGqlTypes.getGalleryImagesVariables>({
            query: Queries.getGalleryImages,
            variables: { id },
        });

        const gallery = result.galleries?.data[0];
        if (!gallery) {
            throw new ApiError(I18n.formatMessage({ id: "api.error.notFound" }));
        }

        return gallery.images;
    }

    public static async updateGallery(id: string, input: AdminGqlTypes.UpdateGalleryInput): Promise<AdminGqlTypes.Gallery> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.updateGallery, AdminGqlTypes.updateGalleryVariables>({
            mutation: Mutations.updateGallery,
            variables: { id, input },
        });
        return result.updateGallery;
    }

    public static async deleteGallery(id: string): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.deleteGallery, AdminGqlTypes.deleteGalleryVariables>({
            mutation: Mutations.deleteGallery,
            variables: { id },
        });
    }

    public static async attachDocument(contentId: string, documentId: string): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.attachDocument, AdminGqlTypes.attachDocumentVariables>({
            mutation: Mutations.attachDocument,
            variables: { contentId, documentId },
        });
    }

    public static async detachDocument(contentId: string, documentId: string): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.detachDocument, AdminGqlTypes.detachDocumentVariables>({
            mutation: Mutations.detachDocument,
            variables: { contentId, documentId },
        });
    }

    public static async deleteContent(id: string): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.deleteContent, AdminGqlTypes.deleteContentVariables>({
            mutation: Mutations.deleteContent,
            variables: { id },
        });
    }

    public static async listProductBrands(variables: AdminGqlTypes.listProductBrandsVariables): Promise<AdminGqlTypes.listProductBrands_listProductBrands> {
        const result = await GraphQLClient.query<AdminGqlTypes.listProductBrands, AdminGqlTypes.listProductBrandsVariables>({
            query: Queries.listProductBrands,
            variables,
        });
        return result.listProductBrands!;
    }

    public static async getProductCategoryById(id: number): Promise<AdminGqlTypes.listProductCategoriesById_listProductCategories_data> {
        const result = await GraphQLClient.query<AdminGqlTypes.listProductCategoriesById, AdminGqlTypes.listProductCategoriesByIdVariables>({
            query: Queries.listProductCategoriesById,
            variables: {
                first: 1,
                filters: {
                    id,
                },
            },
        });

        if (!result.listProductCategories?.data || !result.listProductCategories.data[0]) {
            throw new ApiError(I18n.formatMessage({ id: "api.error.notFound" }));
        }

        return result.listProductCategories.data[0];
    }

    public static async getProductCategoryChildren(parent_id: number | null): Promise<AdminGqlTypes.getProductCategoryChildren_listProductCategories_data[]> {
        const result = await GraphQLClient.query<AdminGqlTypes.getProductCategoryChildren, AdminGqlTypes.getProductCategoryChildrenVariables>({
            query: Queries.getProductCategoryChildren,
            variables: { first: 9999, filters: { parent_id }, sortBy: { field: AdminGqlTypes.ProductCategorySortField.position, direction: AdminGqlTypes.OrderDirection.ASC } },
        });

        if (!result.listProductCategories?.data) {
            throw new ApiError(I18n.formatMessage({ id: "api.error.notFound" }));
        }

        return result.listProductCategories.data;
    }

    public static async updateProductCategory(id: number, input: AdminGqlTypes.ProductCategoryUpdateInput): Promise<AdminGqlTypes.ProductCategory | null> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.updateProductCategory, AdminGqlTypes.updateProductCategoryVariables>({
            mutation: Mutations.updateProductCategory,
            variables: { id, input },
        });

        return result.updateProductCategory;
    }

    public static async getProductBrandById(id: number): Promise<AdminGqlTypes.getProductBrandById_listProductBrands_data> {
        const result = await GraphQLClient.query<AdminGqlTypes.getProductBrandById, AdminGqlTypes.getProductBrandByIdVariables>({
            query: Queries.getProductBrandById,
            variables: {
                first: 1,
                filters: {
                    id,
                },
            },
        });

        if (!result.listProductBrands?.data || !result.listProductBrands.data[0]) {
            throw new ApiError(I18n.formatMessage({ id: "api.error.notFound" }));
        }

        return result.listProductBrands.data[0];
    }

    public static async listProducts(variables: AdminGqlTypes.listProductsVariables): Promise<AdminGqlTypes.listProducts_listProducts> {
        const result = await GraphQLClient.query<AdminGqlTypes.listProducts, AdminGqlTypes.listProductsVariables>({
            query: Queries.listProducts,
            variables,
        });
        return result.listProducts!;
    }

    public static async listProductGratis(variables: AdminGqlTypes.listProductGratisVariables): Promise<AdminGqlTypes.listProductGratis_listProductGratis> {
        const result = await GraphQLClient.query<AdminGqlTypes.listProductGratis, AdminGqlTypes.listProductGratisVariables>({
            query: Queries.listProductGratis,
            variables,
        });
        return result.listProductGratis!;
    }

    public static async updateProductGratis(id: number, input: AdminGqlTypes.ProductGratisInput): Promise<AdminGqlTypes.Product | null> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.updateProductGratis, AdminGqlTypes.updateProductGratisVariables>({
            mutation: Mutations.updateProductGratis,
            variables: { id, input },
        });

        return result.updateProductGratis;
    }

    public static async getProductById(id: number): Promise<AdminGqlTypes.getProductById_product> {
        const result = await GraphQLClient.query<AdminGqlTypes.getProductById, AdminGqlTypes.getProductByIdVariables>({
            query: Queries.getProductById,
            variables: { id },
        });

        return result.product;
    }

    public static async updateProduct(id: number, input: AdminGqlTypes.ProductUpdateInput): Promise<AdminGqlTypes.Product | null> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.updateProduct, AdminGqlTypes.updateProductVariables>({
            mutation: Mutations.updateProduct,
            variables: { id, input },
        });

        return result.updateProduct;
    }

    public static async listProductParamStructures(): Promise<AdminGqlTypes.ProductParamStructure[]> {
        const result = await GraphQLClient.query<AdminGqlTypes.listProductParamStructures, AdminGqlTypes.listProductParamStructuresVariables>({
            query: Queries.listProductParamStructures,
            variables: {
                first: 9999,
            },
        });
        return result.productParamList!.data;
    }

    public static async listProductLists(variables: AdminGqlTypes.listProductListsVariables): Promise<AdminGqlTypes.listProductLists_listProductLists> {
        const result = await GraphQLClient.query<AdminGqlTypes.listProductLists, AdminGqlTypes.listProductListsVariables>({
            query: Queries.listProductLists,
            variables,
        });
        return result.listProductLists!;
    }

    public static async getProductListById(id: number): Promise<AdminGqlTypes.getProductListById_listProductLists_data> {
        const result = await GraphQLClient.query<AdminGqlTypes.getProductListById, AdminGqlTypes.getProductListByIdVariables>({
            query: Queries.getProductListById,
            variables: {
                first: 1,
                filters: {
                    id,
                },
            },
        });
        if (!result.listProductLists?.data || !result.listProductLists.data[0]) {
            throw new ApiError(I18n.formatMessage({ id: "api.error.notFound" }));
        }

        return result.listProductLists.data[0];
    }

    public static async updateProductBrand(id: number, input: AdminGqlTypes.ProductBrandInput): Promise<AdminGqlTypes.ProductBrand | null> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.updateProductBrand, AdminGqlTypes.updateProductBrandVariables>({
            mutation: Mutations.updateProductBrand,
            variables: { id, input },
        });

        return result.updateProductBrand;
    }

    public static async createRole(name: string, input: AdminGqlTypes.RoleInput): Promise<number> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.createRole, AdminGqlTypes.createRoleVariables>({
            mutation: Mutations.createRole,
            variables: { name, input },
        });
        return result.createRole!.id;
    }

    public static async getRoleById(id: number): Promise<AdminGqlTypes.getRoleById_listRoles_data> {
        const result = await GraphQLClient.query<AdminGqlTypes.getRoleById, AdminGqlTypes.getRoleByIdVariables>({
            query: Queries.getRoleById,
            variables: {
                first: 1,
                filters: {
                    id,
                },
            },
        });

        if (!result.listRoles?.data || !result.listRoles.data[0]) {
            throw new ApiError(I18n.formatMessage({ id: "api.error.notFound" }));
        }

        return result.listRoles.data[0];
    }

    public static async createProductList(input: AdminGqlTypes.ProductListInput): Promise<number> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.createProductList, AdminGqlTypes.createProductListVariables>({
            mutation: Mutations.createProductList,
            variables: { input },
        });
        return result.createProductList.id;
    }

    public static async updateProductList(id: number, input: AdminGqlTypes.ProductListInput): Promise<AdminGqlTypes.ProductList | null> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.updateProductList, AdminGqlTypes.updateProductListVariables>({
            mutation: Mutations.updateProductList,
            variables: { id, input },
        });

        return result.updateProductList;
    }

    public static async listBadges(variables: AdminGqlTypes.listBadgesVariables): Promise<AdminGqlTypes.listBadges_listBadges> {
        const result = await GraphQLClient.query<AdminGqlTypes.listBadges, AdminGqlTypes.listBadgesVariables>({
            query: Queries.listBadges,
            variables,
        });
        return result.listBadges!;
    }

    public static async getBadgeById(id: number): Promise<AdminGqlTypes.listBadgeById_listBadges_data> {
        const result = await GraphQLClient.query<AdminGqlTypes.listBadgeById, AdminGqlTypes.listBadgeByIdVariables>({
            query: Queries.listBadgeById,
            variables: {
                first: 1,
                filters: {
                    id,
                },
            },
        });

        if (!result.listBadges?.data || !result.listBadges.data[0]) {
            throw new ApiError(I18n.formatMessage({ id: "api.error.notFound" }));
        }

        return result.listBadges.data[0];
    }

    public static async createBadge(input: AdminGqlTypes.BadgeInput): Promise<number> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.createBadge, AdminGqlTypes.createBadgeVariables>({
            mutation: Mutations.createBadge,
            variables: { input },
        });
        return result.createBadge!.id;
    }

    public static async updateBadge(id: number, input: AdminGqlTypes.BadgeInput): Promise<AdminGqlTypes.Badge | null> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.updateBadge, AdminGqlTypes.updateBadgeVariables>({
            mutation: Mutations.updateBadge,
            variables: { id, input },
        });

        return result.updateBadge;
    }

    public static async listProductParamSelectItems(
        variables: AdminGqlTypes.listProductParamSelectItemsVariables
    ): Promise<AdminGqlTypes.listProductParamSelectItems_productParamList> {
        const result = await GraphQLClient.query<AdminGqlTypes.listProductParamSelectItems, AdminGqlTypes.listProductParamSelectItemsVariables>({
            query: Queries.listProductParamSelectItems,
            variables,
        });
        return result.productParamList!;
    }

    public static async updateRole(id: number, input: AdminGqlTypes.RoleInput): Promise<AdminGqlTypes.Role | null> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.updateRole, AdminGqlTypes.updateRoleVariables>({
            mutation: Mutations.updateRole,
            variables: { id, input },
        });

        return result.updateRole;
    }

    public static async listPermissions(variables: AdminGqlTypes.listPermissionsVariables): Promise<AdminGqlTypes.listPermissions_listPermissions> {
        const result = await GraphQLClient.query<AdminGqlTypes.listPermissions, AdminGqlTypes.listPermissionsVariables>({
            query: Queries.listPermissions,
            variables,
        });
        return result.listPermissions!;
    }

    public static async deleteRole(id: number): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.deleteRole, AdminGqlTypes.deleteRoleVariables>({
            mutation: Mutations.deleteRole,
            variables: { id },
        });
    }

    public static async listProductCategorySelectItems(): Promise<AdminGqlTypes.ProductCategorySelectItem[]> {
        const result = await GraphQLClient.query<AdminGqlTypes.listProductCategorySelectItems>({
            query: Queries.listProductCategorySelectItems,
        });
        return result.productCategoryTree!;
    }

    public static async listProductFilterParams(): Promise<AdminGqlTypes.listProductFilterParams_listProductFilterParams[]> {
        const result = await GraphQLClient.query<AdminGqlTypes.listProductFilterParams>({
            query: Queries.listProductFilterParams,
        });
        return result.listProductFilterParams!;
    }

    public static async listClpePromotionSelectItems(
        variables: AdminGqlTypes.listClpePromotionSelectItemsVariables
    ): Promise<AdminGqlTypes.listClpePromotionSelectItems_listPromotions> {
        const result = await GraphQLClient.query<AdminGqlTypes.listClpePromotionSelectItems>({
            query: Queries.listClpePromotionSelectItems,
            variables,
        });
        return result.listPromotions!;
    }

    public static async listProductBrandSelectItems(
        variables: AdminGqlTypes.listProductBrandSelectItemsVariables
    ): Promise<AdminGqlTypes.listProductBrandSelectItems_listProductBrands> {
        const result = await GraphQLClient.query<AdminGqlTypes.listProductBrandSelectItems, AdminGqlTypes.listProductBrandSelectItemsVariables>({
            query: Queries.listProductBrandSelectItems,
            variables,
        });
        return result.listProductBrands!;
    }

    public static async listBanners(variables: AdminGqlTypes.listBannersVariables): Promise<AdminGqlTypes.listBanners_listBanners> {
        const result = await GraphQLClient.query<AdminGqlTypes.listBanners, AdminGqlTypes.listBannersVariables>({
            query: Queries.listBanners,
            variables,
        });
        return result.listBanners!;
    }

    public static async createBanner(input: AdminGqlTypes.BannerInput): Promise<number> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.createBanner, AdminGqlTypes.createBannerVariables>({
            mutation: Mutations.createBanner,
            variables: { input },
        });
        return result.createBanner!.id;
    }

    public static async updateBanner(id: number, input: AdminGqlTypes.BannerInput): Promise<AdminGqlTypes.Banner | null> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.updateBanner, AdminGqlTypes.updateBannerVariables>({
            mutation: Mutations.updateBanner,
            variables: { id, input },
        });

        return result.updateBanner;
    }

    public static async getBannerById(id: number): Promise<AdminGqlTypes.getBannerById_listBanners_data> {
        const result = await GraphQLClient.query<AdminGqlTypes.getBannerById, AdminGqlTypes.getBannerByIdVariables>({
            query: Queries.getBannerById,
            variables: {
                first: 1,
                filters: {
                    id,
                },
            },
        });

        if (!result.listBanners?.data || !result.listBanners.data[0]) {
            throw new ApiError(I18n.formatMessage({ id: "api.error.notFound" }));
        }

        return result.listBanners.data[0];
    }

    public static async listUsers(variables: AdminGqlTypes.listUsersVariables): Promise<AdminGqlTypes.listUsers_listUsers> {
        const result = await GraphQLClient.query<AdminGqlTypes.listUsers, AdminGqlTypes.listUsersVariables>({
            query: Queries.listUsers,
            variables,
        });
        return result.listUsers!;
    }

    public static async getUserById(id: number): Promise<AdminGqlTypes.User> {
        const result = await GraphQLClient.query<AdminGqlTypes.getUserById, AdminGqlTypes.getUserByIdVariables>({
            query: Queries.getUserById,
            variables: { id },
        });
        const discount = result.getUser;
        if (!discount) {
            throw new ApiError(I18n.formatMessage({ id: "api.error.notFound" }));
        }
        return discount;
    }

    public static async updateUser(
        id: number,
        input: AdminGqlTypes.UserInput,
        remove_rplus_card: boolean | null,
        replacement_rplus_card: string | null
    ): Promise<AdminGqlTypes.User | null> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.updateUser, AdminGqlTypes.updateUserVariables>({
            mutation: Mutations.updateUser,
            variables: { id, input, remove_rplus_card, replacement_rplus_card },
        });

        return result.updateUser;
    }

    public static async listUserOrders(variables: AdminGqlTypes.listUserOrdersVariables): Promise<AdminGqlTypes.listUserOrders_listOrders> {
        const result = await GraphQLClient.query<AdminGqlTypes.listUserOrders, AdminGqlTypes.listUserOrdersVariables>({
            query: Queries.listUserOrders,
            variables,
        });
        return result.listOrders!;
    }

    public static async persons(variables: AdminGqlTypes.personsVariables): Promise<AdminGqlTypes.persons_persons> {
        const result = await GraphQLClient.query<AdminGqlTypes.persons, AdminGqlTypes.personsVariables>({
            query: Queries.persons,
            variables,
        });

        return result.persons!;
    }

    public static async getPerson(id: string): Promise<AdminGqlTypes.Person> {
        const result = await GraphQLClient.query<AdminGqlTypes.getPerson, AdminGqlTypes.getPersonVariables>({
            query: Queries.getPerson,
            variables: { id },
        });

        if (!result.person) {
            throw new ApiError(I18n.formatMessage({ id: "api.error.notFound" }));
        }
        return result.person;
    }

    public static async createPerson(input: AdminGqlTypes.PersonInput): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.createPerson, AdminGqlTypes.createPersonVariables>({
            mutation: Mutations.createPerson,
            variables: { input },
        });
    }

    public static async updatePerson(id: string, input: AdminGqlTypes.PersonInput): Promise<AdminGqlTypes.Person> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.updatePerson, AdminGqlTypes.updatePersonVariables>({
            mutation: Mutations.updatePerson,
            variables: { id, input },
        });

        return result.updatePerson;
    }

    public static async deletePerson(id: string): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.deletePerson, AdminGqlTypes.deletePersonVariables>({
            mutation: Mutations.deletePerson,
            variables: { id },
        });
    }

    public static async benefits(variables: AdminGqlTypes.benefitsVariables): Promise<AdminGqlTypes.benefits_benefits> {
        const result = await GraphQLClient.query<AdminGqlTypes.benefits, AdminGqlTypes.benefitsVariables>({
            query: Queries.benefits,
            variables,
        });

        return result.benefits!;
    }

    public static async getBenefit(id: string): Promise<AdminGqlTypes.Benefit> {
        const result = await GraphQLClient.query<AdminGqlTypes.getBenefit, AdminGqlTypes.getBenefitVariables>({
            query: Queries.getBenefit,
            variables: { id },
        });

        if (!result.benefit) {
            throw new ApiError(I18n.formatMessage({ id: "api.error.notFound" }));
        }
        return result.benefit;
    }

    public static async createBenefit(input: AdminGqlTypes.BenefitInput): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.createBenefit, AdminGqlTypes.createBenefitVariables>({
            mutation: Mutations.createBenefit,
            variables: { input },
        });
    }

    public static async updateBenefit(id: string, input: AdminGqlTypes.BenefitInput): Promise<AdminGqlTypes.Benefit> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.updateBenefit, AdminGqlTypes.updateBenefitVariables>({
            mutation: Mutations.updateBenefit,
            variables: { id, input },
        });

        return result.updateBenefit;
    }

    public static async deleteBenefit(id: string): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.deleteBenefit, AdminGqlTypes.deleteBenefitVariables>({
            mutation: Mutations.deleteBenefit,
            variables: { id },
        });
    }

    public static async departments(variables: AdminGqlTypes.departmentsVariables): Promise<AdminGqlTypes.departments_departments> {
        const result = await GraphQLClient.query<AdminGqlTypes.departments, AdminGqlTypes.departmentsVariables>({
            query: Queries.departments,
            variables,
        });

        return result.departments!;
    }

    public static async getDepartment(id: string): Promise<AdminGqlTypes.Department> {
        const result = await GraphQLClient.query<AdminGqlTypes.getDepartment, AdminGqlTypes.getDepartmentVariables>({
            query: Queries.getDepartment,
            variables: { id },
        });

        if (!result.department) {
            throw new ApiError(I18n.formatMessage({ id: "api.error.notFound" }));
        }
        return result.department;
    }

    public static async createDepartment(input: AdminGqlTypes.DepartmentInput): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.createDepartment, AdminGqlTypes.createDepartmentVariables>({
            mutation: Mutations.createDepartment,
            variables: { input },
        });
    }

    public static async updateDepartment(id: string, input: AdminGqlTypes.DepartmentInput): Promise<AdminGqlTypes.Department> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.updateDepartment, AdminGqlTypes.updateDepartmentVariables>({
            mutation: Mutations.updateDepartment,
            variables: { id, input },
        });

        return result.updateDepartment;
    }

    public static async deleteDepartment(id: string): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.deleteDepartment, AdminGqlTypes.deleteDepartmentVariables>({
            mutation: Mutations.deleteDepartment,
            variables: { id },
        });
    }

    public static async divisions(variables: AdminGqlTypes.divisionsVariables): Promise<AdminGqlTypes.divisions_divisions> {
        const result = await GraphQLClient.query<AdminGqlTypes.divisions, AdminGqlTypes.divisionsVariables>({
            query: Queries.divisions,
            variables,
        });

        return result.divisions!;
    }

    public static async getDivision(id: string): Promise<AdminGqlTypes.Division> {
        const result = await GraphQLClient.query<AdminGqlTypes.getDivision, AdminGqlTypes.getDivisionVariables>({
            query: Queries.getDivision,
            variables: { id },
        });

        if (!result.division) {
            throw new ApiError(I18n.formatMessage({ id: "api.error.notFound" }));
        }
        return result.division;
    }

    public static async createDivision(input: AdminGqlTypes.DivisionInput): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.createDivision, AdminGqlTypes.createDivisionVariables>({
            mutation: Mutations.createDivision,
            variables: { input },
        });
    }

    public static async updateDivision(id: string, input: AdminGqlTypes.DivisionInput): Promise<AdminGqlTypes.Division> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.updateDivision, AdminGqlTypes.updateDivisionVariables>({
            mutation: Mutations.updateDivision,
            variables: { id, input },
        });

        return result.updateDivision;
    }

    public static async deleteDivision(id: string): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.deleteDivision, AdminGqlTypes.deleteDivisionVariables>({
            mutation: Mutations.deleteDivision,
            variables: { id },
        });
    }

    public static async successStories(variables: AdminGqlTypes.successStoriesVariables): Promise<AdminGqlTypes.successStories_successStories> {
        const result = await GraphQLClient.query<AdminGqlTypes.successStories, AdminGqlTypes.successStoriesVariables>({
            query: Queries.successStories,
            variables,
        });

        return result.successStories!;
    }

    public static async getSuccessStory(id: string): Promise<AdminGqlTypes.SuccessStory> {
        const result = await GraphQLClient.query<AdminGqlTypes.getSuccessStory, AdminGqlTypes.getSuccessStoryVariables>({
            query: Queries.getSuccessStory,
            variables: { id },
        });

        if (!result.successStory) {
            throw new ApiError(I18n.formatMessage({ id: "api.error.notFound" }));
        }
        return result.successStory;
    }

    public static async createSuccessStory(input: AdminGqlTypes.SuccessStoryInput): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.createSuccessStory, AdminGqlTypes.createSuccessStoryVariables>({
            mutation: Mutations.createSuccessStory,
            variables: { input },
        });
    }

    public static async updateSuccessStory(id: string, input: AdminGqlTypes.SuccessStoryInput): Promise<AdminGqlTypes.SuccessStory> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.updateSuccessStory, AdminGqlTypes.updateSuccessStoryVariables>({
            mutation: Mutations.updateSuccessStory,
            variables: { id, input },
        });

        return result.updateSuccessStory;
    }

    public static async deleteSuccessStory(id: string): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.deleteSuccessStory, AdminGqlTypes.deleteSuccessStoryVariables>({
            mutation: Mutations.deleteSuccessStory,
            variables: { id },
        });
    }

    public static async redirections(variables: AdminGqlTypes.redirectionsVariables): Promise<AdminGqlTypes.redirections_redirections> {
        const result = await GraphQLClient.query<AdminGqlTypes.redirections, AdminGqlTypes.redirectionsVariables>({
            query: Queries.redirections,
            variables,
        });

        return result.redirections!;
    }

    public static async getRedirection(id: number): Promise<AdminGqlTypes.Redirection> {
        const result = await GraphQLClient.query<AdminGqlTypes.getRedirection, AdminGqlTypes.getRedirectionVariables>({
            query: Queries.getRedirection,
            variables: { id },
        });

        if (!result.redirection) {
            throw new ApiError(I18n.formatMessage({ id: "api.error.notFound" }));
        }
        return result.redirection;
    }

    public static async createRedirection(input: AdminGqlTypes.RedirectionInput): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.createRedirection, AdminGqlTypes.createRedirectionVariables>({
            mutation: Mutations.createRedirection,
            variables: { input },
        });
    }

    public static async updateRedirection(id: number, input: AdminGqlTypes.RedirectionInput): Promise<AdminGqlTypes.Redirection> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.updateRedirection, AdminGqlTypes.updateRedirectionVariables>({
            mutation: Mutations.updateRedirection,
            variables: { id, input },
        });

        return result.updateRedirection;
    }

    public static async deleteRedirection(id: number): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.deleteRedirection, AdminGqlTypes.deleteRedirectionVariables>({
            mutation: Mutations.deleteRedirection,
            variables: { id },
        });
    }

    public static async listShippingMethodSelectItems(
        variables: AdminGqlTypes.listShippingMethodSelectItemsVariables
    ): Promise<AdminGqlTypes.listShippingMethodSelectItems_listShippingMethods> {
        const result = await GraphQLClient.query<AdminGqlTypes.listShippingMethodSelectItems, AdminGqlTypes.listShippingMethodSelectItemsVariables>({
            query: Queries.listShippingMethodSelectItems,
            variables,
        });
        return result.listShippingMethods!;
    }

    public static async listPaymentMethodSelectItems(
        variables: AdminGqlTypes.listPaymentMethodSelectItemsVariables
    ): Promise<AdminGqlTypes.listPaymentMethodSelectItems_listPaymentMethods> {
        const result = await GraphQLClient.query<AdminGqlTypes.listPaymentMethodSelectItems, AdminGqlTypes.listPaymentMethodSelectItemsVariables>({
            query: Queries.listPaymentMethodSelectItems,
            variables,
        });
        return result.listPaymentMethods!;
    }

    public static async listMailTemplates(variables: AdminGqlTypes.listMailTemplatesVariables): Promise<AdminGqlTypes.listMailTemplates_listMailTemplates> {
        const result = await GraphQLClient.query<AdminGqlTypes.listMailTemplates, AdminGqlTypes.listMailTemplatesVariables>({
            query: Queries.listMailTemplates,
            variables,
        });
        return result.listMailTemplates!;
    }

    public static async getMailTemplateById(id: number): Promise<AdminGqlTypes.getMailTemplateById_listMailTemplates_data> {
        const result = await GraphQLClient.query<AdminGqlTypes.getMailTemplateById, AdminGqlTypes.getMailTemplateByIdVariables>({
            query: Queries.getMailTemplateById,
            variables: { first: 1, filters: { id } },
        });

        if (!result.listMailTemplates?.data || !result.listMailTemplates.data[0]) {
            throw new ApiError(I18n.formatMessage({ id: "api.error.notFound" }));
        }

        return result.listMailTemplates.data[0];
    }

    public static async updateMailTemplate(id: number, input: AdminGqlTypes.UpdateMailTemplateInput): Promise<AdminGqlTypes.MailTemplate> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.updateMailTemplate, AdminGqlTypes.updateMailTemplateVariables>({
            mutation: Mutations.updateMailTemplate,
            variables: { id, input },
        });

        return result.updateMailTemplate;
    }

    public static async previewMailTemplate(content: string): Promise<string> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.previewMailTemplate, AdminGqlTypes.previewMailTemplateVariables>({
            mutation: Mutations.previewMailTemplate,
            variables: { content },
        });

        return result.previewMailTemplate;
    }

    public static async listPages(variables: AdminGqlTypes.listPagesVariables): Promise<AdminGqlTypes.listPages_listPages> {
        const result = await GraphQLClient.query<AdminGqlTypes.listPages, AdminGqlTypes.listPagesVariables>({
            query: Queries.listPages,
            variables,
        });
        return result.listPages!;
    }

    public static async createPage(input: AdminGqlTypes.PageInput): Promise<number> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.createPage, AdminGqlTypes.createPageVariables>({
            mutation: Mutations.createPage,
            variables: { input },
        });

        return result.createPage.id;
    }

    public static async getPageById(id: number): Promise<AdminGqlTypes.Page> {
        const result = await GraphQLClient.query<AdminGqlTypes.getPageById, AdminGqlTypes.getPageByIdVariables>({
            query: Queries.getPageById,
            variables: { id },
        });

        if (!result.page) {
            throw new ApiError(I18n.formatMessage({ id: "api.error.notFound" }));
        }
        return result.page;
    }

    public static async updatePage(id: number, input: AdminGqlTypes.PageInput): Promise<AdminGqlTypes.Page> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.updatePage, AdminGqlTypes.updatePageVariables>({
            mutation: Mutations.updatePage,
            variables: { id, input },
        });

        return result.updatePage;
    }

    public static async listShippingMethods(variables: AdminGqlTypes.listShippingMethodsVariables): Promise<AdminGqlTypes.listShippingMethods_listShippingMethods> {
        const result = await GraphQLClient.query<AdminGqlTypes.listShippingMethods, AdminGqlTypes.listShippingMethodsVariables>({
            query: Queries.listShippingMethods,
            variables,
        });
        return result.listShippingMethods!;
    }

    public static async getShippingMethodById(id: string): Promise<AdminGqlTypes.getShippingMethodById_listShippingMethods_data> {
        const result = await GraphQLClient.query<AdminGqlTypes.getShippingMethodById, AdminGqlTypes.getShippingMethodByIdVariables>({
            query: Queries.getShippingMethodById,
            variables: { first: 1, filters: { id } },
        });

        if (!result.listShippingMethods?.data || !result.listShippingMethods.data[0]) {
            throw new ApiError(I18n.formatMessage({ id: "api.error.notFound" }));
        }

        return result.listShippingMethods.data[0];
    }

    public static async updateShippingMethod(id: string, input: AdminGqlTypes.ShippingMethodInput): Promise<AdminGqlTypes.ShippingMethod | null> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.updateShippingMethod, AdminGqlTypes.updateShippingMethodVariables>({
            mutation: Mutations.updateShippingMethod,
            variables: { id, input },
        });

        return result.updateShippingMethod;
    }

    public static async listOrders(variables: AdminGqlTypes.listOrdersVariables): Promise<AdminGqlTypes.listOrders_listOrders> {
        const result = await GraphQLClient.query<AdminGqlTypes.listOrders, AdminGqlTypes.listOrdersVariables>({
            query: Queries.listOrders,
            variables,
        });
        return result.listOrders!;
    }

    public static async listGiftCardOrders(variables: AdminGqlTypes.listGiftCardOrdersVariables): Promise<AdminGqlTypes.listGiftCardOrders_listGiftCardOrders> {
        const result = await GraphQLClient.query<AdminGqlTypes.listGiftCardOrders, AdminGqlTypes.listGiftCardOrdersVariables>({
            query: Queries.listGiftCardOrders,
            variables,
        });
        return result.listGiftCardOrders!;
    }

    public static async listStoreOrders(variables: AdminGqlTypes.listStoreOrdersVariables): Promise<AdminGqlTypes.listStoreOrders_listOrders> {
        const result = await GraphQLClient.query<AdminGqlTypes.listStoreOrders, AdminGqlTypes.listStoreOrdersVariables>({
            query: Queries.listStoreOrders,
            variables,
        });
        return result.listOrders!;
    }

    public static async listPaymentStatusSelectItems(): Promise<AdminGqlTypes.PaymentStatus[]> {
        const result = await GraphQLClient.query<AdminGqlTypes.listPaymentStatusSelectItems>({
            query: Queries.listPaymentStatusSelectItems,
        });
        return result.paymentStatuses!;
    }

    public static async getOrderById(increment_id: number): Promise<AdminGqlTypes.Order> {
        const result = await GraphQLClient.query<AdminGqlTypes.getOrderById, AdminGqlTypes.getOrderByIdVariables>({
            query: Queries.getOrderById,
            variables: { increment_id },
        });

        if (!result.order) {
            throw new ApiError(I18n.formatMessage({ id: "api.error.notFound" }));
        }
        return result.order;
    }

    public static async giftCardOrder(id: number): Promise<AdminGqlTypes.GiftCardOrder> {
        const result = await GraphQLClient.query<AdminGqlTypes.giftCardOrder, AdminGqlTypes.giftCardOrderVariables>({
            query: Queries.giftCardOrder,
            variables: { id },
        });

        if (!result.giftCardOrder) {
            throw new ApiError(I18n.formatMessage({ id: "api.error.notFound" }));
        }
        return result.giftCardOrder;
    }

    public static async giftCardOrderGiftCards(id: number): Promise<AdminGqlTypes.GiftCard[]> {
        const result = await GraphQLClient.query<AdminGqlTypes.giftCardOrderGiftCards, AdminGqlTypes.giftCardOrderGiftCardsVariables>({
            query: Queries.giftCardOrderGiftCards,
            variables: { id },
        });

        if (!result.giftCardOrder?.gift_cards) {
            throw new ApiError(I18n.formatMessage({ id: "api.error.notFound" }));
        }
        return result.giftCardOrder.gift_cards.filter(gc => gc !== null) as AdminGqlTypes.GiftCard[];
    }

    public static async updateOrder(increment_id: number, input: AdminGqlTypes.OrderInput): Promise<AdminGqlTypes.Order | null> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.updateOrder, AdminGqlTypes.updateOrderVariables>({
            mutation: Mutations.updateOrder,
            variables: { increment_id, input },
        });

        return result.updateOrder;
    }

    public static async closeOrder(increment_id: number, delivered: boolean): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.closeOrder, AdminGqlTypes.closeOrderVariables>({
            mutation: Mutations.closeOrder,
            variables: { increment_id, delivered },
        });
    }

    public static async stornoInvoice(increment_id: number): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.stornoInvoice, AdminGqlTypes.stornoInvoiceVariables>({
            mutation: Mutations.stornoInvoice,
            variables: { increment_id },
        });
    }

    public static async refundPayment(increment_id: number): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.refundPayment, AdminGqlTypes.refundPaymentVariables>({
            mutation: Mutations.refundPayment,
            variables: { increment_id },
        });
    }

    public static async deleteOrder(increment_id: number): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.deleteOrder, AdminGqlTypes.deleteOrderVariables>({
            mutation: Mutations.deleteOrder,
            variables: { increment_id },
        });
    }

    // TODO: Prod backend not ready yet: https://bigfishhu.atlassian.net/browse/ROS-3917
    // public static async correctInvoice(increment_id: number, items: AdminGqlTypes.OrderItemInputV2[]): Promise<AdminGqlTypes.Order> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    public static async correctInvoice(increment_id: number, items: AdminGqlTypes.InvoiceCorrectionItemInput[]): Promise<AdminGqlTypes.Order> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.correctInvoice, AdminGqlTypes.correctInvoiceVariables>({
            mutation: Mutations.correctInvoice,
            variables: { increment_id, items },
        });

        return result.correctInvoice;
    }

    public static async listWebsiteNotifications(
        variables: AdminGqlTypes.listWebsiteNotificationsVariables
    ): Promise<AdminGqlTypes.listWebsiteNotifications_listWebsiteNotifications> {
        const result = await GraphQLClient.query<AdminGqlTypes.listWebsiteNotifications, AdminGqlTypes.listWebsiteNotificationsVariables>({
            query: Queries.listWebsiteNotifications,
            variables,
        });
        return result.listWebsiteNotifications!;
    }

    public static async createWebsiteNotification(input: AdminGqlTypes.WebsiteNotificationInput): Promise<number> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.createWebsiteNotification, AdminGqlTypes.createWebsiteNotificationVariables>({
            mutation: Mutations.createWebsiteNotification,
            variables: { input },
        });
        return result.createWebsiteNotification!.id;
    }

    public static async getWebsiteNotificationById(id: number): Promise<AdminGqlTypes.getWebsiteNotificationById_listWebsiteNotifications_data> {
        const result = await GraphQLClient.query<AdminGqlTypes.getWebsiteNotificationById, AdminGqlTypes.getWebsiteNotificationByIdVariables>({
            query: Queries.getWebsiteNotificationById,
            variables: { first: 1, filters: { id } },
        });

        if (!result.listWebsiteNotifications?.data || !result.listWebsiteNotifications.data[0]) {
            throw new ApiError(I18n.formatMessage({ id: "api.error.notFound" }));
        }

        return result.listWebsiteNotifications.data[0];
    }

    public static async updateWebsiteNotification(id: number, input: AdminGqlTypes.WebsiteNotificationInput): Promise<AdminGqlTypes.WebsiteNotification | null> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.updateWebsiteNotification, AdminGqlTypes.updateWebsiteNotificationVariables>({
            mutation: Mutations.updateWebsiteNotification,
            variables: { id, input },
        });

        return result.updateWebsiteNotification;
    }

    public static async listSites(): Promise<AdminGqlTypes.Site[]> {
        const result = await GraphQLClient.query<AdminGqlTypes.listSites>({
            query: Queries.listSites,
        });
        return result.sites!;
    }

    public static async updateShipmentStatus(orderIds: number[], status: AdminGqlTypes.ShipmentStatusStoreEnum): Promise<Array<{ id: number }>> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.updateShipmentStatus, AdminGqlTypes.updateShipmentStatusVariables>({
            mutation: Mutations.updateShipmentStatus,
            variables: { order_ids: orderIds, status },
        });

        return result.updateShipmentStatus;
    }

    public static async storeAdminPrintOrders(ids: number[]): Promise<string> {
        const result = await GraphQLClient.query<AdminGqlTypes.storeAdminPrintOrders>({
            query: Queries.storeAdminPrintOrders,
            variables: { ids },
        });

        return result.storeAdminPrintOrders;
    }

    public static async listStoresForSelect(variables: AdminGqlTypes.listStoresForSelectVariables): Promise<AdminGqlTypes.StoreSelectItem[]> {
        const result = await GraphQLClient.query<AdminGqlTypes.listStoresForSelect, AdminGqlTypes.listStoresForSelectVariables>({
            query: Queries.listStoresForSelect,
            variables,
        });
        return result.listStores!.data;
    }

    public static async deletePage(id: number): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.deletePage, AdminGqlTypes.deletePageVariables>({
            mutation: Mutations.deletePage,
            variables: { id },
        });
    }

    public static async deleteBanner(id: number): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.deleteBanner, AdminGqlTypes.deleteBannerVariables>({
            mutation: Mutations.deleteBanner,
            variables: { id },
        });
    }

    public static async deleteBadge(id: number): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.deleteBadge, AdminGqlTypes.deleteBadgeVariables>({
            mutation: Mutations.deleteBadge,
            variables: { id },
        });
    }

    public static async deleteWebsiteNotification(id: number): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.deleteWebsiteNotification, AdminGqlTypes.deleteWebsiteNotificationVariables>({
            mutation: Mutations.deleteWebsiteNotification,
            variables: { id },
        });
    }

    public static async modifyOrder(increment_id: number, input: AdminGqlTypes.OrderModifyInput): Promise<AdminGqlTypes.Order> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.modifyOrder, AdminGqlTypes.modifyOrderVariables>({
            mutation: Mutations.modifyOrder,
            variables: { increment_id, input },
        });

        return result.modifyOrder;
    }

    public static async listShipmentStatuses(): Promise<AdminGqlTypes.ShipmentStatus[]> {
        const result = await GraphQLClient.query<AdminGqlTypes.listShipmentStatuses>({
            query: Queries.listShipmentStatuses,
        });
        return result.shipmentStatuses;
    }

    public static async deleteProductList(id: number): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.deleteProductList, AdminGqlTypes.deleteProductListVariables>({
            mutation: Mutations.deleteProductList,
            variables: { id },
        });
    }

    public static async deleteAdmin(id: number): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.deleteAdmin, AdminGqlTypes.deleteAdminVariables>({
            mutation: Mutations.deleteAdmin,
            variables: { id },
        });
    }

    public static async deleteUser(id: number): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.deleteUser, AdminGqlTypes.deleteUserVariables>({
            mutation: Mutations.deleteUser,
            variables: { id },
        });
    }

    public static async printOrder(increment_ids: number[]): Promise<string> {
        const result = await GraphQLClient.query<AdminGqlTypes.printOrder>({
            query: Queries.printOrder,
            variables: { increment_ids },
        });

        return result.printOrder;
    }

    public static async listSystemSettings(variables: AdminGqlTypes.listSystemSettingsVariables): Promise<AdminGqlTypes.listSystemSettings_listSettings> {
        const result = await GraphQLClient.query<AdminGqlTypes.listSystemSettings, AdminGqlTypes.listSystemSettingsVariables>({
            query: Queries.listSystemSettings,
            variables,
        });
        return result.listSettings!;
    }

    public static async listPaymentMethods(variables: AdminGqlTypes.listPaymentMethodsVariables): Promise<AdminGqlTypes.listPaymentMethodSelectItems_listPaymentMethods> {
        const result = await GraphQLClient.query<AdminGqlTypes.listPaymentMethods, AdminGqlTypes.listPaymentMethodsVariables>({
            query: Queries.listPaymentMethods,
            variables,
        });
        return result.listPaymentMethods!;
    }

    public static async getPaymentMethodById(id: string): Promise<AdminGqlTypes.getPaymentMethodById_listPaymentMethods_data> {
        const result = await GraphQLClient.query<AdminGqlTypes.getPaymentMethodById, AdminGqlTypes.getPaymentMethodByIdVariables>({
            query: Queries.getPaymentMethodById,
            variables: { first: 1, filters: { id } },
        });

        if (!result.listPaymentMethods?.data || !result.listPaymentMethods.data[0]) {
            throw new ApiError(I18n.formatMessage({ id: "api.error.notFound" }));
        }

        return result.listPaymentMethods.data[0];
    }

    public static async updatePaymentMethod(id: string, input: AdminGqlTypes.PaymentMethodInput): Promise<AdminGqlTypes.PaymentMethod | null> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.updatePaymentMethod, AdminGqlTypes.updatePaymentMethodVariables>({
            mutation: Mutations.updatePaymentMethod,
            variables: { id, input },
        });

        return result.updatePaymentMethod;
    }

    public static async adminLoginAsUser(id: number): Promise<string> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.adminLoginAsUser, AdminGqlTypes.adminLoginAsUserVariables>({
            mutation: Mutations.adminLoginAsUser,
            variables: { id },
        });

        return result.adminLoginAsUser.token;
    }

    public static async listTimeWindowIntervalSelectItems(): Promise<string[]> {
        const result = await GraphQLClient.query<AdminGqlTypes.listTimeWindowIntervalSelectItems>({
            query: Queries.listTimeWindowIntervalSelectItems,
        });
        return result.orderTimeWindowIntervals!;
    }

    public static async listShipmentStatusSelectItems(): Promise<AdminGqlTypes.ShipmentStatusSelectItem[]> {
        const result = await GraphQLClient.query<AdminGqlTypes.listShipmentStatusSelectItems>({
            query: Queries.listShipmentStatusSelectItems,
        });
        return result.shipmentStatuses!;
    }

    public static async listCalendarDays(variables: AdminGqlTypes.listCalendarDaysVariables): Promise<AdminGqlTypes.listCalendarDays_listCalendarDays> {
        const result = await GraphQLClient.query<AdminGqlTypes.listCalendarDays, AdminGqlTypes.listCalendarDaysVariables>({
            query: Queries.listCalendarDays,
            variables,
        });
        return result.listCalendarDays!;
    }

    public static async getCalendarDay(day: string): Promise<AdminGqlTypes.listCalendarDays_listCalendarDays_data> {
        const result = await GraphQLClient.query<AdminGqlTypes.listCalendarDays, AdminGqlTypes.listCalendarDaysVariables>({
            query: Queries.listCalendarDays,
            variables: {
                first: 1,
                filters: { date: { from: day, to: day } },
            },
        });

        if (!result.listCalendarDays?.data || !result.listCalendarDays.data[0]) {
            throw new ApiError(I18n.formatMessage({ id: "api.error.notFound" }));
        }

        return result.listCalendarDays.data[0];
    }

    public static async setCalendarDay(variables: AdminGqlTypes.setCalendarDayVariables): Promise<AdminGqlTypes.setCalendarDay_setCalendarDay> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.setCalendarDay, AdminGqlTypes.setCalendarDayVariables>({
            mutation: Mutations.setCalendarDay,
            variables,
        });
        return result.setCalendarDay!;
    }

    public static async resetCalendarDay(date: Date): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.resetCalendarDay, AdminGqlTypes.resetCalendarDayVariables>({
            mutation: Mutations.resetCalendarDay,
            variables: { date },
        });
    }

    public static async updateSetting(id: string, input: AdminGqlTypes.SettingInput): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.updateSetting, AdminGqlTypes.updateSettingVariables>({
            mutation: Mutations.updateSetting,
            variables: { id, input },
        });
    }

    public static async listProductParams(variables: AdminGqlTypes.productParamListVariables): Promise<AdminGqlTypes.productParamList_productParamList> {
        const result = await GraphQLClient.query<AdminGqlTypes.productParamList, AdminGqlTypes.productParamListVariables>({
            query: Queries.listProductParams,
            variables,
        });
        return result.productParamList!;
    }

    public static async productParam(id: string): Promise<AdminGqlTypes.productParam_productParam> {
        const result = await GraphQLClient.query<AdminGqlTypes.productParam, AdminGqlTypes.productParamVariables>({
            query: Queries.productParam,
            variables: { id },
        });
        return result.productParam!;
    }

    public static async updateProductParam(id: string, input: AdminGqlTypes.ProductParamUpdateInput): Promise<AdminGqlTypes.updateProductParam_updateProductParam> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.updateProductParam, AdminGqlTypes.updateProductParamVariables>({
            mutation: Mutations.updateProductParam,
            variables: { id, input },
        });
        return result.updateProductParam!;
    }

    public static async exportOrder(increment_ids: number[]): Promise<string> {
        const result = await GraphQLClient.query<AdminGqlTypes.exportOrder, AdminGqlTypes.exportOrderVariables>({
            query: Queries.exportOrder,
            variables: { increment_ids },
        });
        return result.exportOrder;
    }

    public static async listOrdersExport(
        filters: AdminGqlTypes.OrderFilters | null | undefined,
        sortBy: AdminGqlTypes.OrderSort | null | undefined
    ): Promise<AdminGqlTypes.listOrdersExport_listOrdersExport> {
        const result = await GraphQLClient.query<AdminGqlTypes.listOrdersExport, AdminGqlTypes.listOrdersExportVariables>({
            query: Queries.listOrdersExport,
            variables: { filters, sortBy },
        });
        return result.listOrdersExport!;
    }

    public static async listGiftCardsExport(
        filters: AdminGqlTypes.GiftCardOrderFilters | null | undefined,
        sortBy: AdminGqlTypes.GiftCardOrderSort | null | undefined
    ): Promise<AdminGqlTypes.listGiftCardsExport_listGiftCardsExport> {
        const result = await GraphQLClient.query<AdminGqlTypes.listGiftCardsExport, AdminGqlTypes.listGiftCardsExportVariables>({
            query: Queries.listGiftCardsExport,
            variables: { filters, sortBy },
        });
        return result.listGiftCardsExport!;
    }

    public static async listLogs(variables: AdminGqlTypes.modelUpdateLogVariables): Promise<AdminGqlTypes.modelUpdateLog_modelUpdateLog> {
        const result = await GraphQLClient.query<AdminGqlTypes.modelUpdateLog, AdminGqlTypes.modelUpdateLogVariables>({
            query: Queries.modelUpdateLog,
            variables,
        });
        return result.modelUpdateLog;
    }

    public static async listMaintenances(variables: AdminGqlTypes.listMaintenancesVariables): Promise<AdminGqlTypes.listMaintenances_maintenances> {
        const result = await GraphQLClient.query<AdminGqlTypes.listMaintenances, AdminGqlTypes.listMaintenancesVariables>({
            query: Queries.listMaintenances,
            variables,
        });
        return result.maintenances!;
    }

    public static async createMaintenance(input: AdminGqlTypes.MaintenanceInput): Promise<string> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.createMaintenance, AdminGqlTypes.createMaintenanceVariables>({
            mutation: Mutations.createMaintenance,
            variables: { input },
        });
        return result.createMaintenance.id;
    }

    public static async getMaintenanceById(id: string): Promise<AdminGqlTypes.getMaintenanceById_maintenance | null> {
        const result = await GraphQLClient.query<AdminGqlTypes.getMaintenanceById, AdminGqlTypes.getMaintenanceByIdVariables>({
            query: Queries.getMaintenanceById,
            variables: { id },
        });

        return result.maintenance;
    }

    public static async updateMaintenance(id: string, input: AdminGqlTypes.MaintenanceInput): Promise<AdminGqlTypes.Maintenance> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.updateMaintenance, AdminGqlTypes.updateMaintenanceVariables>({
            mutation: Mutations.updateMaintenance,
            variables: { id, input },
        });

        return result.updateMaintenance;
    }

    public static async deleteMaintenance(id: string): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.deleteMaintenance, AdminGqlTypes.deleteMaintenanceVariables>({
            mutation: Mutations.deleteMaintenance,
            variables: { id },
        });
    }

    public static async session(): Promise<AuthGqlTypes.session_session> {
        const result = await GraphQLClient.query<AuthGqlTypes.session>({ query: Queries.session, context: { client: "auth" } });
        return result.session;
    }

    public static async listMenus(variables: AdminGqlTypes.listMenusVariables): Promise<AdminGqlTypes.listMenus_listMenus> {
        const result = await GraphQLClient.query<AdminGqlTypes.listMenus, AdminGqlTypes.listMenusVariables>({
            query: Queries.listMenus,
            variables,
        });
        return result.listMenus!;
    }

    public static async createMenu(input: AdminGqlTypes.MenuInput): Promise<number> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.createMenu, AdminGqlTypes.createMenuVariables>({
            mutation: Mutations.createMenu,
            variables: { input },
        });
        return result.createMenu.id;
    }

    public static async getMenuById(id: number): Promise<AdminGqlTypes.getMenuById_menu | null> {
        const result = await GraphQLClient.query<AdminGqlTypes.getMenuById, AdminGqlTypes.getMenuByIdVariables>({
            query: Queries.getMenuById,
            variables: { id },
        });

        return result.menu;
    }

    public static async updateMenu(id: number, input: AdminGqlTypes.MenuInput): Promise<AdminGqlTypes.Menu> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.updateMenu, AdminGqlTypes.updateMenuVariables>({
            mutation: Mutations.updateMenu,
            variables: { id, input },
        });

        return result.updateMenu;
    }

    public static async deleteMenu(id: number): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.deleteMenu, AdminGqlTypes.deleteMenuVariables>({
            mutation: Mutations.deleteMenu,
            variables: { id },
        });
    }

    public static async listPagesForMenu(variables: AdminGqlTypes.listPagesVariables): Promise<AdminGqlTypes.listPagesForMenu_listPages> {
        const result = await GraphQLClient.query<AdminGqlTypes.listPagesForMenu, AdminGqlTypes.listPagesForMenuVariables>({
            query: Queries.listPagesForMenu,
            variables: {
                ...variables,
                sortBy: { field: AdminGqlTypes.PageSortField.name, direction: AdminGqlTypes.OrderDirection.ASC },
            },
        });
        return result.listPages!;
    }

    public static async listPagesForSelect(variables: AdminGqlTypes.listPagesVariables): Promise<AdminGqlTypes.listPagesForSelect_listPages> {
        const result = await GraphQLClient.query<AdminGqlTypes.listPagesForSelect, AdminGqlTypes.listPagesForSelectVariables>({
            query: Queries.listPagesForSelect,
            variables: {
                ...variables,
                sortBy: { field: AdminGqlTypes.PageSortField.name, direction: AdminGqlTypes.OrderDirection.ASC },
            },
        });
        return result.listPages!;
    }

    public static async listProductCategoriesForMenu(): Promise<AdminGqlTypes.listProductCategoriesForMenu_productCategoryTree[]> {
        const result = await GraphQLClient.query<AdminGqlTypes.listProductCategoriesForMenu>({
            query: Queries.listProductCategoriesForMenu,
        });
        return result.productCategoryTree!;
    }

    public static async listProductsForMenu(variables: AdminGqlTypes.listProductsVariables): Promise<AdminGqlTypes.listProductsForMenu_listProducts> {
        const result = await GraphQLClient.query<AdminGqlTypes.listProductsForMenu, AdminGqlTypes.listProductsForMenuVariables>({
            query: Queries.listProductsForMenu,
            variables: {
                ...variables,
                sortBy: { field: AdminGqlTypes.ProductSortField.name, direction: AdminGqlTypes.OrderDirection.ASC },
            },
        });
        return result.listProducts!;
    }

    public static async listProductListsForMenu(variables: AdminGqlTypes.listProductListsVariables): Promise<AdminGqlTypes.listProductListsForMenu_listProductLists> {
        const result = await GraphQLClient.query<AdminGqlTypes.listProductListsForMenu, AdminGqlTypes.listProductListsForMenuVariables>({
            query: Queries.listProductListsForMenu,
            variables: {
                ...variables,
                sortBy: { field: AdminGqlTypes.ProductListSortField.name, direction: AdminGqlTypes.OrderDirection.ASC },
            },
        });
        return result.listProductLists!;
    }

    public static async listProductBrandsForMenu(variables: AdminGqlTypes.listProductBrandsVariables): Promise<AdminGqlTypes.listProductBrandsForMenu_listProductBrands> {
        const result = await GraphQLClient.query<AdminGqlTypes.listProductBrandsForMenu, AdminGqlTypes.listProductBrandsForMenuVariables>({
            query: Queries.listProductBrandsForMenu,
            variables: {
                ...variables,
                sortBy: { field: AdminGqlTypes.ProductBrandSortField.name, direction: AdminGqlTypes.OrderDirection.ASC },
            },
        });
        return result.listProductBrands!;
    }

    public static async listAdminContentsForMenu(params: { first: number }): Promise<AdminGqlTypes.listAdminContentsForMenu_adminContents> {
        const result = await GraphQLClient.query<AdminGqlTypes.listAdminContentsForMenu, AdminGqlTypes.listAdminContentsForMenuVariables>({
            query: Queries.listAdminContentsForMenu,
            variables: { ...params, sortBy: { field: AdminGqlTypes.ContentField.title, direction: AdminGqlTypes.OrderDirection.ASC } },
        });

        return {
            ...result.adminContents!,
            data: result.adminContents!.data.filter((content: AdminGqlTypes.listAdminContentsForMenu_adminContents_data): content is AdminGqlTypes.TextContentForMenu => {
                return content.__typename === "TextContent";
            }),
        };
    }

    public static async testMailTemplate(variables: AdminGqlTypes.testMailTemplateVariables): Promise<boolean> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.testMailTemplate, AdminGqlTypes.testMailTemplateVariables>({
            mutation: Mutations.testMailTemplate,
            variables,
        });
        return result.testMailTemplate;
    }

    public static async testMailTemplateAll(variables: AdminGqlTypes.testMailTemplateAllVariables): Promise<boolean> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.testMailTemplateAll, AdminGqlTypes.testMailTemplateAllVariables>({
            mutation: Mutations.testMailTemplateAll,
            variables,
        });
        return result.testMailTemplateAll;
    }

    public static async listTodos(variables: AdminGqlTypes.listTodosVariables): Promise<AdminGqlTypes.listTodos_listTodos> {
        const result = await GraphQLClient.query<AdminGqlTypes.listTodos, AdminGqlTypes.listTodosVariables>({
            query: Queries.listTodos,
            variables,
        });
        return result.listTodos!;
    }

    public static async getTodoById(id: number): Promise<AdminGqlTypes.getTodoById_todo> {
        const result = await GraphQLClient.query<AdminGqlTypes.getTodoById, AdminGqlTypes.getTodoByIdVariables>({
            query: Queries.getTodoById,
            variables: { id },
        });

        return result.todo!;
    }

    public static async updateTodo(id: number, input: AdminGqlTypes.TodoInput): Promise<AdminGqlTypes.Todo | null> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.updateTodo, AdminGqlTypes.updateTodoVariables>({
            mutation: Mutations.updateTodo,
            variables: { id, input },
        });

        return result.updateTodo;
    }

    public static async listAdminsForSelect(variables: AdminGqlTypes.listAdminsForSelectVariables): Promise<AdminGqlTypes.listAdminsForSelect_listAdmins> {
        const result = await GraphQLClient.query<AdminGqlTypes.listAdminsForSelect, AdminGqlTypes.listAdminsForSelectVariables>({
            query: Queries.listAdminsForSelect,
            variables,
        });
        return result.listAdmins!;
    }

    public static async listProductBundles(variables: AdminGqlTypes.listProductBundlesVariables): Promise<AdminGqlTypes.listProductBundles_listProductBundles> {
        const result = await GraphQLClient.query<AdminGqlTypes.listProductBundles, AdminGqlTypes.listProductBundlesVariables>({
            query: Queries.listProductBundles,
            variables,
        });
        return result.listProductBundles!;
    }

    public static async createProductBundle(input: AdminGqlTypes.ProductBundleInput): Promise<number> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.createProductBundle, AdminGqlTypes.createProductBundleVariables>({
            mutation: Mutations.createProductBundle,
            variables: { input },
        });
        return result.createProductBundle!.id;
    }

    public static async updateProductBundle(id: number, input: AdminGqlTypes.ProductBundleInput): Promise<AdminGqlTypes.Product | null> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.updateProductBundle, AdminGqlTypes.updateProductBundleVariables>({
            mutation: Mutations.updateProductBundle,
            variables: { id, input },
        });
        return result.updateProductBundle;
    }

    public static async listProductVariants(variables: AdminGqlTypes.listProductVariantsVariables): Promise<AdminGqlTypes.listProductVariants_listProductVariants> {
        const result = await GraphQLClient.query<AdminGqlTypes.listProductVariants, AdminGqlTypes.listProductVariantsVariables>({
            query: Queries.listProductVariants,
            variables,
        });
        return result.listProductVariants!;
    }

    public static async getProductVariantById(id: number): Promise<AdminGqlTypes.getProductVariantById_product> {
        const result = await GraphQLClient.query<AdminGqlTypes.getProductVariantById, AdminGqlTypes.getProductVariantByIdVariables>({
            query: Queries.getProductVariantById,
            variables: { id },
        });

        return result.product;
    }

    public static async createProductVariant(input: AdminGqlTypes.ProductVariantInput): Promise<number | null> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.createProductVariant, AdminGqlTypes.createProductVariantVariables>({
            mutation: Mutations.createProductVariant,
            variables: { input },
        });
        return result.createProductVariant?.id ?? null;
    }

    public static async updateProductVariant(id: number, input: AdminGqlTypes.ProductVariantInput): Promise<AdminGqlTypes.ProductVariant | null> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.updateProductVariant, AdminGqlTypes.updateProductVariantVariables>({
            mutation: Mutations.updateProductVariant,
            variables: { id, input },
        });

        return result.updateProductVariant;
    }

    public static async listRatings(variables: AdminGqlTypes.listRatingsVariables): Promise<AdminGqlTypes.listRatings_listOrderRatings> {
        const result = await GraphQLClient.query<AdminGqlTypes.listRatings, AdminGqlTypes.listRatingsVariables>({
            query: Queries.listRatings,
            variables,
        });
        return result.listOrderRatings!;
    }

    public static async orderProductCategory(input: AdminGqlTypes.ProductCategoryOrderInput[]): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.orderProductCategory, AdminGqlTypes.orderProductCategoryVariables>({
            mutation: Mutations.orderProductCategory,
            variables: { input },
        });
    }

    public static async listFulfillment(variables: AdminGqlTypes.listFulfillmentVariables): Promise<AdminGqlTypes.listFulfillment_listFulfillment> {
        const result = await GraphQLClient.query<AdminGqlTypes.listFulfillment, AdminGqlTypes.listFulfillmentVariables>({
            query: Queries.listFulfillment,
            variables,
        });
        return result.listFulfillment!;
    }

    public static async createFulfillment(input: AdminGqlTypes.FulfillmentInput): Promise<number> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.createFulfillment, AdminGqlTypes.createFulfillmentVariables>({
            mutation: Mutations.createFulfillment,
            variables: { input },
        });
        return result.createFulfillment!.id;
    }

    public static async updateFulfillment(id: number, input: AdminGqlTypes.FulfillmentInput): Promise<AdminGqlTypes.Fulfillment | null> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.updateFulfillment, AdminGqlTypes.updateFulfillmentVariables>({
            mutation: Mutations.updateFulfillment,
            variables: { id, input },
        });

        return result.updateFulfillment;
    }

    public static async getFulfillmentById(id: number): Promise<AdminGqlTypes.getFulfillmentById_listFulfillment_data> {
        const result = await GraphQLClient.query<AdminGqlTypes.getFulfillmentById, AdminGqlTypes.getFulfillmentByIdVariables>({
            query: Queries.getFulfillmentById,
            variables: { first: 1, filters: { id } },
        });

        if (!result.listFulfillment?.data || !result.listFulfillment.data[0]) {
            throw new ApiError(I18n.formatMessage({ id: "api.error.notFound" }));
        }

        return result.listFulfillment.data[0];
    }

    public static async deleteFulfillment(id: number): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.deleteFulfillment, AdminGqlTypes.deleteFulfillmentVariables>({
            mutation: Mutations.deleteFulfillment,
            variables: { id },
        });
    }

    public static async clearCache(): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.clearCache>({
            mutation: Mutations.clearCache,
        });
    }

    public static async clearProductCache(): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.clearProductCache>({
            mutation: Mutations.clearProductCache,
        });
    }

    public static async clearProductCategoryCache(): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.clearProductCategoryCache>({
            mutation: Mutations.clearProductCategoryCache,
        });
    }

    public static async clearProductPriceCache(): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.clearProductPriceCache>({
            mutation: Mutations.clearProductPriceCache,
        });
    }

    public static async indexStatus(): Promise<number> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.indexStatus>({
            mutation: Queries.indexStatus,
        });

        return result.indexStatus.queue_length;
    }

    public static async queueProductIndex(): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.queueProductIndex>({
            mutation: Mutations.queueProductIndex,
        });
    }

    public static async indexProduct(lfdnr: number[]): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.indexProduct>({
            mutation: Mutations.indexProduct,
            variables: { lfdnr },
        });
    }

    public static async listCampaigns(variables: AdminGqlTypes.listCampaignsVariables): Promise<AdminGqlTypes.listCampaigns_listCampaigns> {
        const result = await GraphQLClient.query<AdminGqlTypes.listCampaigns, AdminGqlTypes.listCampaignsVariables>({
            query: Queries.listCampaigns,
            variables,
        });
        return result.listCampaigns!;
    }

    public static async createCampaign(input: AdminGqlTypes.CampaignInput): Promise<number> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.createCampaign, AdminGqlTypes.createCampaignVariables>({
            mutation: Mutations.createCampaign,
            variables: { input },
        });
        return result.createCampaign!.id;
    }

    public static async getCampaignById(id: number): Promise<AdminGqlTypes.Campaign> {
        const result = await GraphQLClient.query<AdminGqlTypes.getCampaignById, AdminGqlTypes.getCampaignByIdVariables>({
            query: Queries.getCampaignById,
            variables: { id },
        });

        return result.campaign;
    }

    public static async updateCampaign(id: number, input: AdminGqlTypes.CampaignInput): Promise<AdminGqlTypes.Campaign | null> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.updateCampaign, AdminGqlTypes.updateCampaignVariables>({
            mutation: Mutations.updateCampaign,
            variables: { id, input },
        });

        return result.updateCampaign;
    }

    public static async deleteCampaign(id: number): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.deleteCampaign, AdminGqlTypes.deleteCampaignVariables>({
            mutation: Mutations.deleteCampaign,
            variables: { id },
        });
    }

    public static async deleteProductVariant(id: number): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.deleteProductVariant, AdminGqlTypes.deleteProductVariantVariables>({
            mutation: Mutations.deleteProductVariant,
            variables: { id },
        });
    }

    public static async replaceCardToVirtual(user_id: number): Promise<string | undefined> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.replaceCardToVirtual, AdminGqlTypes.replaceCardToVirtualVariables>({
            mutation: Mutations.replaceCardToVirtual,
            variables: { user_id },
        });
        return result.replaceCardToVirtual?.card_number;
    }

    public static async listSubscriptions(variables: AdminGqlTypes.listSubscriptionsVariables): Promise<AdminGqlTypes.listSubscriptions_listOrderSubscriptions> {
        const result = await GraphQLClient.query<AdminGqlTypes.listSubscriptions, AdminGqlTypes.listSubscriptionsVariables>({
            query: Queries.listSubscriptions,
            variables,
        });
        return result.listOrderSubscriptions!;
    }

    public static async getSubscriptionById(id: number): Promise<AdminGqlTypes.Subscription> {
        const result = await GraphQLClient.query<AdminGqlTypes.getSubscriptionById, AdminGqlTypes.getSubscriptionByIdVariables>({
            query: Queries.getSubscriptionById,
            variables: { id },
        });

        if (!result.orderSubscription) {
            throw new ApiError(I18n.formatMessage({ id: "api.error.notFound" }));
        }
        return result.orderSubscription;
    }

    public static async createGiftCardOrderPrize(input: AdminGqlTypes.GiftCardOrderPrizeInput): Promise<number> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.createGiftCardOrderPrize, AdminGqlTypes.createGiftCardOrderPrizeVariables>({
            mutation: Mutations.createGiftCardOrderPrize,
            variables: { input },
        });

        return result.createGiftCardOrderPrize.id;
    }

    public static async createGiftCardOrderCompany(input: AdminGqlTypes.GiftCardOrderCompanyInput): Promise<number> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.createGiftCardOrderCompany, AdminGqlTypes.createGiftCardOrderCompanyVariables>({
            mutation: Mutations.createGiftCardOrderCompany,
            variables: { input },
        });

        return result.createGiftCardOrderCompany.id;
    }

    public static async getGiftCardDesigns(): Promise<AdminGqlTypes.GiftCardDesign[]> {
        const result = await GraphQLClient.query<AdminGqlTypes.getGiftCardDesigns>({
            query: Queries.getGiftCardDesigns,
        });
        return result.giftCardDesign!;
    }

    public static async getGiftCardOrderTypes(): Promise<AdminGqlTypes.GiftCardOrderTypeWithTitle[]> {
        const result = await GraphQLClient.query<AdminGqlTypes.getGiftCardOrderTypes>({
            query: Queries.getGiftCardOrderTypes,
        });
        return result.giftCardOrderType!;
    }

    public static async resendShipment(increment_id: number): Promise<AdminGqlTypes.Order> {
        const result = await GraphQLClient.mutate<AdminGqlTypes.resendShipment, AdminGqlTypes.resendShipmentVariables>({
            mutation: Mutations.resendShipment,
            variables: { increment_id },
        });

        return result.resendShipment;
    }

    public static async resendGiftCard(id: number, email: string): Promise<void> {
        await GraphQLClient.mutate<AdminGqlTypes.resendGiftCard, AdminGqlTypes.resendGiftCardVariables>({
            mutation: Mutations.resendGiftCard,
            variables: { id, email },
        });
    }
}
